import React, { useEffect, useState, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Container,
  HeaderBar,
  DataGridTable,
  Blade,
  Tabs,
  SubmitDialog,
  Toast as toast,
} from 'components'
import { InputFormField } from 'components/hookForm'
import { agentManagementStatus } from 'constants/status'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import styles from './agentsList.module.scss'
import strings from 'strings'
import { emailValidator } from 'tools/validators'
import { createAgent, getAgentList, deleteAgent, editAgent, getCarriersForAgent } from 'api'
import { actionList, agentsListTableColumns } from './agentsListTableData'
import CarriersForAgents from './carriersForAgents'
import { reportException } from 'tools/logs'

const t = strings.AGENT_MANAGEMENT

const toastType: ObjectAnyKeys = {
  reject: 'error',
  hold: 'warning',
  deposit: 'success',
}

interface IAgentManagementListProps { }

interface IBladeContentProps { }

const AgentManagementList: React.FunctionComponent<IAgentManagementListProps> =
  () => {
    const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
    const [isEditForm, setIsEditForm] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [agentListData, setAgentListData] = useState([])
    const [selectedAgent, setSelectedAgent] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState<any>(null)
    const [motorCarriersForAgents, setMotorCarriersForAgents] = useState([])
    const [showMotorCarriersForAgents, setShowMotorCarriersForAgents] = useState(false)
    const [isAssignedCarriers, setIsAssignedCarriers] = useState(false)
    const [carriersLoading, setCarriersLoading] = useState(false)
    const [currentAgent, setCurrentAgent] = useState({})

    const methods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const callAgentList = useCallback(() => {
      setLoading(true)
      getAgentList()
        .then((response: any) => {
          const flatData = response.data.flatMap((agency: any) =>
            agency.agents.map((agent: any) => ({
              ...agent,
              agencyDomain: agency.agencyId
            }))
          )
          setAgentListData(flatData)
        })
        .catch((error: any) => {
          reportException(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }, [])

    const replaceNulls = (data: any): any => {
      if (data === null || data === '') return "-";
      if (Array.isArray(data)) return data.map(replaceNulls);
      if (typeof data === 'object' && data !== null) {
        return Object.keys(data).reduce((acc, key) => {
          acc[key] = replaceNulls(data[key]);
          return acc;
        }, {} as any);
      }
      return data;
    };

    const callCarriersForAgent = useCallback((domain: string, agentId: string, status: string) => {
      setCarriersLoading(true)
      getCarriersForAgent(domain, agentId, status)
        .then((response: any) => {
          const transformedCarrierList = replaceNulls(response?.carrierList)
          setMotorCarriersForAgents(transformedCarrierList)
          setCurrentAgent({
            agentId: agentId,
            domain: domain
          })
        })
        .catch((error: any) => {
          reportException(error)
        })
        .finally(() => {
          setCarriersLoading(false)
        })
    }, [])

    useEffect(() => {
      callAgentList()
    }, [])

    const handleClose = () => {
      setShowTemplateForm(false)
      setShowMotorCarriersForAgents(false)
      setIsAssignedCarriers(false)
      reset({})
    }

    const handleActions = (_id: number | string, actionName: string) => {
      setSelectedAgent(_id.toString())
      const templateFormData = agentListData.filter(
        (item: any) => item.agentId === _id
      )
      setSelectedRowData(templateFormData[0])

      if (actionName?.toLowerCase() === 'edit') {
        setIsEditForm(true)
        reset(templateFormData[0])
        setShowTemplateForm(true)
      } else {
        setShowDeleteModal(true)
      }
    }

    const handleAgentDelete = () => {
      setBtnLoader(true)
      deleteAgent(selectedRowData?.agencyDomain, selectedRowData?.agentId)
        .then((res: any) => {
          toast({
            id: "deleteAgentToast",
            subTitle: res?.description ?? 'Agent deleted successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          setShowDeleteModal(false)
          callAgentList()
        })
    }

    const handleCheckboxSelection = (selections: any) => {
      console.log('checkbox', selections)
    }

    const handleSelectionActions = (
      actionName: string,
      selectionModel: any
    ) => {
      toast({
        id: "updatedRowsAgentManagement",
        type: toastType[actionName],
        title: actionName,
        subTitle: `${selectionModel?.length} rows updated`,
      })
    }

    const handleCarriersForAgent = ((domain: string, agentId: string, status: string) => {
      setShowMotorCarriersForAgents(true)
      if (status === 'assigned') {
        setIsAssignedCarriers(true)
      }
      callCarriersForAgent(domain, agentId, status)
    })

    const getDomainFromEmail = (email: string) => {
      const emailParts = email.split('@');
      if (emailParts.length !== 2) {
        console.error('Invalid email address');
      }
      return emailParts[1];
    }

    const onSubmit = (data: any) => {
      setBtnLoader(true)
      if (isEditForm) {
        const payLoad = {
          firstName: data?.firstName,
          lastName: data?.lastName
        }
        const domain = data?.agencyDomain
        editAgent(payLoad, domain, data?.agentId)
          .then((res: any) => {
            toast({
              id: "editAgentToast",
              subTitle: res?.description ?? 'Agent was modified successfully',
            })
          })
          .finally(() => {
            setBtnLoader(false)
            setShowTemplateForm(false)
            callAgentList()
          })
      } else {
        const payLoad = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.emailId,
        }
        const domain = getDomainFromEmail(payLoad?.email);
        createAgent(payLoad, domain)
          .then((res: any) => {
            toast({
              id: "createAgentToast",
              subTitle: res?.description ?? 'Agent created successfully',
            })
          })
          .finally(() => {
            setBtnLoader(false)
            setShowTemplateForm(false)
            callAgentList()
          })
      }
    }

    const BladeContent = (_props: IBladeContentProps) => {
      return (
        <Container className={styles.bladeBodyContainer}>
          <FormProvider {...methods}>
            <InputFormField
              id="firstName"
              name="firstName"
              label={strings.FIRST_NAME}
              maxLength={128}
              required
            />
            <InputFormField
              id={"lastName"}
              name="lastName"
              label={strings.LAST_NAME}
              maxLength={128}
              required
            />
            {!isEditForm &&
              <InputFormField
                id="emailId"
                name="emailId"
                label={strings.EMAIL}
                maxLength={128}
                validate={emailValidator}
                required
              />}
          </FormProvider>
        </Container>
      )
    }

    return (
      <Container className={styles.agentListContainer}>
        <HeaderBar
          title={t.AGENT_MANAGEMENT}
          primaryBtnProps={{
            label: t.CREATE_AGENT,
            onClick: () => {
              reset({})
              setIsEditForm(false)
              setShowTemplateForm(true)
            },
          }}
        />
        <Tabs
          tabData={agentManagementStatus}
          value={tabValue}
          id={agentManagementStatus[tabValue].label}
          handleChange={(_e, val) => setTabValue(val)}
          sxContainer={{ marginLeft: '1rem' }}
        />
        <DataGridTable
          title={t.AGENTS}
          loading={loading}
          data={agentListData || []}
          columns={agentsListTableColumns(handleCarriersForAgent).columns}
          columnVisibilityModel={{ agentId: false }}
          checkboxSelection={false}
          totalCount={agentListData?.length}
          reduceHeight={280}
          actionList={actionList}
          multiSearchFilter={false}
          getRowId={(row: any) => row?.agentId}
          handleActions={handleActions}
          handleCheckboxSelection={handleCheckboxSelection}
          handleSelectionActions={handleSelectionActions}
        />
        <Blade
          open={showTemplateForm}
          title={isEditForm ? t.EDIT_AGENT : t.CREATE_AGENT}
          onClose={handleClose}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
              id: "createOrUpdateAgentBladeConfirmation",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit(onSubmit),
              loading: btnLoader,
              disabled: btnLoader,
            },
            secondary: {
              id: "closeAgentCreationOrUpdateBlade",
              label: 'Close',
              variant: 'outlined',
              onClick: handleClose,
            },
          }}
        />
        <CarriersForAgents
          open={showMotorCarriersForAgents}
          currentAgent={currentAgent}
          motorCarriersForAgents={motorCarriersForAgents}
          isAssignedCarriers={isAssignedCarriers}
          loading={carriersLoading}
          onClose={handleClose}
        />
        <SubmitDialog
          cancelId="cancelDeleteAgent"
          type="delete"
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title="Delete Confirmation"
          body1={`Are you sure you want to delete this agent called: ${selectedRowData?.firstName} ${selectedRowData?.lastName}?`}
          body2="Once deleted, it will be no longer available"
          primaryBtnProps={{
            id: "confirmDelete",
            label: 'Yes, I confirm',
            loading: btnLoader,
            onClick: handleAgentDelete,
            disabled: btnLoader
          }}
        />
      </Container>
    )
  }

export default AgentManagementList
