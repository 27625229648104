import { rbacActions, rbacDomains, rbacElements } from "rbac/sections"
import { validateAccessPrivilege } from "rbac/tools"

const reportingAccessControl = () => {
    const domainName = rbacDomains.REPORTING

    const canViewMenu = () => {
        const elementName = rbacElements.MENU_ITEM
        const actionName = rbacActions.VIEW
        const hasPrivilege = validateAccessPrivilege(domainName, elementName, actionName)

        return hasPrivilege
    }

    return {
        canViewMenu,
    }
}

export default reportingAccessControl