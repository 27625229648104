import { useEffect, useState } from 'react'
import { getAgentMyCarrierList, getMyCarrierList } from 'api'
import {
  Container,
  DataGridTable,
  Blade,
  Icon,
  TypoGraph,
  Chip,
  Toast as toast,
} from 'components'
import { getCarrierTableData } from 'tools/tableDataHelper'
import { useNavigate } from 'react-router'
import styles from './myCarriers.module.scss'
import { isAgentLogin } from 'tools/auth'
import { FormProvider, useForm } from 'react-hook-form'
import strings from 'strings'
import { editCarrier } from 'api'
import { copyToClipboard, getCodeTypeValue } from 'tools/helper'
import { InputFormField } from 'components/hookForm'
import { emailValidator } from 'tools/validators'
import { actionList, myCarrierList } from './myCarriersTableData'
import { useGridApiRef } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'

const t = strings.CARRIERS

interface IMyCarrierProps {}

interface IEditCarrierPayload {
  policyId?: string;
  dot?: string;
  carrierName?: string;
  cuid?: string;
}

const MyCarrier: React.FC<IMyCarrierProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
  const [isEditForm, setIsEditForm] = useState(false)
  const [showCarrierDetails, setShowCarrierDetails] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [myCarrierData, setMyCarrierData] = useState([])
  const [companyHasCUID, setcompanyHasCUID] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<any>(null)
  const [selectedCarrier, setSelectedCarrier] = useState<string>('')
  const [initialFormValues, setInitialFormValues] = useState<any>({});
  const navigate = useNavigate()
  const apiRef = useGridApiRef()

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const companyProfileData = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const { handleSubmit, reset } = methods

  const { myCarrierColumn } = myCarrierList

  const isInsuranceCompany = localStorage.getItem('isInsurance') ? (localStorage.getItem('isInsurance') === 'true') : false

  useEffect(() => {
    callCarriersList()
  }, [])

  useEffect(() => {
    if (companyProfileData) {
      setcompanyHasCUID(companyProfileData 
        && companyProfileData.carrierUniqueIds 
        && companyProfileData.carrierUniqueIds.length > 0)
    }
  }, [companyProfileData])

  const callCarriersList = () => {
    setLoading(true)
    if (isAgentLogin()) {
      getAgentMyCarrierList()
        .then((res: any) => {
          const data = getCarrierTableData(res.carrierList)
          setMyCarrierData(data)
        })
        .finally(() => setLoading(false))
    } else {
      getMyCarrierList()
        .then((res: any) => {
          const data = getCarrierTableData(res)
          setMyCarrierData(data)
        })
        .finally(() => setLoading(false))
    }
  }

  myCarrierColumn[1].handleClick = (row: any) => {
    if (isInsuranceCompany || isAgentLogin()) {
      if (row.dot !== "" && row.dot !== "-") {
        localStorage.setItem('carrierCodeType', 'DOT')
        localStorage.setItem('carrierCodeValue', row.dot)
      }
      else if (row.mc !== "" && row.mc !== "-") {
        localStorage.setItem('carrierCodeType', 'MC')
        localStorage.setItem('carrierCodeValue', row.mc)
      }
      else if (row.uuid !== "" && row.uuid !== "-") {
        localStorage.setItem('carrierCodeType', 'UUID')
        localStorage.setItem('carrierCodeValue', row.uuid)
      }
      else {
        localStorage.removeItem('carrierCodeType')
        localStorage.removeItem('carrierCodeValue')
      }
      localStorage.setItem('eldProviderRisk', row.eldVendorDisplayName)
      localStorage.setItem('carrierName', row.carrierName)
      localStorage.setItem('carrierId', row.carrierId)
      navigate('details')
    }
    else {
      setSelectedRow(row)
      setShowCarrierDetails(true)
    }
  }

  myCarrierColumn[4].handleClick = (row: any) => {
    copyToClipboard(row.email)
  }

  const handleActions = (_id: number | string, actionName: string) => {
    setSelectedCarrier(_id.toString())
    const templateFormData = myCarrierData.filter(
      (item: any) => {
        const splittedId = _id.toString().split('|')
        const id = splittedId[0]
        const eld = splittedId[1]
        return item.carrierId == id && item.eldVendorDisplayName == eld
      }
    )
    setSelectedRowData(templateFormData[0])

    if (actionName?.toLowerCase() === 'edit') {
      setIsEditForm(true)
      reset(templateFormData[0])
      setInitialFormValues(templateFormData[0])
      setShowTemplateForm(true)
    }
  }

  const handleClose = () => {
    setShowTemplateForm(false)
    reset({})
  }

  const handleExport = () => {
    const csvString = apiRef.current.getDataAsCsv()
    const modifiedCsv = csvString
      .split('\n')
      .map((row) => row.split(',').slice(0, -1).join(','))
      .join('\n')
    const blob = new Blob([modifiedCsv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'MyCarriersExport.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  const onSubmit = (data: any) => {
    setBtnLoader(true)
    const emptyValue = '-'
    if (isEditForm) {
      const payLoad : IEditCarrierPayload = {
        policyId: data?.policyId && data?.policyId !== '-' ? data?.policyId : null,
        carrierName: data?.carrierName && data?.carrierName !== '-' ? data?.carrierName : null,
        dot: data?.dot && data?.dot !== '-' ? data?.dot : null,
        cuid: data?.cuid && data?.cuid !== '-' ? data?.cuid : null,
      }
      var codeType = ""
      var codeValue = ""
      if (initialFormValues?.dot && initialFormValues?.dot !== emptyValue) {
        codeType = "DOT"
        codeValue = initialFormValues?.dot
      } else if (initialFormValues?.mc && initialFormValues?.mc !== emptyValue) {
        codeType = "MC"
        codeValue = initialFormValues?.mc
      } else if (initialFormValues?.scac && initialFormValues?.scac !== emptyValue) {
        codeType = "SCAC"
        codeValue = initialFormValues?.scac
      } else if (initialFormValues?.carrierId && initialFormValues?.carrierId !== emptyValue) {
        codeType = "TCID"
        codeValue = initialFormValues?.carrierId
      } else if (initialFormValues?.cuid && initialFormValues?.cuid !== emptyValue) {
        codeType = "CUID"
        codeValue = initialFormValues?.cuid
      }
      editCarrier(payLoad, codeType, codeValue)
        .then((res: any) => {
          toast({
            id: "editCarrierToast",
            subTitle: res?.description ?? 'Carrier was modified successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          callCarriersList()
        })
    }
  }

  const loadPagination = (pageNumber: number, pageSize: number) => {
    console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
  }

  const tableMenuItems = [
    {
      label: 'Download as CSV',
      key: 'export',
      action: () => {
        handleExport()
      },
    },
  ]

  const EldStatusComponent = (props: any) => {
    const { enabled, iconName, title } = props
    return (
      <Container className={styles.badgeContainer}>
        <Icon
          name={iconName}
          size={30}
          boxClassName={enabled ? '' : 'myCarrierInactiveIcon'}
        />
        <TypoGraph content={title} />
        <Chip
          label={enabled ? 'Verified' : 'Not Verified'}
          variant="filled"
          sx={{
            backgroundColor: !enabled ? '#F5F7FA' : '#E1FAEB',
            color: !enabled ? '#707A8B' : '#20693D',
          }}
        />
      </Container>
    )
  }

  const BladeContent = (_props: any) => {
    return (
      <Container className={styles.bladeBodyContainer}>
        <FormProvider {...methods}>
            <InputFormField
              id="carrierName"
              name="carrierName"
              label={strings.CARRIERS.CARRIER_NAME}
              maxLength={128}
              disabled={!isEditForm}
              required={!!initialFormValues.carrierName && initialFormValues.carrierName !== '-'}
            />
            <InputFormField
              id="firstName"
              name="firstName"
              label={strings.FIRST_NAME}
              maxLength={128}
              disabled={true}
            />
            <InputFormField
              id={"lastName"}
              name="lastName"
              label={strings.LAST_NAME}
              maxLength={128}
              disabled={true}
            />
            <InputFormField
              id="email"
              name="email"
              label={strings.EMAIL}
              maxLength={128}
              disabled={true}
              validate={emailValidator}
              required
            />
            <InputFormField
              id="dot"
              name="dot"
              label={strings.CARRIERS.DOT}
              maxLength={10}
              disabled={!isEditForm}
              required={!!initialFormValues.dot && initialFormValues.dot !== '-'}
            />
            <InputFormField
              id="mc"
              name="mc"
              label={strings.CARRIERS.MC}
              maxLength={20}
              disabled={true}
              required
            />
            <InputFormField
              id="scac"
              name="scac"
              label={strings.CARRIERS.SCAC}
              maxLength={10}
              disabled={true}
              required
            />
            <InputFormField
              id="cuid"
              name="cuid"
              label={strings.CARRIERS.CUID}
              maxLength={50}
              disabled={!isEditForm}
              required={companyHasCUID && !!initialFormValues.cuid && initialFormValues.cuid !== '-'}
            />
            {isEditForm &&
            <InputFormField
              id={"policyId"}
              name="policyId"
              label={strings.CARRIERS.POLICY_ID}
              maxLength={30}
              required={!!initialFormValues.policyId && initialFormValues.policyId !== '-'}
            />}
          </FormProvider>
      </Container>
    )
  }

  return (
    <Container>
      <DataGridTable
        apiRef={apiRef}
          id={"carriersTable"}
        title={'My Carriers'}
        loading={loading}
        data={myCarrierData || []}
        columns={myCarrierColumn}
        columnVisibilityModel={{carrierId: false}}
        totalCount={myCarrierData?.length}
        {
          ...(isInsuranceCompany && {
            actionList: actionList,
            handleActions: handleActions
          })
        }
        reduceHeight={250}
        checkboxSelection={false}
        multiSearchFilter={false}
        menuList={tableMenuItems}
        getRowId={(row: any) => row?.carrierId + '|' + row?.eldVendorDisplayName}
        handlePagination={(pageNumber, pageSize) =>
          loadPagination(pageNumber, pageSize)
        }
      />
      {!isInsuranceCompany && 
      <Blade
        title={selectedRow?.carrierName}
        open={showCarrierDetails}
        onClose={() => setShowCarrierDetails(false)}
        bodyNode={<BladeContent />}
      />}
      {isInsuranceCompany &&
      <Blade
          open={showTemplateForm && isEditForm}
          title={isEditForm ? t.EDIT_CARRIER : ""}
          onClose={handleClose}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
              id: "createOrUpdateAgentBladeConfirmation",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit(onSubmit),
              loading: btnLoader,
              disabled: btnLoader,
            },
            secondary: {
              id: "closeAgentCreationOrUpdateBlade",
              label: 'Close',
              variant: 'outlined',
              onClick: handleClose,
            },
          }}
        />}
    </Container>
  )
}

export default MyCarrier
