import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'
import {
  getEmailTemplateList,
  getEmailTemplateTypes,
  getCarrierAccountList,
  updateEmailTemplate,
  createEmailTemplate,
  getEmailTemplateById,
} from 'api'
import {
  Container,
  HeaderBar,
  DataGridTable,
  Blade,
  Toast as toast,
} from 'components'
import { DropdownFormField, InputFormField } from 'components/hookForm'
import { emailTemplateList } from 'constants/tableData'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import styles from './emailTemplateList.module.scss'
import strings from 'strings'

const formatTemplateTypeOptions = (data: any) => {
  return data?.map((item: any) => ({
    value: item?.carrierEmailTemplateTypeId,
    label: item?.carrierEmailTemplateTypeName,
  }))
}

const formatEmailAccountOptions = (data: any) => {
  return data?.map((item: any) => ({
    value: item?.carrierEmailAccountSettingId,
    label: item?.senderEmail,
  }))
}

const t = strings.EMAIL_TEMPLATES

const toastType: ObjectAnyKeys = {
  reject: 'error',
  hold: 'warning',
  deposit: 'success',
}

interface IEmailTemplateListProps {}

interface IBladeContentProps {}

const EmailTemplateList: React.FunctionComponent<IEmailTemplateListProps> =
  () => {
    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
    const [isEditForm, setIsEditForm] = useState(false)
    const [emailTemplateListData, setEmailTemplateListData] = useState([])
    const [emailTemplateContent, setEmailTemplateContent] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState({
      templateTypeOptions: [],
      emailAccountOptions: [],
    })

    const editorRef = useRef<any>(null)

    const { emailTemplateColumn, actionList } = emailTemplateList

    const methods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const handleTemplateTypeChange = (e: any) => {
      const id = e.target.value
      getEmailTemplateById(id).then((res: any) => {
        setEmailTemplateContent(res?.defaultContent)
      })
    }

    const loadEmailTemplatePage = useCallback(async () => {
      try {
        setLoading(true)
        callEmailTemplateList()
        const templateTypes: any = await getEmailTemplateTypes()
        const emailAccounts: any = await getCarrierAccountList()
        setDropdownOptions({
          templateTypeOptions: formatTemplateTypeOptions(templateTypes?.data),
          emailAccountOptions: formatEmailAccountOptions(emailAccounts),
        })
      } finally {
        setLoading(false)
      }
    }, [])

    const callEmailTemplateList = () => {
      setLoading(true)
      getEmailTemplateList()
        .then((res: any) => setEmailTemplateListData(res?.data))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
      loadEmailTemplatePage()
    }, [loadEmailTemplatePage])

    const handleActions = (_id: number | string, actionName: string) => {
      if (actionName === 'edit') {
        const templateFormData: any = emailTemplateListData.filter(
          (item: any) => item.carrierEmailTemplateId === _id
        )[0]
        setIsEditForm(true)
        const formData = {
          emailTemplateName: templateFormData?.emailTemplateName,
          carrierEmailTemplateTypeId:
            templateFormData?.carrierEmailTemplateType
              ?.carrierEmailTemplateTypeId,
          carrierEmailAccountSettingId:
            templateFormData?.carrierEmailAccountSetting
              ?.carrierEmailAccountSettingId,
          templateId: templateFormData?.carrierEmailTemplateId,
        }
        setEmailTemplateContent(templateFormData?.emailTemplateContent)
        reset(formData)
        setShowTemplateForm(true)
      } else {
        toast({
            id:"updatedEmailTemplate",
          type: toastType[actionName],
          title: actionName,
          subTitle: 'Updated Successfully',
        })
      }
    }

    const handleCheckboxSelection = (selections: any) => {
      console.log('checkbox', selections)
    }

    const handleSelectionActions = (
      actionName: string,
      selectionModel: any
    ) => {
      toast({
          id:"updatedRowsEmailTemplate",
        type: toastType[actionName],
        title: actionName,
        subTitle: `${selectionModel?.length} rows updated`,
      })
    }

    const loadPayments = (pageNumber: number, pageSize: number) => {
      console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
    }

    const onSubmit = (data: any) => {
      if (editorRef.current) {
        setEmailTemplateContent(editorRef.current.getContent())
        setBtnLoader(true)
        if (isEditForm) {
          updateEmailTemplate(data, editorRef.current.getContent())
            .then((res: any) => {
              toast({ id:"updateEmailTemplateToast",title: 'Updated Successfully', subTitle: res?.message })
              setShowTemplateForm(false)
              callEmailTemplateList()
            })
            .finally(() => setBtnLoader(false))
        } else {
          createEmailTemplate(data, editorRef.current.getContent())
            .then((res: any) => {
              toast({ id:"createEmailTempalteToast",title: 'Created Successfully', subTitle: res?.message })
              setShowTemplateForm(false)
              callEmailTemplateList()
            })
            .finally(() => setBtnLoader(false))
        }
      }
    }

    const BladeContent = (_props: IBladeContentProps) => {
      return (
        <Container className={styles.bladeBodyContainer}>
          <FormProvider {...methods}>
            <InputFormField
                id={"emailTemplateName"}
              name="emailTemplateName"
              label={t.TEMPLATE_NAME}
              maxLength={128}
              required
            />
            <DropdownFormField
                id={"carrierEmailTemplateTypeId"}
              name="carrierEmailTemplateTypeId"
              label={t.TEMPLATE_TYPE}
              options={dropdownOptions?.templateTypeOptions}
              onFieldChange={handleTemplateTypeChange}
              required
            />
            <DropdownFormField
                id={"carrierEmailAccountSettingId"}
              name="carrierEmailAccountSettingId"
              label={t.EMAIL_ACCOUNTS}
              options={dropdownOptions?.emailAccountOptions}
              required
            />
            <Editor
                id={"emailTemplateTextEditor"}
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey={process.env.REACT_APP_TEXT_EDITOR_API_KEY}
              initialValue={emailTemplateContent}
            />
          </FormProvider>
        </Container>
      )
    }

    return (
      <Container className={styles.emailTemplateContainer}>
        <HeaderBar
          title={t.EMAIL_TEMPLATES}
          primaryBtnProps={{
            label: t.NEW_TEMPLATE,
            onClick: () => {
              reset({})
              setIsEditForm(false)
              setShowTemplateForm(true)
            },
          }}
        />
        <DataGridTable
          title={t.EMAIL_TEMPLATES}
          data={emailTemplateListData || []}
          loading={loading}
          columns={emailTemplateColumn}
          columnVisibilityModel={{carrierEmailTemplateId: false}}
          checkboxSelection={false}
          totalCount={emailTemplateListData?.length}
          reduceHeight={240}
          actionList={actionList}
          multiSearchFilter={false}
          getRowId={(row) => row?.carrierEmailTemplateId}
          handleActions={handleActions}
          handleCheckboxSelection={handleCheckboxSelection}
          handleSelectionActions={handleSelectionActions}
          handlePagination={(pageNumber, pageSize) =>
            loadPayments(pageNumber, pageSize)
          }
        />
        <Blade
          open={showTemplateForm}
          title={isEditForm ? t.EDIT_TEMPLATE : t.CREATE_TEMPLATE}
          onClose={() => {
            setShowTemplateForm(false)
            setEmailTemplateContent('')
          }}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
                id:"updateOrCreateEmailTemplate",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit((data) => onSubmit(data)),
              loading: btnLoader,
            },
            secondary: {
                id:"closeCreateOrEditEmailTemplate",
              label: 'Close',
              variant: 'outlined',
              onClick: () => setShowTemplateForm(false),
            },
          }}
        />
      </Container>
    )
  }

export default EmailTemplateList
