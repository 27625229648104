import { Container } from '@mui/material';
import './spinner.scss';

const Spinner = () => {
  return (
    <Container sx={{padding:'2rem', marginLeft:'auto', marginRight: 'auto'}}>
      <div className="spinner"></div>
    </Container>
  );
};

export default Spinner;