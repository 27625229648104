import strings from 'strings'

export function passwordStrengthValidator(password: string) {
  const regularExpression = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$]).{8,}$"
  )
  if (!regularExpression.test(password)) {
    return strings.PASSWORD_ERROR_FORMAT
  }
}

export function emailValidator(email: string) {
  const regularExpression = new RegExp(
    '^[a-zA-Z0-9._%+-/]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'
  )
  if (!regularExpression.test(email)) {
    return strings.EMAIL_ERROR_FORMAT
  }
}