import MuiDivider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'

interface IDividerProps {
  title?: string
  textAlign?: 'center' | 'left' | 'right'
  variant?: 'fullWidth' | 'inset' | 'middle' | undefined
  orientation?: 'horizontal' | 'vertical'
  sx?: object
  rootSx?: object
}

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.h3,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const Divider: React.FC<IDividerProps> = (props) => {
  const {
    title,
    textAlign = 'left',
    variant = 'fullWidth',
    orientation = 'horizontal',
    sx,
    rootSx,
  } = props
  return (
    <Root sx={rootSx}>
      <MuiDivider
        sx={sx}
        textAlign={textAlign}
        variant={variant}
        orientation={orientation}
      >
        {title ? title : ''}
      </MuiDivider>
    </Root>
  )
}

export default Divider
