import request from 'tools/request'

export const getEmailTemplateList = async () => {
  const companyId = localStorage.getItem('companyId')
  const res = await request.get('emailtemplateresource/list', {
    params: {
      companyId: companyId,
      templateTypeId: '',
    },
  })
  return res?.data
}

export const getNewUserEmailTemplateList = async () => {
  const companyId = localStorage.getItem('companyId')
  const res = await request.get('emailtemplateresource/templatetype/newuser', {
    params: {
      companyId: companyId,
    },
  })
  return res?.data
}

export const getEmailTemplateById = async (templateTypeId: any) => {
  if (!templateTypeId) return
  const res: any = await request.get('emailtemplatetyperesource/get', {
    params: {
      templateTypeId,
    },
  })
  return res?.data?.data[0]
}

export const getEmailTemplateTypes = async () => {
  const res = await request.post('emailtemplatetyperesource/list')
  return res?.data
}

export const updateEmailTemplate = async (
  formPayload: any,
  emailTemplateContent: string
) => {
  const companyId = localStorage.getItem('companyId')
  const payLoad = {
    ...formPayload,
    companyId,
    emailTemplateContent,
  }
  const res = await request.post('emailtemplateresource/update', payLoad)
  return res?.data
}

export const createEmailTemplate = async (
  formPayload: any,
  emailTemplateContent: string
) => {
  const companyId = localStorage.getItem('companyId')
  const payLoad = {
    ...formPayload,
    templateId: '',
    companyId,
    emailTemplateContent,
  }
  const res = await request.post('emailtemplateresource/add', payLoad)
  return res?.data
}
