import React, { useEffect, useState, useCallback } from 'react';
import {
    Container,
    Blade,
    DataGridTable,
    Toast as toast
} from 'components';
import { carrierListTableColumns } from '../agentsListTableData';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import {
    massiveCarrierAssign,
    massiveCarrierUnassign,
    carrierAssign,
    carrierUnassign
} from 'api';
import styles from '../agentsList.module.scss';
import strings from 'strings';
import { reportException } from 'tools/logs';

const t = strings.AGENT_MANAGEMENT;

interface ICarriersForAgentsProps {
    open: boolean;
    currentAgent: any;
    motorCarriersForAgents: any;
    isAssignedCarriers: boolean;
    loading: boolean;
    onClose: () => void;
}

const CarriersForAgents: React.FC<ICarriersForAgentsProps> = (props: ICarriersForAgentsProps) => {
    const { open, currentAgent, motorCarriersForAgents, isAssignedCarriers, loading, onClose } = props;
    const [btnLoader, setBtnLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(true);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        setDisableBtn(selectedRows.length === 0);
    }, [selectedRows.length]);

    const handleClose = () => {
        setSelectedRows([]);
        onClose();
    };

    const handleCheckboxSelection = useCallback((selections: GridRowSelectionModel) => {
        setSelectedRows(selections);
    }, []);

    const handleAssignUnassign = useCallback((action: any, domain: string, agentId: any, payload: any) => {
        setBtnLoader(true);
        action(domain, agentId, payload)
            .then((response: any) => toast({ id: `handleCarrierAgentToast`, title: response?.message }))
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setBtnLoader(false);
                handleClose();
            });
    }, []);

    const onSubmit = () => {
        const { domain, agentId } = currentAgent;
        if (selectedRows.length === 0) {
            console.error('No rows selected');
            return;
        }

        if (isAssignedCarriers) {
            selectedRows.length === 1
                ? handleAssignUnassign(carrierUnassign, domain, agentId, selectedRows[0])
                : handleAssignUnassign(massiveCarrierUnassign, domain, agentId, selectedRows);
        } else {
            selectedRows.length === 1
                ? handleAssignUnassign(carrierAssign, domain, agentId, selectedRows[0])
                : handleAssignUnassign(massiveCarrierAssign, domain, agentId, selectedRows);
        }
    };

    const MotorCarriersBlade = () => (
        <Container className={styles.bladeBodyContainer}>
            <Container className={styles.resultsContainer} sx={{ mt: 1 }}>
                <DataGridTable
                    loading={loading}
                    data={motorCarriersForAgents || []}
                    columns={carrierListTableColumns.columns}
                    columnVisibilityModel={{ carrierId: false }}
                    getRowId={(row: any) => row?.carrierId}
                    showFilter={true}
                    multiSearchFilter={false}
                    showTitle={false}
                    checkboxSelection={true}
                    reduceHeight={210}
                    handleCheckboxSelection={handleCheckboxSelection}
                    customSelectionModel={selectedRows}
                />
            </Container>
        </Container>
    );

    return (
        <Blade
            open={open}
            title={isAssignedCarriers ? t.ASSIGNED_CARRIERS : t.AVAILABLE_CARRIERS}
            onClose={handleClose}
            bodyNode={<MotorCarriersBlade />}
            size={'lg'}
            footerBtns={{
                primary: {
                    id: "carriersForAgentsBlade",
                    label: isAssignedCarriers ? t.UNASSIGN : t.ASSIGN,
                    onClick: onSubmit,
                    loading: btnLoader,
                    disabled: btnLoader || disableBtn,
                },
                secondary: {
                    id: "closeCarriersForAgentsBlade",
                    label: 'Cancel',
                    variant: 'outlined',
                    onClick: handleClose
                },
            }}
        />
    );
};

export default CarriersForAgents;
