import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Blade, Button, Container, Divider, Toast as toast, } from "components"
import { InputFormField } from "components/hookForm"
import { FormProvider, useForm } from "react-hook-form"
import { useState } from "react"
import styles from './carrierInvitation.module.scss'
import strings from 'strings'
import { inviteAccount } from "api"
import { getChildCarrierParamsData } from 'tools/helper'
import regex from 'regex'
import { reportException } from "tools/logs"

const t = strings.CARRIERS_MANAGEMENT

interface IChildCarrierInvitationProps {
    open: boolean
    onClose: () => void
    onSuccessCallback: () => void
}

const ChildCarrierInvitation: React.FC<IChildCarrierInvitationProps> = (props) => {
    const { open, onClose, onSuccessCallback } = props
    const [loading, setLoading] = useState<boolean>(false)
    const [openConfirmationPrompt, setOpenConfirmationPrompt] = useState<boolean>(false)
    const [invitationLink, setInvitationLink] = useState<string | null>(null)

    const methods = useForm<any>({
        reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const handleDialogClose = () => {
        setOpenConfirmationPrompt(false)
    }

    const handleGenerateInvitation = (formData: any) => {
        setOpenConfirmationPrompt(false)
        setLoading(true)
        const params = getChildCarrierParamsData(formData)
        const payLoad = {
            "contactInfo": {
                "email": params['email'],
                "firstName": params['firstName'],
                "lastName": params['lastName']
            },
            "carrierInfo": {
                "carrierName": params['carrierName']
            }
        }
        inviteAccount(payLoad)
            .then((res: any) => {
                toast({ id:"carrierToast", title: res?.message })
                setInvitationLink(res?.invitationUrl)
            })
            .catch((error: any) => {
                reportException(error)
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const copyToClipboard = async () => {
        try {
            const permissions = await navigator.permissions.query({name: "clipboard-write" as PermissionName})
            if (permissions.state === "granted" || permissions.state === "prompt") {
                if (invitationLink) {
                    await navigator.clipboard.writeText(invitationLink);
                    toast({ id:"urlCopied", title: "Invitation link copied!"})
                }
            } else {
                //throw new Error("Can't access the clipboard. Check your browser permissions.")
                toast({ id: "urlCopiedError", title: "Can't access the clipboard. Check your browser permissions.", type: 'error'})
            }
        } catch(error: any) {
            reportException(error)
            // console.log('Error copying to clipboard: ' + error);
            toast({ id: "clipboardError", title: "Error copying to clipboard: " + error, type: 'error'})
        }
    }

    const BladeContent = (props: any) => {
        return (
            <Container className={styles.bladeBodyContainer}>
                <Container className={styles.fieldsContainer}>
                    <FormProvider {...methods}>
                        <InputFormField id="carrierName" type="text" label="Name of the Account" name="carrierName" disabled={loading} required />
                        <InputFormField 
                            id="email" 
                            type="text" 
                            label="Email Address" 
                            name="email" 
                            disabled={loading} 
                            required
                            pattern={{
                                value: regex.EMAIL_REGEX_PATTERN,
                                message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                            }}
                        />
                        <InputFormField id="firstName" type="text" label="First Name" name="firstName" disabled={loading} required />
                        <InputFormField type="text" label="Last Name" name="lastName" disabled={loading} required />
                        <Button 
                            variant="contained"
                            size="large"
                            label="Generate link"
                            loading={loading}
                            onClick={() => !loading && setOpenConfirmationPrompt(true)} />
                        <Divider />
                        <InputFormField id="invitationLink" type="text" label="Invitation link" name="invitationLink" value={invitationLink || ""} disabled />
                        <Button variant="outlined" size="large" label="Copy Invitation Link" onClick={copyToClipboard} loading={loading} disabled={!invitationLink} />
                    </FormProvider>
                </Container>
                <Dialog
                    open={openConfirmationPrompt}
                    keepMounted
                    onClose={handleDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{t.CONFIRMATION_DIALOG_TITLE}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t.CONFIRMATION_DIALOG_MESSAGE}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} label={t.DISAGREE_BUTTON_TEXT} variant="outlined" />
                        <Button onClick={handleSubmit((data) => handleGenerateInvitation(data))} label={t.AGREE_BUTTON_TEXT} />
                    </DialogActions>
                </Dialog>
            </Container>
        )
    }

    return (
        <Container>
            <Blade
                title={t.INVITATION_TITLE}
                open={open}
                onClose={onClose}
                size={'sm'}
                bodyNode={<BladeContent />}
                footerBtns={null}
            />
        </Container>
    )
}

export default ChildCarrierInvitation