import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { onboardOauth } from 'api'
import { Container, Icon, TypoGraph } from 'components'
import {
  getCookiesValues,
} from 'pages/cob/partial/oauthHelper'
import { DeleteCookie } from 'tools/cookies'
import styles from './directCob.module.scss'
import { reportException } from 'tools/logs'

interface IDirectCobProps {}

const DirectCob: React.FC<IDirectCobProps> = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<any>({})
  const [apiCallFinished, setApiCallFinished] = useState(false)
  const [isDirectOnboarding, setIsDirectOnboarding] = useState(false)
  const [urlParams, setUrlParams] = useState<URLSearchParams>(new URLSearchParams(''))
  const search = useLocation().search

  const marketplaceOnboardingSeparator = '~'
  const cookies = getCookiesValues()
  const invitationKey: string = cookies?.invitationKey ?? ''
  let isValidCSRF: boolean = true
  let code: any = null
  let scope: any = null
  let state: any = null
  let identifier: any = null
  let identifierParts: any = null

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const hasValidUrlParams = (urlParams: any) => {
    return urlParams.get('identifier') && 
        urlParams.get('identifier').length > 0 && 
        urlParams.get('identifier').includes(marketplaceOnboardingSeparator) &&
        urlParams.get('code') &&
        urlParams.get('code').length > 0 &&
        (!urlParams.get('error') || 
        urlParams.get('error').length === 0);
  }

  const isValidRedirectCookie = (invitationToken: any) => {
    return invitationToken && 
        invitationToken.length > 0 && 
        invitationToken !== undefined && 
        invitationToken !== '';
  }

  const callOnboardingEndpoint = () => {
    console.log('Entering callOnboardingEndpoint')
    code = urlParams.get('code')
    identifier = urlParams.get('identifier')
    identifierParts = identifier.split(marketplaceOnboardingSeparator)
    const payLoad = {
      eldName: identifierParts[0],
      companyId: identifierParts[1],
      code: code,
    }

    return onboardOauth(payLoad)
            .then((res: any) => {
              console.log('----- SUCCESS! -----')
              setApiCallFinished(true);
              setLoading(false)
              setResponse({
                status: res.status,
                message: res.message,
              })
            })
            .catch((error: any) => {
              reportException(error)
              console.log('----- ERROR :( -----')
              setApiCallFinished(true);
              setLoading(false)
              setResponse({
                status: error.status,
                message: error.message,
              })
            })
  }

  useEffect(() => {
    if (search.length > 0) {
      setLoading(true);

      const url = (search.includes("?identifier=") === true && search.includes("?code=") === true) ? search.replace("?code=", "&code=") : search
      setUrlParams(new URLSearchParams(url))
    }
    else {
      console.log('No params found');
    }
  }, [search])

  useEffect(() => {
    if (urlParams !== null && urlParams !== undefined && urlParams.toString().length > 0) {
      if (hasValidUrlParams(urlParams) === true) {
        code = urlParams.get('code');
        scope = urlParams.get('scope') ?? '';
        state = urlParams.get('state') ?? '';

        scope = decodeURIComponent(scope)
        state = decodeURIComponent(state)

        if (state !== "" && state.length > 0 && cookies.csrf !== null && cookies.csrf !== undefined) {
          if (state !== cookies.csrf) {
            isValidCSRF = false;
            console.log('Invalid CSRF state');
          }
        }
        else {
          console.log('No CSRF validation needed')
        }

        identifier = urlParams.get('identifier');
        if (isValidCSRF) {
          if (isValidRedirectCookie(invitationKey) === true) {
            DeleteCookie('invitationkey', true);
            window.location.href = window.location.protocol + '//' + window.location.hostname + '/cob?invitationkey=' + invitationKey + '&code=' + code;
          }
          else {
            identifierParts = identifier.split(marketplaceOnboardingSeparator);
            setIsDirectOnboarding(true)
          }
        }
      }
      else {
        setLoading(false)
        setIsDirectOnboarding(true)
        setApiCallFinished(true)
        setResponse({
          status: 'ERROR',
          message: 'Invalid onboarding parameters',
        })
      }
    }
  }, [urlParams])

  useEffect(() => {
    if (apiCallFinished === false && isDirectOnboarding === true) {
      if (!invitationKey || invitationKey === undefined) {
        callOnboardingEndpoint()
      }
      else {
        setLoading(false);
        setResponse({
          status: 'ERROR',
          message: 'It seems the installation process is broken. Please contact support@truckercloud.com for assistance.'
        });
      }
    }
  }, [isDirectOnboarding, identifierParts, urlParams, apiCallFinished]);

  return (
    <Container className={styles.directCobContainer}>
      <Container>
        {companyProfile?.favicon ? (
          <Icon
            type="images"
            url={companyProfile?.favicon}
            boxClassName="fullLogo"
            width={500}
            height={150}
          />
        ) : (
          <Icon name={'icon_logo'} width={200} height={30} />
        )}
      </Container>
      {loading === true && (
        <>
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
          <Container>
            <TypoGraph
              variant="h5"
              content="Thank you for installing our application!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="We will finish the process for you please wait"
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {isDirectOnboarding === true && apiCallFinished === true && response?.status !== 'OK' && loading === false && (
        <>
          <Container>
            <Icon name="icon_dialog_warning" size={200} />
            <TypoGraph
              variant="h5"
              content="The Onboarding failed!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content={response?.message}
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {isDirectOnboarding === true && apiCallFinished === true && response?.status === 'OK' && loading === false && (
        <>
          <Container>
            <Icon name="icon_dialog_success" size={200} />
            <TypoGraph
              variant="h5"
              content="The Onboarding was successful!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="You can close the tab now."
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
    </Container>
  )
}

export default DirectCob
