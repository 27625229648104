import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

const tagListColumnsDefinition = (onHandleCarriersForTag: (tagId: string, status: string) => void) => [
    {
        field: 'id',
        headerName: 'Tag ID',
        minWidth: 50,
        flex: 0,
        sortable: false,
    },
    {
        field: 'category',
        headerName: 'Category',
        minWidth: 100,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'name',
        headerName: 'Tag',
        minWidth: 100,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'availableMotorCarriers',
        headerName: 'Available Carriers',
        minWidth: 150,
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Button
                    sx={{borderRadius: 25}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onHandleCarriersForTag(params?.row?.id, 'unassigned')}
                >
                    Assign Carriers
                </Button>
            )
        },
    },
    {
        field: 'assignedMotorCarriers',
        headerName: 'Assigned Carriers',
        minWidth: 150,
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Button
                    sx={{borderRadius: 25}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onHandleCarriersForTag(params?.row?.id, 'assigned')}
                >
                    Manage Carriers
                </Button>
            )
        },
    },

]

const availableCarriersColumnsDefinition = [
    {
        field: 'carrierEnrollmentId',
        headerName: 'Enrollment Id',
        minWidth: 50,
        flex: 0,
        sortable: false,
    },
    {
        field: 'carrier_name',
        headerName: 'Carrier Name',
        minWidth: 80,
        flex: 1.2,
        sortable: true,
    },
    {
        field: 'dot',
        headerName: 'DOT',
        minWidth: 80,
        flex: 0.8,
        sortable: true
    },
    {
        field: 'mc',
        headerName: 'MC',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'scac',
        headerName: 'SCAC',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'uuid',
        headerName: 'UUID',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'cuid',
        headerName: 'CUID',
        minWidth: 80,
        flex: 0.8,
        sortable: true,

    },
];

const actionList = [
    {
        name: 'Edit',
        icon: 'icon_edit',
        iconSize: 40,
    },
    {
        name: 'Delete',
        icon: 'icon_delete',
        iconSize: 40,
    },
]

const tagListColumns = (onHandleCarriersForTag: (tagId: string, status: string) => void) => ({
    columns: tagListColumnsDefinition(onHandleCarriersForTag).map(({ field, headerName, minWidth, flex, sortable, renderCell }) => ({
        field,
        headerName,
        minWidth,
        flex,
        sortable,
        renderCell,
    }))
})

const carrierListTableColumns = {
    columns: availableCarriersColumnsDefinition.map(({ field, headerName, minWidth, flex, sortable }) => ({
        field,
        headerName,
        minWidth,
        flex,
        sortable,
    }))
};

export { tagListColumns, carrierListTableColumns, actionList }