const profileTabs: any = [
    {
      label: "Profile",
      value: 0,
    },
    {
      label: "Password",
      value: 1,
    },
  ];
  
  export { profileTabs };
  