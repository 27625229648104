import request from 'tools/request'

const getUserList = async () => {
  const companyId = localStorage.getItem('companyId')
  const userType = localStorage.getItem('userType')
  const res = await request.get('userresource/user/userlist', {
    params: {
      companyid: companyId,
      usertype: userType,
    },
  })
  return res?.data
}

const createUser = async (payLoad: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}users/`, payLoad)
  return res?.data
}

const deleteUser = async (userId: string | number) => {
  const res = await request.post(
    `userresource/user/changestatus?userid=${userId}`
  )
  return res?.data
}

export { getUserList, createUser, deleteUser}
