import { Container, DataGridTable } from 'components'
import { riskDashboard } from 'constants/tableData'
import styles from './riskDetails.module.scss'
import { useNavigate } from 'react-router'
import { useGridApiRef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'

interface IRiskDetailsProps {
    print: boolean,
    isLoading: boolean,
    detailsData: [],
    isAgent: boolean
}

const RiskDetails: React.FC<IRiskDetailsProps> = (props) => {
    const { print, isLoading, detailsData, isAgent } = props
    const { riskColumns, riskGroups } = riskDashboard
    const navigate = useNavigate()
    const apiRef = useGridApiRef()

    const [riskColumnsState, setRiskColumnsState] = useState<any>(riskColumns)
    const [riskGroupsState, setRiskGroupsState] = useState<any>(riskGroups)
    const [loadingRiskTable, setLoadingRiskTable] = useState<boolean>(true)

    useEffect(() => {
        if (isAgent) {
            setRiskColumnsState(riskColumns.slice(0, 13))
            setRiskGroupsState(riskGroups.slice(0, 6))
        }
        setLoadingRiskTable(false)
    }, [])

    riskColumns[0].handleClick = (row: any) => {
        const dot = row.carrierCodes.find((item: any) => item.codeType === 'DOT' && item.codeValue !== '-')
        const mc = row.carrierCodes.find((item: any) => item.codeType === 'MC' && item.codeValue !== '-')
        const uuid = row.carrierCodes.find((item: any) => item.codeType === 'UUID' && item.codeValue !== '-')
        if (dot) {
            localStorage.setItem('carrierCodeType', 'DOT')
            localStorage.setItem('carrierCodeValue', dot.codeValue)
        }
        else if (mc) {
            localStorage.setItem('carrierCodeType', 'MC')
            localStorage.setItem('carrierCodeValue', mc.codeValue)
        }
        else if (uuid) {
            localStorage.setItem('carrierCodeType', 'UUID')
            localStorage.setItem('carrierCodeValue', uuid.codeValue)
        }
        else {
            localStorage.removeItem('carrierCodeType')
            localStorage.removeItem('carrierCodeValue')
        }
        localStorage.setItem('carrierName', row.fleetName)
        localStorage.setItem('eldProviderRisk', row.eldProvider)
        row.carrierId ? localStorage.setItem('carrierId', row.carrierId) : localStorage.removeItem('carrierId')
        navigate('../carriers/details')
    }

    const handleRiskExport = () => {
      const csvString = apiRef.current.getDataAsCsv()
      const csvRows = csvString.split('\n')
      const modifiedCsv = csvRows.slice(1).join('\n')
      const blob = new Blob([modifiedCsv], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'RiskDataExport.csv'
      a.click()
      URL.revokeObjectURL(url)
    }

    const tableMenuItems = [
      {
        label: 'Download as CSV',
        key: 'export',
        action: () => {
          handleRiskExport()
        },
      },
    ]

    return (
        <>
            {!loadingRiskTable && (
                <Container
                    className={`${styles.riskDetailsContainer} ${
                        print ? styles.printContainer : ''
                    }`}
                >
                    <DataGridTable
                        data={detailsData}
                        columns={riskColumnsState}
                        columnGroupingModel={riskGroupsState}
                        columnHeaderHeight={30}
                        getRowId={(row: any) => row?.fleetName + '|' + row?.eldProvider + '|' + row?.companyEldAuthenticationId}
                        reduceHeight={280}
                        rowHeight={65}
                        showTitle={false}
                        showFilter={true}
                        multiSearchFilter={false}
                        checkboxSelection={false}
                        loading={isLoading}
                        autoHeight={print}
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        sortField={'totalMiles'}
                        sortOrder={'desc'}
                        menuList={tableMenuItems}
                        apiRef={apiRef}
                    />
                </Container>
            )}
        </>
    )
}

export default RiskDetails