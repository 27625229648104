import { Blade, Container, DataGridTable } from 'components'
import React, { useState } from 'react'
import { gzcListTableColumns } from './garagingZipcodeConfigTableData'
import styles from '../radiusOfOperation.module.scss'
import { useGridApiRef } from '@mui/x-data-grid'

interface IGarageZipcodeConfigProps {
  isOpen: boolean
  currentData: any
  updateCurrentData: (data: any) => void
  loading: boolean
  onClose: () => void
  onSave: () => void
  btnLoader: boolean
}

const GaragingZipcodeConfig: React.FC<IGarageZipcodeConfigProps> = (props) => {
  const { isOpen, currentData, updateCurrentData, loading, onClose, onSave, btnLoader } =
    props
  const [disableBtn, setDisableBtn] = useState(true)
  const apiRef = useGridApiRef()

  const handleEditManualGzc = (updatedRow: any) => {
    updateCurrentData(updatedRow)
    setDisableBtn(false)
    return updatedRow
  }

  const handleClose = () => {
    setDisableBtn(true)
    onClose()
  }

  const handleSave = () => {
    onSave()
    setDisableBtn(true)
  }

  const BladeContent = () => {
    return (
      <Container className={styles.bladeBodyContainer}>
        <Container className={styles.resultsContainer} sx={{ mt: 1 }}>
          <DataGridTable
            apiRef={apiRef}
            loading={loading}
            data={currentData || []}
            columns={gzcListTableColumns.columns}
            columnVisibilityModel={{}}
            getRowId={(row: any) => row?.assetEldId}
            showFilter={true}
            multiSearchFilter={false}
            showTitle={false}
            checkboxSelection={false}
            reduceHeight={260}
            editMode={'row'}
            processRowUpdate={(updatedRow: any, originalRow: any) =>
              handleEditManualGzc(updatedRow)
            }
            onProcessRowUpdateError={() => {}}
          />
        </Container>
      </Container>
    )
  }
  return (
    <>
      <Blade
        title="Garaging Zip Code Configuration"
        open={isOpen}
        onClose={handleClose}
        size={950}
        bodyNode={<BladeContent />}
        footerBtns={{
          primary: {
            label: 'Save',
            onClick: handleSave,
            loading: btnLoader,
            disabled: disableBtn,
          },
          secondary: {
            label: 'Cancel',
            onClick: handleClose,
          },
        }}
      />
    </>
  )
}
export default GaragingZipcodeConfig
