import React, { useState } from 'react'
import { profileTabs } from 'constants/tabs'
import { Card, Container, HeaderBar, TabPanel, Tabs } from 'components'
import strings from 'strings'
import styles from './profile.module.scss'
import PasswordTab from './partial/passwordTab'
import ProfileTab from './partial/profileTab'

const t = strings.PROFILE_STRING

const Profile: React.FunctionComponent = () => {
  const [tabValue, setTabValue] = useState<number>(0)

  return (
    <Container>
      <HeaderBar title={t.PROFILE} />
      <Tabs
        id={profileTabs[tabValue].label}
        tabData={profileTabs || []}
        value={tabValue}
        handleChange={(_e: React.SyntheticEvent, val: number) =>
          setTabValue(val)
        }
        sxContainer={{
          marginLeft: '1rem',
        }}
      />
      <Card>
        <Container className={styles.tabPanelContainer} >
          <TabPanel value={tabValue} index={0}>
            <ProfileTab styles={styles} profileStrings={t} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <PasswordTab styles={styles} strings={t} />
          </TabPanel>
        </Container>
      </Card>
    </Container>
  )
}

export default Profile
