import { Card, Container, Icon, TypoGraph } from 'components'
import { ObjectStrings } from 'interfaces'
import { changelogHtmlString } from 'constants/changelogData'
import colors from 'theme/colors'

interface IChangelogProps {
  styles: ObjectStrings
  companyProfile: any
}

const MarketingChangelog = (props: IChangelogProps) => {
  const { styles, companyProfile } = props

  return (
    <Container className={styles.marketingContainer}>
      <Container className={styles.transmittingContainer}></Container>
      <Container>
        <Card>
          {companyProfile?.favicon ? (
            <Icon
              type="images"
              url={companyProfile?.favicon}
              boxClassName="fullLogo"
              size={80}
            />
          ) : (
            <Icon name="icon_logo" size={80} />
          )}
          <Container
            innerHtml={{
              __html: changelogHtmlString(),
            }}
          />
        </Card>
      </Container>
      <Container className={styles.blogContainer}>
        <TypoGraph
          variant="caption"
          content="version 0.1.0"
          align="center"
          color={colors.white}
        />
      </Container>
    </Container>
  )
}

export default MarketingChangelog
