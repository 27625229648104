import request from 'tools/request'

export const getRolesList = async () => {
  const companyId = localStorage.getItem('companyId')
  const res = await request.get('roleresource/role/get', {
    params: {
      companyId: companyId,
    },
  })
  return res?.data
}
