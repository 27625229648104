import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListItemButton, CircularProgress, Box, InputAdornment, IconButton, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import userGuides from 'constants/userGuideCred'
import { getCobList } from 'api'
import {
  Container,
  HeaderBar,
  Card,
  Icon,
  TypoGraph,
  Toast as toast,
  Divider,
} from 'components'
import { CobMenuBar, AuthorizeModal } from './partial'
import {
  isCookiePresent,
  getCookiesValues,
  compareCsrfToken,
  setCanalCookies,
  setOneNetworkCookies,
  checkIsOneNetwork,
  checkIsCoverwhaleTest,
  setCoverwhaleTestCookies,
  resetCookies,
} from './partial/oauthHelper'
import bootIntercom from 'tools/intercom'
import colors from 'theme/colors'
import styles from './cob.module.scss'
import WidgetErrorPage from './partial/errorPage'
import { createPendoOnboardingData } from 'tools/pendo'
import { reportException } from 'tools/logs'
import { Clear } from '@mui/icons-material'

const cobImgUrl = process.env.REACT_APP_COB_IMAGE_URL

const buildCobMenubarData = (res: any) => {
  const codeTypes = res?.carrierInfo?.carrierCodes?.map((item: any) => ({
    icon: 'hash',
    name: `${item?.codeType} - ${item?.codeValue}`,
  }))
  let data: any[] = []
  data = [
    {
      icon: 'icon_profile',
      name: `${res?.contactInfo?.firstName} ${res?.contactInfo?.lastName}`,
    },
    {
      icon: 'icon_carrier',
      name: res?.carrierInfo?.carrierName,
    },
    {
      icon: 'mail',
      name: res?.contactInfo?.email,
    },
    ...codeTypes,
  ]
  return data
}

interface ICobListProps {
  cobData?: any
  apiKey?: any
  canalRedirection?: any
  oneNetworkCustomBehavior?: any
  coverwhaleTestCustomBehavior?: any
  widgetErrorTitle?: any
  widgetErrorMessage?: any
  setWidgetErrorTitle?: any
  setWidgetErrorMessage?: any
  isSimulation?: boolean
}

const CobList: React.FC<ICobListProps> = (props) => {
  const { 
    cobData, 
    apiKey, 
    canalRedirection, 
    oneNetworkCustomBehavior, 
    coverwhaleTestCustomBehavior, 
    widgetErrorTitle, 
    widgetErrorMessage, 
    setWidgetErrorTitle, 
    setWidgetErrorMessage, 
    isSimulation } = props

  const [cobListData, setCobListData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [menubarData, setMenubarData] = useState<any[]>([])
  const [eldList, setEldList] = useState<any>([])
  const [fullEldList, setFullEldList] = useState<any>([])
  const [providerSearchText, setProviderSearchText] = useState<string>("")
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(false)
  const [selectedEld, setSelectedEld] = useState({})
  const [inviteKey, setInviteKey] = useState<string | null>('')
  const [eldIndex, setEldIndex] = useState<any>()
  const [cookieData, setCookieData] = useState<any>()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [oneNetworkData, setOneNetworkData] = useState<any>(oneNetworkCustomBehavior)
  const [coverwhaleTestData, setCoverwhaleTestData] = useState<any>(coverwhaleTestCustomBehavior)

  const navigate = useNavigate()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const code = searchParams.get('code')
  const csrfToken = searchParams.get('state')

  useEffect(() => {
    resetCookies()
  }, [])

  // Triggers whenever state param (csrf) is present and it will validate with current csrf cookie
  useEffect(() => {
    if (csrfToken && code && !canalRedirection) {
      if (!compareCsrfToken(csrfToken)) {
        toast({ type: 'error', title: 'Invalid csrf token!' })
        navigate('/login')
      }
    } else if (canalRedirection) {
      setCanalCookies(canalRedirection)
    }
  }, [csrfToken, code, canalRedirection, navigate])

  useEffect(() => {
    if (code && isCookiePresent() && cobListData?.eldInfo) {
      const cookies: any = getCookiesValues()
      if (checkIsOneNetwork() === true) {
        setOneNetworkBehavior(cookies)
      } else {
        if (checkIsCoverwhaleTest() === true) {
          setCoverwhaleTestBehavior(cookies)
        }
        const selectedEldObject = cobListData?.eldInfo.find((eld: any) => 
          eld.eldVendor.toLowerCase() === cookies?.eldVendor?.toLowerCase()
        )
        if (selectedEldObject) {
          setSelectedEld(selectedEldObject)
          setEldIndex(cobListData?.eldInfo.indexOf(selectedEldObject))
          setShowAuthorizeModal(true)
        }
      }
      setCookieData({ ...cookies, code: code })
    }
    else if (cobListData?.eldInfo && oneNetworkCustomBehavior) {
      setOneNetworkBehavior(null)
    } else if (cobListData?.eldInfo && coverwhaleTestCustomBehavior) {
      setCoverwhaleTestBehavior(null)
    }
  }, [code, cobListData])

  /** To get the list of eld vendors */
  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const invitationkey = searchParams.get('invitationkey')
    let eldListData;
    if (!invitationkey && !cobData) navigate('/login', { replace: true })
    bootIntercom()
    setLoading(true)
    if (cobData) {
      setInviteKey(apiKey)
      const menubar = buildCobMenubarData(cobData)
      setMenubarData(menubar)
      setCobListData(cobData)
      eldListData = cobData.eldInfo.sort(
        (a: any, b: any) => a.eldVendor.localeCompare(b.eldVendor)
      )
      setEldList(eldListData)
      setFullEldList(eldListData)
      setLoading(false)
      createPendoOnboardingData(cobData)
    } else {
      setInviteKey(invitationkey)
      getCobList(invitationkey)
        .then((res: any) => {
          const menubar = buildCobMenubarData(res)
          setMenubarData(menubar)
          setCobListData(res)
          eldListData = res.eldInfo.sort(
            (a: any, b: any) => a.eldVendor.localeCompare(b.eldVendor)
          )
          setEldList(eldListData)
          setFullEldList(eldListData)
          createPendoOnboardingData(res)
        })
        .catch((error: any) => {
          reportException(error)
          if (oneNetworkCustomBehavior) {
            setWidgetErrorTitle("Server Error")
            setWidgetErrorMessage("Something went wrong. Please try again later.")
          }
          navigate('/login', { replace: true })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [navigate, search, cobData, apiKey])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  const handleSelectedEld = (data: any, index: any) => {
    setSelectedEld(data)
    setShowAuthorizeModal(true)
    setEldIndex(index)
  }

  const setOneNetworkBehavior = (cookies: any) => {
    const eldName = searchParams.get('eldProvider') ?? cookies.eldProvider
    const eldIndex: number = cobListData?.eldInfo?.findIndex((item: any) => item?.eldVendor.toLowerCase() === eldName.toLowerCase())
    const eld: any = cobListData?.eldInfo[eldIndex]
    if (eld?.overallStatus === 'VERIFIED') {
      setWidgetErrorTitle("User Error")
      setWidgetErrorMessage("You are already onboarded!")
    }
    if (eldIndex >= 0) {
      if (cookies && !oneNetworkCustomBehavior) {
        setOneNetworkData({
          carrierName: cookies?.carrierName,
          eldProvider: cookies?.eldProvider,
          scac: cookies?.scac,
        })
      } else {
        setOneNetworkCookies(oneNetworkCustomBehavior)
      }
      setSelectedEld(cobListData?.eldInfo[eldIndex])
      setEldIndex(eldIndex)
      setShowAuthorizeModal(true)
    }
    else {
      setWidgetErrorTitle("Configuration Error")
      setWidgetErrorMessage("Invalid ELD Provider")
    }
  }

  const setCoverwhaleTestBehavior = (cookies: any) => {
    if (cookies && cookies?.policyId && !coverwhaleTestCustomBehavior) {
      setCoverwhaleTestData({
        policyId: cookies?.policyId,
      })
    } else {
      setCoverwhaleTestCookies(coverwhaleTestCustomBehavior)
    }
  }

  const handleSearchProvider = useCallback((text: string) => {
    setProviderSearchText(text)
    if (text === "") {
      setEldList(fullEldList)
    } else {
      const eldListData = eldList.filter((item: any) => 
        item.eldVendor.toLowerCase().includes(text.toLowerCase()))
      setEldList(eldListData)
    }
  }, [eldList])

  const EldListSearch = useMemo(() => {
    return (
      <Container sx={{marginBottom:'1rem'}}>
        <FormControl sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="search-onboarding-provider">Search for your Provider</InputLabel>
          <OutlinedInput
            id="search-onboarding-provider"
            type="text"
            value={providerSearchText}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search onboarding provider"
                  edge="end"
                  onClick={() => handleSearchProvider("")}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            }
            label="Search for your Provider"
            onChange={(e) => handleSearchProvider(e.target.value)}
          />
        </FormControl>
      </Container>
    )
  }, [providerSearchText, handleSearchProvider])

  return (
    <Container>
      <Box sx={{ display: 'flex' }}>
        {!oneNetworkData &&
        <CobMenuBar menubarData={menubarData} />
        }
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            margin: 'auto',
            marginTop: windowWidth < 1000 ? '6rem' : 'auto',
            position: 'relative',
          }}
        >
          <HeaderBar
            title="Carrier Onboarding"
            mainContainerClass={
              windowWidth < 1000 ? styles.mobileHeaderContainer : ''
            }
            primaryBtnProps={{
              label: 'Request New ELD',
              onClick: () =>
                window.open(
                  'https://truckercloud.zendesk.com/hc/en-us/requests/new?ticket_form_id=16519443971483'
                ),
            }}
          />
          {loading ? (
            <Container className={styles.loaderContainer}>
              <CircularProgress />
            </Container>
          ) : windowWidth < 1000 ? (
            <>
              {EldListSearch}
              <Container className={styles.mobileResponsiveContainer}>
                <Card>
                  <TypoGraph variant="body1" content="ELD PROVIDERS" />
                  <Divider />
                  <Container className={styles.eldColumns}>
                    <TypoGraph variant="body2" content="ELD" />
                    <TypoGraph variant="body2" content="Name" />
                  </Container>
                  {eldList?.map((item: any, index: any) => (
                    <ListItemButton
                      key={item?.eldVendorId}
                      sx={{ borderRadius: '12px' }}
                      onClick={
                        item?.overallStatus !== 'VERIFIED'
                          ? () => handleSelectedEld(item, index)
                          : undefined
                      }
                      className={`${styles.eldItems} ${
                        item?.overallStatus !== 'VERIFIED'
                          ? ''
                          : styles.cardDisabled
                      }`}
                    >
                      <Icon
                        type="images"
                        url={`${cobImgUrl}/${item?.eldVendor}`}
                        height={50}
                        width={150}
                        boxClassName={styles.logoContainer}
                      />
                      <Container>
                        <TypoGraph content={item?.eldVendor || ''} />
                        {item?.overallStatus !== 'VERIFIED' ? (
                          ''
                        ) : (
                          <TypoGraph
                            variant="subtitle2"
                            content="Already Onboarded"
                            color={colors.red40}
                          />
                        )}
                      </Container>
                    </ListItemButton>
                  ))}
                </Card>
              </Container>
            </>
          ) : (
            <>
              {EldListSearch}
              <Container className={styles.cardMainContainer}>
                {eldList?.map((item: any, index: any) =>
                  item?.overallStatus !== 'VERIFIED' ? (
                    <Card key={item.eldProviderId}>
                      <Container className={styles.cardContentContainer}>
                        <ListItemButton
                            id={item?.eldVendor}
                          sx={{ borderRadius: '12px' }}
                          onClick={() => handleSelectedEld(item, index)}
                        >
                          <Container className={styles.cardContentContainer}>
                            <Icon
                              type="images"
                              url={`${cobImgUrl}/${item?.eldVendor}`}
                              size={100}
                              boxClassName={styles.logoContainer}
                            />
                            <TypoGraph
                              content={item?.eldVendor || ''}
                              sx={{ textAlign: 'center' }}
                            />
                          </Container>
                        </ListItemButton>
                        <TypoGraph
                          variant="subtitle2"
                          content="FIND MY CREDENTIALS"
                          color={colors.primary}
                          sx={{
                            textAlign: 'center',
                          }}
                          onClick={() =>
                            window.open(
                              userGuides[cobListData?.invitationInfo?.invitingCompanyName?.replace(/\s/g, '')?.toLowerCase()?.replace('&', '')] || 
                              'https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435-TruckerCloud-Carrier-ELD-Onboarding-Guides'
                            )
                          }
                          mt={4}
                          link
                        />
                      </Container>
                    </Card>
                  ) : (
                    <Card key={item.eldProviderId} containerClass={styles.cardDisabled}>
                      <Container className={styles.cardContentContainer}>
                        <Container className={styles.cardContentContainer}>
                          <Icon
                            type="images"
                            url={`${cobImgUrl}/${item?.eldVendor}`}
                            size={100}
                            boxClassName={styles.logoContainer}
                          />
                          <TypoGraph
                            content={item?.eldVendor || ''}
                            sx={{ textAlign: 'center' }}
                          />
                        </Container>
                        <TypoGraph
                          variant="subtitle2"
                          content="ALREADY ONBOARDED!"
                          color={colors.red40}
                          sx={{
                            textAlign: 'center',
                          }}
                          mt={4}
                        />
                      </Container>
                    </Card>
                  )
                )}
              </Container>
            </>
          )}
        </Box>
      </Box>
      {(oneNetworkData && widgetErrorTitle && widgetErrorMessage) ?
      <WidgetErrorPage
        errorTitle={widgetErrorTitle}
        errorMessage={widgetErrorMessage}
        isOneNetwork={oneNetworkData} />
      :
      <AuthorizeModal
        open={showAuthorizeModal}
        handleClose={() => setShowAuthorizeModal(oneNetworkData)}
        selectedEld={selectedEld}
        invitationKey={inviteKey}
        carrierId={cobListData?.carrierInfo?.carrierId}
        cobListData={cobListData}
        eldIndex={eldIndex}
        cookieData={cookieData}
        canalRedirection={canalRedirection}
        oneNetworkCustomBehavior={oneNetworkData}
        coverwhaleTestCustomBehavior={coverwhaleTestData}
        isSimulation={isSimulation}
      />
      }
    </Container>
  )
}

export default CobList