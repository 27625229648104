import * as React from 'react'
import MuiContainer from '@mui/material/Container'

interface IContainerProps {
  id?:string
  className?: string
  disableGutters?: boolean
  children?: React.ReactNode
  onClick?: () => void
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  sx?: object
  ref?: any
  innerHtml?: {
    __html: string
  }
}

const Container: React.FunctionComponent<IContainerProps> = (props) => {
  const {
    id,
    className,
    disableGutters = true,
    maxWidth = false,
    children,
    sx = {},
    innerHtml,
    ref,
    onClick,
  } = props
  return (
    <MuiContainer
      ref={ref}
      className={`${className}`}
      sx={{ display: 'grid', ...sx }}
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      dangerouslySetInnerHTML={innerHtml}
      onClick={onClick}
    >
      {children}
    </MuiContainer>
  )
}

export default Container
