const requiredData = [
  {
    icon: 'icon_lock',
    title: 'Authorization',
    content: 'Authorization to retrieve ELD data',
  },
  {
    icon: 'icon_location',
    title: 'Location Data',
    content: 'GPS location data',
  },
  {
    icon: 'icon_carrier',
    title: 'Vehicle Data',
    content: 'Critical vehicle data (Make, Model, VIN)',
  },
]

export { requiredData }
