import { Button, Chip, Container, HeaderBar, TypoGraph, TagFilter } from 'components'
import styles from './riskDashboard.module.scss'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { RiskDetails, RiskSummaryPanel } from './partial'
import { getNewRiskScores, getNewRiskScoresForAgent, getRiskScoresFilteredByTag, getSubtagsForFilter } from 'api'
import { isAgentLogin, isInsuranceLogin } from 'tools/auth'
import { reportException } from 'tools/logs'

interface IRiskDashboardProps {}

const RiskDashboard: React.FC<IRiskDashboardProps> = (props) => {
    const [riskData, setRiskData] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)
    const [tagLoading, setTagLoading] = useState(true)
    const [updateDate, setUpdateDate] = useState<any>()
    const [printReport, setPrintReport] = useState<boolean>(false)
    const tagCategories = localStorage.getItem('tagCategories') ? JSON.parse(localStorage.getItem('tagCategories') || '') : []
    const [selectedTagCategories, setSelectedTagCategories] = useState<any>([])
    const [subTags, setSubTags] = useState<any>([])
    const [selectedSubTags, setSelectedSubTags] = useState<any>([])

    const ref = useRef<HTMLDivElement>(null)

    const isAgent = isAgentLogin()
    const isInsurance = isInsuranceLogin()

    useEffect(() => {
        if (isAgent) {
            fetchRiskScoreData(getNewRiskScoresForAgent)
        } else {
            fetchRiskScoreData(getNewRiskScores)
        }
    }, [])

    useEffect(() => {
        if (Object.entries(tagCategories).length === 1 && isInsurance) {            
            getSubtagsForFilter(Object.keys(tagCategories).toString())
                .then((res: any) => {
                    if(res.tagSubCategories !== null && res.tagSubCategories !== undefined) {
                        setSubTags(res.tagSubCategories)
                        setSelectedTagCategories(Object.keys(tagCategories))
                    }
                })
                .catch((error: any) => {
                    reportException(error)
                })
                .finally(() => {
                    setTagLoading(false)
                })
        }
    }, [])

    const callSubTagsList = useCallback((selectedTags: any, currentSelectedSubTags?: any) => {
        getSubtagsForFilter(selectedTags.join(','))
        .then((res: any) => {
            if (res.tagSubCategories !== null && res.tagSubCategories !== undefined) {  
                if(currentSelectedSubTags) {
                    const mergedSubTags = {...currentSelectedSubTags, ...res.tagSubCategories}
                    setSubTags(mergedSubTags)
                }else{
                    setSubTags(res.tagSubCategories)
                }
            }
        })
        .catch((error: any) => {
            reportException(error)
        })
        .finally(() => {
            setTagLoading(false)
        })
    }, [])

    const handleTagCategorySelection = (selectedTags: any) => {
        setSelectedTagCategories(selectedTags)
        setTagLoading(true)
        if(selectedTags.length > 0) {
            const currentSelectedSubTags = getSelectedSubTagsData(selectedSubTags)
            callSubTagsList(selectedTags, currentSelectedSubTags)
        }else {
            setSubTags([])
            if(selectedSubTags.length > 0) {
                setSelectedSubTags([])
                setIsLoading(true)
                if (isAgent) {
                    fetchRiskScoreData(getNewRiskScoresForAgent)
                } else {
                    fetchRiskScoreData(getNewRiskScores)
                }
            }
            setTagLoading(false)
        }
    }

    const getSelectedSubTagsData = (selectedSubTags: any) => {
        const filteredSubTags: any = {}

        for (const id of selectedSubTags) {
          if (subTags.hasOwnProperty(id)) {
            filteredSubTags[id] = subTags[id]
          }
        }
      
        return filteredSubTags;
    }

    const handleSubTagSelection = (selectedTags: any) => {
        setIsLoading(true)
        setTagLoading(true)
        getRiskScoresFilteredByTag(selectedTags.join(','))
            .then((res: any) => {
                setRiskData(res)
            })
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setIsLoading(false)
                setTagLoading(false)
            })
        if (selectedTags.length < selectedSubTags.length) {
            const currentSelectedSubTags = getSelectedSubTagsData(selectedTags)
            callSubTagsList(selectedTagCategories, currentSelectedSubTags)
        }
        setSelectedSubTags(selectedTags)
        
    }
    
    const fetchRiskScoreData = (riskScoreCall: any) => {
        riskScoreCall()
            .then((res: any) => {
                if (res) {
                    setRiskData(res)
                    const hasRiskScore = res?.details?.filter((item: any) => {
                        return (item?.fleetScore && item?.fleetScore > 0)
                    })
                    if (hasRiskScore?.length > 0) {
                        setUpdateDate(hasRiskScore[0]?.executionDate)
                    }
                }
            })
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setIsLoading(false)
                setTagLoading(false)
            })
    }

    const checkFiltersVisibility = () => {
        if (Object.entries(tagCategories).length === 0) {
            return false
        } else {
            return true
        }
    }

    const HeaderDom = () => {
        return (
            <Container className={styles.headerContainer}>
                <Container className={styles.headerTitleContainer}>
                    <TypoGraph
                        variant="h1"
                        sx={{ marginRight: '1rem' }}
                        mb={0}
                        content="Risk Analysis"
                    />
                    {updateDate && (
                        <Chip
                            label={`Updated ${moment(updateDate).format('dddd, MMMM Do')}`}
                            variant="filled"
                            sx={{
                                backgroundColor: '#E1FAEB',
                                color: '#20693D',
                            }}
                        />
                    )}
                </Container>
                <Container className={styles.btnContainer}>
                    <ReactToPrint
                        trigger={() => <Button label="Export Report" />}
                        content={() => ref.current}
                        onBeforeGetContent={() => setPrintReport(true)}
                        onAfterPrint={() => setPrintReport(false)}
                        documentTitle={`Risk Analysis Report`}
                    />
                </Container>
            </Container>
        )
    }

    return (
        <Container>
            <HeaderBar dom={<HeaderDom />} />
            <div ref={ref}>
                {(isInsurance && checkFiltersVisibility()) && (                
                    <TagFilter 
                        mainTags={Object.entries(tagCategories)} 
                        subTags={Object.entries(subTags)}
                        selectedMainTags={selectedTagCategories}
                        selectedSubTags={selectedSubTags}
                        onMainTagSelectionChange={handleTagCategorySelection} 
                        onSubTagSelectionChange={handleSubTagSelection}
                        isLoading={tagLoading}
                        title='Filter by Tags'
                    />
                )}
                <RiskSummaryPanel 
                    summaryData={riskData?.summary}
                    print={printReport}
                    isLoading={isLoading}
                    isAgent={isAgent}
                />
                <RiskDetails
                    detailsData={riskData?.details}
                    print={printReport}
                    isLoading={isLoading}
                    isAgent={isAgent}
                />
            </div>
        </Container>
    )
}

export default RiskDashboard