import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid"
import { Tooltip, TypoGraph } from "components"
import { ColumnDescriptionWithoutTitle } from "components/columnDescription"
import HeaderWithIcon from "components/tableHeaderWithIcon"
import { numberOrStringComparator, numberComparator } from "tools/comparators"
import { formatRiskCell } from "tools/helper"
import CircleIcon from '@mui/icons-material/Circle';

const riskScoresColumnFlex = 0.15
const fleetDetailsTableData = {
    column: [
      {
        field: 'assetId',
        headerName: 'Asset ID',
        width: 90,
      },
      {
        field: 'assetEldId',
        headerName: 'Asset ID',
        minWidth: 90,
        flex: 0.3,
        sortable: true,
        sortComparator: numberOrStringComparator,
        renderHeader: () => {
          return (
            <ColumnDescriptionWithoutTitle description="ID" />
          )
        },
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              <TypoGraph
                component="span"
                variant="body1"
              >
                {params.value}
              </TypoGraph>
            </Tooltip>
          )
        },
      },
      {
        field: 'vin',
        headerName: 'VIN',
        minWidth: 170,
        flex: 0.5,
        renderHeader: () => {
          return <ColumnDescriptionWithoutTitle description="VEHICLE IDENTIFIER" />
        },
        valueGetter: (value: any, row: any) => {
          return row?.identificationNo || '-'
        }
      },
      {
        field: 'totalMiles',
        headerName: 'Miles',
        minWidth: 90,
        flex: 0.2,
        sortable: true,
        sortComparator: numberComparator,
        cellClassName: 'centeredCell',
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="TOTAL" />
        ),
        renderCell: (params: GridRenderCellParams) => {
          return <TypoGraph content={params.row?.totalMiles} />
        },
        valueGetter: (value: any, row: any) => {
          return row.totalMiles
        },
      },
      {
        field: 'score',
        headerName: 'Score',
        headerClassName: 'withBorder',
        width: 90,
        flex: riskScoresColumnFlex,
        headerAlign: 'center' as const,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="SUMMARY" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'summaryScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => (
          <TypoGraph
            sx={{ fontWeight: 'bold' }}
            // variant="h6"
            content={formatRiskCell(params.row?.score)}
          />
        ),
        valueGetter: (value: any, row: any) => {
          return row.score
        },
      },
      {
        field: 'safetyIndex',
        headerName: 'Safety Index',
        headerClassName: 'withBorder',
        width: 80,
        flex: riskScoresColumnFlex,
        headerAlign: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="INDEX" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'safetyScoreStart centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return <TypoGraph content={formatRiskCell(params.row?.harsh.index)} />
        },
        valueGetter: (value: any, row: any) => {
          return row?.harsh.index
        },
      },
      {
        field: 'safetySev1',
        headerName: 'Safety High-G',
        width: 80,
        sortable: true,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="HIGH-G" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'safetyScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(params.row?.harsh?.severity1, 1)}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.harsh?.severity1, 1)
        },
      },
      {
        field: 'safetySev2',
        headerName: 'Safety Brake',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="BRAKE" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'safetyScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(params.row?.harsh?.severity2, 1)}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.harsh?.severity2, 1)
        },
      },
      {
        field: 'safetySev3',
        headerName: 'Safety Cor/Acc',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="COR/ACC" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'safetyScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(params.row?.harsh?.severity3, 1)}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.harsh?.severity3, 1)
        },
      },
      {
        field: 'speedingIndex',
        headerName: 'Speeding Index',
        headerClassName: 'withBorder',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="INDEX" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'speedingScoreStart centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return <TypoGraph content={formatRiskCell(params.row?.speed?.index)} />
        },
        valueGetter: (value: any, row: any) => {
          return row?.speed?.index
        },
      },
      {
        field: 'speedingLimitPlus5',
        headerName: 'Speeding Limit Plus 5',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="5-10" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'speedingScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(
                params.row?.speed?.pingsOverSpeedLimitPlus5,
                1
              )}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus5, 1)
        },
      },
      {
        field: 'speedingLimitPlus10',
        headerName: 'Speeding Limit Plus 10',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="10.1-15" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'speedingScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(
                params.row?.speed?.pingsOverSpeedLimitPlus10,
                1
              )}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus10, 1)
        },
      },
      {
        field: 'speedingLimitPlus15',
        headerName: 'Speeding Limit Plus 15',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="15.1+" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'speedingScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(
                params.row?.speed?.pingsOverSpeedLimitPlus15,
                1
              )}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus15, 1)
        },
      },
      {
        field: 'speedingOver75',
        headerName: 'Speeding Over 75',
        width: 80,
        flex: riskScoresColumnFlex,
        align: 'center' as const,
        sortable: true,
        sortComparator: numberComparator,
        renderHeader: () => (
          <ColumnDescriptionWithoutTitle description="75+" />
        ),
        cellClassName: (params: GridCellParams<any>) =>
          'speedingScoreCell centeredCell',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <TypoGraph
              content={formatRiskCell(params.row?.speed?.pingsOver75mph, 1)}
            />
          )
        },
        valueGetter: (value: any, row: any) => {
          return formatRiskCell(row?.speed?.pingsOver75mph, 1)
        },
      },
    ],
    groups: [
      {
        groupId: 'asset',
        headerName: 'Asset',
        headerClassName: 'columnGroupTitle',
        renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
        children: [{ field: 'assetEldId' }],
      },
      {
        groupId: 'vin',
        headerName: 'VIN',
        headerClassName: 'columnGroupTitle',
        renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
        children: [{ field: 'vin' }],
      },
      {
        groupId: 'totalMiles',
        headerName: 'Miles',
        headerClassName: 'columnGroupTitle',
        renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
        children: [{ field: 'totalMiles' }],
      },
      {
        groupId: 'summaryScore',
        headerName: 'Score',
        headerClassName: 'columnGroupTitle withBorder centered',
        renderHeaderGroup: (params: any) => (
          <HeaderWithIcon
            {...params}
            icon={
              <CircleIcon
                fontSize="small"
                sx={{
                  color: '#8CC0E8',
                  width: '1rem',
                  height: 'auto',
                  paddingRight: '0.2rem',
                }}
              />
            }
          />
        ),
        children: [{ field: 'score' }],
      },
      {
        groupId: 'safety',
        headerName: 'Safety Events',
        description: 'per 1k miles',
        headerClassName: 'columnGroupTitle withBorder centered',
        renderHeaderGroup: (params: any) => (
          <HeaderWithIcon
            {...params}
            icon={
              <CircleIcon
                fontSize="small"
                sx={{
                  color: '#F9706B',
                  width: '1rem',
                  height: 'auto',
                  paddingRight: '0.2rem',
                }}
              />
            }
          />
        ),
        children: [
          { field: 'safetyIndex' },
          { field: 'safetySev1' },
          { field: 'safetySev2' },
          { field: 'safetySev3' },
        ],
      },
      {
        groupId: 'speeding',
        headerName: 'Speeding %',
        description: 'time over limit',
        headerClassName: 'columnGroupTitle withBorder centered',
        renderHeaderGroup: (params: any) => (
          <HeaderWithIcon
            {...params}
            icon={
              <CircleIcon
                fontSize="small"
                sx={{
                  color: '#89EBB0',
                  width: '1rem',
                  height: 'auto',
                  paddingRight: '0.2rem',
                }}
              />
            }
          />
        ),
        children: [
          { field: 'speedingIndex' },
          { field: 'speedingLimitPlus5' },
          { field: 'speedingLimitPlus10' },
          { field: 'speedingLimitPlus15' },
          { field: 'speedingOver75' },
        ],
      },
    ],
  }

  export default fleetDetailsTableData