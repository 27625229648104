import ErrorBoundary from './ErrorBoundary'
import { RouteProps } from 'react-router-dom'
import AppContainer from 'appContainer'
import * as Sentry from "@sentry/browser";

const isProduction: boolean = process.env.REACT_APP_ENVIRONMENT == 'production'
const isLocal: boolean = process.env.REACT_APP_ENVIRONMENT == 'local'
const enableSentry: boolean = process.env.REACT_APP_SENTRY_ENABLED == 'true'
const enableUserFeedback: boolean = process.env.REACT_APP_SENTRY_USER_FEEDBACK == 'true'
const sentryMessageLength: number = process.env.REACT_APP_SENTRY_MESSAGE_LENGTH ? parseInt(process.env.REACT_APP_SENTRY_MESSAGE_LENGTH) : 500
if (enableSentry) {
    const sentryConfig: Sentry.BrowserOptions = {
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        debug: !isProduction,
        allowUrls: !isLocal ? [/https?:\/\/(.+\.)?truckercloud\.com/] : undefined,
        maxValueLength: sentryMessageLength,
        beforeSend(event, hint) {
            if (enableUserFeedback) {
                // Check if it is an exception, and if so, show the report dialog
                if (event.exception && event.event_id) {
                    Sentry.showReportDialog({ eventId: event.event_id });
                }
            }
            return event;
        }
    }

    Sentry.init(sentryConfig);
}

const App: React.FunctionComponent<RouteProps> = () => {
    return (
        <ErrorBoundary>
            <AppContainer />
        </ErrorBoundary>
    )
}

export default App
