const colors = {
  primary: '#44A3DB', // brand primary
  secondary: '#EDF6FC', // brand secondary

  // Feedback colors
  error: '#F9635E',
  success: '#5ABFAE',
  warning: '#F5B529',

  // Status colors
  new: '#8BB8E2',
  que: '#97E6AC',
  held: '#F5B529',
  //waiting: '#F2B90C', // very close to 'warning' need to understand utility

  // let's review and discuss color implements and nomenclature
  //subTitle1: '#636363',
  //body: '#F8F8F8',
  //greyOld: 'rgb(102, 102, 102)',
  //black: 'black',
  //white: '#FFFFFF',

  // Grey tones (Dark -> Light)
  black: '#000', // (use sparingly: when everything is black, nothing is.)
  midnight: '#212121',
  charcoal: '#333333',
  grey: '#696969', // default body text WCAG compliance
  granite: '#666666',
  silver: '#d8d8d8',
  platinum: '#ededed',
  paper: '#f8f8f8', // body background
  white: '#fff',

  green40: '#57C281',
  red40: '#FA8D8D',
  silver40: '#C4C4C4',
}

export default colors
