import { Container, HeaderBar, Tabs } from "components"
import { connectedAccountsTabs } from "constants/status"
import { useState } from "react"
import { ChildCarrierInvitation, InvitedAccounts, MyConnectedAccounts } from "./partial"
import styles from './connectedAccounts.module.scss'
import strings from 'strings'

const t = strings.CARRIERS_MANAGEMENT

interface IConnectedAccountsProps {
    
}

const ConnectedAccounts: React.FC<IConnectedAccountsProps> = (props) => {
    const [tabValue, setTabValue] = useState(0)
    const [showInviteCarrier, setShowInviteCarrier] = useState(false)

    const handleSuccessCallback = () => {
        setShowInviteCarrier(false)
    }

    return (
        <Container id={"containerConnectedAccountsList"} className={styles.connectedAccountsContainer}>
            <HeaderBar
                title={t.MY_CONNECTED_ACCOUNTS}
                primaryBtnProps={{
                    label: t.INVITE_CARRIER,
                    className: styles.invitationButton,
                    onClick: () => setShowInviteCarrier(true),
                }}
            />
            <Tabs
                tabData={connectedAccountsTabs}
                value={tabValue}
                id={connectedAccountsTabs[tabValue].label}
                handleChange={(_e, val) => setTabValue(val)}
                sxContainer={{ marginLeft: '1rem' }}
            />
            {tabValue === 0 && <MyConnectedAccounts />}
            {tabValue === 1 && <InvitedAccounts />}
            <ChildCarrierInvitation
                open={showInviteCarrier}
                onClose={() => setShowInviteCarrier(false)}
                onSuccessCallback={handleSuccessCallback}
            />
        </Container>
    )
}

export default ConnectedAccounts