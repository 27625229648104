const transmittingData = [
  {
    name: 'DOLLARS\nPROCESSED',
    icon: 'icon_dollars',
    count: '2.1M',
  },
  {
    name: 'PAYMENTS TRANSMITTED',
    icon: 'icon_payments_trans',
    count: '125K',
  },
  {
    name: 'CHECKS CAPTURED',
    icon: 'icon_checks',
    count: '90K',
  },
  {
    name: 'FDIC BANKS',
    icon: 'icon_banks',
    count: '24',
  },
]

const changelogHtmlString = (companyName?: string) => {
  return `<br />
<div style="text-align: center; font-family: Inter-Medium; color: #666666; font-size: 20px; font-weight: 600;">Risk Intelligence powered by TruckerCloud</div> <br />
<br />
<div><span style="font-family: Inter-Regular" font-weight: 400;>The leading telematics data platform for commercial auto insurance delivers insights across 40+ telematics providers, focused on identifying behavioral trends over time. TruckerCloud provides actionable insights specific to mileage and geographical exposure, as well as speeding and driving behavior by fleet and asset.</span></div> <br />
<br />
<div><span style="text-align: center;font-family: Inter-Regular" font-weight: 400;>Please reach out to <b><a href="mailto:support@truckercloud.com" style="text-decoration: none; color: #52a5ea;">support@truckercloud.com</a></b> if you have any questions.</span></div> <br />
`
}

export { transmittingData, changelogHtmlString }
