import request from 'tools/request'

const getTagCategories = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-category`,
    {
      params: {
        isActive: true,
      },
    }
  )
  return res?.data
}

const createTagSubcategory = async (payLoad: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory`,
    payLoad
  )
  return res?.data
}

const getTagSubcategories = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory`
  )
  return res?.data
}

const editTagSubcategory = async (payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/edit`,
    payLoad
  )
  return res?.data
}

const deleteTagSubcategory = async (payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/delete`,
    payLoad
  )
  return res?.data
}

const getAvailableCarriersForTags = async (tagId: string) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/${tagId}/available-carriers`
  )
  return res?.data
}

const getAssignedCarriersForTags = async (tagId: string) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/${tagId}/tagged-carriers`
  )
  return res?.data
}

const assignCarriersForTags = async (tagId: string, payLoad: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/${tagId}`,
    payLoad
  )
  return res?.data
}

const unassignCarriersForTags = async (tagId: string, payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/tag-subcategory/${tagId}/unassign-carriers`,
    payLoad
  )
  return res?.data
}

export {
  getTagCategories,
  createTagSubcategory,
  getTagSubcategories,
  editTagSubcategory,
  deleteTagSubcategory,
  getAvailableCarriersForTags,
  getAssignedCarriersForTags,
  assignCarriersForTags,
  unassignCarriersForTags,
}
