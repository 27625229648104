import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router'
import {
  getEldTerms,
  onboardCarrier,
  onboardOauth,
  carrierSignupOnboarding,
  updateLastCheckedEld,
} from 'api'
import {
  Container,
  Modal,
  Icon,
  TypoGraph,
  Button,
  Toast as toast,
} from 'components'
import { InputFormField } from 'components/hookForm'
import { CheckBoxField } from 'components/controllers'
import {
  checkEldVendor,
  evaluateOAuth,
  buildMotiveUrl,
  buildCookies,
  removeAllCookies,
  buildSamsaraCookies,
  buildSamsaraUrl,
  checkIsCanal,
  buildCanalRedirection,
  checkIsOneNetwork,
  getCookiesValues,
  evaluateCompanyId,
  checkIsCoverwhaleTest
} from '../oauthHelper'
import { requiredData } from 'constants/cob'
import styles from './authorize.module.scss'
import colors from 'theme/colors'
import { CircularProgress, Backdrop } from '@mui/material'
import { GetCookie } from 'tools/cookies'
import WidgetSuccessPage from '../successPage'
import { isEldProviderForSimulation } from 'tools/customBehaviors'
import { reportException } from 'tools/logs'

const cobImgUrl = process.env.REACT_APP_COB_IMAGE_URL

interface IAuthorizeModalProps {
  id?:string
  open: boolean
  selectedEld: any
  invitationKey: string | null
  carrierId: string | number | null
  cobListData: any
  eldIndex?: any
  cookieData?: any
  canalRedirection?: any
  oneNetworkCustomBehavior?: any
  coverwhaleTestCustomBehavior?: any
  isSimulation?: boolean
  handleClose: () => void
}

const AuthorizeModal: React.FC<IAuthorizeModalProps> = (props) => {
  const {
    id,
    open,
    handleClose,
    selectedEld,
    invitationKey,
    carrierId,
    cobListData,
    eldIndex,
    cookieData,
    canalRedirection,
    oneNetworkCustomBehavior,
    coverwhaleTestCustomBehavior,
    isSimulation,
  } = props
  const eldDataSharing = selectedEld?.eldDataSharing || {}

  const [showPermissions, setShowPermissions] = useState(false)
  const [step, setStep] = useState(0)
  const [termsUrl, setTermsUrl] = useState('')
  const [eldFormData, setEldFormData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [doneBtnLoader, setDoneBtnLoader] = useState(false)
  const [authorizeStatus, setAuthorizeStatus] = useState(0) // 0 - shows normal screen with t&c, 1 - shows success modal content, 2 - shows credential failed modal content, 3 - shows modal loader
  const [mobileView, setMobileView] = useState(window.innerWidth < 1000)
  const [driverPermissionReady, setDriverPermissionReady] = useState(false)
  const [hosPermissionReady, setHosPermissionReady] = useState(false)
  const [successfulOnboarding, setSuccessfulOnboarding] = useState(false)
  const [fvInvitationToken, setFvInvitationToken] = useState(null);
  const [loadingTermsAndCondition, setLoadingTermsAndCondition] = useState<boolean>(false)
  const fvUrl = process.env.REACT_APP_FV_URL

  const navigate = useNavigate()

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (cookieData && cobListData) {
      setAuthorizeStatus(3)
      setLoading(true)
      setEldFormData((prev: any) => ({
        ...prev.eldFormData,
        eldDataSharing: {
          ...cookieData?.eldDataSharing,
        },
      }))
      const companyId = evaluateCompanyId(cobListData, selectedEld, false)
      const payLoad: any = {
        invitationToken: cookieData?.invitationKey,
        code: cookieData?.code,
        companyId: companyId,
        eldName: cookieData?.eldVendor,
        eldDataSharing: cookieData?.eldDataSharing,
      }
      if (checkIsCanal()) {
        payLoad.postbackStatus = {
          url: cookieData?.canalUrl ?? canalRedirection?.url,
          type: 'GET',
          state: cookieData?.canalState ?? canalRedirection?.state,
        }
      }
      else if (checkIsOneNetwork()) {
        payLoad.postbackStatus = {
          url: 'https://rtvn9499.onenetwork.com/oms/rest/LNTL/lntl-tranzlogix/NotifyCarrier',
          type: 'POST',
          data: [
            '[CARRIER_NAME]|' + (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.carrierName : null),
            '[ELD_PROVIDER]|' + (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.eldProvider : null),
            '[SCAC]|'+ (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.scac : null),
          ],
        }
      }
      else if (checkIsCoverwhaleTest()) {
        payLoad.postbackStatus = {
          url: 'https://hkdk.events/3yhd3bkli6u5pa',
          type: 'POST',
          usesAdditionalData: true,
          data: [
            '[POLICY_ID]|' + (coverwhaleTestCustomBehavior ? coverwhaleTestCustomBehavior.policyId : GetCookie('policyId', true)),
          ],
        }
      }
      onboardOauth(payLoad)
        .then((res: any) => {
          setFvInvitationToken(res?.invitationToken)
          toast({ title: res?.message })
          setAuthorizeStatus(1)
          removeAllCookies(false, false, false)
        })
        .catch((error: any) => {
          reportException(error)
          setAuthorizeStatus(2)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [cookieData, cobListData])

  useEffect(() => {
    if (!doneBtnLoader && !successfulOnboarding) {
      const defaultValues = selectedEld?.eldMetadataParams?.map((item: any) => {
        if (!item?.paramIsVisible) return null
        return {
          [item.paramName]: item?.paramDefaultValue || '',
        }
      })
      const formData = Object.assign({}, ...(defaultValues || []))
      reset(formData)
      setShowPermissions(false)
      setEldFormData(selectedEld)
      setStep(0)
      setLoadingTermsAndCondition(true)
      if (!!open) {
        getEldTerms(invitationKey, selectedEld?.eldVendor).then((res: any) => {
          setTermsUrl(res?.url || '')
        }).finally(() => {
          setLoadingTermsAndCondition(false)
        });
        if (open && selectedEld && invitationKey) {
          updateLastCheckedEld(invitationKey, selectedEld?.eldVendorId)
        }
      }
    }
  }, [open, invitationKey, selectedEld, doneBtnLoader, successfulOnboarding, reset])

  useEffect(() => {
    const handleWindowResize = () => {
      setMobileView(window.innerWidth < 1000)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })
  
  useEffect(() => {
    if (driverPermissionReady === true && hosPermissionReady === true) {
      const companyId = evaluateCompanyId(cobListData, selectedEld, true)
      const oAuthEldIndex = checkEldVendor(selectedEld)
      if (oAuthEldIndex === 1) {
        if(!!selectedEld.isFvOauth){
          const selectedEldFv = cobListData?.removedElds?.find(
            (info: { eldProviderId: number }) => info.eldProviderId === selectedEld?.eldFvProviderId) ?? selectedEld;
          buildCookies(
            eldIndex,
            invitationKey,
            eldFormData?.eldDataSharing,
            selectedEldFv
          )
          window.location.href = window.location.protocol+"//"+fvUrl+ '/cob/fv?invitationToken='+ invitationKey+"&eldProviderId="+selectedEld?.eldProviderId
        }else{
          buildCookies(
            eldIndex,
            invitationKey,
            eldFormData?.eldDataSharing,
            selectedEld
          )
  
          window.location.href = buildMotiveUrl(companyId, selectedEld)
        }   
      } else if (oAuthEldIndex === 2) {
        const csrf = buildSamsaraCookies(
          eldIndex,
          invitationKey,
          eldFormData?.eldDataSharing,
          selectedEld
        )
        window.location.href = buildSamsaraUrl(companyId, selectedEld, csrf)
      }
      else setStep(1)
    }
  }, [driverPermissionReady, hosPermissionReady])

  const handleConfirmPermission = () => {
    setShowPermissions(false)
  }

  const onSubmitCredentials = (formData: any) => {
    setLoading(true)
    let payLoad = { ...eldFormData }
    Object.entries(formData).forEach(([key, val]) => {
      payLoad?.eldMetadataParams?.map((item: any) => {
        if (item?.paramName === key) {
          item['paramValue'] = val
        }
        return item
      })
    })
    payLoad = {
      carrierInfo: {
        carrierId: carrierId,
        carrierName: (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.carrierName : 
                  coverwhaleTestCustomBehavior ? coverwhaleTestCustomBehavior.carrierName : null),
        carrierCodes: cobListData?.carrierInfo?.carrierCodes,
      },
      contactInfo: cobListData?.contactInfo,
      invitationInfo: cobListData?.invitationInfo,
      eldInfo: [payLoad],
    }
    if (checkIsCanal() === true) {
      payLoad.postbackStatus = {
        url: cookieData?.canalUrl ?? canalRedirection?.url,
        type: 'GET',
        state: cookieData?.canalState ?? canalRedirection?.state,
      }
    }
    else if (checkIsOneNetwork() === true) {
      payLoad.postbackStatus = {
        url: '',
        type: 'POST',
        state: '',
        data: [
          '[CARRIER_NAME]|' + (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.carrierName : null),
          '[ELD_PROVIDER]|' + (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.eldProvider : null),
          '[SCAC]|'+ (oneNetworkCustomBehavior ? oneNetworkCustomBehavior.scac : null),
        ],
      }
    }
    else if (checkIsCoverwhaleTest() === true) {
      payLoad.postbackStatus = {
        url: 'https://hkdk.events/3yhd3bkli6u5pa',
        type: 'POST',
        state: '',
        usesAdditionalData: true,
        data: [
          '[POLICY_ID]|' + (coverwhaleTestCustomBehavior ? coverwhaleTestCustomBehavior.policyId : GetCookie('policyId', true)),
        ],
      }
    }
    onboardCarrier(invitationKey, payLoad)
      .then((res: any) => {
        toast({id:"carrierOnboardingToast", title: res?.message })
        cobListData.eldInfo[eldIndex].overallStatus = 'VERIFIED'
        setAuthorizeStatus(1)
      })
      .catch((error: any) => {
        reportException(error)
        setAuthorizeStatus(2)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const setOAuthPermissions = (name: string, checked: boolean) => {
    setEldFormData((prev: any) => ({
        ...prev,
        eldDataSharing: {
          ...prev.eldDataSharing,
          [name]: checked,
        },
    }))
    if (name === 'driverData') setDriverPermissionReady(true)
    else if (name === 'hoursOfServiceData') setHosPermissionReady(true)
  }

  const handleCheckboxSelection = (e: any, name: any) => {
    setEldFormData((prev: any) => ({
      ...prev,
      eldDataSharing: {
        ...prev.eldDataSharing,
        [name]: e.target.checked,
      },
    }))
  }

  const handleDone = () => {
    setDoneBtnLoader(true)
    const inviteKey = !!cobListData?.invitationInfo.hasFreightVisibility
      && !!fvInvitationToken
      ? fvInvitationToken
      : invitationKey
    carrierSignupOnboarding(inviteKey)
      .then((res: any) => {
        setAuthorizeStatus(0)
        setStep(0)
        setShowPermissions(false)
        reset({})
        handleClose()
        if (checkIsCanal() && res?.data?.postbackSent !== true) {
          const canalRedirection = buildCanalRedirection(200)
          removeAllCookies(true, false, false)
          window.location.href = canalRedirection
        } else if (oneNetworkCustomBehavior || checkIsOneNetwork()) {
          removeAllCookies(false, true, false)
          setSuccessfulOnboarding(true)
        } else if (coverwhaleTestCustomBehavior || checkIsCoverwhaleTest()) {
          removeAllCookies(false, false, true)
          navigate('/login', { replace: true })
        } else {
          removeAllCookies(true, true, true)
          navigate('/login', { replace: true })
        }
      })
      .catch((error: any) => {
        reportException(error)
        const statusCode = error?.response?.status
        if (checkIsCanal()) {
          const canalRedirection = buildCanalRedirection(statusCode)
          window.location.href = canalRedirection
        }
      })
      .finally(() => {
        setDoneBtnLoader(false)
      })
  }

  const handleAddAnotherEld = () => {
    removeAllCookies(false, false, false)
    setAuthorizeStatus(0)
    setStep(0)
    handleClose()
  }

  const handleTryAgain = () => {
    if (cookieData) {
      const oAuthEldIndex = checkEldVendor(selectedEld)
      const companyId = evaluateCompanyId(cobListData, selectedEld, true)
      const isOAuth = evaluateOAuth(cobListData?.invitationInfo, selectedEld)
      if (isOAuth && isOAuth === true) {
        if (oAuthEldIndex === 1) {
          if(!!selectedEld.isFvOauth){
            const selectedEldFv = cobListData?.removedElds?.find(
              (info: { eldProviderId: number }) => info.eldProviderId === selectedEld?.eldFvProviderId) ?? selectedEld;
            buildCookies(
              eldIndex,
              invitationKey,
              eldFormData?.eldDataSharing,
              selectedEldFv
            )
            window.location.href = window.location.protocol+"//"+fvUrl+ '/cob/fv?invitationToken='+ invitationKey+"&eldProviderId="+selectedEld?.eldProviderId
          }else{
            buildCookies(
              eldIndex,
              invitationKey,
              eldFormData?.eldDataSharing,
              selectedEld
            )
            window.location.href = buildMotiveUrl(companyId, selectedEld)
          }  
        } else if (oAuthEldIndex === 2) {
          const csrf = buildSamsaraCookies(
            eldIndex,
            invitationKey,
            eldFormData?.eldDataSharing,
            selectedEld
          )
          window.location.href = buildSamsaraUrl(companyId, selectedEld, csrf)
        }
      } else {
        setAuthorizeStatus(0)
        setStep(1)
      }
    } else {
      setAuthorizeStatus(0)
      setStep(1)
    }
  }

  const handleAuthorize = async () => {
    if (!isSimulation || (isSimulation === true && isEldProviderForSimulation(selectedEld.eldVendor) === false)) {
      const isOAuth = evaluateOAuth(cobListData?.invitationInfo, selectedEld)
      if (isOAuth === true) {
        // Manually set permissions in case of OAuth
        setOAuthPermissions('driverData', true)
        setOAuthPermissions('hoursOfServiceData', true)

        await new Promise((resolve) => setTimeout(resolve, 0))
      } else setStep(1)
    }
  }

  const RenderInputField = (props: any) => {
    const { item } = props
    if (!item?.paramIsVisible) return <></>
    switch (item?.paramName) {
      case 'password':
        return (
          <InputFormField
              id={"eldPassword"}
            type="password"
            name={item?.paramName}
            label={item?.paramName}
            required={item?.paramIsRequired}
          />
        )
      default:
        return (
          <InputFormField
              id={"eldApikey"}
            name={item?.paramName}
            label={item?.paramName}
            required={item?.paramIsRequired}
          />
        )
    }
  }

  const AuthorizeComponent = () => {
    return (
      <Container>
        <Container
          className={`${styles.formContainer} ${
            mobileView && styles.mobileFormContainer
          }`}
        >
          <FormProvider {...methods}>
            {selectedEld?.eldMetadataParams?.map((item: any) => (
              <RenderInputField item={item} />
            ))}
          </FormProvider>
        </Container>
        <Container
          className={`${styles.infoContainer} ${
            mobileView && styles.mobileInfoContainer
          }`}
        >
          <Icon name="icon_info" />
          <TypoGraph>
            <TypoGraph
              variant="subtitle2"
              component="span"
              content="Learn how to obtain this information from "
            />
            <TypoGraph
              variant="subtitle2"
              component="span"
              content={selectedEld?.eldVendorDisplayName}
              color={colors.primary}
              onClick={() =>
                window.open(
                  'https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435'
                )
              }
              link
            />
          </TypoGraph>
          <Icon
            name="icon_redirect"
            color={colors.primary}
            onClick={() =>
              window.open(
                'https://truckercloud.zendesk.com/hc/en-us/articles/4415549969435'
              )
            }
            iconButton
          />
        </Container>
        <Container className={styles.authorizeBtnContainer}>
          <Button id={"backVerifyCredentialsButton"} variant="outlined" label="Back" onClick={() => setStep(0)} />
          <Button
              id={"verifyCredentialsButton"}
            label="Verify Credentials"
            loading={loading}
            onClick={handleSubmit(onSubmitCredentials)}
          />
        </Container>
      </Container>
    )
  }

  const PermissionComponent = () => {
    const isOAuth = evaluateOAuth(cobListData?.invitationInfo, selectedEld)
    return (
      <>
        <Container
          className={`${styles.sectionListContainer} ${
            mobileView && styles.mobileSectionList
          }`}
        >
          <Container className={styles.actionSectionCentered}>
            <TypoGraph
              variant="subtitle1"
              content="Required Data"
              className={styles.sectionTitle}
            />
            <Container className={styles.requireContainer}>
              {requiredData.map((item: any) => (

                <Container key={item.title} className={styles.requireItem}>
                  <Icon name={item.icon} color={colors.black} size={30} />
                  <Container>
                    <TypoGraph content={item.title} />
                    <TypoGraph
                      variant="subtitle2"
                      content={item.content}
                      color={colors.grey}
                    />
                  </Container>
                </Container>
              ))}
            </Container>
          </Container>
          <Container className={styles.actionSection}>
            <TypoGraph
              variant="subtitle1"
              content="Suggested Data"
              className={styles.sectionTitle}
            />
            <Container className={styles.requireContainer}>
              {/* Driver Data */}
              <Container
                className={`${styles.requireItem} ${
                  showPermissions &&
                  !eldDataSharing?.driverDataEnabled &&
                  styles.disableContainer
                }`}
              >
                <Container className={styles.checkboxContainer}>
                  {showPermissions && (
                    <CheckBoxField
                        id={"driverData"}
                      onChange={(e) => handleCheckboxSelection(e, 'driverData')}
                      value={eldFormData?.eldDataSharing?.driverData}
                      checked={isOAuth && isOAuth === true}
                      disabled={!eldDataSharing?.driverDataEnabled || isOAuth && isOAuth === true}
                    />
                  )}
                  <Icon name="icon_profile" color={colors.black} size={30} />
                </Container>
                <Container>
                  <TypoGraph content="Driver Data" />
                  <TypoGraph
                    variant="subtitle2"
                    content="Driver ID’s, Email, Phone"
                    color={colors.grey}
                  />
                </Container>
              </Container>
              {/* HOS Data */}
              <Container
                className={`${styles.requireItem} ${
                  showPermissions &&
                  !eldDataSharing?.hoursOfServiceDataEnabled &&
                  styles.disableContainer
                }`}
              >
                <Container className={styles.checkboxContainer}>
                  {showPermissions && (
                    <CheckBoxField
                        id={"HOSData"}
                      onChange={(e) =>
                        handleCheckboxSelection(e, 'hoursOfServiceData')
                      }
                      value={eldFormData?.eldDataSharing?.hoursOfServiceData}
                      disabled={!eldDataSharing?.hoursOfServiceDataEnabled || isOAuth}
                      checked={isOAuth}
                    />
                  )}
                  <Icon name="cob_clock" color={colors.black} size={30} />
                </Container>
                <Container>
                  <TypoGraph content="Hours of Service" />
                  <TypoGraph
                    variant="subtitle2"
                    content="Hours of Service"
                    color={colors.grey}
                  />
                </Container>
              </Container>
              {/* Engine Data */}
              <Container
                className={`${styles.requireItem} ${
                  showPermissions &&
                  !eldDataSharing?.engineDataEnabled &&
                  styles.disableContainer
                }`}
              >
                <Container className={styles.checkboxContainer}>
                  {showPermissions && (
                    <CheckBoxField
                        id={"engineData"}
                      onChange={(e) => handleCheckboxSelection(e, 'engineData')}
                      value={eldFormData?.eldDataSharing?.engineData}
                      disabled={!eldDataSharing?.engineDataEnabled}
                    />
                  )}
                  <Icon name="cob_engine" color={colors.black} size={30} />
                </Container>
                <Container>
                  <TypoGraph content="Engine Data" />
                  <TypoGraph
                    variant="subtitle2"
                    content="Diagnostics & maintenance"
                    color={colors.grey}
                  />
                </Container>
              </Container>
              {/* Safety Data */}
              <Container
                className={`${styles.requireItem} ${
                  showPermissions &&
                  !eldDataSharing?.safetyEventsDataEnabled && 
                  styles.disableContainer
                }`}
              >
                <Container className={styles.checkboxContainer}>
                  {showPermissions && (
                    <CheckBoxField
                      id={"safetyEventsData"}
                      onChange={(e) => handleCheckboxSelection(e, 'safetyEventsData')}
                      // Value for "value" for Safety is coming from Backend, but logic is:
                      // !companyProfile?.fullData?.allowSafetyPermissionSelect && cobListData?.invitationInfo?.isInsurance
                      value={eldFormData?.eldDataSharing?.safetyEventsData}
                      checked={isOAuth && isOAuth === true}
                      disabled={!eldDataSharing?.safetyEventsDataEnabled || isOAuth && isOAuth === true}
                    />
                  )}
                  <Icon name="cob_safety" color={colors.black} size={30} />
                </Container>
                <Container>
                  <TypoGraph content="Safety Events Data" />
                  <TypoGraph
                    variant="subtitle2"
                    content="Safety events"
                    color={colors.grey}
                  />
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
        <Container
          className={`${styles.permissionBtnContainer} ${
            mobileView && styles.mobilePermissionBtn
          }`}
        >
          {showPermissions ? (
            <Button
                id={"confirmPermissions"}
              variant="outlined"
              label="Confirm Permission"
              onClick={handleConfirmPermission}
            />
          ) : (
            <Button
                id={"checkPermissions"}
              variant="outlined"
              label="Permissions Management"
              onClick={() => setShowPermissions(true)}
            />
          )}
          <Button  id={"authorizeOnboard"} label="Authorize" onClick={handleAuthorize} />
        </Container>
      </>
    )
  }

  const ModalContent = () => {
    return (
      <Container
        className={`${styles.modalContentContainer} ${
          mobileView && styles.mobileModalContent
        }`}
      >
        {!oneNetworkCustomBehavior &&
        <Icon
          name="icon_cancel"
          boxClassName={styles.cancelBtn}
          onClick={() => {
            handleClose()
            setAuthorizeStatus(0)
            setStep(0)
          }}
          iconButton
        />}
        <Container
          className={`${styles.logoSwitchContainer} ${
            mobileView && styles.mobileLogoSwitch
          }`}
        >
          {companyProfile?.logo ? (
            <Icon
              type="images"
              url={companyProfile?.logo}
              boxClassName="fullLogo"
              width={200}
              height={50}
            />
          ) : (
            <Icon
              name={open ? 'icon_logoFullname' : 'icon_logo'}
              width={200}
              height={30}
            />
          )}
          <Icon
            name="alternate_arrow"
            size={mobileView ? 15 : 35}
            boxClassName={mobileView ? styles.mobileArrow : ''}
          />
          <Icon
            type="images"
            url={`${cobImgUrl}/${selectedEld?.eldVendor}`}
            size={100}
            boxClassName={styles.logoContainer}
          />
        </Container>
        {authorizeStatus === 1 && (
          <Container>
            <Container className={styles.titleContainer}>
              <TypoGraph
                variant="h2"
                content="Credentials Verified"
                sx={{ textAlign: 'center' }}
              />
              <TypoGraph
                variant="body2"
                content={`Your ${selectedEld?.eldVendor} credentials have been verified.`}
                sx={{ textAlign: 'center' }}
              />
              <Icon name="icon_dialog_success" size={mobileView ? 150 : 300} />
            </Container>
            <Container className={styles.permissionBtnContainer}>
              {!oneNetworkCustomBehavior &&
              <Button
                id={"addOtherEld"}
                variant="outlined"
                label="Add Another ELD"
                onClick={handleAddAnotherEld}
              />
              }
              <Button
                id={"doneOnboard"}
                label="Done"
                loading={doneBtnLoader}
                onClick={handleDone}
              />

            </Container>
          </Container>
        )}
        {authorizeStatus === 2 && (
          <Container>
            <Container className={styles.titleContainer}>
              <TypoGraph
                variant="h2"
                content="Credentials Failed"
                sx={{ textAlign: 'center' }}
              />
              <TypoGraph
                variant="body2"
                content={`Your ${selectedEld?.eldVendor} credentials could not be verified. Please try again.`}
                sx={{ textAlign: 'center' }}
              />
              <Icon name="icon_dialog_warning" size={mobileView ? 150 : 300} />
            </Container>
            <Container className={styles.permissionBtnContainer}>
              <Button id={"tryToVerifyAgain"} label="Try Again" onClick={handleTryAgain} />
            </Container>
          </Container>
        )}
        {authorizeStatus === 3 && (
          <Container>
            <Container className={styles.titleContainer}>
              <TypoGraph
                variant="h2"
                content="Verifying Credentials"
                sx={{ textAlign: 'center' }}
              />
              <TypoGraph
                variant="body2"
                content={`We are verifying credentials, this should only take a moment.`}
                sx={{ textAlign: 'center' }}
              />
              <Container className={styles.verifyLoaderContainer}>
                <CircularProgress />
              </Container>
            </Container>
          </Container>
        )}
        {authorizeStatus === 0 && !loadingTermsAndCondition && (
          <>
            <Container className={styles.titleContainer}>
              <TypoGraph
                variant="h2"
                content={(cobListData?.invitationInfo?.invitingCompanyName ?? "TruckerCloud") + ` will be able to connect to ${selectedEld?.eldVendor}`}
                className={mobileView ? styles.authorizeTitle : styles.titleContainer}
              />
              <TypoGraph
                sx={{ textAlign: 'center' }}
                className={mobileView ? styles.subtitleContainer : ''}
              >
                <TypoGraph
                  variant="body2"
                  component="span"
                  content={`By authorizing, you agree to TruckerCloud's `}
                />
                <TypoGraph
                  variant="body2"
                  component={mobileView ? 'div' : 'span'}
                  content={`Terms & Conditions`}
                  color={colors.primary}
                  onClick={() => window.open(termsUrl)}
                  link
                />
              </TypoGraph>
            </Container>
            {step === 0 && <PermissionComponent />}
            {step === 1 && <AuthorizeComponent />}
          </>
        )}
        {authorizeStatus === 0 && loadingTermsAndCondition && (
          <Container className={styles.loadingModal}>
            <CircularProgress  />
          </Container>
        )}
      </Container>
    )
  }

  return (
    <>
      {(oneNetworkCustomBehavior &&
      <>
        <Backdrop open={open} className={styles.backdrop}>
        {successfulOnboarding === true ? 
          <WidgetSuccessPage
            isOneNetwork={true}
            messageTitle={'Onboarding completed!'}
            messageText={`Your ${selectedEld?.eldVendor} onboarding it's finished.`}
          />
         :
          <Modal
            open={open}
            dom={<ModalContent />}
            dismissOnClose={false}
            handleClose={() => {
              handleClose()
            }}
            className={styles.showScrollbar}
            backdropFused={true}
          />}
        </Backdrop>
      </>
      ) || (
      <Modal
        open={open}
        handleClose={() => {
          handleClose()
          setAuthorizeStatus(0)
          setStep(0)
        }}
        dom={<ModalContent />}
      />
      )}
    </>
  )
}

export default AuthorizeModal
