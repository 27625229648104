import React from 'react'
import { Route, Routes, RouteProps } from 'react-router-dom'
import Page404 from 'pages/page404'
import CarrierList from 'pages/carriers'
import CarrierDetails from 'pages/carriers/partial/carrierDetails'
import strings from 'strings'

const CarriersRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path={strings.ROUTES.CURRENT_PATH} element={<CarrierList />} />
      <Route path={strings.ROUTES.CARRIER_DETAILS} element={<CarrierDetails />} />
      <Route path={strings.ROUTES.WILDCARD} element={<Page404 />} />
    </Routes>
  )
}

export default CarriersRoutes
