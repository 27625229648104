import request from 'tools/request'

export const getEmailAccountsList = async (params?: any) => {
  const res = await request.get('carrieremailaccount/list', {
    params: {
      ...params,
      // companyId: 3151
    },
  })
  return res?.data
}

/**Paylod = {senderName, senderEmail, id:null, companyId: 3151} */
export const addEmailAccount = async (payload: any) => {
  const res = await request.post('carrieremailaccount/add', {
    companyId: 3151,
    ...payload,
  })
  return res?.data
}

export const updateEmailAccount = async (payload: any) => {
  const res = await request.post('carrieremailaccount/update', {
    companyId: 3151,
    ...payload,
  })
  return res?.data
}

export const getCarrierAccountList = async () => {
  const res = await request.get('carrieremailaccount/list/verified')
  return res?.data
}
