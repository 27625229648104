const clientSearch = (query: string, data: any) => {
  // Convert the query to lowercase for case-insensitive search
  const searchTerm = query.toLowerCase()

  // Filter the data array based on the search criteria
  const filteredData = data.filter((item: any) => {
    const values = Object.values(item)
    for (let value of values) {
      if ((
            typeof value === 'string' && value.toLowerCase().includes(searchTerm)
          ) || (
            Array.isArray(value) && value.some((v: any) => v.codeType === 'DOT' && v.codeValue.toLowerCase().includes(searchTerm))
          )) {
        return true
      }
    }

    return false
  })

  return filteredData
}

export default clientSearch
