import 'App.scss';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import { TypoGraph, Container } from 'components';
import { useEffect, useState } from 'react';
import { formatRiskCell } from 'tools/helper';
import { numberComparator, milesRangeComparator, numberOrStringComparator } from 'tools/comparators';
interface ColumnDescriptionWithoutTitleProps {
    description: string;
    cell?: boolean;
}

const ColumnDescriptionWithoutTitle = ({ description, cell }: ColumnDescriptionWithoutTitleProps) => {
    return (
        <Container
            className="columnDescriptionContainer withoutTitle"
            sx={cell ? { gridTemplateRows: '0.5rem 0.5rem' } : undefined}
        >
            <TypoGraph
                variant="subtitle1"
                content={description}
                className="descriptionText"
                sx={{ paddingBottom: '1rem!important', marginLeft: 'auto!important', marginRight: 'auto!important' }}
            />
        </Container>
    );
};
const classNameColor = (params: GridCellParams<any>) => {
    switch (params?.value) {
        case 'Poor':
            return 'poor';
        case 'Average':
            return 'average';
        case 'Great':
            return 'great';
        default:
            return '';
    }
};
const firstRowStyle =(params: GridCellParams<any>) =>{
    if(params?.row?.index === 0){
        return 'firstRow'
    }
}

const percentileGroupsDefinition = [
    {
        groupId: 'carrierName',
        headerName: 'Fleet Name',
        fields: [{ field: 'carrierName' }],
        headerClassName: 'columnGroupTitle withBottomBorder centered',
    },
    {
        groupId: 'vin',
        headerName: 'VIN',
        fields: [{ field: 'vin' }],
        headerClassName: 'columnGroupTitle withBorder withBottomBorder centered',
    },
    {
        groupId: 'miles',
        headerName: 'Miles',
        fields: [{ field: 'miles' }],
        headerClassName: 'columnGroupTitle withBorder withBottomBorder centered',
    },
    {
        groupId: 'overallScore',
        headerName: 'Overall Score',
        fields: [{ field: 'overallScore' }],
        headerClassName: 'columnGroupTitle withBorder withBottomBorder centered',
    },
    {
        groupId: 'speed',
        headerName: 'Speed',
        fields: [
            { field: 'speedOver5To15' },
            { field: 'speedOver15' },
        ],
        headerClassName: 'columnGroupTitle withBorder withBottomBorder centered',
    },
    {
        groupId: 'harshEvents',
        headerName: 'Harsh Events',
        fields: [
            { field: 'harshEventSev1' },
            { field: 'harshEventSev2' },
            { field: 'harshEventSev3' },
        ],
        headerClassName: 'columnGroupTitle withBorder withBottomBorder centered',
    },
];
const percentileColumnsDefinition = [
    { 
        field: 'carrierName', 
        headerName: 'Fleets', 
        minWidth: 150, 
        flex: 1.5, 
        sortable: true, 
        className: 'percentileTable'
    },
    { 
        field: 'vin', 
        headerName: 'Vehicle Identifier', 
        minWidth: 100, 
        flex: 1.2, 
        sortable: true,
        className: 'percentileTable columnCellBorder center'
    },
    { 
        field: 'miles', 
        headerName: 'Total', 
        minWidth: 100, 
        flex: 1, 
        sortable: true,
        sortComparator: milesRangeComparator,
        className: 'percentileTable columnCellBorder center',
    },
    { 
        field: 'overallScore', 
        headerName: 'Summary', 
        minWidth: 100, 
        flex: 1, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.overallScore, 1)
        ),
    },
    { 
        field: 'speedOver5To15', 
        headerName: '5-15 Over', 
        minWidth: 80, 
        flex: 0.8, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.speedOver5To15, 1)
        ),
    },
    { 
        field: 'speedOver15', 
        headerName: '15 Over', 
        minWidth: 80, 
        flex: 0.8, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.speedOver15, 1)
        ),
    },
    { 
        field: 'harshEventSev1', 
        headerName: 'Extreme', 
        minWidth: 80, 
        flex: 0.8, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.harshEventSev1, 1)
        ),
    },
    { 
        field: 'harshEventSev2', 
        headerName: 'Braking', 
        minWidth: 80, 
        flex: 0.8, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.harshEventSev2, 1)
        ),
    },
    { 
        field: 'harshEventSev3', 
        headerName: 'Corn/Accel', 
        minWidth: 80, 
        flex: 0.8, 
        sortable: true,
        sortComparator: numberComparator,
        className: 'percentileTable columnCellBorder center',
        renderCell: (params: GridRenderCellParams) => (
            formatRiskCell(params.row?.harshEventSev3, 1)
        ),
    },
];

interface FleetPercentileTableDataProps {
    onDataReady: (data: any) => void;
}

const FleetPercentileTableData: React.FC<FleetPercentileTableDataProps> = ({ onDataReady }) => {
    const [filteredPercentileGroups, setFilteredPercentileGroups] = useState([]);
    const [filteredPercentileColumns, setFilteredPercentileColumns] = useState([]);

    useEffect(() => {
        const groups : any = percentileGroupsDefinition;
        const columns : any = percentileColumnsDefinition;

        setFilteredPercentileGroups(groups);
        setFilteredPercentileColumns(columns);
    }, []);

    useEffect(() => {
        const percentileTable = {
            percentileGroups: filteredPercentileGroups.map(({ groupId, headerName, fields, headerClassName }) => ({
                groupId,
                headerName,
                headerClassName,
                children: fields,
            })),
            percentileColumns: filteredPercentileColumns.map(({ field, headerName, minWidth, flex, sortable, className, sortComparator, renderCell }) => {
                const columnDef: any = {
                    field,
                    headerName,
                    headerClassName: `${className} center`,
                    minWidth,
                    flex,
                    sortable,
                    renderHeader: () => <ColumnDescriptionWithoutTitle description={headerName} />,
                    renderCell,
                    cellClassName: (params: GridCellParams<any>) => `${className} ${classNameColor(params)} ${firstRowStyle(params)}`,
                };
            
                if (sortComparator !== undefined) {
                    columnDef.sortComparator = sortComparator;
                }
            
                return columnDef;
            })
        };

        onDataReady(percentileTable);
    }, [filteredPercentileGroups, filteredPercentileColumns, onDataReady]);

    return null;
};

export {
    FleetPercentileTableData,
};
