import { Container, Tabs } from 'components'
import { carrierDetailsTabs, fleetDetailsTabsForAgents } from 'constants/status'
import { useState, useRef } from 'react'
import InsuranceCarriers from '../insuranceCarriers'
import HeartbeatDashboard from '../heartbeat'
import HeartbeatTrendsDashboard from '../heartbeatTrends'
import MileageReportingDashboard from 'pages/reporting/partial/mileageReporting'
import LocationReportingDashboard from 'pages/reporting/partial/locationReporting'
import FleetMilesByZipDashboard from '../fleetMilesByZip'
import FleetDetailsForAgents from '../fleetDetailsForAgent'
import { FleetHeartbeat, FleetPercentiles } from 'pages/connectedAccounts/partial'
import { isAgentLogin } from 'tools/auth'
import RadiusOfOperationDashboard from '../radiusOfOperation'

interface ICarrierDetailProps {}

const CarrierDetails: React.FC<ICarrierDetailProps> = (props) => {
    const [carrierDetailsTab, setCarrierDetailsTab] = useState(0)

    const ref = useRef<HTMLDivElement>(null)

    const InsuranceTabPanel = (props: { print: boolean }) => {
        const { print } = props
        switch (carrierDetailsTab) {
            case 0:
                return <InsuranceCarriers print={print} />
            case 1:
                return <HeartbeatDashboard print={print} useCarrierFilters={true} />
            case 2:
                return <HeartbeatTrendsDashboard print={print} useCarrierFilters={true} />
            case 3:
                return <MileageReportingDashboard print={print} useCarrierFilters={true} />
            case 4:
                return <LocationReportingDashboard print={print} useCarrierFilters={true} />
            case 5:
                return <FleetMilesByZipDashboard print={print} useCarrierFilters={true} />
            case 6:
                return <RadiusOfOperationDashboard print={print} useCarrierFilters={true} />
            default:
                return <></>
        }
    }

    const AgentTabPanel = (props: { print: boolean }) => {
        const { print } = props
        switch (carrierDetailsTab) {
            case 0:
                return <FleetDetailsForAgents print={print} />
            case 1:
                return <FleetHeartbeat print={print} />
            case 2:
                return <FleetPercentiles print={print} />
            case 3:
                return <FleetMilesByZipDashboard print={print} useCarrierFilters={true} />
            default:
                return <></>
        }
    }

    const getPageMargins = () => {
      return `@page { margin: 30px!important; }`
    }

    return (
        <Container>
            <Tabs
                tabData={isAgentLogin() ? fleetDetailsTabsForAgents : carrierDetailsTabs}
                value={carrierDetailsTab}
                id={carrierDetailsTabs[carrierDetailsTab].label}
                handleChange={(_e, val) => setCarrierDetailsTab(val)}
                sxContainer={{
                    marginLeft: '1rem',
                }}
            />
            <Container>
                {isAgentLogin() ?
                <AgentTabPanel print={false} />
                :
                <InsuranceTabPanel print={false} />
                }
            </Container>
            <style>{getPageMargins()}</style>
        </Container>
    )                                                     
}

export default CarrierDetails