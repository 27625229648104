import { ReactNode } from 'react'
import MuiCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

interface ICardProps {
  children?: ReactNode
  raised?: boolean
  classes?: object
  sxContainer?: object
  sxContent?: object
  onClick?: any
  containerClass?: string
}

const Card: React.FC<ICardProps> = (props) => {
  const {
    children,
    raised = false,
    classes,
    sxContainer,
    sxContent,
    containerClass,
    onClick,
  } = props
  return (
    <MuiCard
      sx={{
        border: '1px solid #E9E9E9',
        boxShadow: '1px 5px 16px rgb(0 0 0 / 3%)',
        borderRadius: '14px',
        ...sxContainer,
      }}
      classes={classes}
      raised={raised}
      onClick={onClick}
      className={containerClass}
    >
      <CardContent sx={sxContent}>{children}</CardContent>
    </MuiCard>
  )
}

export default Card
