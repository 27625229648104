import { Container } from 'components'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { useEffect, useState } from 'react'

interface IColorPicker {
  sendSelectedColor?: (color: string) => void;
  openColorPicker?: boolean;
  defaultColor: string;
  onclose?: () => void;
}

const ColorPicker: React.FC<IColorPicker> = (props) => {
  const {sendSelectedColor = () => {}, openColorPicker = false, onclose = () => {}, defaultColor} = props
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    setDisplayColorPicker(openColorPicker)
  },[openColorPicker])

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
    onclose()
  }

  const handleChange = (color: any) => {
    setColor(color.hex)
    sendSelectedColor(color.hex)
  }

  const styles = reactCSS({
    default: {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <Container sx={styles.popover}>
          <Container sx={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </Container>
      ) : null}
    </div>
  )
}

export default ColorPicker
