import { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { Chip, Container, HeaderBar, TypoGraph, Tabs, Button } from 'components'
import { reportingTabs } from 'constants/status'
import styles from './reporting.module.scss'
import { InsuranceHeartbeatReport, MileageReportingDashboard, LocationReportingDashboard } from './partial'
import FleetMilesByZipDashboard from 'pages/carriers/partial/fleetMilesByZip'

interface IReportingProps {}

const Reporting: React.FC<IReportingProps> = (props) => {
  const [reportingTab, setReportingTab] = useState(0)
  const [updateDate, setUpdateDate] = useState<any>()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const HeaderDom = () => {
    return (
      <Container className={styles.headerContainer}>
        <Container className={styles.headerTitleContainer}>
          <TypoGraph
            variant="h1"
            sx={{ marginRight: '1rem' }}
            mb={0}
            content="Reporting Dashboard"
          />
          {updateDate && (
            <Chip
              label={`Updated ${moment(updateDate).format('dddd, MMMM Do')}`}
              variant="filled"
              sx={{
                backgroundColor: '#E1FAEB',
                color: '#20693D',
              }}
            />
          )}
        </Container>
      </Container>
    )
  }

  const TabPanel = (props: { print: boolean }) => {
    const { print } = props
    switch (reportingTab) {
      case 0:
        return (
          <MileageReportingDashboard
            print={print}
            useCarrierFilters={false}
          />
        )
      case 1:
        return ( 
          <LocationReportingDashboard
            print={print}
            useCarrierFilters={false}
          />
        )
      case 2:
        return (
          <InsuranceHeartbeatReport
            print={print}
            useCarrierFilters={false}
          />
        )
      case 3:
        return (
          <FleetMilesByZipDashboard
            print={print}
            useCarrierFilters={false}
          />
        )
      default:
        return <></>
    }
  }

  const getPageMargins = () => {
    return `@page { margin: 30px!important; }`
  }

  return (
    <Container>
      <HeaderBar dom={<HeaderDom />} />
      <Tabs
        tabData={reportingTabs}
        value={reportingTab}
        id={reportingTabs[reportingTab].label}
        handleChange={(_e, val) => setReportingTab(val)}
        sxContainer={{
          marginLeft: '1rem',
        }}
      />
      <Container>
        <TabPanel print={false} />
      </Container>
      <style>{getPageMargins()}</style>
      <div style={{ visibility: 'hidden' }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div ref={ref} style={{ width: '65rem' }}>
            <TabPanel print={true} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Reporting
