
import { ResponsiveBar } from '@nivo/bar'

const BarChart = ({ data /* see data tab */ }) => (
    <div style={{ height: '12rem', width: '22rem' }}>
        <ResponsiveBar
            data={data}
            keys={[
                'sandwich',
            ]}
            indexBy="country"
            margin={{ top: 20, right: 40, bottom: 20, left: 30 }}
            padding={0.7}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={'#36a8ee'}
            theme={{
                fontSize: 10,
                textColor: '#A3A3A3',
                "grid": {
                    "line": {
                        "stroke": "#f5f5f5",
                        "strokeWidth": 1
                    }
                },
            }}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'country',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'food',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            role="application"
            ariaLabel="Nivo bar chart demo"
            enableLabel={false}
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
        />
    </div>
)

export default BarChart