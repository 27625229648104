import {
  Container,
  Icon,
  SliderWithInput as Slider,
  Spinner,
  TypoGraph,
  Toast as toast,
} from 'components'
import styles from './radiusOfOperationFilter.module.scss'
import { Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { getRadiusSettings, updateRadiusSettings } from 'api'
import { reportException } from 'tools/logs'

interface IRadiusOfOperationFilterProps {
  onButtonClick: () => void
  reloadDashboard: () => void
}

const RadiusOfOperationFilter: React.FC<IRadiusOfOperationFilterProps> = (
  props
) => {
  const { onButtonClick, reloadDashboard } = props
  const [radiusSettings, setRadiusSettings] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [radius1, setRadius1] = useState<number>()
  const [radius2, setRadius2] = useState<number>()
  const [radius3, setRadius3] = useState<number>()

  const radiusRanges = {
    radius1Min: 50,
    radius1Max: 150.9,
    radius2Min: 151,
    radius2Max: 350.9,
    radius3Min: 351,
    radius3Max: 1000,
  }

  const defaultRadiusSettings = {
    radius1: 100,
    radius2: 250,
    radius3: 500,
  }

  useEffect(() => {
    callGetRadiusSettings()
  }, [])

  useEffect(() => {
    if (radius1 || radius2 || radius3) {
      callUpdateRadiusSettings()
    }
  }, [radius1, radius2, radius3])

  const callGetRadiusSettings = useCallback(() => {
    setIsLoading(true)
    getRadiusSettings(
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((response: any) => {
        setRadiusSettings(response)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setIsLoading(false)
        reloadDashboard()
      })
  }, [])

  const callUpdateRadiusSettings = useCallback(() => {
    const payload = {
      radius1: radius1?.toFixed(1) ?? defaultRadiusSettings.radius1,
      radius2: radius2?.toFixed(1) ?? defaultRadiusSettings.radius2,
      radius3: radius3?.toFixed(1) ?? defaultRadiusSettings.radius3,
    }

    updateRadiusSettings(
      payload,
      localStorage.getItem('carrierId') ?? '',
      localStorage.getItem('carrierCodeType') ?? '',
      localStorage.getItem('carrierCodeValue') ?? ''
    )
      .then((response: any) => {
        toast({
          id: 'updateRadiusSettings',
          type: 'success',
          subTitle: response?.message,
        })
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        callGetRadiusSettings()
      })
  }, [radius1, radius2, radius3])

  return (
    <Container className={styles.filterContainer}>
      <Container className={styles.sliderContainer}>
        {(isLoading && <Spinner />) || (
          <>
            <Slider
              initialValue={
                radiusSettings?.radius1 ?? defaultRadiusSettings.radius1
              }
              min={radiusRanges.radius1Min}
              max={radiusRanges.radius1Max}
              maxInputLength={5}
              step={0.1}
              color="primary"
              useInputLabel={true}
              title="Radius 1"
              label="Miles"
              onValueChange={(value) => setRadius1(value)}
            />
            <Slider
              initialValue={
                radiusSettings?.radius2 ?? defaultRadiusSettings.radius2
              }
              min={radiusRanges.radius2Min}
              max={radiusRanges.radius2Max}
              maxInputLength={5}
              step={0.1}
              color="primary"
              useInputLabel={true}
              title="Radius 2"
              label="Miles"
              onValueChange={(value) => setRadius2(value)}
            />
            <Slider
              initialValue={
                radiusSettings?.radius3 ?? defaultRadiusSettings.radius3
              }
              min={radiusRanges.radius3Min}
              max={radiusRanges.radius3Max}
              maxInputLength={5}
              step={0.1}
              color="primary"
              useInputLabel={true}
              title="Radius 3"
              label="Miles"
              onValueChange={(value) => setRadius3(value)}
            />
          </>
        )}
      </Container>
      <Button className={styles.configButton} onClick={onButtonClick}>
        <Icon materialIcon="Settings" size={30} />
        <TypoGraph variant="body1" align="center">
          Garaging Zip Code Configuration
        </TypoGraph>
      </Button>
    </Container>
  )
}
export default RadiusOfOperationFilter
