import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import Button from 'components/button'
import React from 'react'

export interface IButtonMenuItem {
  id?: string
  onClick: () => void
  label: string
  useDialog?: boolean
  dialogTitle?: string
  dialogMsg?: string
  dialogAgreeText?: string
  dialogDisagreeText?: string
  handleClose: () => void
}

const ButtonMenuItem: React.FC<IButtonMenuItem> = (props) => {
  const {
    onClick,
    label,
    useDialog,
    dialogTitle,
    dialogMsg,
    dialogAgreeText,
    dialogDisagreeText,
    handleClose,
  } = props

  const [openConfirmationPrompt, setOpenConfirmationPrompt] =
    React.useState<boolean>(false)

  const handleMenuClick = () => {
    if (useDialog) {
      setOpenConfirmationPrompt(true)
    } else {
      onClick()
      handleClose()
    }
  }

  const handleDialogClose = () => {
    setOpenConfirmationPrompt(false)
    handleClose()
  }

  const handleDialogAgree = () => {
    setOpenConfirmationPrompt(false)
    onClick()
    handleClose()
  }

  return (
    <>
      <MenuItem onClick={handleMenuClick}>{label}</MenuItem>
      {useDialog && (
        <Dialog
          open={openConfirmationPrompt}
          keepMounted
          onClose={handleDialogClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {dialogMsg || 'Are you sure you want to proceed with this action?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              label={dialogDisagreeText || 'Disagree'} 
              variant="outlined"
            />
            <Button onClick={handleDialogAgree} label={dialogAgreeText || 'Agree'} />
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
export default ButtonMenuItem