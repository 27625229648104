import request from 'tools/request'

const widgetApiUrl = process.env.REACT_APP_WIDGET_API_URL

export const submitCarrierInvitation = async (formData: any, apiKey: any, uniqueIds?: any) => {
  const payload = (uniqueIds!==null || uniqueIds!==undefined) ? uniqueIds : null
  const res = await request.put(`${widgetApiUrl}carriers/invitation`, payload, {
    params: {
      apiKey: apiKey,
      ...formData,
    },
    headers: {
      'Content-Type': 'application/json', 
    },
  })
  const data: any = res?.data;
  const filteredData = {
    ...data, eldInfo: data?.eldInfo.filter((item: { showCobList: boolean }) => item?.showCobList === true),
    removedElds: data?.eldInfo.filter((item: { showCobList: boolean }) => item?.showCobList === false)
  }
  return filteredData
}
