import { GridRenderCellParams } from "@mui/x-data-grid";
import { Chip, Tooltip, TypoGraph } from "components";
import { carrierInviteStatus } from "constants/status";
import { numberOrStringComparator, stringDateComparator } from "tools/comparators";

const invitedCarrierList = {
    column: [
      {
        field: 'carrierId',
        headerName: 'ID',
        width: 90,
      },
      {
        field: 'carrierName',
        sortable: true,
        headerName: 'Carrier Name',
        minWidth: 250,
        flex: 0.9,
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              {params.value}
            </Tooltip>
          )
        },
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        width: 150,
        minWidth: 250,
        flex: 1,
        handleClick: (row: any) => {},
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={250}
            >
              <TypoGraph
                component="span"
                variant="body2"
                onClick={() => this.handleClick(params.row)}
                link
              >
                {params.value}
              </TypoGraph>
            </Tooltip>
          )
        },
      },
      {
        field: 'dot',
        headerName: 'DOT',
        sortable: true,
        sortComparator: numberOrStringComparator,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'mc',
        headerName: 'MC',
        width: 90,
        sortable: true,
        sortComparator: numberOrStringComparator,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'scac',
        headerName: 'SCAC',
        sortable: true,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'cuid',
        headerName: 'CUID',
        sortable: true,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'eldVendorDisplayName',
        headerName: 'ELD Provider',
        sortable: true,
        minWidth: 160,
        flex: 0.6,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              {params.value || 'Unknown'}
            </Tooltip>
          )
        },
      },
      {
        field: 'invitedDate',
        headerName: 'Invited Date',
        sortable: true,
        sortComparator: stringDateComparator,
        width: 160,
        renderCell: function (params: GridRenderCellParams) {
          const invitedDate : string = params.row?.invitedDate ?? ""
          return (
              <TypoGraph component="span" variant="body1">
                { invitedDate }
              </TypoGraph>
          )
        },        
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        minWidth: 180,
        flex: 0.6,
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Chip
              label={carrierInviteStatus[params.value]?.label}
              variant="filled"
              sx={{
                backgroundColor: carrierInviteStatus[params.value].background,
                color: carrierInviteStatus[params.value].color,
              }}
            />
          )
        },
      },
    ],
  }

  export {
    invitedCarrierList
  }