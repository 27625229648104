import { useFormContext, Controller } from 'react-hook-form'
import DropdownField, { IDropdownProps } from 'components/controllers/dropdown'

interface IDropdownFieldProps extends IDropdownProps {
  name: string
  required?: boolean
  onFieldChange?: (e: any) => void
}

const DropdownFormField: React.FC<IDropdownFieldProps> = (props) => {
  const { name, required = false, options = [], onFieldChange } = props
  const { control } = useFormContext()
  const isRequired = required ? 'Field is Required' : undefined
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate: {},
      }}
      render={({
        field: { onChange, onBlur, value = '', ref },
        fieldState: { error },
      }) => {
        return (
          <DropdownField

            options={options}
            value={value ? value : ''}
            onChange={(e) => {
              onChange(e)
              onFieldChange && onFieldChange(e)
            }}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            {...props}
          />
        )
      }}
    />
  )
}

export default DropdownFormField
