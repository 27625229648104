import request from 'tools/request'

const apiUrl = `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/radius-operation/`

const getRadiusSettings = async (
  carrierId: string,
  codeType: string,
  codeValue: string
) => {
  const res = await request.get(
    `${apiUrl}settings?carrierId=${carrierId}&codeType=${codeType}&codeValue=${codeValue}`
  )
  return res.data
}

const updateRadiusSettings = async (
  payload: any,
  carrierId: string,
  codeType: string,
  codeValue: string
) => {
  const res = await request.put(
    `${apiUrl}settings?carrierId=${carrierId}&codeType=${codeType}&codeValue=${codeValue}`,
    payload
  )
  return res.data
}

const getGaragingZipcodeConfigs = async (
  carrierId: string,
  codeType: string,
  codeValue: string
) => {
  const res = await request.get(
    `${apiUrl}garaging-zipcode?carrierId=${carrierId}&codeType=${codeType}&codeValue=${codeValue}`
  )
  return res.data
}

const updateGaragingZipcodeConfigs = async (
  payload: any,
  carrierId: string,
  codeType: string,
  codeValue: string
) => {
  const res = await request.put(
    `${apiUrl}garaging-zipcode?carrierId=${carrierId}&codeType=${codeType}&codeValue=${codeValue}`,
    payload
  )
  return res.data
}

export {
  getRadiusSettings,
  updateRadiusSettings,
  getGaragingZipcodeConfigs,
  updateGaragingZipcodeConfigs,
}
