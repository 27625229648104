// Unique ID'S helpers
const checkUniqueIdParameters = (carrierUniqueIds: any, searchParams: any) => {
    if (!carrierUniqueIds) {
        return null;
    }
    const hasMatch = carrierUniqueIds.some((carrierUniqueId: { codeType: any }) =>
        searchParams.has(carrierUniqueId.codeType)
    );
    return hasMatch ? carrierUniqueIds : null;
};

const isNotEmptyArray = (arr: any) => {
    return Array.isArray(arr) && arr.length > 0 && arr.some(item => !!item);
};

const filterUniqueIds = (formData: any, companyProfile: any) => {
    return Object.entries(formData)
        .filter(([paramName, paramValue]) =>
            !['email', 'firstName', 'lastName'].includes(paramName) &&
            paramValue !== null &&
            paramValue !== undefined &&
            paramValue !== '' &&
            companyProfile?.carrierUniqueIds.some((item: { codeType: any }) => item?.codeType === paramName) &&
            paramName.toLocaleUpperCase() !== "SCAC" && 
            paramName.toLocaleUpperCase() !== "UUID"
        )
        .map(([paramName, paramValue]) => ({
            codeType: paramName,
            codeValue: paramValue,
        }));
};

const checkCompanyDefaultUniqueId = (companyProfile: any) =>{
    if(!isNotEmptyArray(companyProfile)){
        return null;
    }
    return companyProfile.some((item: { codeType: any }) => item?.codeType === "defaultid");
}

const filterCompanyDefaultUniqueId = (companyProfile: any) =>{
    if(!isNotEmptyArray(companyProfile)){
        return null;
    }
    return companyProfile.filter((item: { codeType: any }) => item?.codeType.toLocaleLowerCase() !== "defaultid");
}

const checkCompanyDefaultIdUniqueIdIsRequired = (companyProfile: any) =>{
    if(!isNotEmptyArray(companyProfile)){
        return null;
    }
    return companyProfile.some((item: { codeType: any, required: true }) => item?.codeType === "defaultid" && item?.required === true);
}

//Simulation Mode
const isEldProviderForSimulation = (eldProvider: string) => {
    const eldProvidersForSimulation : any = ["Motive", "Samsara"]
    return eldProvidersForSimulation.includes(eldProvider)
}

export { checkUniqueIdParameters, isNotEmptyArray, filterUniqueIds, checkCompanyDefaultUniqueId, filterCompanyDefaultUniqueId, isEldProviderForSimulation, checkCompanyDefaultIdUniqueIdIsRequired }
