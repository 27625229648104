import MuiChip from '@mui/material/Chip'

export interface IChipProps {
  label?: string
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  variant?: 'filled' | 'outlined'
  clickable?: boolean
  disabled?: boolean
  size?: 'medium' | 'small'
  sx?: object
}

const Chip: React.FC<IChipProps> = (props) => {
  const {
    label,
    color = 'primary',
    variant = 'outlined',
    clickable,
    disabled,
    size = 'small',
    sx,
  } = props
  if (!label) {
    return <></>
  }
  return (
    <MuiChip
      sx={sx}
      label={label}
      clickable={clickable}
      color={color}
      variant={variant}
      disabled={disabled}
      size={size}
    />
  )
}

export default Chip
