import MuiPopover, { PopoverProps } from '@mui/material/Popover'

interface IDialogProps extends PopoverProps {
  dialogContent?: React.ReactNode
  children?: React.ReactNode
  anchorEl: Element | null
  onClose?: () => void
}

const Popover: React.FC<IDialogProps> = (props) => {
  const {
    children,
    anchorEl,
    content,
    anchorOrigin = {
      vertical: 'center',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'center',
      horizontal: 'left',
    },
    onClose,
  } = props

  const open = Boolean(anchorEl)

  return (
    <MuiPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      {children || content}
    </MuiPopover>
  )
}

export default Popover
