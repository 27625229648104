import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container, Icon, TypoGraph } from 'components'
import {
  buildSamsaraInstallRedirect,
  buildSamsaraRedirectUri,
  generateCsrfToken,
} from '../cob/partial/oauthHelper'
import { CircularProgress } from '@mui/material'
import styles from './installCob.module.scss'
import { SetCookie } from 'tools/cookies'

interface IInstallCob {}

const InstallCob = (props: IInstallCob) => {
  const [loading, setLoading] = useState(true)
  const [isValidUrl, setIsValidUrl] = useState(true)

  const navigate = useNavigate()

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const identifier = searchParams.get('identifier')
  const cid = searchParams.get('cid')

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  useEffect(() => {
    setLoading(true)
    if (identifier && cid) {
      const identifierParts = identifier.split(/[~?=&]/)
      const csrfPayload = {
        eldVendor: identifierParts[0],
        companyId: identifierParts[1],
      }
      const csrf = generateCsrfToken(csrfPayload)
      SetCookie('csrf', csrf, 0.1, true)
      const redirectUri = buildSamsaraRedirectUri(identifier)
      const url = buildSamsaraInstallRedirect(cid, csrf, redirectUri)
      window.location.href = url
      setLoading(false)
    } else {
      setIsValidUrl(false)
      setLoading(false)
    }
  }, [identifier, cid, navigate])

  return (
    <Container className={styles.directCobContainer}>
      <Container>
        {companyProfile?.favicon ? (
          <Icon
            type="images"
            url={companyProfile?.favicon}
            boxClassName="fullLogo"
            width={500}
            height={150}
          />
        ) : (
          <Icon name={'icon_logo'} width={200} height={30} />
        )}
      </Container>
      {loading && (
        <>
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
          <Container>
            <TypoGraph
              variant="h5"
              content="Welcome to TruckerCloud!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="We will finish the process for you please wait"
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {!isValidUrl && !loading && (
        <>
          <Container>
            <Icon name="icon_dialog_warning" size={200} />
            <TypoGraph
              variant="h5"
              content="The Onboarding was failed!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="This may occur because of"
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
    </Container>
  )
}

export default InstallCob
