import request from 'tools/request'

const createAgent = async (payLoad: any, agencyId: any) => {
    const res = await request.post(
        `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${agencyId}/agents/`, payLoad)
    return res?.data
}

const getAgentList = async () => {
    const res = await request.get(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies`)
    return res?.data
}

const editAgent = async (payLoad: any, domain: string, agentId: string) => {
    const res = await request.put(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}`, payLoad)
    return res?.data
}

const deleteAgent = async (domain: string, agentId: string) => {
    const res = await request.delete(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}`)
    return res?.data
}

const getCarriersForAgent = async (domain: string, agentId: string, status: string) => {
    const res = await request.get(`${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/${domain}/agent/${agentId}`,
        {
            params: {
                status: status,
            },
        })
    return res?.data
}

const massiveCarrierAssign = async (domain: string, agentId: string, payLoad: any, ) => {
    const res = await request.put(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}/carriers/massive-assign`, payLoad)
    return res?.data
}

const massiveCarrierUnassign = async (domain: string, agentId: string, payLoad: any, ) => {
    const res = await request.put(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}/carriers/massive-unassign`, payLoad)
    return res?.data
}

const carrierAssign = async (domain: string, agentId: string, carrierId: number) => {
    const res = await request.put(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}/carrier/${carrierId}/assign`)
    return res?.data
}

const carrierUnassign = async (domain: string, agentId: string, carrierId: number) => {
    const res = await request.put(`${process.env.REACT_APP_USER_MANAGEMENT_API_URL}agencies/${domain}/agents/${agentId}/carrier/${carrierId}/unassign`)
    return res?.data
}

export { 
    createAgent,
    getAgentList,
    editAgent,
    deleteAgent,
    getCarriersForAgent,
    massiveCarrierAssign,
    massiveCarrierUnassign,
    carrierAssign,
    carrierUnassign
}