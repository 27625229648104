import Helmet from 'react-helmet'

const getContainerHemmet = (config: any) => {
  return (
    <Helmet>
      <link
        id="favicon"
        rel="icon"
        href={config?.favicon ? config?.favicon : 'icon_logo.svg'}
        type="image/x-icon"
      />
    </Helmet>
  )
}

export { getContainerHemmet }
