import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Container, Icon, Tooltip, TypoGraph } from "components";
import colors from 'theme/colors'
import { numberOrStringComparator, stringDateComparator } from "tools/comparators";

const myCarrierList = {
    myCarrierColumn: [
      {
        field: 'carrierId',
        headerName: 'ID',
        width: 90,
        minWidth: 90,
      },
      {
        field: 'carrierName',
        sortable: true,
        headerName: 'Carrier Name',
        minWidth: 250,
        flex: 1.1,
        handleClick: (row: any) => {},
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              <TypoGraph
                id={"carrierName_" + params.row.carrierId}
                component="span"
                variant="body1"
                color={colors.primary}
                onClick={() => this.handleClick(params.row)}
                link
              >
                {params.value}
              </TypoGraph>
            </Tooltip>
          )
        },
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        width: 100,
        minWidth: 80,
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        width: 150,
        minWidth: 250,
        flex: 1,
        handleClick: (row: any) => {},
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={250}
            >
              <TypoGraph
                component="span"
                variant="body2"
                onClick={() => this.handleClick(params.row)}
                link
              >
                {params.value}
              </TypoGraph>
            </Tooltip>
          );
        },
      },
      {
        field: 'dot',
        headerName: 'DOT',
        sortable: true,
        sortComparator: numberOrStringComparator,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'mc',
        headerName: 'MC',
        width: 90,
        sortable: true,
        sortComparator: numberOrStringComparator,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'scac',
        headerName: 'SCAC',
        sortable: true,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'cuid',
        headerName: 'CUID',
        sortable: true,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'policyId',
        headerName: 'Policy ID',
        sortable: true,
        width: 90,
        minWidth: 90,
        flex: 0.3,
      },
      {
        field: 'eldVendorDisplayName',
        headerName: 'ELD Provider',
        sortable: true,
        minWidth: 160,
        flex: 0.6,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              {params.value}
            </Tooltip>
          )
        },
      },
      {
        field: 'numberOfTrucks',
        headerName: 'Truck',
        sortable: true,
        sortComparator: numberOrStringComparator,
        width: 80,
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: 'numberOfDrivers',
        headerName: 'Trailer',
        sortable: true,
        sortComparator: numberOrStringComparator,
        width: 80,
        minWidth: 80,
        flex: 0.2,
      },
      {
        field: 'statusInfo',
        headerName: 'Onboard Date',
        sortable: true,
        sortComparator: stringDateComparator,
        width: 110,
        minWidth: 110,
        flex: 0.4,
      },
      {
        field: 'data',
        headerName: 'T&C',
        sortable: false,
        width: 60,
        minWidth: 60,
        flex: 0.2,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Container
              sx={{
                ml: '0',
              }}
            >
              <Tooltip
                title={'Terms & Conditions'}
                containerClassName="ellipsis"
                maxWidth={80}
              >
                <Button
                  onClick={() => window.open(params.row?.letterOfAuthorizationUrl, '_blank')}
                  className={
                    params.row?.letterOfAuthorizationUrl ? 'termsAndConditionButton' : 'inactiveButton'
                  }
                  disabled={!params.row?.letterOfAuthorizationUrl}
                >
                  <Icon
                    materialIcon="PictureAsPdf"
                    size={24}
                  />
                </Button>
              </Tooltip>
            </Container>
          )
        },
      },
    ],
  }

const actionList = [
    {
        name: 'Edit',
        icon: 'icon_edit',
        iconSize: 40,
    },
];

export {
    myCarrierList,
    actionList
}