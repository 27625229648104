import { styled } from '@mui/material/styles';
import { GridColumnGroupHeaderParams } from '@mui/x-data-grid';
import TypoGraph from 'components/typograph';

interface HeaderWithIconProps extends GridColumnGroupHeaderParams {
    icon: React.ReactNode;
}

const HeaderWithIconRoot = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0.5rem',
}));
  
const HeaderWithIcon = (props: HeaderWithIconProps) => {
    const { icon, ...params } = props;

    return (
        <HeaderWithIconRoot>
            {icon}
            <TypoGraph
                variant="subtitle1"
                content={params.headerName ?? params.groupId ?? ""}
                className="descriptionText"
                sx={{fontWeight:'700', color:'black', fontSize:'0.9rem'}}
            />
            {params.description &&
            <TypoGraph
                variant="caption"
                content={params.description}
                sx={{marginLeft:'0.4rem'}}
            />
            }
        </HeaderWithIconRoot>
    );
}

export default HeaderWithIcon