import {
  useFormContext,
  Controller,
  ValidationRule,
  Validate,
} from 'react-hook-form'
import TextField, { ITextFieldProps } from 'components/controllers/input'

interface IInputFieldProps extends ITextFieldProps {
    id?:string
  name: string
  required?: boolean
  maxLength?: number | { value: number; message: string }
  minLength?: number | { value: number; message: string }
  pattern?: ValidationRule<RegExp> | undefined
  validate?: Validate<any, any> | Record<string, Validate<any, any>> | undefined
}

const InputFormField: React.FC<IInputFieldProps> = (props) => {
  const {
      id,
    name,
    required = false,
    maxLength,
    minLength,
    pattern,
    validate,
  } = props
  const { control } = useFormContext()
  const isRequired = required ? 'Field is Required' : undefined
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        validate: validate,
        maxLength: maxLength,
        minLength: minLength,
        pattern: pattern,
      }}
      render={({
        field: { onChange, onBlur, value = '', ref },
        fieldState: { error },
      }) => {
        return (
          <TextField
              id={id}
            value={value ? value : ''}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={Boolean(error)}
            helperText={error?.message}
            {...props}
          />
        )
      }}
    />
  )
}

export default InputFormField
