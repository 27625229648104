import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronLeftRight from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import { List, ListItem, ListItemIcon, Drawer } from '@mui/material'
import { DrawerHeader, MuiDrawer } from 'theme/themeOverride'
import { Icon, TypoGraph, Container } from 'components'
import styles from './menubar.module.scss'

interface ICobMenuBarProps {
  menubarData: any[]
}

const CobMenuBar: React.FC<ICobMenuBarProps> = (props) => {
  const { menubarData = [] } = props
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [open, setOpen] = useState(true)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [mobileMenu, setMobileMenu] = useState(false) // handles mobile menubar open and close state

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    if (windowWidth < 1000) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [windowWidth])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const openCloseIconEvent = {
    onMouseOver: () => setShowCloseIcon(true),
    onMouseLeave: () => {
      setTimeout(() => {
        if (!showCloseIcon) {
          setShowCloseIcon(false)
        }
      }, 800)
    },
    onMouseOut: () => setShowCloseIcon(false),
  }

  return (
    <div {...openCloseIconEvent}>
      {showCloseIcon && windowWidth > 1000 && (
        <Icon
          boxClassName={`${styles.leftIconButton} ${
            !open && styles.rightIconButton
          }`}
          onClick={handleDrawerOpen}
        >
          {open ? (
            <ChevronLeftIcon sx={{ color: 'black' }} />
          ) : (
            <ChevronLeftRight sx={{ color: 'black' }} />
          )}
        </Icon>
      )}
      {windowWidth < 1000 && (
        <Container className={styles.mobileHeader}>
          <Container>
            <Icon onClick={() => setMobileMenu(true)}>
              <MenuIcon />
            </Icon>
          </Container>
          <Container>
            {companyProfile?.logo ? (
              <Icon
                type="images"
                url={companyProfile?.logo}
                boxClassName={styles.logoContainer}
              />
            ) : (
              <Icon name="icon_logoFullname" width={200} height={30} />
            )}
          </Container>
        </Container>
      )}
      {windowWidth < 1000 ? (
        <Drawer
          anchor={'left'}
          open={mobileMenu}
          onClose={() => setMobileMenu(false)}
        >
          <DrawerHeader className={styles.mobileMenuHeader}>
            <Icon
              name="icon_cancel"
              boxClassName={styles.cancelBtn}
              onClick={() => {
                setMobileMenu(false)
              }}
              iconButton
            />
          </DrawerHeader>
          <List className={styles.menuList} style={{ width: '15rem' }}>
            {menubarData.map((item: any) => (
              <ListItem
                key={item.name}
                className={styles.listItem}
                disablePadding
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                  <Icon name={item.icon} width={25} height={25} />
                </ListItemIcon>
                <TypoGraph
                  variant="body1"
                  mb={0}
                  className={styles.textEllipsis}
                >
                  {item.name}
                </TypoGraph>
              </ListItem>
            ))}
          </List>
          <List className={styles.stickyMenuList}>
            <ListItem
              className={styles.listItem}
              onClick={() =>
                window.open(
                  'https://truckercloud.zendesk.com/hc/en-us/requests/new?ticket_form_id=4415135008155'
                )
              }
              disablePadding
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                }}
              >
                <Icon name="icon_help" width={25} height={25} />
              </ListItemIcon>
              <TypoGraph variant="body1" mb={0}>
                Help
              </TypoGraph>
            </ListItem>
          </List>
        </Drawer>
      ) : (
        <MuiDrawer variant="permanent" open={open}>
          <DrawerHeader>
            {companyProfile?.logo && companyProfile?.favicon ? (
              <Icon
                type="images"
                url={open ? companyProfile?.logo : companyProfile?.favicon}
                boxClassName="fullLogo"
                width={200}
                height={50}
              />
            ) : (
              <Icon
                name={open ? 'icon_logoFullname' : 'icon_logo'}
                width={200}
                height={30}
              />
            )}
          </DrawerHeader>
          <List className={styles.menuList}>
            {menubarData.map((item: any) => (
              <ListItem
                key={item.name}
                className={styles.listItem}
                disablePadding
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                  <Icon name={item.icon} width={25} height={25} />
                </ListItemIcon>
                <TypoGraph
                  variant="body1"
                  mb={0}
                  className={styles.textEllipsis}
                >
                  {item.name}
                </TypoGraph>
              </ListItem>
            ))}
          </List>
          <List className={styles.stickyMenuList}>
            <ListItem
              className={styles.listItem}
              onClick={() =>
                window.open(
                  'https://truckercloud.zendesk.com/hc/en-us/requests/new?ticket_form_id=4415135008155'
                )
              }
              disablePadding
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                }}
              >
                <Icon name="icon_help" width={25} height={25} />
              </ListItemIcon>
              <TypoGraph variant="body1" mb={0}>
                Help
              </TypoGraph>
            </ListItem>
          </List>
        </MuiDrawer>
      )}
    </div>
  )
}

export default CobMenuBar
