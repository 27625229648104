import { useState } from 'react'
import Slider from '@mui/material/Slider'
import { TextField } from '@mui/material'
import Container from 'components/layout'
import TypoGraph from 'components/typograph'
import styles from './sliderWithInput.module.scss'

export interface ISliderWithInputProps {
  initialValue: number
  min: number
  max: number
  maxInputLength?: number
  step?: number
  color?: string
  useInputLabel: boolean
  title: string
  label: string
  onValueChange?: (value: number) => void
}

const SliderWithInput: React.FC<ISliderWithInputProps> = (props) => {
  const {
    initialValue,
    min,
    max,
    maxInputLength = 5,
    step,
    color = 'primary',
    useInputLabel,
    title,
    label,
    onValueChange,
  } = props
  const [value, setValue] = useState(initialValue)
  const [isEditing, setIsEditing] = useState(false)

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(Array.isArray(newValue) ? newValue[0] : newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value
  
    if (newValue.length > maxInputLength) {
      return
    }
  
    let numericValue = parseFloat(newValue);

    if (!isNaN(numericValue)) {

      if (numericValue < min) {
        setValue(min)
      } else if (numericValue > max) {
        setValue(max)
      } else {
        setValue(numericValue)
      }
    } else {
      setValue(min)
    }
  }

  const handleBlur = () => {
    onValueChange && onValueChange(value)
    setIsEditing(false)
  }

  const handleLabelClick = () => {
    if (useInputLabel) {
      setIsEditing(!isEditing)
    }
  }
  return (
    <Container>
      <TypoGraph variant="h6" align="center">
        {title}
      </TypoGraph>
      <Slider
        value={value}
        onChange={handleSliderChange}
        min={min}
        max={max}
        step={step}
        onChangeCommitted={() => onValueChange && onValueChange(value)}
      />
      <Container className={styles.labelGroup}>
        {isEditing && useInputLabel ? (
          <>
            <TextField
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur}
              type='number'
              variant='standard'
              inputProps={{
                type: 'number',
                maxLength: maxInputLength,
                min: min,
                max: max,
                style: { width: `${value.toString().length + 3}ch` },
              }}
            />

            <TypoGraph variant="body1">{label}</TypoGraph>
          </>
        ) : (
          <TypoGraph variant="body1" onClick={handleLabelClick}>
            {useInputLabel ? `${value} ${label}` : label}
          </TypoGraph>
        )}
      </Container>
    </Container>
  )
}
export default SliderWithInput
