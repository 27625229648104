// import cookies from 'js-cookie'
import { GetCookie, SetCookie, DeleteCookie, ClearCookies } from 'tools/cookies'
import CryptoJS from 'crypto-js'

/**
 * 0 - Not an oAuth
 * 1 - Motive
 * 2 - Samsara
 */

const evaluateCompanyId = (cobListData: any, selectedEld: any, replaceWithParentCompanyIfExists: boolean) => {
  const companyId = !!cobListData?.invitationInfo.hasFreightVisibility
    && !!selectedEld?.isFvOauth
    ? selectedEld?.oAuth[0]?.companyId
    : cobListData?.invitationInfo?.carrierInvitingCompanyId
      ? replaceWithParentCompanyIfExists ? 
        cobListData?.invitationInfo?.carrierInvitingCompanyId 
        : cobListData?.invitationInfo?.invitingCompanyId
      : cobListData?.invitationInfo?.invitingCompanyId;
  return companyId;
};

const checkEldVendor = (data: any) => {
  const oAuthLen = data?.oAuth?.length
  const vendorName = data?.eldVendor
  if (oAuthLen > 0 && vendorName === 'Motive') return 1
  if (oAuthLen > 0 && vendorName === 'Samsara') return 2
  return 0
}

const selectedEldValidForOAuth = (eldSelected: any, invitationInfo: any, oAuth: any) => {
  let result = eldSelected 
    && invitationInfo 
    && eldSelected.oAuth 
    && eldSelected.oAuth.length > 0 
    && invitationInfo.canUseOAuth 
    && invitationInfo.canUseOAuth === true 
    && oAuth 
    && oAuth !== undefined 
    && oAuth !== null;
  return result
}

const evaluateOAuth = (invitationInfo: any, selectedEld: any) => {
  if (!selectedEld || !invitationInfo) {
    return null
  }
  const oAuth =
    !!invitationInfo?.hasFreightVisibility && !!selectedEld?.isFvOauth
      ? selectedEld.oAuth?.[0]
      : selectedEld.oAuth?.find((o: { companyId: any }) =>
          invitationInfo.carrierInvitingCompanyId
            ? o.companyId === invitationInfo.carrierInvitingCompanyId
            : o.companyId === invitationInfo.invitingCompanyId
        )

  return selectedEldValidForOAuth(selectedEld, invitationInfo, oAuth)
}

const getOauthIndex = (companyId: any, data: any) => {
  const oAuth = data?.oAuth
  const oAuthObj = oAuth?.filter(
    (item: any) => item?.companyId === companyId
  )[0]
  return oAuthObj
}

const generateCsrfToken = (eldData: any) => {
  const csrfToken = CryptoJS.SHA256(
    `${eldData?.eldVendor}${eldData?.companyId}`
  ).toString()
  return csrfToken
}

const compareCsrfToken = (state: any) => {
  const csrfToken = GetCookie('csrf', true)
  return csrfToken === state
}

const resetCookies = () => {
  if (sessionStorage.getItem('cookiesReset') === null) {
    // Dropping cookies at the begining of onboarding, in case it's not OAuth
    if (GetCookie('eldIndex', true) === null || GetCookie('invitationKey', true) === null) {
      ClearCookies(true)
      sessionStorage.setItem('cookiesReset', '1')
    } else {
      console.log('OAuth detected, no cookies are cleared')
    }
  } else {
    console.log('Cookies has already been reset')
  }
}

const buildMotiveUrl = (companyId: any, data: any) => {
  const oAuth = getOauthIndex(companyId, data)
  const scope = Object.values(oAuth.scopes).join(' ')
  const url = `https://api.gomotive.com/oauth/authorize?client_id=${oAuth.clientId}&redirect_uri=${oAuth.redirectUri}&response_type=code&scope=${scope}`
  return url
}

const buildSamsaraUrl = (companyId: any, data: any, csrf: any) => {
  const oAuth = getOauthIndex(companyId, data)
  const url = `https://api.samsara.com/oauth2/authorize?client_id=${oAuth.clientId}&redirect_uri=${oAuth.redirectUri}&state=${csrf}&response_type=code`
  return url
}

const buildCookies = (
  eldIndex: any,
  invitationKey: any,
  eldDataSharing: any,
  selectedEld: any
) => {
  SetCookie('eldIndex', eldIndex, 0.1, true)
  SetCookie('invitationKey', invitationKey, 0.1, true)
  SetCookie('eldDataSharing', JSON.stringify(eldDataSharing), 0.1, true)
  SetCookie('eldVendor', selectedEld?.eldVendor, 0.1, true)
}

const buildSamsaraCookies = (
  eldIndex: any,
  invitationKey: any,
  eldDataSharing: any,
  selectedEld: any
) => {
  const csrf = generateCsrfToken(selectedEld)
  SetCookie('eldIndex', eldIndex, 0.1, true)
  SetCookie('invitationKey', invitationKey, 0.1, true)
  SetCookie('eldDataSharing', JSON.stringify(eldDataSharing), 0.1, true)
  SetCookie('eldVendor', selectedEld?.eldVendor, 0.1, true)
  SetCookie('csrf', csrf, 0.1, true)
  return csrf
}

const isCookiePresent = () => {
  const invitationKey = GetCookie('invitationKey', true)
  if (invitationKey) return true
  else return false
}

const getCookiesValues = () => {
  const eldIndex = GetCookie('eldIndex', true)
  const invitationKey = GetCookie('invitationKey', true)
  const eldDataSharingJson: any = GetCookie('eldDataSharing', true)
  const eldVendor = GetCookie('eldVendor', true)
  const csrf = GetCookie('csrf', true)
  const canalUrl = GetCookie('canalUrl', true)
  const canalState = GetCookie('canalState', true)
  const carrierName = GetCookie('carrierName', true)
  const eldProvider = GetCookie('eldProvider', true)
  const scac = GetCookie('scac', true)
  return {
    eldDataSharing: JSON.parse(eldDataSharingJson),
    eldIndex,
    invitationKey,
    eldVendor,
    csrf,
    canalUrl,
    canalState,
    carrierName,
    eldProvider,
    scac,
  }
}

const removeAllCookies = (includeCanal: boolean, includeOneNetwork: boolean, includeCoverwhaleTest: boolean) => {
  DeleteCookie('eldIndex', true)
  DeleteCookie('invitationKey', true)
  DeleteCookie('eldDataSharing', true)
  DeleteCookie('eldVendor', true)
  DeleteCookie('csrf', true)
  if (includeCanal === true) {
    DeleteCookie('canalUrl', true)
    DeleteCookie('canalState', true)
  }
  if (includeOneNetwork === true) {
    DeleteCookie('carrierName', true)
    DeleteCookie('eldProvider', true)
    DeleteCookie('scac', true)
  }
  if (includeCoverwhaleTest === true) {
    DeleteCookie('policyId', true)
  }
}

/** Canal redirections */

const setCanalCookies = (payLoad: any) => {
  SetCookie('canalUrl', payLoad?.url, 0.1, true)
  SetCookie('canalState', payLoad?.state, 0.1, true)
}

const getCanalCookies = () => {
  const url = GetCookie('canalUrl', true)
  const state = GetCookie('canalState', true)
  return {
    url,
    state,
  }
}

const checkIsCanal = () => {
  const isCanal = GetCookie('canalUrl', true) !== null && GetCookie('canalState', true) !== null
  return isCanal
}

const buildCanalRedirection = (code: any) => {
  let message = ''
  switch (code?.toString()) {
    case '400':
      message = 'The request was invalid or cannot be otherwise served.'
      break
    case '401':
      message = 'Authentication credentials were missing or incorrect.'
      break
    case '403':
      message = 'The request has been refused or access is not allowed.'
      break
    case '404':
      message =
        'The URI requested is invalid or the resource requested does not exists.'
      break
    case '429':
      message = 'Too many requests were done to the endpoint.'
      break
    case '500':
    default:
      break
  }
  const canalCookies = getCanalCookies()
  const queryParams: any = {
    state: canalCookies?.state,
    code,
  }
  if (message) {
    queryParams['message'] = message
  }
  const baseUrl = canalCookies?.url
  // Build the query string
  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
    )
    .join('&')
  const redirectionUrl = baseUrl + '?' + queryString
  return redirectionUrl
}

/** OneNetwork behavior */

const checkIsOneNetwork = () => {
  const isOneNetwork = GetCookie('carrierName', true) !== null && GetCookie('eldProvider', true) !== null && GetCookie('scac', true) !== null
  return isOneNetwork
}

const setOneNetworkCookies = (payLoad: any) => {
  SetCookie('carrierName', payLoad?.carrierName, 0.1, true)
  SetCookie('eldProvider', payLoad?.eldProvider, 0.1, true)
  SetCookie('scac', payLoad?.scac, 0.1, true)
}

/** Coverwhale-test behavior */

const checkIsCoverwhaleTest = () => {
  const isCoverwhaleTest = GetCookie('policyId', true) !== null
  return isCoverwhaleTest
}

const setCoverwhaleTestCookies = (payLoad: any) => {
  if (!payLoad || (!payLoad.policyId && !payLoad.PolicyId)) {
    return false
  }
  SetCookie('policyId', payLoad?.policyId ? payLoad?.policyId : payLoad?.PolicyId, 0.1, true)
  return true
}

/** Samsara install flow helpers */

const buildSamsaraRedirectUri = (identifier: any) => {
  const redirectUri = `https://${window.location.hostname}/cob/direct?identifier=${identifier}`
  return redirectUri
}

const buildSamsaraInstallRedirect = (cid: any, csrf: any, redirectUri: any) => {
  const url = `https://api.samsara.com/oauth2/authorize?client_id=${cid}&redirect_uri=${redirectUri}&state=${csrf}&response_type=code`
  return url
}

export {
  buildMotiveUrl,
  checkEldVendor,
  evaluateOAuth,
  getOauthIndex,
  evaluateCompanyId,
  buildCookies,
  isCookiePresent,
  getCookiesValues,
  removeAllCookies,
  resetCookies,
  buildSamsaraCookies,
  buildSamsaraUrl,
  compareCsrfToken,
  generateCsrfToken,
  /** Canal Redirections */
  setCanalCookies,
  getCanalCookies,
  checkIsCanal,
  buildCanalRedirection,
  /** OneNetwork Behavior */
  checkIsOneNetwork,
  setOneNetworkCookies,
  /** Coverwhale-test Behavior */
  checkIsCoverwhaleTest,
  setCoverwhaleTestCookies,
  /** Samsara install redirections */
  buildSamsaraRedirectUri,
  buildSamsaraInstallRedirect,
}
