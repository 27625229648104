import { IMenuAccess, IMenuDefinition } from "interfaces";
import { accountsAccessControl, profileAccessControl } from "rbac";
import { fleetReportingAccessControl } from "rbac/managers";
import strings from "strings";

const menus: IMenuDefinition[] = [
    {
        name: strings.MENU.ACCOUNTS,
        url: strings.ROUTES.ACCOUNTS,
        canViewMenu: accountsAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.FLEET_REPORTING,
        url: strings.ROUTES.FLEET_REPORTING,
        canViewMenu: fleetReportingAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.PROFILE,
        url: strings.ROUTES.PROFILE,
        canViewMenu: profileAccessControl().canViewMenu,
    }
]

const getCarrierMenuAccess = () : IMenuAccess[] => {
    const menuList: IMenuAccess[] = menus
        .filter(menu => menu.canViewMenu()) // Filter based on access control
        .map(({ name, url }) => ({ name, url })); // Map to the desired output structure

    // Adding the Help menu since its inclusion doesn't depend on permissions
    menuList.push({ name: strings.MENU.HELP });

    return menuList
}

export default getCarrierMenuAccess
