import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropzone from 'react-dropzone'
import { CircularProgress } from '@mui/material'
import {
  getWhitelabelDetails,
  uploadCompanyFavIcon,
  uploadBannerImage,
  uploadLogoImage,
  updateCompanyProfile,
  getCompanyProfile,
} from 'api'
import { dispatchWhiteLabelTheme } from 'actions/whitelabel'
import {
  Card,
  Container,
  HeaderBar,
  TypoGraph,
  ColorPicker,
  Icon,
  Button,
  Divider,
  Toast as toast,
} from 'components'
import { TextField } from 'components/controllers'
import strings from 'strings'
import styles from './whiteLabel.module.scss'

const t = strings.WHITE_LABEL

interface IWhileLabelProps {}

const WhileLabel: React.FC<IWhileLabelProps> = (props) => {
  const primaryColor = useSelector(
    (state: any) => state?.whiteLabel?.theme?.primary
  )
  const [files, setFiles] = useState<any>({
    logoImage: {
      file: [],
      loading: false,
    },
    bannerImage: {
      file: [],
      loading: false,
    },
    favImage: {
      file: [],
      loading: false,
    },
  })
  const [logoUrl, setLogoUrl] = useState('')
  const [bannerUrl, setBannerurl] = useState('')
  const [favUrl, setFavUrl] = useState('')
  const [color, setColor] = useState(primaryColor)
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoader, setSubmitLoader] = useState(false)

  const companyName = localStorage.getItem('companyName') || ''

  const dispatch = useDispatch()

  const dropzoneLogoRef: any = createRef()
  const dropzoneBannerRef: any = createRef()
  const dropzoneFavRef: any = createRef()

  const dropzoneData = {
    logoImage: {
      ref: dropzoneLogoRef,
      location: 'logoImage',
      size: '230px * 35px',
      dialog: 'logo',
      title: 'Logo',
    },
    bannerImage: {
      ref: dropzoneBannerRef,
      location: 'bannerImage',
      size: '400px * 400px',
      dialog: 'banner',
      title: 'Banner',
    },
    favImage: {
      ref: dropzoneFavRef,
      location: 'favImage',
      size: '500px * 500px',
      dialog: 'fav',
      title: 'Favicon',
    },
  }

  const openDialog = (type: string) => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneLogoRef.current && type === 'logo') {
      dropzoneLogoRef.current.open()
    }
    if (dropzoneBannerRef.current && type === 'banner') {
      dropzoneBannerRef.current.open()
    }
    if (dropzoneFavRef.current && type === 'fav') {
      dropzoneFavRef.current.open()
    }
  }

  const handleColorChange = (color: string) => {
    setColor(color)
    dispatch(dispatchWhiteLabelTheme({ primary: color }))
  }

  const handleColorCodeChange = (e: any) => {
    const value = e.target.value
    setColor(value)
  }

  /** Triggers on first render to get and set the company profile */
  useEffect(() => {
    setLoading(true)
    getWhitelabelDetails()
      .then((res: any) => {
        const companyProfile = res?.companyProfile
        setLogoUrl(companyProfile?.logo)
        setBannerurl(companyProfile?.banner)
        setFavUrl(companyProfile?.favicon)
        setColor(companyProfile?.colourCode)
        dispatch(
          dispatchWhiteLabelTheme({ primary: companyProfile?.colourCode })
        )
      })
      .finally(() => setLoading(false))
  }, [dispatch])

  /** submits the form payload to update company profile */
  const handleUpdateWhiteLabel = async () => {
    setSubmitLoader(true)
    const payLoad = {
      banner: bannerUrl,
      logo: logoUrl,
      favicon: favUrl,
      colourCode: color,
    }
    updateCompanyProfile(payLoad)
      .then((res: any) => {
        getCompanyProfile(dispatch)
        return res
      })
      .then((res) => {
        toast({ id:"companyProfileToast",title: 'Success', subTitle: res?.message })
      })
      .finally(() => setSubmitLoader(false))
  }

  /** To update the loading state of the specific image uploader */
  const handleUploadLoading = (location: any, bool: boolean) => {
    setFiles((prev: any) => ({
      ...prev,
      [location]: {
        ...prev[location],
        loading: bool,
      },
    }))
  }

  const handleFileUploads = async (location: any, file: any) => {
    const formData = new FormData()
    formData.append('file', file[0])
    if (location === 'logoImage') {
      handleUploadLoading(location, true)
      uploadLogoImage(formData)
        .then((res: any) => {
          setLogoUrl(`${res?.LogoUrl}?${Math.floor(Math.random() * 10000000)}`)
        })
        .finally(() => handleUploadLoading(location, false))
    }
    if (location === 'bannerImage') {
      handleUploadLoading(location, true)
      uploadBannerImage(formData)
        .then((res: any) => {
          setBannerurl(
            `${res?.BannerUrl}?${Math.floor(Math.random() * 10000000)}`
          )
        })
        .finally(() => handleUploadLoading(location, false))
    }
    if (location === 'favImage') {
      handleUploadLoading(location, true)
      uploadCompanyFavIcon(formData)
        .then((res: any) => {
          setFavUrl(
            `${res?.FaviconUrl}?${Math.floor(Math.random() * 10000000)}`
          )
        })
        .finally(() => handleUploadLoading(location, false))
    }
  }

  const DropzoneComponent = (props: any) => {
    const { data, fileData, url, icon } = props
    console.log('fileData', fileData, url)
    return (
      <Container className={styles.mtb1}>
        <Divider title={data.title} />
        {url && !fileData.loading && <Container>{icon}</Container>}
        <Dropzone
          ref={data.ref}
          accept={{ 'image/*': [] }}
          multiple={false}
          onDrop={(file: any) => handleFileUploads(data.location, file)}
        >
          {({ getRootProps, getInputProps }) => (
            <>
              {url && !fileData.loading ? (
                <Button
                    id={"changeImage"}
                  variant="outlined"
                  label="Change Image"
                  onClick={() => openDialog(data.dialog)}
                  className={styles.changeBtn}
                />
              ) : (
                <div
                  {...getRootProps({
                    className: 'dropzone',
                    onDrop: (event) => event.stopPropagation(),
                  })}
                  className={styles.dropzoneContainer}
                >
                  {fileData.loading ? (
                    <Container className={styles.dropZoneLoader}>
                      <CircularProgress />
                    </Container>
                  ) : (
                    <>
                      <input {...getInputProps()} />
                      <Container className={styles.fileUploadText}>
                        <Icon name="icon_uploader" size={46} />
                        <TypoGraph
                          content={'Drag and Drop or Browse your file'}
                          className={styles.dropzoneText}
                        />
                        <TypoGraph
                          variant="body2"
                          sx={{ fontWeight: 400 }}
                          content={`image size: ${data.size}`}
                          className={styles.dropzoneText}
                        />
                      </Container>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </Dropzone>
      </Container>
    )
  }

  console.log('logo', logoUrl)

  return (
    <Container>
      <HeaderBar
        title={t.WHITE_LABEL}
        primaryBtnProps={{
          label: strings.UPDATE,
          loading: submitLoader,
          onClick: handleUpdateWhiteLabel,
        }}
      />
      <Card>
        {loading ? (
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
        ) : (
          <>
            <TypoGraph content={t.WHITE_LABEL} variant="h6" />
            <Container className={styles.formContainer}>
              <Container>
                <TextField
                  label="Organization Name"
                  value={companyName}
                  disabled
                />
                <Container className={styles.themeContainer}>
                  <TextField
                    label="Theme Color"
                    value={color}
                    onChange={(e) => handleColorCodeChange(e)}
                    onClick={() => setOpenColorPicker(!openColorPicker)}
                  />
                  <ColorPicker

                    onclose={() => setOpenColorPicker(false)}
                    sendSelectedColor={handleColorChange}
                    openColorPicker={openColorPicker}
                    defaultColor={primaryColor}
                  />
                </Container>
                <DropzoneComponent
                  data={dropzoneData.logoImage}
                  fileData={files.logoImage}
                  url={logoUrl}
                  icon={
                    <Icon
                      type="images"
                      size={400}
                      height={200}
                      url={logoUrl}
                      boxClassName={styles.previewImage}
                    />
                  }
                />
                <DropzoneComponent
                  data={dropzoneData.bannerImage}
                  fileData={files.bannerImage}
                  url={bannerUrl}
                  icon={
                    <Icon
                      type="images"
                      size={400}
                      height={200}
                      url={bannerUrl}
                      boxClassName={styles.previewImage}
                    />
                  }
                />
                <DropzoneComponent
                  data={dropzoneData.favImage}
                  fileData={files.favImage}
                  url={favUrl}
                  icon={
                    <Icon
                      type="images"
                      size={400}
                      height={200}
                      url={favUrl}
                      boxClassName={styles.previewImage}
                    />
                  }
                />
              </Container>
            </Container>
          </>
        )}
      </Card>
    </Container>
  )
}

export default WhileLabel
