const bootIntercom = (userName?: string, email?: string) => {
  // @ts-ignore
  window.Intercom('boot', {
    // @ts-ignore
    app_id: 'jyc9kifw',
    email: userName,
    name: email,
    created_at: new Date(),
  })
}

export const shutdownIntercom = () => {
  // @ts-ignore
  window.Intercom('shutdown')
}

export default bootIntercom
