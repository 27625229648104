import { IMenuItem } from "interfaces";
import { IMenuAccess } from "interfaces/IMenuAccess";
import { getCarrierMenuAccess } from "rbac";
import strings from "strings";
import { MenuList } from "types/menuList";

const carrierMenuBar = () => {
  const carrierMenuBarList: MenuList = {
    list1: [],
    list2: []
  };

  const menuList1: IMenuItem[] = [
    {
      name: strings.MENU.ACCOUNTS,
      muiIcon: 'GroupsOutlined',
      id: 'accounts',
    },
    {
      name: strings.MENU.FLEET_REPORTING,
      muiIcon: 'AutoGraph',
      id: 'fleet-reporting',
    },
  ]

  const menuList2: IMenuItem[] = [
    {
      name: strings.MENU.HELP,
      icon: 'icon_help',
      id: 'help',
      url: 'https://truckercloud.zendesk.com/hc/en-us/requests/new?ticket_form_id=4415135008155',
    },
    {
      name: strings.MENU.PROFILE,
      icon: 'icon_profile',
      id: 'profile',
    }
  ]

  const carrierMenuAccess: IMenuAccess[] = getCarrierMenuAccess()
  carrierMenuBarList.list1.push(...menuList1.filter((item: IMenuItem) => carrierMenuAccess.some(accessItem => accessItem.name === item.name)))
  carrierMenuBarList.list2.push(...menuList2.filter((item: IMenuItem) => carrierMenuAccess.some(accessItem => accessItem.name === item.name)))

  return carrierMenuBarList
}

export default carrierMenuBar
