import { Container, HeaderBar, Tabs } from 'components'
import { useState } from 'react'
import { fleetReportingTabs } from 'constants/status'
import FleetHeartbeat from './fleetHeartbeat'
import FleetPercentiles from './fleetPercentiles'
import FleetDetailsForAgents from 'pages/carriers/partial/fleetDetailsForAgent'
import FleetMilesByZipDashboard from 'pages/carriers/partial/fleetMilesByZip'

interface IFleetDetailProps { }

const FleetReporting: React.FC<IFleetDetailProps> = (props) => {
    const [fleetReportingTab, setFleetReportingTab] = useState(0)
    const reportingTitle = 'Fleet Reporting'

    const TabPanel = (props: { print: boolean }) => {
        const { print } = props
        switch (fleetReportingTab) {
            case 0:
                return <FleetHeartbeat print={print} />
            case 1:
                return <FleetPercentiles print={print} />
            case 2:
                return <FleetDetailsForAgents print={print} />
            case 3:
                return <FleetMilesByZipDashboard print={print} useCarrierFilters={false} />
            default:
                return <FleetHeartbeat print={print} />
        }
    }

    const getPageMargins = () => {
        return `@page { margin: 30px!important; }`
    }

    return (
        <Container>
            <HeaderBar
                title={reportingTitle}
            />
            <Tabs
                tabData={fleetReportingTabs}
                value={fleetReportingTab}
                id={fleetReportingTabs[fleetReportingTab].label}
                handleChange={(_e, val) => setFleetReportingTab(val)}
                sxContainer={{
                    marginLeft: '1rem',
                }}
            />
            <Container>
                <TabPanel print={false} />
            </Container>
            <style>{getPageMargins()}</style>
        </Container>
    )
}

export default FleetReporting