import * as Sentry from "@sentry/browser";

const reportUsageMessage = (message: string) => {
    Sentry.captureMessage(message, "info");
}

const reportException = (error: any) => {
    Sentry.captureException(error)
}

export {
    reportUsageMessage,
    reportException
}