import React, { useEffect, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { CircularProgress, Radio } from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'
import { getCarrierSearchList, inviteCarrier } from 'api'
import {
  Blade,
  Container,
  Button,
  TypoGraph,
  Divider,
  Icon,
  DataGridTable,
  Modal,
  Toast as toast,
} from 'components'
import { getCarrierParamsData, getCodeTypeValue } from 'tools/helper'
import { RadioField, TextField } from 'components/controllers'
import { InputFormField } from 'components/hookForm'
import { searchCarrierList } from 'constants/tableData'
import styles from './searchCarriers.module.scss'

interface ISearchCarrierProps {
  open: boolean
  onClose: () => void
  onSuccessCallback: () => void
}

const SearchCarrier: React.FC<ISearchCarrierProps> = React.memo(
  (props: ISearchCarrierProps) => {
    const { open, onClose, onSuccessCallback } = props
    const [searchFilter, setSearchFilter] = useState<any>({
      'carrierName:fuzzy': { checked: true },
    })
    const [searchResults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [inviteLoader, setInviteLoader] = useState(false)
    const [selectionModel, setSelectionModel] = useState<any>([])

    searchCarrierList.column[1].renderCell = (params: GridRenderCellParams) => {
      return (
        <Radio id="searchedCarrierRadio" checked={selectionModel[0] === params.row?.id} name="radio" />
      )
    }

    const selectedCarrier: any = searchResults.filter((item: any) => {
      return item.id === selectionModel[0]
    })[0]

    const methods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const inviteFormMethods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const handleInvite = () => {
      if (!selectedCarrier) {
        return
      }
      setShowInviteModal(true)
      inviteFormMethods.reset({
        contactInfo: {
          email: selectedCarrier?.contactInfo?.email,
          firstName: selectedCarrier?.contactInfo?.firstName,
          lastName: selectedCarrier?.contactInfo?.lastName,
        },
      })
    }
    useEffect(() => {
      reset({})
      setSearchResults([])
      setInviteLoader(false)
    }, [reset])

    const onSubmit = (formData: any) => {
      setLoading(true)
      const params = getCarrierParamsData(formData)
      getCarrierSearchList(params)
        .then((res: any) => {
          const data = res?.map((item: any) => {
            item['id'] = Math.floor(Math.random() * 1000)
            return item
          })
          setSearchResults(data)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const handleSendInvite = (formData: any) => {
      setInviteLoader(true)
      const codeTypeValue = getCodeTypeValue(
        selectedCarrier?.carrierInfo?.carrierCodes
      )
      formData['carrierInfo'] = {
        carrierName: selectedCarrier?.carrierInfo?.carrierName,
      }
      inviteCarrier(formData, codeTypeValue?.codeType, codeTypeValue?.codeValue)
        .then((res: any) => {
          toast({ id:"carrierToast", title: res?.message })
          setShowInviteModal(false)
          onSuccessCallback()
        })
        .finally(() => setInviteLoader(false))
    }

    const handleRadioChange = (e: any, name: string) => {
      reset({})
      setSearchFilter((prev: any) => ({
        [name]: {
          ...prev[name],
          checked: true,
        },
      }))
    }

    const SelectedSearch = (props: any) => {
      const { label, name, type } = props
      const checked = searchFilter[name]?.checked || false
      return (
        <Container className={styles.searchFieldContainer}>
          <FormProvider {...methods}>
            <RadioField
               id={name.split(":")[0]}
              checked={checked}
              onChange={(e) => handleRadioChange(e, name)}
            />
            <InputFormField
              id={"input"+name.toUpperCase()}
              type={type}
              name={name}
              label={label}
              disabled={!checked}
              required={checked}
            />
          </FormProvider>
        </Container>
      )
    }

    const BladeContent = (props: any) => {
      return (
        <Container className={styles.bladeBodyContainer}>
          <Container className={styles.searchContainer}>
            <Container className={styles.fieldsContainer}>
              <SelectedSearch
                type="text"
                label="Carrier Name"
                name="carrierName:fuzzy"
              />
              <SelectedSearch type="number" label="MC" name="mc" />
              <SelectedSearch type="number" label="DOT" name="dot" />
              <SelectedSearch type="number" label="SCAC" name="scac" />
              <SelectedSearch type="number" label="UUID" name="uuid" />
              <SelectedSearch type="text" label="Policy ID" name="policy_id" />
            </Container>

            <Button
              id={'searchCarrier'}
              label="Search"
              variant="outlined"
              className={styles.searchBtn}
              onClick={handleSubmit((data) => onSubmit(data))}
            />
          </Container>
          <Container className={styles.resultsContainer}>
            <TypoGraph variant="h6" content="Results" />
            <Divider />
            {searchResults?.length > 0 && !loading ? (
              <DataGridTable
                data={searchResults || []}
                columns={searchCarrierList.column}
                columnVisibilityModel={{ id: false }}
                getRowId={(row: any) => row?.id}
                showFilter={false}
                showTitle={false}
                checkboxSelection={false}
                reduceHeight={330}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log('new', newSelectionModel)
                  setSelectionModel(newSelectionModel)
                }}
              />
            ) : (
              <Container className={styles.emptyResultContainer}>
                {loading && <CircularProgress />}
                {!loading && (
                  <>
                    <Icon name="dataSearch" size={130} />
                    <TypoGraph
                      variant="body2"
                      content="First, search for a Carrier, MC, or DOT"
                    />
                  </>
                )}
              </Container>
            )}
          </Container>
        </Container>
      )
    }

    const InviteCarrierForm = () => {
      return (
        <Container className={styles.inviteCarrierContainer}>
          <Icon name="inviteCarrier" height={100} width={200} />
          <TypoGraph
            content="Send Carrier Invitation to join TruckerCloud and Connect"
            variant="body2"
            className={styles.description}
          />
          <FormProvider {...inviteFormMethods}>
            <Container className={styles.fieldItemContainer}>
              <TextField
                id="inputCarrierName"
                label="Carrier Name"
                value={selectedCarrier?.carrierInfo?.carrierName}
                disabled
              />
              <TextField
                id="inputMC"
                label="MC"
                value={
                  selectedCarrier?.carrierInfo?.carrierCodes.filter(
                    (item: any) => item.codeType === 'MC'
                  )[0]?.codeValue || ''
                }
                disabled
              />
              <TextField
                id="inputDOT"
                label="DOT"
                value={
                  selectedCarrier?.carrierInfo?.carrierCodes.filter(
                    (item: any) => item.codeType === 'DOT'
                  )[0]?.codeValue || ''
                }
                disabled
              />
              <TextField
                id="inputUUID"
                label="UUID"
                value={
                  selectedCarrier?.carrierInfo?.carrierCodes.filter(
                    (item: any) => item.codeType === 'UUID'
                  )[0]?.codeValue || ''
                }
                disabled
              />
            </Container>
            <Container className={styles.fieldItemContainer}>
              <InputFormField
                id="inputCarrierEmail"
                label="Carrier Email"
                name="contactInfo.email"
                disabled={selectedCarrier?.contactInfo?.email}
                required
              />
              <InputFormField
                id="inputFirstName"
                label="First Name"
                name="contactInfo.firstName"
                disabled={selectedCarrier?.contactInfo?.firstName}
                required
              />
              <InputFormField
                id="inputLastName"
                label="Last Name"
                name="contactInfo.lastName"
                disabled={selectedCarrier?.contactInfo?.lastName}
                required
              />
            </Container>
          </FormProvider>
        </Container>
      )
    }

    return (
      <Container>
        <Blade
          title="Search and Invite"
          open={open}
          onClose={onClose}
          size={'lg'}
          bodyNode={<BladeContent />}
          footerBtns={{ 

            primary: {
                id:"inviteCarrierButton",
              label: 'Invite',
              onClick: handleInvite,
              disabled: !selectedCarrier,
            },
            secondary: {
              id:"closeBladeCarriers",
              label: 'Close',
              variant: 'outlined',
            },
          }}
        />
        <Modal
          open={showInviteModal}
          title={'Invite New Carrier'}
          handleClose={() => setShowInviteModal(false)}
          size="md"
          body={<InviteCarrierForm />}
          primaryBtnProps={{
            id:"sendInvitationButton",
            label: 'Send Invitation',
            loading: inviteLoader,
            onClick: inviteFormMethods.handleSubmit((data) =>
              handleSendInvite(data)
            ),
          }}
        />
      </Container>
    )
  }

)

export default SearchCarrier
