import { embedDashboard } from '@superset-ui/embedded-sdk';
import { Card, Container, TypoGraph } from 'components';
import React, { useCallback, useEffect, useRef } from 'react';
import { getSupersetGuestToken } from 'api/dashboard';
import { reportException } from 'tools/logs';

interface IInsuranceHeartbeatProps {
  print?: boolean
  useCarrierFilters: boolean
}

const InsuranceHeartbeatReport: React.FC<IInsuranceHeartbeatProps> = (props) => {
  const { useCarrierFilters } = props
  const localStorageDashboardId = localStorage.getItem('embeddedDashboardIds') ?? JSON.stringify([])
  const insuranceHeartbeatDashboardId = JSON.parse(localStorageDashboardId)
  const dashboardId: string = insuranceHeartbeatDashboardId?.INSURANCE_HEARTBEAT ?? process.env.REACT_APP_SUPERSET_INSURANCE_HEARTBEAT ?? ""
  const dashboardUrl: string = process.env.REACT_APP_SUPERSET_URL ?? ""

  const tokenPromiseRef = useRef<Promise<string> | null>(null);

  const getGuestToken = useCallback(() => {
    if (!tokenPromiseRef.current) {
      tokenPromiseRef.current = getSupersetGuestToken(dashboardId, useCarrierFilters)
        .then((response: any) => response.token)
        .catch((error: any) => {
          reportException(error)
          throw error;
        })
    }
    return tokenPromiseRef.current;
  }, [dashboardId, useCarrierFilters]);

  const dashboardEmbedded = useRef(false);

  useEffect(() => {
    if (!dashboardEmbedded.current) {
      embedDashboard({
        id: dashboardId,
        supersetDomain: dashboardUrl,
        mountPoint: document.getElementById("setContainer") as HTMLElement,
        debug: true,
        fetchGuestToken: getGuestToken,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
          },
        },
      })
      dashboardEmbedded.current = true;
    }
  }, [dashboardEmbedded, getGuestToken])

  return (
    <>
      <TypoGraph
        variant="h2"
        sx={{ marginRight: '1rem', marginY: '1rem' }}
        mb={0}
        content={"Portfolio Heartbeat"}
      />
      <Card>
        <Container>
          <div id="setContainer" style={{ width: '90vw', height: '80vh' }}>
          </div>
        </Container>
      </Card>
    </>
  )
}
export default InsuranceHeartbeatReport
