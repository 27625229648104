import { useCallback, useEffect, useRef, useState } from 'react'
import {
    Container,
    HeaderBar,
    TypoGraph,
    Card,
    Chip,
  } from 'components'
import moment from 'moment'
import styles from '../fleetViewOverTime.module.scss'
import { getSupersetGuestTokenWithoutRules } from 'api/dashboard'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useSelector } from 'react-redux'
import { isAgentLogin, isCarrierLogin } from 'tools/auth'
import { reportException } from 'tools/logs'

interface IFleetHeartbeat {
    print?: boolean
}

const FleetHeartbeat: React.FC<IFleetHeartbeat> = (props) => {
    let riskEldObject: any = {}
    const supersetContainerId = "setContainer"
    const [companyProfile, setCompanyProfile] = useState<any>(null)
    const companyProfileData = useSelector(
        (state: any) => state?.whiteLabel?.companyProfile
    )
    const dashboardId: string = process.env.REACT_APP_SUPERSET_FLEET_HEARTBEAT ?? ""
    const dashboardUrl: string = process.env.REACT_APP_SUPERSET_URL ?? ""
    const shortCode = window.location.host.split('.')[0];

    const tokenPromiseRef = useRef<Promise<string> | null>(null);

    const getGuestToken = useCallback(() => {
        if (!tokenPromiseRef.current) {
            tokenPromiseRef.current = getSupersetGuestTokenWithoutRules(dashboardId)
                .then((response: any) => response.token)
                .catch((error:any) => {
                    reportException(error)
                    throw error;
            })
        }
        return tokenPromiseRef.current;
    }, [dashboardId]);

    const dashboardEmbedded = useRef(false);

    useEffect(() => {
        if (companyProfileData && Object.keys(companyProfileData).length !== 0) {
            setCompanyProfile(companyProfileData?.fullData);
        }
    }, [companyProfileData])

    useEffect(() => {
        if (companyProfile && !dashboardEmbedded.current) {
            const localStorageValidation =  localStorage.getItem("invitingCompanyId") == null ||
                                            localStorage.getItem("invitingCompanyId") == '' ||
                                            localStorage.getItem("invitingCompanyId") == '0' 

            if(localStorageValidation && companyProfile.companyId!==undefined){
                localStorage.setItem("invitingCompanyId", companyProfile.companyId);
            }
            embedDashboard({
                id: dashboardId,
                supersetDomain: dashboardUrl,
                mountPoint: document.getElementById(supersetContainerId) as HTMLElement,
                debug: true,
                fetchGuestToken: getGuestToken,
                dashboardUiConfig: {
                    hideTitle: true,
                    filters: {
                        expanded: true,
                    },
                    urlParams: {
                        ...(isCarrierLogin() && {companyId: localStorage.getItem('companyId')}),
                        companyShortCode: shortCode,
                        ...(localStorage.getItem('invitingCompanyId') !== null && { invitingCompanyId: localStorage.getItem('invitingCompanyId') }),
                        ...(isAgentLogin() && { 
                            carrierCode: localStorage.getItem('carrierCodeType'), 
                            codeValue: localStorage.getItem('carrierCodeValue') 
                        }),            
                    },
                },
            })
            dashboardEmbedded.current = true;
        }
    }, [companyProfile, getGuestToken]);
    
    const HeaderDom = () => {
        return (
            <Container className={styles.headerContainer}>
                <Container className={styles.headerTitleContainer}>
                    <TypoGraph
                        variant="h1"
                        sx={{ marginRight: '1rem' }}
                        mb={0}
                        content={'Fleet Heartbeat'}
                    />
                    <Chip
                        label={`Updated ${moment(riskEldObject?.updated).format('dddd, MMMM Do')}`}
                        variant="filled"
                        sx={{
                            backgroundColor: '#E1FAEB',
                            color: '#20693D',
                        }}
                    />
                </Container>
            </Container>
        )
    }


    return (
        <Container className={styles.insuranceBladeBodyContainer}>
            <Container>
                <HeaderBar dom={<HeaderDom />} />
                <Card>
                    <Container>
                        <div id={supersetContainerId} style={{ width: '95vw', height: '80vh' }}>
                        </div>
                    </Container>
                </Card>
            </Container>
        </Container>
    )
}

export default FleetHeartbeat