import { produce } from 'immer';
import {IWhiteLabelInitialState} from 'interfaces/Iredux'
import { SET_THEME, COMPANY_PROFILE } from 'actions/whitelabel'

export const initialState: IWhiteLabelInitialState = {
    theme: {
        primary: '#44A3DB'
    },
    companyProfile: {}
}

const whiteLabelReducer = (state = initialState, action: any) => {
  return produce(state, draft => {
    switch (action.type) {
      case SET_THEME:
        draft.theme = action.data
        break;
      case COMPANY_PROFILE:
        draft.companyProfile = action.data
        break;
      default:
        break
    }
  })
}

export default whiteLabelReducer
