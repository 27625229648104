import React from 'react'
import MaterialButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

export interface IButtonProps {
  id?:string
  variant?: 'text' | 'contained' | 'outlined'
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
  onClick?: () => void
  label: string
  fullWidth?: boolean
  className?: string
  loading?: boolean
  pill?: boolean
  type?: 'button' | 'submit' | 'reset'
  style?: any
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
}

const Button: React.FC<IButtonProps> = (props) => {
  const {
    id,
    type,
    variant = 'contained',
    disabled = false,
    size = 'medium',
    onClick = () => {},
    label,
    fullWidth = false,
    className,
    loading = false,
    pill = true,
    color = 'primary',
    style,
  } = props

  const MuiButton = styled(MaterialButton)(({ theme }) => ({
    borderRadius: pill ? 25 : theme.shape.borderRadius,
  }))

  if (!label) {
    return <></>
  }

  return (
    <MuiButton
      type={type}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onClick={() => !loading && onClick()}
      fullWidth={fullWidth}
      className={className}
      {...props}
      id={id}
      style={style}
    >
      {loading && (
        <CircularProgress
          size={20}
          sx={{
            color: 'white',
            marginRight: '15px',
          }}
        />
      )}
      {label}
    </MuiButton>
  )
}

export default Button
