import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import SettingsRoute from './settingsRoute'
import Page404 from 'pages/page404'
import Profile from 'pages/profile'
import CarriersRoutes from './carriersRoute'
import Reporting from 'pages/reporting'
import AccountsRoutes from './accountsRoute'
import { isAgentLogin, isCarrierLogin } from 'tools/auth'
import strings from 'strings'
import { IMenuAccess } from 'interfaces'
import FleetReporting from 'pages/connectedAccounts/partial/fleetReporting'
import RiskDashboard from 'pages/riskDashboard'
import { RouteComponentProps } from '@reach/router'

interface IApplicationRoute extends RouteComponentProps {
  isAuth: boolean,
  menuAccess: any
}

const ApplicationRoute: React.FunctionComponent<IApplicationRoute> = (
  props
) => {
  const { isAuth, menuAccess } = props

  const isAvailableMenuAccess = (path: string) : boolean => {
    return menuAccess.some((menuItem : IMenuAccess) => menuItem.url === path)
  }

  const getCommonRoutes = () => {
    return (
      <>
        <Route path={strings.ROUTES.LOGIN} 
          element={<Navigate to={strings.ROUTES.SETTINGS} />}
        />
        <Route
          path={strings.ROUTES.PROFILE}
          element={
            <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.PROFILE)}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </>
    )
  }

  const getCompanyRoutes = () => {
    if (isCarrierLogin()) {
      return (
        <>
        <Route path="" element={<Navigate to="/accounts" />} />
        <Route
          path={strings.ROUTES.ACCOUNTS}
          element={
            <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.ACCOUNTS)}>
              <AccountsRoutes />
            </ProtectedRoute>
          }
        />
        <Route
          path={strings.ROUTES.FLEET_REPORTING}
          element={
            <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.FLEET_REPORTING)}>
              <FleetReporting />
            </ProtectedRoute>
          }
        />
        </>
      )
    }
    if (isAgentLogin()) {
      return (
        <>
        <Route path="" element={<Navigate to="/dashboard" />} />
        <Route
            path={strings.ROUTES.DASHBOARD}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.DASHBOARD)}>
                <RiskDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={strings.ROUTES.CARRIERS}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.CARRIERS)}>
                <CarriersRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path={strings.ROUTES.FLEET_REPORTING}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.FLEET_REPORTING)}>
                <FleetReporting />
              </ProtectedRoute>
            }
          />
        </>
      )
    }
    else {
      return (
        <>
          <Route path="" element={<Navigate to="/dashboard" />} />
          <Route
            path={strings.ROUTES.DASHBOARD}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.DASHBOARD)}>
                <RiskDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={strings.ROUTES.SETTINGS}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.SETTINGS)}>
                <SettingsRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path={strings.ROUTES.CARRIERS}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.CARRIERS)}>
                <CarriersRoutes />
              </ProtectedRoute>
            }
          />
          <Route
            path={strings.ROUTES.REPORTING}
            element={
              <ProtectedRoute isAuth={isAuth} hasAccess={isAvailableMenuAccess(strings.ROUTES.REPORTING)}>
                <Reporting />
              </ProtectedRoute>
            }
          />
        </>
      )
    }
  }

  return (
    <Routes>
      {getCommonRoutes()}
      {getCompanyRoutes()}
    </Routes>
  )
}

export default ApplicationRoute
