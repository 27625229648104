const userGuides: any = {
    accidentplan: 'https://docs.google.com/document/d/1iXPgkDW6COkCxew4Zgid3N6nJ2C-noPrBA0dlZPKMfA/edit',
    adgile: 'https://docs.google.com/document/d/1en2ONZ6PlBxfSk5wLNCPolm_gzWoSpKhywv9Pe2h5Ws/edit',
    canal: 'https://docs.google.com/document/d/1M7XPGpuVs_rYNMf59sC-mU04iKRH7s8iAZaNKBHvQTk/edit',
    cloudtrucks: 'https://docs.google.com/document/d/1DQEJN_uIrTbiRD4yTg8eqpr1gJ015KqbHV3zeM_tD98/edit',
    crumforster: 'https://docs.google.com/document/d/13p0Rt4j-TCuhR4ud-r4SAlx16PbTAHFWq6lFHungMfg/edit',
    expresswaybill: 'https://docs.google.com/document/d/1K-c8VN1I5Zg-SI5lg9tj6Llp1oMDO6yIqoTpQcD33w4/edit',
    flextms: 'https://docs.google.com/document/d/1SlxuelU2014QgGx9BNBaup_6chCATISLug6WLicl81w/edit',
    hdvi: 'https://docs.google.com/document/d/1fuJd5VMOc1uUKxO3NgEcRe_wOwTxIM2SXV4vGhhYgCU/edit',
    loadright: 'https://docs.google.com/document/d/1WncNJSt-mnFvTnwmn7iEJFufoRCbOHV_M_NZFFA91BY/edit',
    loadsmart: 'https://docs.google.com/document/d/1HpRhJCmNK73W53pF4IUMZbBTSMwom0vxGDyEBItHCSg/edit',
    nirvanainsurance: 'https://docs.google.com/document/d/1Yx47Qs_p1nNzzz0_owPHPev6iegUXXIXU9cFGe5Q7uc/edit',
    overhaul: 'https://docs.google.com/document/d/1BbLLCtUx1D_bDN5Ah5orV2z8UAAR4FzTYDDaS3YSc_Q/edit',
    repowr: 'https://docs.google.com/document/d/1l8eAudtGrR1aXFccJ1VJczUA3KB1xo8_4P3epkiexNw/edit',
    skyward: 'https://docs.google.com/document/d/11L-zvN0ew-JxYtdWNqmtSE0Sh3el3QrAbppanLSmipg/edit',
    sps: 'https://docs.google.com/document/d/1-41l04lTzYvcoAiuqm5sfaFku36Tcu43cUgSqeoYT54/edit',
    truckbase: 'https://docs.google.com/document/d/1xGmiMc5E8Z1VFDvpFJpfi9-Nlc5JZGo7CgjUv8PaE1s/edit',
    truckerpath: 'https://docs.google.com/document/d/1dsuf84LzYdNq2L1AMniujk0Mfhe5kxPH5CueV3HP6JI/edit',
    truetms: 'https://docs.google.com/document/d/1LbY0narnrPDzDtQ1CukPiMeRGo0xUOMjtpYy57EFQh0/edit',
    tvsanalytics: 'https://docs.google.com/document/d/1J1YNZqKICyGidt43SAQRxJuhqpaqkCoZwiE8tWWzNKY/edit',
    warplogistics: 'https://docs.google.com/document/d/1mK0T62D-dBz7qwR5_qYenhA0z5d7nSnU_stqhV-dUFY/edit',
    zeuslogics: 'https://docs.google.com/document/d/1RNmc-FT93DpHmZ6te8l3u7HkbARDtF7toaym_XYJFxY/edit'
}

export default userGuides