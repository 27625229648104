import { ToastContainer as TContainer, toast as event } from 'react-toastify'
import { Container, TypoGraph } from 'components'

interface IToastContainerProps {
  id?:string
  position?: 'bottom-right'
  autoClose?: number
}

export const ToastContainer: React.FC<IToastContainerProps> = (props) => {
  const {
    id,
    position = 'bottom-right',
    autoClose = 5000, // default autoclose is 5 sec
  } = props
  return (
    <TContainer
      position={position}
      autoClose={autoClose}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

interface IToastContent {
  id?:string
  title: string
  subTitle?: string
}

const ToastContent: React.FC<IToastContent> = (props) => {
  const { id, title, subTitle } = props
  return (
    <Container >
      <TypoGraph content={title} sx={{ textTransform: 'capitalize' }} />
      {subTitle && <TypoGraph variant="subtitle2" content={subTitle} />}
    </Container>
  )
}

interface IToastProps {
  id? :string
  type?: 'info' | 'success' | 'warning' | 'error'
  title?: string
  subTitle?: string
  position?: 'bottom-right'
  autoClose?: number
  theme?: 'light' | 'dark' | 'colored'
  showIcon?: boolean
}

const Toast = (props: IToastProps) => {
  const {
    id,
    type = 'success',
    title = '',
    subTitle = '',
    position = 'bottom-right',
    autoClose = 5000,
    theme = 'light',
    showIcon = true,
  } = props
  return event[type](<ToastContent id={id} title={title} subTitle={subTitle} />, {
    toastId:id,
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
    icon: showIcon,
  })
}

export default Toast
