import { useCallback, useEffect, useState } from 'react'
import { getInvitedCarrierList } from 'api/carriers'
import { Container, DataGridTable } from 'components'
import { invitedCarrierList } from './invitedCarriersTableData'
import { getInvitedCarrierData } from 'tools/tableDataHelper'
import { copyToClipboard } from 'tools/helper'

interface IInvitedCarrierProps {
  reload?: number | boolean
}

const InvitedCarrier: React.FC<IInvitedCarrierProps> = (props) => {
  const { reload } = props
  const [loading, setLoading] = useState(false)
  const [invitedCarrierData, setInvitedCarrierData] = useState([])

  const { column } = invitedCarrierList

  const loadPagination = (pageNumber: number, pageSize: number) => {
    console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
  }

  const callInvitedCarrierList = useCallback(() => {
    setLoading(true)
    getInvitedCarrierList()
      .then((res: any) => {
        const data = getInvitedCarrierData(res)
        setInvitedCarrierData(data)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    callInvitedCarrierList()
  }, [callInvitedCarrierList])

  useEffect(() => {
    callInvitedCarrierList()
  }, [reload, callInvitedCarrierList])

  column[4].handleClick = (row: any) => {
    copyToClipboard(row.email)
  }

  return (
    <Container>
      <DataGridTable
        title={'Invited Carriers'}
        data={invitedCarrierData || []}
        loading={loading}
        columns={column}
        columnVisibilityModel={{carrierId: false}}
        multiSearchFilter={false}
        totalCount={invitedCarrierData?.length}
        reduceHeight={260}
        checkboxSelection={false}
        getRowId={(row: any) => row.carrierId}
        handlePagination={(pageNumber, pageSize) =>
          loadPagination(pageNumber, pageSize)
        }
      />
    </Container>
  )
}

export default InvitedCarrier
