import request from 'tools/request'

export const userLogin = async (loginRequest: any) => {
    return await request.post('userresource/login', loginRequest)
}

export const changePassword = async (payLoad: any) => {
    const res = await request.put(
      `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}users/reset-password`,
      payLoad
    )
    return res?.data
}

export const requestPasswordReset = async (payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_USER_MANAGEMENT_API_URL}users/request-password-reset`,
    payLoad
  )
  return res?.data
}