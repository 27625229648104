import {GridComparatorFn} from '@mui/x-data-grid'

/**
 * Comparators for each used column type
 */
const dateComparator: GridComparatorFn<Date> = (v1, v2) =>
  v1.getDate() - v2.getDate();

const numberComparator: GridComparatorFn<any> = (v1, v2) => {
  // Handle "-" values as empty cells
  if (v1 === "-" && v2 !== "-") {
    return -1; // v1 is empty, so it comes before v2
  } else if (v1 !== "-" && v2 === "-") {
    return 1; // v2 is empty, so it comes before v1
  } else if (v1 === "-" && v2 === "-") {
    return 0; // Both v1 and v2 are empty, consider them equal
  }

  // Remove commas from the values
  const cleanedV1 = v1.replace(/,/g, '');
  const cleanedV2 = v2.replace(/,/g, '');

  // Parse v1 and v2 as numbers and compare them
  const num1 = parseFloat(cleanedV1);
  const num2 = parseFloat(cleanedV2);
  
  if (isNaN(num1) || isNaN(num2)) {
    // Handle invalid numeric values, such as NaN
    return 0; // Consider them equal
  }

  return num1 - num2; // Compare as numbers
};

const numberOrStringComparator: GridComparatorFn<any> = (v1, v2) => {
  // Handle "-" values as empty cells
  if (v1 === "-" && v2 !== "-") {
    return -1; // v1 is empty, so it comes before v2
  } else if (v1 !== "-" && v2 === "-") {
    return 1; // v2 is empty, so it comes before v1
  } else if (v1 === "-" && v2 === "-") {
    return 0; // Both v1 and v2 are empty, consider them equal
  }

  // Check if both values are numbers
  const isNum1 = /^\d+$/.test(v1);
  const isNum2 = /^\d+$/.test(v2);

  if (isNum1 && isNum2) {
    // Both are numbers, use number comparison
    return parseFloat(v1) - parseFloat(v2);
  } else if (isNum1) {
    // v1 is number, v2 is not, v1 comes first
    return -1;
  } else if (isNum2) {
    // v2 is number, v1 is not, v2 comes first
    return 1;
  } else {
    // Both are non-numeric, use case-insensitive alphabetical comparison
    const str1 = v1.toString().toLowerCase();
    const str2 = v2.toString().toLowerCase();

    if (str1 > str2) {
      return -1;
    } else if (str1 < str2) {
      return 1;
    } else {
      return 0;
    }
  }
};

const booleanComparator: GridComparatorFn<boolean> = (v1, v2) => {
  if (v1 && !v2) {
    return -1; // v1 is true and v2 is false, so v1 comes before v2
  } else if (!v1 && v2) {
    return 1; // v1 is false and v2 is true, so v2 comes before v1
  } else {
    return 0; // Both v1 and v2 are either true or false, so they are considered equal
  }
};

const milesRangeComparator: GridComparatorFn<any> = (v1, v2) => {
  // Handle "-" values as empty cells
  if (v1 === "-" && v2 !== "-" || v1 === undefined && v2 !== undefined) {
    return -1; // v1 is empty, so it comes before v2
  } else if (v1 !== "-" && v2 === "-" || v1 !== undefined && v2 === undefined) {
    return 1; // v2 is empty, so it comes before v1
  } else if (v1 === "-" && v2 === "-" || v1 === undefined && v2 === undefined) {
    return 0; // Both v1 and v2 are empty, consider them equal
  }

  // Remove rangeUnit from v1 and v2
  const rangeUnit = "K";
  const cleanedV1 = v1.replace(/,/g, '').toString().split("-")[0].replace(rangeUnit, ""); //"10K-11K" -> 10K -> 10
  const cleanedV2 = v2.replace(/,/g, '').toString().split("-")[0].replace(rangeUnit, "");

  // Parse v1 and v2 as numbers and compare them
  const num1 = parseFloat(cleanedV1);
  const num2 = parseFloat(cleanedV2);

  if (isNaN(num1) || isNaN(num2)) {
    // Handle invalid numeric values, such as NaN
    return 0; // Consider them equal
  }

  return num1 - num2;
};

const stringDateComparator: GridComparatorFn<any> = (v1, v2) => {
  const date1 = new Date(v1).toISOString();
  const date2 = new Date(v2).toISOString();

  if (date1 < date2) return -1;
  if (date1 > date2) return 1;
  return 0;
};

export {
    dateComparator,
    numberComparator,
    numberOrStringComparator,
    booleanComparator,
    milesRangeComparator,
    stringDateComparator
}