import { useState, useEffect, useCallback, useMemo } from 'react'
import debounce from 'lodash.debounce'
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridActionsCellItem,
  GridSlotsComponent,
  gridClasses,
  GridRowSelectionModel,
  GridPaginationModel,
  GridSortModel,
  GridCallbackDetails,
  GridColumnVisibilityModel,
  GridRowParams,
} from '@mui/x-data-grid'
import {
  Box,
  Fade,
  Paper,
  ListItemText,
  MenuList,
  MenuItem,
} from '@mui/material'
import Popper from '@mui/material/Popper'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Icon, Card, TypoGraph, Container, Popover } from 'components'
import { MultiSelectField } from 'components/controllers'
import clientSearch from 'tools/searchQuery'
import { GridColumnGroupingModel } from '@mui/x-data-grid/models/gridColumnGrouping'
import styles from './table.module.scss'

const dataGridSx = {
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '.MuiDataGrid-columnSeparator--sideRight': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeaderDraggableContainer': {
    width: 'auto',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .actionButton': {
    display: 'none',
  },
  '.MuiDataGrid-actionsCell': {
    position: 'absolute',
    right: '2rem',
  },
  [`& .${gridClasses.row}:hover`]: {
    '.actionButton': {
      display: 'block',
    },
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-selectedRowCount ': {
    visibility: 'hidden',
  },
  background: 'transparent',
}

const tableCustomizeList = [
  {
    label: 'Column Customization',
    key: 'column',
  },
  {
    label: 'Density',
    key: 'density',
  },
  {
    label: 'Download',
    key: 'download',
  },
]

interface IActionList {
  id?: string
  icon: string
  name: string
  className?: string
  iconSize?: number
  selection?: boolean
}

interface IFilterList {
  label: string
  key: string
}

interface IMenuListItems {
  label: string
  key: string
  action: () => void
}

interface ITableProps {
  id?: string
  loading?: boolean
  columns: GridColDef[]
  columnGroupingModel?: GridColumnGroupingModel
  columnVisibilityModel?: GridColumnVisibilityModel
  data: GridRowsProp
  totalCount?: number
  title?: string
  defaultPageSize?: number
  checkboxSelection?: boolean
  reduceHeight?: number
  autoHeight?: boolean
  paginationMode?: 'client' | 'server'
  columnHeaderHeight?: number
  actionList?: IActionList[]
  components?: Partial<GridSlotsComponent> | undefined
  filters?: IFilterList[]
  rowHeight?: number
  showFilter?: boolean
  showTitle?: boolean
  multiSearchFilter?: boolean
  rowsPerPageOptions?: number[],
  disableSelectionOnClick?: boolean,
  customSelectionModel?: GridRowSelectionModel
  onSingleQuickFilterChange?: (value: any) => any
  getRowId?: (row: any) => any
  handleCheckboxSelection?: (selections: GridRowSelectionModel) => void
  handleActions?: (id: string | number, name: string) => void
  handleSelectionActions?: (
    name: string,
    selectionModel: GridRowSelectionModel
  ) => void
  handlePagination?: (pageCount: number, pageSize: number) => void
  onSelectionModelChange?: (newSelectionModel: GridRowSelectionModel) => void
  sortField?: any
  sortOrder?: any
  menuList?: IMenuListItems[]
  apiRef?: any
  isRowSelectable?: (params: GridRowParams) => boolean
  editMode?: 'row' | 'cell'
  processRowUpdate?: any
  onProcessRowUpdateError?: any
}

const TableComponent: React.FC<ITableProps> = (props) => {
  const {
    id,
    loading, // shows the loader inside the table
    data, // array of datas which will be paried from the column name
    totalCount, // table total count
    paginationMode = 'client', // you can change the paginationMode to handle pagination in server side or client side
    columns, // colums with array of objects
    columnGroupingModel,  // groups of columns
    columnVisibilityModel, // visibility for columns
    columnHeaderHeight = 50, // height for columns' headers
    defaultPageSize = 100,
    checkboxSelection = true, // To show or hide the checkbox selection in table
    actionList, // list of actions items to show while hover the row
    reduceHeight = 220, // reduces table height
    components,
    filters,
    rowHeight = 52,
    title,
    showFilter = true,
    showTitle = true,
    multiSearchFilter = true,
    rowsPerPageOptions = [5, 10, 20, 50, 100],
    autoHeight,
    disableSelectionOnClick,
    customSelectionModel,
    onSelectionModelChange,
    getRowId = (row: any) => row?.id, // to get unique row id,
    handleActions, // func to handle the actions
    handleCheckboxSelection, // func to handle the checkbox selection
    handlePagination, // func to handle the pagination
    onSingleQuickFilterChange, // func to handle quick search
    sortField,
    sortOrder,
    menuList, //list of items for dot menu next to search bar
    apiRef,
    isRowSelectable,
    editMode,
    processRowUpdate,
    onProcessRowUpdateError,
  } = props
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [customColumns, setCustomColumns] = useState(() => columns)
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [filterPopper, setFilterPopper] = useState(null)
  const [tablePopover, setTablePopover] = useState(null)
  const [inputVal, setInputVal] = useState('')
  const [key, setMultiKey] = useState<number>(0)
  const [tableData, setTableData] = useState<any>([])
  const [filterValues, setFilterValues] = useState<any>({
    freeSearches: [],
  })
  
  /*
   * function to build list of action cells
   * {param} id => will contain the row ID and will be passed to handleActions
   * ${return} => list of action array
   */
  const buildActionData = useCallback(
    (id: number | string) => {
      const actionItems = actionList?.map((item) => {
        return (
          <GridActionsCellItem
            id={item.id}
            icon={<Icon name={item?.icon} size={item?.iconSize || 25} />}
            label={item?.name}
            showInMenu={true}
            onClick={() => handleActions && handleActions(id, item?.name)}
          />
        )
      })
      return actionItems
    },
    [actionList, handleActions]
  )

  /**
   * This effect is used to merge the action object with columns
   * This logic will only if we list of actions from the props
   */
  useEffect(() => {
    const action = {
      field: 'actions',
      type: 'actions' as 'actions',
      width: 0,
      cellClassName: 'actions',
      getActions: ({ id }: { id: string | number }) => buildActionData(id),
    }
    const actionsLength = actionList?.length
    if (actionsLength) {
      setCustomColumns([...columns, action])
    } else {
      setCustomColumns(columns)
    }
  }, [actionList, columns, buildActionData])

  useEffect(() => {
    if (data) {
      setTableData(data)
      setSelectionModel(customSelectionModel);
    }
  }, [data])

  const handleSingleQuickFilter = useCallback(
    (val: any) => {
      if (!val) {
        setTableData(data)
      } else {
        const searchedData = clientSearch(val, data)
        setTableData(searchedData)
      }
    },
    [data]
  )

  const debouncedChangeHandler = useMemo(
    () => debounce((val) => handleSingleQuickFilter(val), 1000),
    [handleSingleQuickFilter]
  )

  const handleRecordVal = (val: string) => {
    setFilterValues((prev: any) => ({
      ...prev,
      freeSearches: [...prev.freeSearches, val],
    }))
    setInputVal('')
    setAnchorEl(null)
    setMultiKey(Math.random() * 1000)
  }

  const handleFilterKey = (val: any) => {
    setFilterPopper(null)
  }

  const handleTableCustomize = (val: any) => {
    setTablePopover(null)
  }

  return (
    <Card sxContent={{ padding: 0, paddingBottom: '0!important' }}>
      {showTitle && (
        <Container sx={{ padding: '1rem' }}>
          <TypoGraph variant="h2" content={title ? title : 'Search & Filter'} />
        </Container>
      )}
      {showFilter && (
        <Container className={styles.filterContainer}>
          <Container className={styles.moreContainer}>
            <IconButton
              id="actionsButtonTopRow"
              onClick={(e: any) => {
                setTablePopover(e.target)
                setAnchorEl(null)
                setFilterPopper(null)
              }}
            >
              <MoreVertIcon htmlColor="grey" />
            </IconButton>
          </Container>
          <Container className={styles.filterIconContainer}>
            <FilterListIcon htmlColor="grey" />
          </Container>
          <Container>
            <MultiSelectField
              options={[]}
              value={filterValues.freeSearches}
              freeSolo={true}
              placeholder="Search and Filter"
              className={styles.noFieldset}
              multiple={multiSearchFilter}
              onInputChange={(e: any, val: any) => {
                setInputVal(val)
                setAnchorEl(e.target)
                setFilterPopper(null)
                !multiSearchFilter && debouncedChangeHandler(val)
                !multiSearchFilter &&
                  onSingleQuickFilterChange &&
                  onSingleQuickFilterChange(val)
              }}
              onChange={(e: any, val) => {
                setFilterValues((prev: any) => ({ ...prev, freeSearches: val }))
                setAnchorEl(null)
                setFilterPopper(e.target)
              }}
              onOpen={(e: any) => {
                setFilterPopper(e.target)
                setAnchorEl(null)
              }}
              onClose={(e) =>
                setTimeout(() => {
                  setFilterPopper(null)
                }, 300)
              }
            />
          </Container>
        </Container>
      )}
      <Box
        sx={{
          height: autoHeight ? 'auto' : `calc(100vh - ${reduceHeight}px)`,
          width: '100%',
        }}
      >
        <DataGrid
          apiRef={apiRef}
          sx={dataGridSx}
          rows={tableData}
          columns={customColumns}
          columnGroupingModel={columnGroupingModel}
          columnHeaderHeight={columnHeaderHeight}
          columnVisibilityModel={columnVisibilityModel}
          getRowId={getRowId}
          pagination
          onPaginationModelChange={(model, details) => {
            if (model.page !== undefined) {
              setCurrentPage(model.page)
            }
            if (model.pageSize !== undefined) {
              setPageSize(model.pageSize)
            }
            handlePagination &&
              handlePagination(
                model.page ?? currentPage,
                model.pageSize ?? pageSize
              )
          }}
          pageSizeOptions={
            rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20, 50, 100]
          }
          loading={loading}
          paginationMode={paginationMode}
          rowCount={totalCount}
          slots={components}
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={(
            newSelectionModel: GridRowSelectionModel
          ) => {
            setSelectionModel(newSelectionModel)
            onSelectionModelChange && onSelectionModelChange(newSelectionModel)
            handleCheckboxSelection &&
              handleCheckboxSelection(newSelectionModel)
          }}
          rowSelectionModel={selectionModel}
          isRowSelectable={isRowSelectable}
          rowHeight={rowHeight}
          autoHeight={autoHeight}
          editMode={editMode}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateError}
          disableRowSelectionOnClick={disableSelectionOnClick}
          disableColumnFilter
          disableColumnMenu
          initialState={{
            ...tableData.initialState,
            pagination: { paginationModel: { pageSize: pageSize } },
            sorting: {
              sortModel:
                sortField && sortOrder
                  ? [{ field: sortField, sort: sortOrder }]
                  : [],
            },
          }}
        />
      </Box>
      {multiSearchFilter && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList>
                  <MenuItem onClick={() => handleRecordVal(inputVal)}>
                    <ListItemText>{`Records contains ${inputVal}`}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      <Popper
        open={Boolean(filterPopper)}
        anchorEl={filterPopper}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <MenuList>
                {filters?.map((item, index) => (
                  <MenuItem
                    onClick={() => handleFilterKey(item)}
                    key={`key-${index}`}
                  >
                    <ListItemText>{item.label}</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Fade>
        )}
      </Popper>
      {/* Table customization popover ex: column hide/show, density, download options  */}
      <Popover
        open={Boolean(tablePopover)}
        anchorEl={tablePopover}
        onClose={() => {
          setTablePopover(null)
        }}
      >
        {menuList ? (
          <MenuList>
            {menuList.map((item, index) => (
              <MenuItem
                onClick={() => {
                  item.action()
                  setTablePopover(null)
                }}
                key={`menu-key-${index}`}
              >
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <MenuList>
            {tableCustomizeList.map((item, index) => (
              <MenuItem
                onClick={() => handleTableCustomize(inputVal)}
                key={`table-customize-key-${index}`}
              >
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Popover>
    </Card>
  )
}

export default TableComponent
