export const settings = [
  {
    title: 'Users',
    link: 'users',
    description: 'Add, update, and delete users',
    buttonLabel: 'Manage Users',
    icon: 'settings_users'
  },
  {
    title: 'Email Templates',
    link: 'email-templates',
    description: 'Add, update, and delete email templates',
    buttonLabel: 'Manage Templates',
    icon: 'settings_emailTemplates'
  },
  {
    title: 'Email Accounts',
    link: 'email-accounts',
    description: 'Email accounts for sending',
    buttonLabel: 'Manage Email',
    icon: 'settings_emailAccounts'
  },
  {
    title: 'White Label',
    link: 'white-label',
    description: 'Name, color & company logo',
    buttonLabel: 'Manage Whitelabel',
    icon: 'settings_whiteLabel'
  },
  {
    title: 'Company Config',
    link: 'company-config',
    description: 'Add or update company config',
    buttonLabel: 'Manage Config',
    icon: 'settings_companyConfig'
  },
  {
    title: 'Agents',
    link: 'agents',
    description: 'Add, update, and delete agents',
    buttonLabel: 'Manage Agents',
    muiIcon: 'SupportAgent'
  },
  {
    title: 'Tags',
    link: 'tags',
    description: 'Add, update, and delete tags',
    buttonLabel: 'Manage Tags',
    muiIcon: 'LabelOutlined'
  }
]
