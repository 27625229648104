import React, { useEffect, useState } from 'react'
import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Icon from 'components/icon'

export interface ITextFieldProps {
  id?: string
  label?: string
  type?: 'password' | 'number' | 'text'
  variant?: 'outlined' | 'filled' | 'standard'
  defaultValue?: string | number
  value?: string | number
  InputLabelProps?: object
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  helperText?: string
  error?: boolean
  multiline?: boolean
  rows?: number
  inputRef?: any
  select?: boolean
  fullWidth?: boolean
  showPasswordIcon?: boolean
  children?: React.ReactNode
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  className?: string
  onChange?: (e: React.ChangeEvent) => void
  onClick?: (e: any) => void
  onBlur?: (e: React.ChangeEvent) => void
  onKeyDown?: (e: any) => void
}

const TextField: React.FunctionComponent<ITextFieldProps> = (props) => {
  const {
    id,
    variant = 'outlined',
    value,
    type = 'text',
    defaultValue,
    onChange = () => {},
    onClick = () => {},
    onKeyDown = () => {},
    InputLabelProps = {},
    readOnly = false,
    required = false,
    disabled = false,
    error = false,
    multiline = false,
    helperText,
    rows = 4,
    inputRef,
    select = false,
    showPasswordIcon = false,
    startAdornment = '',
    endAdornment = '',
    children,
    fullWidth = false,
    placeholder,
    className,
    onBlur,
  } = props
  const [customType, setCustomType] =
    useState<'password' | 'number' | 'text'>(type)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  useEffect(() => {
    if (type === 'password') {
      setShowPassword(true)
    }
  }, [type])

  useEffect(() => {
    if (showPassword) {
      setCustomType('text')
    } else if (showPasswordIcon) {
      setCustomType('password')
    } else {
      setCustomType(type)
    }
  }, [showPassword, showPasswordIcon, type])

  return (
    <MuiTextField
      id={id}
      className={className}
      fullWidth={fullWidth}
      placeholder={placeholder}
      type={customType}
      select={select}
      inputRef={inputRef}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      variant={variant}
      required={required}
      helperText={helperText}
      disabled={disabled}
      error={error}
      multiline={multiline}
      rows={rows}
      InputLabelProps={InputLabelProps}
      onClick={onClick}
      margin="normal"
      InputProps={{
        readOnly: readOnly,
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: (showPasswordIcon || endAdornment) && !select && (
          <InputAdornment position="end">
            {showPasswordIcon ? (
              <Icon onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </Icon>
            ) : (
              endAdornment
            )}
          </InputAdornment>
        ),
      }}
      {...props}
    >
      {children}
    </MuiTextField>
  )
}

export default TextField
