import React, { useEffect, useState, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  Container,
  HeaderBar,
  DataGridTable,
  Blade,
  Tabs,
  SubmitDialog,
  Toast as toast,
} from 'components'
import { DropdownFormField, InputFormField } from 'components/hookForm'
import { tagManagementStatus } from 'constants/status'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import styles from './tagList.module.scss'
import strings from 'strings'
import { reportException } from 'tools/logs'
import {
  createTagSubcategory,
  getTagCategories,
  getTagSubcategories,
  editTagSubcategory,
  deleteTagSubcategory,
  getAvailableCarriersForTags,
  getAssignedCarriersForTags,
} from 'api'
import { actionList, tagListColumns } from './tagListTableData'
import CarriersForTags from './carriersForTags'

const t = strings.TAG_MANAGEMENT

const toastType: ObjectAnyKeys = {
  reject: 'error',
  hold: 'warning',
  deposit: 'success',
}

interface ITagManagementListProps {}

interface IBladeContentProps {}

const TagManagementList: React.FC<ITagManagementListProps> = () => {
  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
  const [isEditForm, setIsEditForm] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedTag, setSelectedTag] = useState<any>()
  const [selectedRowData, setSelectedRowData] = useState<any>(null)
  const [motorCarriersForTags, setMotorCarriersForTags] = useState([])
  const [tagCategoriesList, setTagCategoriesList] = useState([])
  const [tagList, setTagList] = useState([])
  const [showMotorCarriersForAgents, setShowMotorCarriersForAgents] = useState(false)
  const [isAssignedCarriers, setIsAssignedCarriers] = useState(false)
  const [carriersLoading, setCarriersLoading] = useState(false)
  const [currentTag, setCurrentTag] = useState({})

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    callTagList()
  }, [])

  useEffect(() => {
    getTagCategories()
      .then((response: any) => {
        setTagCategoriesList(response)
      })
      .catch((error: any) => {
        reportException(error)
      })
  }, [tagList])

  const callTagList = useCallback(() => {
    setLoading(true)
    getTagSubcategories().
      then((response: any) => {
        setTagList(response)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const callAvailableCarriers = useCallback((tagId: string) => {
    setCarriersLoading(true)
    getAvailableCarriersForTags(tagId)
      .then((response: any) => {
        const transformedCarrierList = replaceNulls(response)
        setMotorCarriersForTags(transformedCarrierList)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setCarriersLoading(false)
      })
  }, [])

  const callAssignedCarriers = useCallback((tagId: string) => {
    setCarriersLoading(true)
    getAssignedCarriersForTags(tagId)
      .then((response: any) => {
        const transformedCarrierList = replaceNulls(response)
        setMotorCarriersForTags(transformedCarrierList)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setCarriersLoading(false)
      })
  }, [])

  const replaceNulls = (data: any): any => {
    if (data === null || data === '') return "-";
    if (Array.isArray(data)) return data.map(replaceNulls);
    if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        acc[key] = replaceNulls(data[key]);
        return acc;
      }, {} as any);
    }
    return data;
  };

  const handleClose = () => {
    setShowTemplateForm(false)
    setShowMotorCarriersForAgents(false)
    setIsAssignedCarriers(false)
    reset({})
  }

  const handleActions = (_id: number | string, actionName: string) => {
    setSelectedTag(_id)
    const templateFormData = tagList.filter(
      (item: any) => item.id === _id
    )
    setSelectedRowData(templateFormData[0])
    if (actionName?.toLowerCase() === 'edit') {
      setIsEditForm(true)
      reset(templateFormData[0])
      setShowTemplateForm(true)
    } else {
      setShowDeleteModal(true)
    }

  }

  const handleTagDelete = () => {
    setBtnLoader(true)

    const payLoad = {
      id: selectedRowData.id,
    }
    
    deleteTagSubcategory(payLoad)
      .then((res: any) => {
        toast({
          id: 'deleteTagToast',
          subTitle: res?.description ?? 'Tag deleted successfully',
        })
      })
      .finally(() => {
        setBtnLoader(false)
        setShowDeleteModal(false)
        setShowTemplateForm(false)
        callTagList()
      })
  }

  const handleSelectionActions = (
    actionName: string,
    selectionModel: any
  ) => {
    toast({
      id: "updatedRowsTagManagement",
      type: toastType[actionName],
      title: actionName,
      subTitle: `${selectionModel?.length} rows updated`,
    })
  }

  const handleCarriersForTags = (tagId: string, status: string) => {
    setShowMotorCarriersForAgents(true)

    if (status.toLowerCase() === 'assigned') {
      setIsAssignedCarriers(true)
      callAssignedCarriers(tagId)
    } else {
      setIsAssignedCarriers(false)
      callAvailableCarriers(tagId)
    }

    setCurrentTag(tagId)
  }    

  const onSubmit = (data: any) => {
    setBtnLoader(true)

    if (isEditForm) {
      const payLoad = {
        id: data.id,
        name: data.tagName,
      }
      editTagSubcategory(payLoad)
        .then((res: any) => {
          toast({
            id: 'editTagToast',
            subTitle: res?.description ?? 'Tag updated successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          callTagList()
        })
    } else {
      const payLoad = {
        name: data.tagName,
        tagCategoryId: data.tagCategory,
      }
      createTagSubcategory(payLoad)
        .then((res: any) => {
          toast({
            id: 'createTagToast',
            subTitle: res?.description ?? 'Tag created successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          callTagList()
        })
    }

  }

  const BladeContent = (_props: IBladeContentProps) => {
    return (
      <Container className={styles.bladeBodyContainer}>
        <FormProvider {...methods}>
          {!isEditForm && (
            <DropdownFormField
              id="tagCategory"
              name="tagCategory"
              label={t.TAG_CATEGORY}
              options={tagCategoriesList.map((tag: any) => {
                return {
                  label: tag.name,
                  value: tag.id,
                }
              })}
              required
            />
          )}
          {isEditForm && (
            <InputFormField
              id='category'
              name='category'
              label={t.TAG_CATEGORY}
              value={selectedRowData?.category}
              disabled
            />
          )}
          <InputFormField
            id="tagName"
            name="tagName"
            label={t.TAG_NAME}
            maxLength={100}
            required
          />
        </FormProvider>
      </Container>
    )
  }

  return (
    <Container className={styles.tagListContainer}>
      <HeaderBar
        title={t.TAG_MANAGEMENT}
        primaryBtnProps={{
          label: t.CREATE_TAG,
          onClick: () => {
            reset({})
            setIsEditForm(false)
            setShowTemplateForm(true)
          },
        }}
      />
      <Tabs
        tabData={tagManagementStatus}
        value={tabValue}
        id={tagManagementStatus[tabValue].label}
        handleChange={(_e, val) => setTabValue(val)}
        sxContainer={{ marginLeft: '1rem' }}
      />
      <DataGridTable
        title={t.TAGS}
        loading={loading}
        data={tagList}
        columns={tagListColumns(handleCarriersForTags).columns}
        columnVisibilityModel={{id: false}}
        checkboxSelection={false}
        totalCount={tagList.length}
        reduceHeight={280}
        actionList={actionList}
        multiSearchFilter={false}
        getRowId={(row:any) => row?.id}
        handleActions={handleActions}
        handleSelectionActions={handleSelectionActions}
      />
      <Blade
        open={showTemplateForm}
        title={isEditForm ? t.EDIT_TAG : t.CREATE_TAG}
        onClose={handleClose}
        bodyNode={<BladeContent />}
        footerBtns={{
          primary: {
            id: 'createOrUpdateTagBladeConfirmation',
            label: isEditForm ? strings.UPDATE : strings.SAVE,
            onClick: handleSubmit(onSubmit),
            loading: btnLoader,
            disabled: btnLoader,
          },
          secondary: {
            id: 'closeTagCreationOrUpdateBlade',
            label: 'Close',
            variant: 'outlined',
            onClick: handleClose,
          },
        }}
      />
      <CarriersForTags
        open={showMotorCarriersForAgents}
        currentTag={currentTag}
        motorCarriersForTags={motorCarriersForTags}
        isAssignedCarriers={isAssignedCarriers}
        loading={carriersLoading}
        onClose={handleClose}
      />
      <SubmitDialog
        cancelId='cancelDeleteTag'
        type='delete'
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        title='Delete Confirmation'
        body1={`Are you sure you want to delete this tag called "${selectedRowData?.name}"?`}
        body2="Once deleted, it will be no longer available"
        primaryBtnProps={{
          id: "confirmDelete",
          label: 'Yes, I confirm',
          loading: btnLoader,
          onClick: handleTagDelete,
          disabled: btnLoader
        }}
      />
    </Container>
  )
}

export default TagManagementList
