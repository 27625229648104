const BreadcrumbList = {
  settings: {
    '/settings': 'Settings',
    '/settings/company-config': 'Company Config',
    '/settings/white-label': 'White Label',
    '/settings/email-templates': 'Email Templates',
    '/settings/email-accounts': 'Email Accounts',
    '/settings/users': 'Users',
    '/settings/agents': 'Agents',
    '/settings/tags': 'Tags',
  },
  carriers: {
    '/carriers': 'Carriers',
    '/carriers/details': 'Carrier Details',
  }
}

/** ***** Breadcrumb Logic *****
 * Switch statement to determine which breadcrumbs are to display on the pages
 */
const renderBreadcrumbs = (path: string) => {
  let breadcrumbs = null
  switch (true) {
    case /\/settings/.test(path):
      breadcrumbs = BreadcrumbList.settings
      break
    case /\/carriers/.test(path):
      BreadcrumbList.carriers['/carriers/details'] = localStorage.getItem('carrierName')
        ? 'Carrier Details for ' + localStorage.getItem('carrierName')
        : 'Carrier Details'
      breadcrumbs = BreadcrumbList.carriers
      break
    default:
      break
  }
  return breadcrumbs
}

export default renderBreadcrumbs