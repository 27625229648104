import React from 'react'
import { FormControlLabel } from '@mui/material'
import MuiRadio from '@mui/material/Radio'

export interface IRadioProps {
  label?: string
  defaultChecked?: boolean
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start'
  disabled?: boolean
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
  checked?: boolean
  className?: string
  error?: boolean
  filled?: boolean
  required?: boolean
  value?: string | boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disableRipple?: boolean
  name?: string
  size?: 'medium' | 'small'
  id?: string
  checkedIcon?: any
  customSize?: number
  inputRef?: any
  inputProps?: object
  sx?: object
}

const Radio: React.FC<IRadioProps> = (props) => {
  const {
    labelPlacement = 'end',
    label = '',
    disabled = false,
    color = 'primary',
    checked = false,
    className = '',
    required = false,
    value,
    onChange = () => {},
    onClick = () => {},
    disableRipple = false,
    name = '',
    size = 'medium',
    id = '',
    customSize = 0,
    inputRef,
    inputProps = {},
    sx = {},
  } = props

  return (
    <FormControlLabel
      control={
        <MuiRadio
          {...props}
          color={color}
          checked={checked}
          className={className}
          onChange={onChange}
          onClick={onClick}
          required={required}
          value={value}
          name={name}
          disableRipple={disableRipple}
          size={size}
          id={id}
          inputRef={inputRef}
          sx={{
            ...{
              '& .MuiSvgIcon-root': customSize ? { fontSize: customSize } : '',
            },
            ...sx,
          }}
          inputProps={{ ...{ 'aria-label': 'controlled' }, ...inputProps }}
        />
      }
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
    />
  )
}

export default Radio
