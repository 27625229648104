import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { CircularProgress } from '@mui/material'
import { ICompanyRequest } from 'interfaces'
import { getCompanyDetails } from 'api'
import { Container, Card, HeaderBar, Divider, TypoGraph } from 'components'
import { InputFormField, CheckboxFormField } from 'components/hookForm'
import styles from './companyConfig.module.scss'
import strings from 'strings'

const t = strings.COMPANY_CONFIG

const CompanyConfig: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false)
  const methods = useForm<ICompanyRequest>({
    reValidateMode: 'onChange',
  })
  const { reset } = methods

  useEffect(() => {
    setLoading(true)
    getCompanyDetails()
      .then((res: any) => {
        reset({
          ...res?.Company,
          companyType: localStorage.getItem('userType'),
        })
      })
      .finally(() => setLoading(false))
  }, [reset])

  return (
    <Container>
      <HeaderBar title={`Company Config`} />
      <Card>
        {loading ? (
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
        ) : (
          <>
            <TypoGraph
              variant="h2"
              sx={{ paddingTop: 1 }}
              content={`Company Details`}
            />
            <Divider />
            <FormProvider {...methods}>
              <Container className={styles.textFieldContainer}>
                <InputFormField
                    id={"companyName"}
                  name="companyName"
                  label={t.COMPANY_NAME}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"companyId"}
                  name="companyId"
                  label={t.COMPANY_ID}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"companyType"}
                  name="companyType"
                  maxLength={128}
                  label={t.COMPANY_TYPE}
                  required
                  disabled
                />
                <InputFormField
                    id={"carrierMCNumber"}
                  name="carrierMCNumber"
                  label={t.MC}
                  maxLength={32}
                  required
                  disabled
                />
                <InputFormField
                    id={"carrierDOTNumber"}
                  name="carrierDOTNumber"
                  label={t.DOT}
                  maxLength={32}
                  required
                  disabled
                />
                <InputFormField
                    id={"scac"}
                  name="scac"
                  label={t.SCAC}
                  maxLength={32}
                  required
                  disabled
                />
                <InputFormField
                    id={"supportEmail"}
                  name="supportEmail"
                  label={t.SUPPORT_EMAIL}
                  maxLength={32}
                  required
                  disabled
                />
                <CheckboxFormField
                    id={"enableOrDisableSubscription"}
                  name="subscription"
                  label={t.EMAIL_SUBSCRIPTION}
                  disabled
                />
              </Container>
              <TypoGraph
                  id={"titleCompanyContacts"}
                variant="h2"
                sx={{ paddingTop: 3 }}
                content={`Company Contacts`}
              />
              <Divider />
              <Container className={styles.textFieldContainer}>
                <InputFormField
                    id={"currentAddress1"}
                  name="currentAddress1"
                  label={t.ADDRESS}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"city"}
                  name="city"
                  label={strings.CITY}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"state"}
                  name="state"
                  label={strings.STATE}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"zipcode"}
                  name="zipcode"
                  label={strings.ZIP}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"companyEmail"}
                  name="companyEmail"
                  label={strings.EMAIL}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"websiteAddress"}
                  name="websiteAddress"
                  label={t.WEBSITE}
                  maxLength={128}
                  required
                  disabled
                />
                <InputFormField
                    id={"phoneNumber"}
                  name="phoneNumber"
                  label={t.PHONE_NUMBER}
                  maxLength={128}
                  required
                  disabled
                />
              </Container>
            </FormProvider>
          </>
        )}
      </Card>
    </Container>
  )
}

export default CompanyConfig
