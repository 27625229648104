import { Button, Menu, MenuItem } from '@mui/material'
import ButtonMenuItem from 'components/buttonMenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react'

export interface IButtonMenu {
  id?: string
  variant?: 'text' | 'contained' | 'outlined'
  disabled?: boolean
  label: string
  color?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    menuItems: Array<{}>
}

const ButtonMenu: React.FC<IButtonMenu> = (props) => {
  const {
    id,
    variant = 'contained',
    disabled = false,
    label,
    color = 'primary',
    menuItems,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openMenu, setOpenMenu] = React.useState(false)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenMenu(false)
  }

  return (
    <>
      <Button
        color={color}
        variant={variant}
        disabled={disabled}
        onClick={handleOpenMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
        {menuItems.map((item: any) => {
          return (
            <ButtonMenuItem
              onClick={item.onClick}
              label={item.label}
              useDialog={item.useDialog}
              dialogTitle={item.dialogTitle}
              dialogMsg={item.dialogMsg}
              dialogAgreeText={item.dialogAgreeText}
              dialogDisagreeText={item.dialogDisagreeText}
              handleClose={handleClose}
            />
          )
        })}
      </Menu>
    </>
  )
}
export default ButtonMenu
