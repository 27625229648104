import { ObjectAnyKeys } from 'interfaces/Ihelper'

export const Types = {
    SET_PAYMENTS_LIST: 'SET_PAYMENTS_LIST',
}

/**
 * Dispatch an action to reducer and changes the state values
 */
export const dispatchPaymentList = (data: ObjectAnyKeys[]) => ({
    type: Types.SET_PAYMENTS_LIST,
    data,
})
