import { embedDashboard } from '@superset-ui/embedded-sdk';
import { Card, Container, TypoGraph } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './fleetMilesByZip.module.scss';
import { getSupersetGuestToken, getSupersetGuestTokenWithoutRules } from 'api/dashboard';
import { Box } from '@mui/material';
import { reportException } from 'tools/logs';
import { useSelector } from 'react-redux';
import { isAgentLogin, isCarrierLogin } from 'tools/auth';

interface IFleetMilesByZipDashboardProps {
  print?: boolean
  useCarrierFilters: boolean
}

const FleetMilesByZipDashboard: React.FC<IFleetMilesByZipDashboardProps> = (props) => {
  const { useCarrierFilters } = props
  const supersetContainerId = "setContainer"
  const localStorageDashboardId = localStorage.getItem('embeddedDashboardIds') ?? JSON.stringify([])
  const milesByZipDashboardId = JSON.parse(localStorageDashboardId)
  const dashboardId: string = milesByZipDashboardId?.MILES_BY_ZIP ?? process.env.REACT_APP_SUPERSET_FLEET_MILES_BY_ZIP ?? ""
  const dashboardUrl: string = process.env.REACT_APP_SUPERSET_URL ?? ""
  const hasValidData: boolean = (useCarrierFilters
    && !!localStorage.getItem('carrierCodeType') && localStorage.getItem('carrierCodeType') != ""
    && !!localStorage.getItem('carrierCodeValue') && localStorage.getItem('carrierCodeValue') != "")
    || !useCarrierFilters

  const tokenPromiseRef = useRef<Promise<string> | null>(null);
  const [companyProfile, setCompanyProfile] = useState<any>(null)
  const companyProfileData = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )
  const isAgentOrCarrier = isAgentLogin() || isCarrierLogin()

  const getGuestToken = useCallback(() => {
    if (!tokenPromiseRef.current) {
      tokenPromiseRef.current = getSupersetGuestTokenWithoutRules(dashboardId)
        .then((response: any) => response.token)
        .catch((error:any) => {
          reportException(error)
          throw error;
        })
    }
    return tokenPromiseRef.current;
  }, [dashboardId, useCarrierFilters]);

  const dashboardEmbedded = useRef(false);
        
  useEffect(() => {
      if (isAgentOrCarrier && companyProfileData && Object.keys(companyProfileData).length !== 0) {
        setCompanyProfile(companyProfileData?.fullData)
      }
    }, [companyProfileData, isAgentOrCarrier])

  useEffect(() => {
    if (companyProfile && isAgentOrCarrier) {
        const localStorageValidation = !localStorage.getItem("invitingCompanyId") || localStorage.getItem("invitingCompanyId") === '0'

        if (localStorageValidation && companyProfile.companyId !== undefined) {
          localStorage.setItem("invitingCompanyId", companyProfile.companyId);
        }
    }
    if (hasValidData && !dashboardEmbedded.current) {
      embedDashboard({
        id: dashboardId,
        supersetDomain: dashboardUrl,
        mountPoint: document.getElementById(supersetContainerId) as HTMLElement,
        debug: true,
        fetchGuestToken: getGuestToken,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
          },
          urlParams: {
            ...{companyId: (localStorage.getItem('invitingCompanyId') !== null ? localStorage.getItem('invitingCompanyId') : localStorage.getItem('companyId'))},
            ...(useCarrierFilters && {
                carrierId: localStorage.getItem('carrierId'),
                carrierCode: localStorage.getItem('carrierCodeType'), 
                codeValue: localStorage.getItem('carrierCodeValue') 
            }),            
          },
        },
      })
      dashboardEmbedded.current = true;
    }
  }, [dashboardEmbedded, getGuestToken, companyProfile, isAgentOrCarrier])

  return (
    <>
      <TypoGraph
        variant="h1"
        sx={{ marginRight: '1rem', marginY: '1rem' }}
        mb={0}
        content={
          useCarrierFilters ?
            `Carrier Miles by Zip for ` + localStorage.getItem('carrierName') :
            `Fleets Miles by Zip`
        }
      />
      <Card>
        <Container>
          {hasValidData ?
            <div id={supersetContainerId} style={{ width: '90vw', height: '75vh' }}>
            </div>
            :
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 'auto', height: '75vh' }}>
              <h5>No data</h5>
            </Box>
          }
        </Container>
      </Card>
    </>
  )
}
export default FleetMilesByZipDashboard