const rbacElements = {
    MENU_ITEM: "MENU",
    
    RISK_SUMMARY_TAB: "SUMMARY",
    RISK_SAFETY_TAB: "SAFETY",
    RISK_SPEED_TAB: "SPEED",
    RISK_GEO_TAB: "GEOGRAPHIC",

    CONNECTED_ACCOUNTS: "CONNECTED_ACCOUNTS",

    MY_CARRIERS: "MY_CARRIERS",
    INVITED_CARRIERS: "INVITED_CARRIERS",

    REPORTING_PORTFOLIO: "PORTFOLIO",

    USERS_SETTINGS: "USERS",
    EMAIL_TEMPLATES_SETTINGS: "EMAIL_TEMPLATES",
    EMAIL_ACCOUNTS_SETTINGS: "EMAIL_ACCOUNTS",
    WHITE_LABEL_SETTINGS: "WHITE_LABEL",
    COMPANY_CONFIG_SETTINGS: "COMPANY_CONFIG",

    PROFILE: "PROFILE",
    PASSWORD: "PASSWORD",
}

export default rbacElements