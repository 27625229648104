import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import {
  Container,
  Modal,
  Icon,
  TypoGraph,
} from 'components'
import styles from './errorPage.module.scss'
import { Backdrop } from '@mui/material'

interface IWidgetErrorPageProps {
  isOneNetwork?: boolean
  errorTitle?: string
  errorMessage?: string
}

const WidgetErrorPage: React.FC<IWidgetErrorPageProps> = (props) => {
  const {
    isOneNetwork,
    errorTitle,
    errorMessage,
  } = props

  const [mobileView, setMobileView] = useState(window.innerWidth < 1000)

  const navigate = useNavigate()

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const ModalContent = () => {
    return (
      <Container
        className={`${styles.modalContentContainer} ${
          mobileView && styles.mobileModalContent
        }`}
      >
        {companyProfile?.logo ? (
          <Icon
            type="images"
            url={companyProfile?.logo}
            boxClassName="fullLogo"
            width={300}
            height={100}
          />
        ) : (
          <Icon
            name={'icon_logoFullname' ?? 'icon_logo'}
            width={300}
            height={50}
          />
        )}
        <Container className={styles.titleContainer}>
          <TypoGraph
            variant="h1"
            content={errorTitle}
            sx={{ textAlign: 'center' }}
          />
          <TypoGraph
            variant="body2"
            content={errorMessage}
            sx={{ textAlign: 'center' }}
          />
          <Icon name="icon_dialog_warning" size={mobileView ? 150 : 300} />
        </Container>
      </Container>
    )
  }

  return (
    <>
      {(isOneNetwork &&
      <Backdrop open={true} className={styles.backdrop}>
        <Modal
          open={true}
          dom={<ModalContent />}
          dismissOnClose={false}
          className={styles.showScrollbar}
          backdropFused={true}
        />
      </Backdrop>
      )}
    </>
  )
}

export default WidgetErrorPage
