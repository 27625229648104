import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import TextField, { ITextFieldProps } from '../input'
import React from 'react'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export interface ISelectOptions {
  label: string
  id: string
}

export interface IMultiSelectProps
  extends Omit<ITextFieldProps, 'value' | 'onChange'> {
  options: ISelectOptions[]
  loading?: boolean
  name?: string
  value?: ISelectOptions[] | string[]
  hightLightColor?: string
  freeSolo?: boolean
  placeholder?: string
  key?: number
  multiple?: boolean
  onOpen?: (e: React.SyntheticEvent<Element, Event>) => void
  onClose?: (e: React.SyntheticEvent<Element, Event>) => void
  onInputChange?: (e: React.SyntheticEvent<Element, Event>, val: string) => void
  onChange?: (e: React.SyntheticEvent<Element, Event>, val: any) => void
  setValue?: (name: string, value: any) => void
}

const MultiSelectField: React.FunctionComponent<IMultiSelectProps> = (
  props
) => {
  const {
    label,
    options,
    loading = false,
    onInputChange,
    onChange,
    value,
    error,
    helperText,
    disabled,
    startAdornment,
    endAdornment,
    freeSolo = false,
    className,
    placeholder,
    key,
    multiple = true,
    onOpen,
    onClose,
    onBlur,
  } = props

  return (
    <Autocomplete
        id={"searchAndFilterBar"}
      key={key}
      loading={loading}
      options={options ? options : []}
      value={value}
      disabled={disabled}
      freeSolo={freeSolo}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={(event, newInputValue) => {
        if (event && onInputChange) {
          onInputChange(event, newInputValue)
        }
      }}
      onChange={(e, val) => {
        if (e && onChange) {
          onChange(e, val)
        }
      }}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.label ? option.label : ''}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {typeof option === 'string' ? option : option.label} // Updated this line
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
          disabled={disabled}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          className={className}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      )}
      multiple={multiple}
      disableCloseOnSelect
    />
  )
}

export default MultiSelectField
