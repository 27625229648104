import { Component, ReactNode } from 'react'
import strings from 'strings'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    state = {
        hasError: false,
    }

    static getDerivedStateFromError(_: Error): State {
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <div>
                        <h1>{strings.ERROR_BOUNDRY_HEADER}</h1>
                        <p>{strings.ERROR_BOUNDRY_BODY}</p>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
