import React from "react";
import {TypoGraph, Container, Card} from "components";
import strings from "strings";
import styles from "./page404.module.scss";

const Page404: React.FunctionComponent = () => {
  return (
    <Container className={styles.container}>
      <Card
        sxContainer={{width: 400, margin: "auto"}}
        sxContent={{textAlign: "center"}}
      >
        <TypoGraph variant="h1" align="center">
          {strings.PAGE_404_TITLE}
        </TypoGraph>
        <TypoGraph align="center">{strings.PAGE_404_SUBHEADER}</TypoGraph>
        <TypoGraph variant="caption" align="center">
          {strings.PAGE_404_MESSAGE}
        </TypoGraph>
      </Card>
    </Container>
  );
};

export default Page404;
