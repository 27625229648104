import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Button, TypoGraph } from 'components'
import { userLogin } from 'api'
import { CheckBoxField, TextField } from 'components/controllers'
import { setupLoginCred, isCarrierLogin, isAgentLogin } from 'tools/auth'
import { ObjectStrings } from 'interfaces'
import bootIntercom from 'tools/intercom'
import strings from 'strings'
import colors from 'theme/colors'

interface ILoginProps {
  styles: ObjectStrings
  handleStep: (val: number) => void
}

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const { styles, handleStep } = props
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [checkedRemember, setCheckedRemember] = useState(false)
  const navigate = useNavigate()

  const onLoginPressed = async (userName: string, password: string) => {
    setLoading(true)
    userLogin({ UserName: userName, Password: password })
      .then((res: any) => {
        const data = res?.data
        bootIntercom(data?.Company_Profile?.email, data?.UserName)
        setupLoginCred(data)
        isCarrierLogin() ? navigate('/accounts') : isAgentLogin() ? navigate('/dashboard') : navigate('/dashboard')
      })
      .finally(() => setLoading(false))
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLoginPressed(userName, password)
    }
  }

  return (
    <Container className={styles.formContainer}>
      <TextField
        label={strings.USER_NAME}
        type="text"
        id={'usernameLogin'}
        onChange={(e: React.ChangeEvent<any>) => setUserName(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <TextField
        label={strings.PASSWORD}
        id={'passwordLogin'}
        onChange={(e: React.ChangeEvent<any>) => setPassword(e.target.value)}
        onKeyDown={handleKeyDown}
        showPasswordIcon
      />
      <Container className={styles.contentLinkContainer}>
        <CheckBoxField
          id={'rememberMeCheckLogin'}
          label="Remember me"
          checked={checkedRemember}
          labelVariant="subtitle1"
          onChange={() => setCheckedRemember(!checkedRemember)}
        />
        <TypoGraph
          id={'forgotPasswordTextLinkDiv'}
          variant="body1"
          content={strings.FORGOT_PASSWORD}
          onClick={() => handleStep(1)}
          color={colors.primary}
          align="right"
          link
        />
      </Container>
      <Container className={styles.buttonContainer}>
        <Button
          id={'loginButton'}
          loading={loading}
          disabled={password === '' || userName === ''}
          variant="contained"
          onClick={() => onLoginPressed(userName, password)}
          label={strings.LOGIN}
        />
      </Container>
    </Container>
  )
}

export default Login
