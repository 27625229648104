import { TextField } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { numberComparator, numberOrStringComparator } from 'tools/comparators'

const gzcColumnsDefinition = [
  {
    field: 'assetEldId',
    headerName: 'Asset ID',
    minWidth: 100,
    flex: 1,
    sortable: true,
    sortComparator: numberOrStringComparator,
  },
  {
    field: 'vin',
    headerName: 'Vehicle Identifier',
    minWidth: 150,
    flex: 1.5,
    sortable: true,
    sortComparator: numberOrStringComparator,
  },
  {
    field: 'eldProvider',
    headerName: 'ELD Provider',
    minWidth: 150,
    flex: 1.5,
    sortable: true,
    sortComparator: numberOrStringComparator,
  },
  {
    field: 'automaticGzc',
    headerName: 'Automatic GZC',
    minWidth: 80,
    flex: 1,
    sortable: true,
    sortComparator: numberComparator,
  },
  {
    field: 'manualGzc',
    headerName: 'Manual GZC',
    minWidth: 80,
    flex: 1,
    sortable: false,
    editable: true,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TextField
          value={params.value}
          onChange={(event) => {
            const newValue = event.target.value
            if (/^\d*$/.test(newValue)) {
              params.api.setEditCellValue({
                id: params.id,
                field: 'manualGzc',
                value: newValue,
              })
            }
          }}
          variant="outlined"
          size="small"
          inputProps={{ maxLength: 5, pattern: '[0-9]*' }}
          InputProps={{
            style: { margin: '5px' },
          }}
        />
      )
    },
    renderEditCell: (params: GridRenderCellParams) => {
      return (
        <TextField
          value={params.value}
          onChange={(event) => {
            const newValue = event.target.value
            if (/^\d*$/.test(newValue)) {
              params.api.setEditCellValue({
                id: params.id,
                field: 'manualGzc',
                value: newValue,
              })
            }
          }}
          variant="outlined"
          size="small"
          inputProps={{ maxLength: 5, pattern: '[0-9]*' }}
          InputProps={{
            style: { margin: '5px' },
          }}
        />
      )
    },
  },
]

const gzcListTableColumns = {
  columns: gzcColumnsDefinition.map(
    ({
      field,
      headerName,
      minWidth,
      flex,
      sortable,
      editable,
      renderCell,
      renderEditCell,
    }) => ({
      field,
      headerName,
      minWidth,
      flex,
      sortable,
      editable,
      renderCell,
      renderEditCell,
    })
  ),
}

export { gzcListTableColumns }
