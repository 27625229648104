import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import { InputFormField } from 'components/hookForm'
import { Button, Container, Toast as toast, TypoGraph } from 'components'
import { ObjectStrings } from 'interfaces'
import { passwordStrengthValidator } from 'tools/validators'
import { requestPasswordReset } from 'api'
import { ClearCookies } from 'tools/cookies'
import { useNavigate } from 'react-router-dom'
import { reportException } from 'tools/logs'

interface IPasswordTabProps {
  id?:string
  styles: ObjectStrings
  strings: ObjectStrings
}

interface IChangePassword {
  id?:string
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const PasswordTab = (props: IPasswordTabProps) => {
  const {id, styles, strings } = props
  const [loading, setLoading] = useState(false)
  const methods = useForm<IChangePassword>()
  const { handleSubmit, getValues } = methods
  const navigate = useNavigate()

  const onSubmit = (formData: IChangePassword) => {
    setLoading(true)
    const payLoad = {
      userId: localStorage.getItem('userId') ?? ''
    }

    requestPasswordReset(payLoad)
      .then((res: any) => {
        toast({ id:"recoverPasswordToast", type: "success", title: "Mail sent successfully!", subTitle: "You have 10 minutes to reset your password." })
        ClearCookies(true)
        navigate('/login')
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <TypoGraph content={strings.CHANGE_PASSWORD} variant="h2" />
      <Container className={styles.formContainer}>
        <Container>
          <TypoGraph
            variant="body2"
            content={strings.REQUEST_PASSWORD_INTRO}
            sx={{ marginTop: '1rem' }}
          />
          <Button
            id={"changePassword"}
            loading={loading}
            label={strings.REQUEST_PASSWORD}
            onClick={handleSubmit(onSubmit)}
            style={{ marginTop: '1rem', width: '350px' }}
          />
        </Container>
      </Container>
    </Container>
  )
}

export default PasswordTab
