import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ErrorResponse } from 'interfaces'
import { Toast as toast } from 'components'
import strings from 'strings'

const API_URL = process.env.REACT_APP_API_URL
const DEFAULT_TIMEOUT: string = process.env.REACT_APP_DEFAULT_TIMEOUT ?? "30000"

const request = axios.create({
  baseURL: API_URL,
  timeout: parseInt(DEFAULT_TIMEOUT, 10),
})

request.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken') || ''
    config.headers = { Authorization: authToken, ...config.headers }
    return config
  },
  (error) => {
    toast({
      id:'errorGenericToastHandle',
      type: 'error',
      subTitle: error?.response?.data?.message || error?.response?.data?.description || error?.message || 'Something Went Wrong',
    })
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    return response
  },
  (error: ErrorResponse) => {
    if (error?.response?.data?.status === 401) {
      const navigate = useNavigate()
      localStorage.removeItem('authToken')
      navigate(strings.ROUTES.LOGIN)
    } else {
      toast({
        id:"errorGenericToast",
        type: 'error',
        subTitle: error?.response?.data?.message || error?.response?.data?.description || 'Something Went Wrong',
      })
      return Promise.reject(error)
    }
  }
)

export default request
