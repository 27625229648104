import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { submitCarrierInvitation } from 'api'
import { RadioField } from 'components/controllers'
import { InputFormField } from 'components/hookForm'
import {
  Modal,
  Container,
  Icon,
  TypoGraph,
  Button,
  Toast as toast,
  Toast,
} from 'components'
import CobList from 'pages/cob'
import regex from 'regex'
import colors from 'theme/colors'
import bootIntercom from 'tools/intercom'
import { areKeysValid } from 'tools/helper'
import strings from 'strings'
import styles from './tcwidget.module.scss'
import WidgetErrorPage from 'pages/cob/partial/errorPage'
import { createPendoWidgetData } from 'tools/pendo'
import { checkCompanyDefaultIdUniqueIdIsRequired, checkCompanyDefaultUniqueId, checkUniqueIdParameters, filterCompanyDefaultUniqueId, filterUniqueIds, isNotEmptyArray } from 'tools/customBehaviors'
import { resetCookies, setCoverwhaleTestCookies } from 'pages/cob/partial/oauthHelper'
import { GetCookie } from 'tools/cookies'
import { reportException, reportUsageMessage } from 'tools/logs'

interface ITcWidgetProps {}

const TcWidget: React.FC<ITcWidgetProps> = (props) => {
  const [searchFilter, setSearchFilter] = useState<any>({
    dot: {
      checked: true,
    },
  })
  const [apiKey, setApiKey] = useState('')
  const [loading, setLoading] = useState(false)
  const [cobData, setCobData] = useState<any>()
  const [canalRedirection, setCanalRedirection] = useState<any>()
  const [isOneNetwork, setIsOneNetwork] = useState<boolean>(false)
  const [hasOneNetworkParameters, setHasOneNetworkParameters] = useState<any>(null)
  const [oneNetworkCustomBehavior, setOneNetworkCustomBehavior] = useState<any>(false)
  const [isCoverwhaleTest, setIsCoverwhaleTest] = useState<boolean>(false)
  const [hasCoverwhaleTestParameters, setHasCoverwhaleTestParameters] = useState<any>(null)
  const [coverwhaleTestCustomBehavior, setCoverwhaleTestCustomBehavior] = useState<any>(false)
  const [pageLoader, setPageLoader] = useState(true)
  const [widgetHasError, setWidgetHasError] = useState<any>(false)
  const [widgetErrorMessage, setWidgetErrorMessage] = useState("")
  const [widgetErrorTitle, setWidgetErrorTitle] = useState("")
  const [preLoadingDone, setPreLoadingDone] = useState<any>(false)
  const [stopLoading, setStopLoading] = useState<any>(null)
  const [payLoadData, setPayLoadData] = useState<any>(null)
  const [payLoadState, setPayLoadState] = useState<any>(null)
  const [hasUniqueIdParameters, setHasUniqueIdParameters] = useState<any>(null)
  const [uniqueIdPayload, setUniqueIdPayload] = useState({}) 
  const [companyProfileFetched, setCompanyProfileFetched] = useState(false)
  const [companyProfile, setCompanyProfile] = useState<any>(null)
  const [uniqueIdForm, setUniqueIdForm] = useState<any>(false)
  const [hasDefaultId, setHasDefaultId] = useState<any>(false)
  const [carrierUniqueIdsFiltered, setCarrierUniqueIdsFiltered] = useState<any>(null)
  const [isSimulationMode, setIsSimulationMode] = useState<boolean>(false)
  const [defaultIdRequired, setDefaultIdRequired] = useState<any>(null)
  const [defaultIdFieldSelected, setDefaultIdFieldSelected] = useState<any>(true)
  const [customCarrierNameLabel, setCustomCarrierNameLabel] = useState<any>(null)
  const [customCarrierEmailLabel, setCustomCarrierEmailLabel] = useState<any>(null)
  const [isWidgetByForm, setIsWidgetByForm] = useState<any>(null)
  const [agentEmailWidget, setAgentEmailWidget] = useState<any>(null)

  let uniqueIdsForm : any = null;

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const companyProfileData = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const validateScac = (scac: string) => {
    return (scac.length >= 2 && scac.length <= 4);
  }

  const { handleSubmit, reset } = methods

  const navigate = useNavigate()
  const [searchParams]: any = useSearchParams()

  const checkCanalParameters = (payLoad: any) => {
    const hasParamsCanal: boolean = (searchParams.get('success') !== null &&
      searchParams.get('state') !== null &&
      payLoad?.codeType !== undefined &&
      payLoad?.codeValue !== undefined);
    return hasParamsCanal;
  }

  const checkIsOneNetwork = () => {
    const is1N = (cobData && cobData.invitationInfo && cobData.invitationInfo.invitingCompanyName.trim() === "One Network")
    return is1N === true
  }

  const checkOneNetworkParameters = () => {
    const hasParams1N = (searchParams.get('eldProvider') !== null || (searchParams.get('codeType') !== null && searchParams.get('codeType') === 'SCAC'))
    return hasParams1N === true
  }

  const checkIsCoverwhaleTest = () => {
    const isCoverwhaleTest = (cobData && cobData.invitationInfo 
      && (cobData.invitationInfo.invitingCompanyName.trim() === "Coverwhale-test" 
        ||cobData.invitationInfo.invitingCompanyName.trim() === "Coverwhale"))
    return isCoverwhaleTest === true
  }

  const checkCoverwhaleTestParameters = () => {
    const coverwhaleCUID = 'PolicyId'
    const uiParams = (uniqueIdsForm && uniqueIdsForm?.uniqueIds.filter((uid: any) => uid.codeType === coverwhaleCUID))
    const hasParams = (searchParams.get(coverwhaleCUID) !== null || (uiParams != null && uiParams.length > 0))
    return hasParams === true
  }

  const getCoverwhaleTestCuidValue = () => {
    const coverwhaleCUID = 'PolicyId'
    let coverwhaleCuidValue = null
    if (searchParams.get(coverwhaleCUID) !== null) {
      coverwhaleCuidValue = searchParams.get(coverwhaleCUID)
    }
    else if (uniqueIdsForm) {
      const cuidValue = uniqueIdsForm?.uniqueIds.filter((uid: any) => uid.codeType === coverwhaleCUID)
      if (cuidValue && cuidValue.length > 0) {
        coverwhaleCuidValue = cuidValue[0].codeValue
      }
    }
    
    return coverwhaleCuidValue
  }
  
  useEffect(() => {
    if (companyProfileData && Object.keys(companyProfileData).length!== 0) {
      setCompanyProfile(companyProfileData?.fullData);
    }
  }, [companyProfileData])

  useEffect(() => {
    if (companyProfile) {
      setUniqueIdForm(isNotEmptyArray(companyProfile?.carrierUniqueIds))
      setHasDefaultId(checkCompanyDefaultUniqueId(companyProfile?.carrierUniqueIds))
      setDefaultIdRequired(checkCompanyDefaultIdUniqueIdIsRequired(companyProfile?.carrierUniqueIds))
      setCarrierUniqueIdsFiltered(filterCompanyDefaultUniqueId(companyProfile?.carrierUniqueIds))
      setCompanyProfileFetched(true);
      setIsSimulationMode(searchParams.get('isDemo') === "true")
      setAgentEmailWidget(companyProfile?.useAgentEmailInWidget ?? false)
      if (companyProfile?.widgetFormLabels) {
        setCustomCarrierNameLabel(companyProfile?.widgetFormLabels?.carrierName ?? null);
        setCustomCarrierEmailLabel(companyProfile?.widgetFormLabels?.carrierEmail ?? null);
      }
    }
  }, [companyProfile]);

  useEffect(() => {
    if (!defaultIdRequired && defaultIdRequired!==null) {
      setSearchFilter({
        dot:{
          checked: false
        }
      })
      
      if ((searchParams.get('codeType') === null || searchParams.get('codeType')?.trim() === '')
        && (searchParams.get('codeValue') === null || searchParams.get('codeValue')?.trim() === '')) {
        setDefaultIdFieldSelected(false)
      }
    }
  }, [defaultIdRequired])

  useEffect(() => {
    if (!payLoadData && !!companyProfileFetched) {
      resetCookies()
      setHasOneNetworkParameters(checkOneNetworkParameters())
      setHasCoverwhaleTestParameters(checkCoverwhaleTestParameters())
      setHasUniqueIdParameters(checkUniqueIdParameters(carrierUniqueIdsFiltered, searchParams))
    }
  }, [payLoadData, companyProfileFetched])

  useEffect(() => {
    if (hasOneNetworkParameters !== null && hasCoverwhaleTestParameters !== null && stopLoading === null) {
      const apiKeyParam = searchParams.get('apiKey')
      if (!apiKeyParam) {
        if (hasOneNetworkParameters === true) {
          setWidgetErrorTitle("Configuration Error")
          setWidgetErrorMessage("The widget URL is not configured correctly. Please contact your One Network representative.")
        }
        else {
          navigate('/login', { replace: true })
        }
      }
      else {
        createPendoWidgetData(companyProfile)
        bootIntercom()
        setApiKey(apiKeyParam)
        // Loop through all entries and populate the object
        const payLoad: any = {}
        for (const [key, value] of searchParams.entries()) {
          if (key === 'email') {
            payLoad[key] = value.replace(/\s/g, '+')
          } else {
            payLoad[key] = value
          }
        }
        if (checkCanalParameters(payLoad) === true) {
          setCanalRedirection({
            url: payLoad?.success,
            state: payLoad?.state,
          })
          delete payLoad?.success
          delete payLoad?.state
        }
        else if (hasOneNetworkParameters === true) {
          if (!searchParams.get('email')) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The email is missing from the configuration.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (!searchParams.get('carrierName')) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The carrier name is missing from the configuration.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (!searchParams.get('eldProvider')) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The ELD provider is missing from the configuration.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (!searchParams.get('codeValue')) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The code value is missing from the configuration.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (!searchParams.get('codeType')) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The code type is missing from the configuration.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (searchParams.get('codeType') && searchParams.get('codeType') !== 'SCAC') {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The SCAC code is missing.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else if (payLoad?.codeType === 'SCAC' && payLoad?.codeValue && validateScac(payLoad?.codeValue) === false) {
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The SCAC code provided has wrong format.")
            setStopLoading(true)
            setPageLoader(false)
          }
          else {
            setOneNetworkCustomBehavior({
              carrierName: payLoad?.carrierName,
              eldProvider: payLoad?.eldProvider,
              scac: payLoad?.codeValue,
            })
            delete payLoad?.eldProvider
            delete payLoad?.scac
            setStopLoading(false)
          }
        }
        else if (hasCoverwhaleTestParameters === true) {
          setCoverwhaleTestCustomBehavior({
            policyId: payLoad?.policyId ?? payLoad?.PolicyId ?? GetCookie('policyId', true),
            carrierName: payLoad?.carrierName ?? GetCookie('carrierName', true),
          })
          setStopLoading(false)
        }
        else {
          setStopLoading(false)
        }

        setPayLoadState(payLoad)
      }
    }
  }, [hasOneNetworkParameters, hasCoverwhaleTestParameters, stopLoading, searchParams])

  useEffect(() => {
    if (payLoadState && !payLoadData && !preLoadingDone && !!companyProfileFetched) {
      const payLoad = payLoadState
      delete payLoad?.apiKey
      if (payLoad?.codeType) {
        setSearchFilter({
          [payLoad?.codeType?.toLowerCase()]: {
            checked: true,
          },
        })
        reset({
          ...payLoad,
          [payLoad?.codeType?.toLowerCase()]: payLoad.codeValue,
        })
      } else {
        reset(payLoad)
      }
      if (!!hasUniqueIdParameters) {
        const uniqueIdPayload = {
          uniqueIds: hasUniqueIdParameters.map((uniqueId: { codeType: any }) => {
            const codeType = uniqueId.codeType;
            const codeValue = searchParams.get(codeType);
        
            return codeValue ? { codeType, codeValue } : null;
          }).filter(Boolean),
        };
        if(!hasDefaultId){
          delete payLoad.codeType;
          delete payLoad.codeValue;
        }
        setUniqueIdPayload(uniqueIdPayload);
      }
      setPayLoadData(payLoad)
      setPreLoadingDone(true)
    }
  }, [payLoadState, payLoadData, preLoadingDone, companyProfile, companyProfileFetched])

  useEffect(() => {
    if (payLoadData && preLoadingDone && !widgetErrorMessage && (isWidgetByForm === null || isWidgetByForm === false)) {
      const keysToCheck = (searchParams.has('codeType') || searchParams.has('codeValue')) ? ['codeType', 'codeValue', 'email'] : ['email', 'carrierName']
      if (hasOneNetworkParameters === true) {
        keysToCheck.push('carrierName')
      }
      if (!!carrierUniqueIdsFiltered) {
        if (hasDefaultId && defaultIdRequired) {
          keysToCheck.push('codeType', 'codeValue');
        }
        if (!hasDefaultId) {
          ['codeType', 'codeValue'].forEach(key => {
            const index = keysToCheck.indexOf(key);
            if (index !== -1) {
              keysToCheck.splice(index, 1);
            }
          });
          if(!keysToCheck.includes('carrierName')){
            keysToCheck.push('carrierName')
          }
        }
        for (const uniqueId of carrierUniqueIdsFiltered) {
          uniqueId?.required && keysToCheck.push(uniqueId?.codeType);
        }
      }
      if (isWidgetByForm === null || isWidgetByForm === false) {
        const isValidForm = areKeysValid(payLoadData, keysToCheck)
        if (isValidForm === true) {
          const apiKeyParam = searchParams.get('apiKey')
          submitCarrierInvitation(payLoadData, apiKeyParam, uniqueIdPayload)
            .then((res: any) => {
              setWidgetHasError(false)
              setCobData(res)
              reportUsageMessage("TC Widget started directly with URL: " + window.location.href)
            })
            .catch((error: any) => {
              reportException(error)
              setWidgetHasError(true)
              if (hasOneNetworkParameters === true) {
                setWidgetErrorTitle("Server Error")
                if (error.response && error.response.data && error.response.data.validationErrors && error.response.data.validationErrors[0] && error.response.data.validationErrors[0].message) {
                  setWidgetErrorMessage('Error: ' + error.response.data.validationErrors[0].message)
                }
                else if (error.response && error.response.data && error.response.data.message) {
                  setWidgetErrorMessage('Error: ' + error.response.data.message)
                }
                else {
                  setWidgetErrorMessage("An error occured while starting the widget. Please try again later.")
                }
              }
            })
            .finally(() => {
              setPageLoader(false)
              setLoading(false)
            })
        } else {
          if (hasOneNetworkParameters === true) {
            setWidgetHasError(true)
            setWidgetErrorTitle("Configuration Error")
            setWidgetErrorMessage("The widget URL is not configured correctly. Please contact your support representative.")
            setPageLoader(false)
            setLoading(false)
          }
          else {
            setPageLoader(false)
          }
        }
      } else {
        setPageLoader(false)
      }
    }
  }, [preLoadingDone, payLoadData, hasOneNetworkParameters, widgetErrorMessage, isWidgetByForm])

  useEffect(() => {
    if (cobData && !pageLoader && !widgetHasError && !widgetErrorMessage) {
      if (hasOneNetworkParameters === true && checkIsOneNetwork() === true) {
        setIsOneNetwork(true)
      } else if (hasOneNetworkParameters === false && checkIsOneNetwork() === true) {
        setWidgetErrorTitle("Configuration Error")
        setWidgetErrorMessage("The widget URL is not configured correctly. Please contact your One Network representative.")
      } else if (hasCoverwhaleTestParameters === true && checkIsCoverwhaleTest() === true) {
        setIsCoverwhaleTest(true)
      }
    }
  }, [cobData, loading, hasOneNetworkParameters, hasCoverwhaleTestParameters, widgetHasError, widgetErrorMessage])

  const handleRadioChange = (e: any, name: string) => {
    reset({})
    setSearchFilter((prev: any) => ({
      [name]: {
        ...prev[name],
        checked: true,
      },
    }))
  }

  const onSubmit = (formData: any) => {
    const payload: any = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      email: formData?.email,
    }
    if (agentEmailWidget && formData?.agentEmail?.trim()) {
      payload.agentEmail = formData.agentEmail;
    } else {
      payload.agentEmail = null;
    }
    if (uniqueIdForm) {
      uniqueIdsForm = {
        uniqueIds: filterUniqueIds(formData, companyProfile),
      };

      if(defaultIdFieldSelected) {
        formData.carrierName = null;
      }

      if(!!formData?.SCAC || !!formData?.UUID){
        payload['codeType'] = formData?.SCAC ? 'SCAC' : 'UUID';
        payload['codeValue'] = formData?.SCAC ? formData?.SCAC : formData?.UUID;
      }

      if (hasDefaultId && defaultIdFieldSelected) {
        payload['codeType'] = formData?.mc ? 'MC' : 'DOT';
        payload['codeValue'] = formData.mc ? formData.mc : formData.dot;
      }

      if (!!formData?.carrierName) {
        payload['carrierName'] = formData?.carrierName;
      }
  
    } else {
      payload['codeType'] = formData?.mc ? 'MC' : 'DOT';
      payload['codeValue'] = formData?.mc ? formData.mc : formData.dot;
    }

    if (isWidgetByForm === null) {
      setIsWidgetByForm(true)
    }

    // To check CW params on form submit
    const cuidValue = getCoverwhaleTestCuidValue()
    if (cuidValue !== undefined && cuidValue !== null) {
      const cookieSet = setCoverwhaleTestCookies({policyId: cuidValue})
      if (cookieSet) {
        setHasCoverwhaleTestParameters(checkCoverwhaleTestParameters())
      } else {
        toast({type: "warning", title: "No PolicyId found for Coverwhale"})
      }
    }

    setLoading(true)
    submitCarrierInvitation(payload, apiKey, uniqueIdsForm)
      .then((res: any) => {
        setCobData(res)
        reportUsageMessage("TC Widget started, with URL: " + window.location.href)
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((error: any) => {
        reportException(error)
        // console.error("Error submitting carrier invitation:", error.message);
      });
  }

  const SelectedSearch = (props: any) => {
    const {id, label, name, type } = props
    const checked = searchFilter[name]?.checked || false
    return (
      <Container className={styles.searchFieldContainer}>
        <RadioField
            id={id}
          checked={checked}
          onChange={(e) => handleRadioChange(e, name)}
        />
        <InputFormField
            id={name}
          type={type}
          name={name}
          label={label}
          disabled={!checked}
          required={checked}
          maxLength={{
            value: 20,
            message: 'Max length allowed is 20',
          }}
        />
      </Container>
    )
  }

  const UniqueIdFormComponent = () => {
    return (<Container className={styles.mcDotField}>
      {carrierUniqueIdsFiltered.map((item: any) => (
        <InputFormField
          id={item?.id}
          label={item?.codeType}
          name={item?.codeType}
          maxLength={{
            value: 30,
            message: 'Max length allowed is 30',
          }}
          required={item?.required}
        />
      ))}
    </Container>)
  }

  const handleUncheck = (e: any, name: string) => {
    if (!defaultIdRequired) {
      reset({})
      setSearchFilter((prev: any) => ({
        [name]: {
          ...prev[name],
          checked: false,
        },
      }))
      const checked = searchFilter[name]?.checked || false
      if (checked) {
        setDefaultIdFieldSelected(false)
      } if (!checked) {
        setDefaultIdFieldSelected(true)
      }
    }
  }

  const DefaultId = (props: any) => {
    const {id, label, name, type } = props
    const checked = searchFilter[name]?.checked || false
    return (
      <Container className={styles.searchFieldContainer}>
        <RadioField
            id={id}
          checked={checked}
          onClick={(e) => handleUncheck(e, name)}
          onChange={(e) => handleRadioChange(e, name)}
          
        />
        <InputFormField
            id={name}
          type={type}
          name={name}
          label={label}
          disabled={!checked}
          required={(defaultIdRequired && checked) || checked}
          maxLength={{
            value: 20,
            message: 'Max length allowed is 20',
          }}
        />
      </Container>
    )
  }

  const ModalContentComponent = () => {
    return (
      <Container className={styles.modalContainer}>
        <Container className={styles.headerContainer}>
          {companyProfile?.logo ? (
            <Icon
              type="images"
              url={companyProfile?.logo}
              boxClassName={styles.fullLogo}
              width={300}
              height={80}
            />
          ) : (
            <Icon
              name="icon_logoFullname"
              boxClassName={styles.fullLogo}
              width={300}
              height={80}
            />
          )}
          <TypoGraph className={styles.typoContainer}>
            <TypoGraph
              variant="body1"
              component="span"
              content="Onboard in less than "
            />
            <TypoGraph
              component="span"
              color={colors.primary}
              content="60 seconds!"
              variant="body1"
            />
            <TypoGraph variant="body1" content="True logistic visibility" />
          </TypoGraph>
        </Container>
        <Container>
          <FormProvider {...methods}>
            {uniqueIdForm && !hasDefaultId ? (
              <Container>
                <UniqueIdFormComponent />
              </Container>
            ) : (uniqueIdForm && hasDefaultId) ? (
              <>
                <Container className={styles.mcDotField}>
                  <DefaultId id={"selectDOTMcWidget"} type="number" label="MC" name="mc" />
                  <DefaultId id={"selectWidget"} type="number" label="DOT" name="dot" />
                </Container>
                <Container>
                  <UniqueIdFormComponent />
                </Container>
              </>
            ) : (
              <Container className={styles.mcDotField}>
                <SelectedSearch id={"selectDOTMcWidget"} type="number" label="MC" name="mc" />
                <SelectedSearch id={"selectWidget"} type="number" label="DOT" name="dot" />
              </Container>
            )}
            <Container>
              {uniqueIdForm && (
                <InputFormField
                  id={"carrierNameWidget"}
                  name="carrierName"
                  label={customCarrierNameLabel ?? "Carrier Name"}
                  maxLength={{
                    value: 100,
                    message: 'Max length allowed is 100',
                  }}
                  disabled={(defaultIdFieldSelected)}
                  required={(!defaultIdFieldSelected)}
                />)}
              
              <InputFormField
                  id={"carrierEmailWidget"}
                name="email"
                label={customCarrierEmailLabel ?? "Carrier Email"}
                pattern={{
                  value: regex.EMAIL_REGEX_PATTERN,
                  message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                }}
                required
              />

              <InputFormField
                id={"firstNameWidget"}
                name="firstName"
                label="First Name"
                maxLength={{
                  value: 100,
                  message: 'Max length allowed is 100',
                }}
              />
              <InputFormField
                id={"lastNameWidget"}
                name="lastName"
                label="Last Name"
                maxLength={{
                  value: 100,
                  message: 'Max length allowed is 100',
                }}
              />
              {agentEmailWidget && 
              (<InputFormField
                id={"agentEmailWidget"}
                name="agentEmail"
                label="Agent Email"
                maxLength={{
                  value: 100,
                  message: 'Max length allowed is 100',
                }}
                pattern={{
                  value: regex.EMAIL_REGEX_PATTERN,
                  message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                }}
              />)}

            </Container>
          </FormProvider>
        </Container>
        <Container className={styles.btnContainer}>
          <Button
              id={"onboardWidget"}
            label="Onboard"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          />
        </Container>
      </Container>
    )
  }

  if (pageLoader) {
    return (
      <Container className="containerLoader">
        <CircularProgress />
      </Container>
    )
  }

  return (!pageLoader && (
      cobData ? (
        <CobList
          cobData={cobData}
          apiKey={cobData?.invitationInfo?.invitationToken}
          canalRedirection={canalRedirection}
          oneNetworkCustomBehavior={oneNetworkCustomBehavior}
          coverwhaleTestCustomBehavior={coverwhaleTestCustomBehavior}
          widgetErrorTitle={widgetErrorTitle}
          widgetErrorMessage={widgetErrorMessage}
          setWidgetErrorTitle={setWidgetErrorTitle}
          setWidgetErrorMessage={setWidgetErrorMessage}
          isSimulation={isSimulationMode}
        />
      ) : (hasOneNetworkParameters === true && widgetErrorTitle && widgetErrorMessage ? (
        <WidgetErrorPage
          errorTitle={widgetErrorTitle}
          errorMessage={widgetErrorMessage}
          isOneNetwork={true}
        />
      ) : (
        <Modal 
          size="sm" 
          open={true} 
          dom={<ModalContentComponent />}
        />
      )
    )))
}

export default TcWidget
