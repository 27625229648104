import { useEffect, useState } from 'react'
import {
    Container,
    DataGridTable,
    HeaderBar,
    TypoGraph,
    Chip,
} from 'components'
import moment from 'moment'
import styles from './fleetPercentiles.module.scss'
import { getPercentiles } from 'api/dashboard'
import { FleetPercentileTableData } from './fleetPercentileTableData'
import { isAgentLogin } from 'tools/auth'

interface IFleetPercentilesProps {
    print?: boolean
}

const FleetPercentiles: React.FC<IFleetPercentilesProps> = (props) => {
    const { print } = props
    const [loading, setLoading] = useState(false)
    const [percentilesData, setPercentilesData] = useState<any>([])
    const [percentileColumns, setPercentileColumns] = useState([]);
    const [percentileGroups, setPercentileGroups] = useState([]);

    let riskEldObject: any = {}

    const handleDataReady = (data: any) => {
        setPercentileColumns(data.percentileColumns);
        setPercentileGroups(data.percentileGroups);
    }

    useEffect(() => {
        setLoading(true)
        if (isAgentLogin()) {
            const codeType: any = localStorage.getItem('carrierCodeType')
            const codeValue : any = localStorage.getItem('carrierCodeValue')
            getPercentiles(codeType, codeValue)
                .then((res: any) => {
                    setPercentilesData(res?.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            getPercentiles()
                .then((res: any) => {
                    setPercentilesData(res?.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const loadPagination = (pageNumber: number, pageSize: number) => {
        console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
    }

    const HeaderDom = () => {
        return (
            <Container className={styles.headerContainer}>
                <Container className={styles.headerTitleContainer}>
                    <TypoGraph
                        variant="h1"
                        sx={{ marginRight: '1rem' }}
                        mb={0}
                        content={'Percentiles'}
                    />
                    <Chip
                        label={`Updated ${moment(riskEldObject?.updated).format('dddd, MMMM Do')}`}
                        variant="filled"
                        sx={{
                            backgroundColor: '#E1FAEB',
                            color: '#20693D',
                        }}
                    />
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.fleetPercentileContainer}>
            <Container>
                <HeaderBar dom={<HeaderDom />} />
                <FleetPercentileTableData onDataReady={handleDataReady} />
                {percentileColumns.length > 0 && percentileGroups.length > 0 && (
                    <DataGridTable
                        title={'Summary by Asset table'}
                        loading={loading}
                        data={percentilesData || []}
                        columns={percentileColumns}
                        columnGroupingModel={percentileGroups}
                        columnHeaderHeight={35}
                        showTitle={false}
                        columnVisibilityModel={{ assetId: false }}
                        totalCount={percentilesData?.length}
                        reduceHeight={280}
                        checkboxSelection={false}
                        multiSearchFilter={false}
                        getRowId={(row: any) => row?.index}
                        handlePagination={(pageNumber, pageSize) =>
                            loadPagination(pageNumber, pageSize)
                        }
                    />
                )}
            </Container>
        </Container>
    )
}

export default FleetPercentiles