import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { Container, TypoGraph } from 'components'
import styles from './tagFilter.module.scss'

interface ITagFilterProps {
  mainTags: [string, string][]
  subTags?: [string, string][]
  selectedMainTags: any[]
  selectedSubTags: any[]
  onMainTagSelectionChange: (selectedTags: any[]) => void
  onSubTagSelectionChange: (selectedTags: any[]) => void
  isLoading?: boolean
  title?: string
  titleSize?: string
  fontSize?: string
}

const TagFilter: React.FC<ITagFilterProps> = (props) => {
  const {
    mainTags,
    subTags,
    selectedMainTags,
    selectedSubTags,
    onMainTagSelectionChange,
    onSubTagSelectionChange,
    isLoading,
    title,
    titleSize = '1.1rem',
    fontSize = '0.9rem',
  } = props

  const handleChangeOnMainTag = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedTags: any
  ) => {
    onMainTagSelectionChange(newSelectedTags)
  }

  const handleChangeOnSubTag = (
    event: React.MouseEvent<HTMLElement>,
    newSelectedTags: any
  ) => {
    onSubTagSelectionChange(newSelectedTags)
  }

  return (
    <Container sx={{ padding: '5px 0' }}>
      {title && (
        <TypoGraph variant="h6" sx={{ fontSize: titleSize }}>
          {title}
        </TypoGraph>
      )}

      <ToggleButtonGroup
        value={selectedMainTags}
        onChange={handleChangeOnMainTag}
        className={styles.filterButtonGroup}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        }}
      >
        {mainTags.map(([id, value]) => (
          <ToggleButton
            key={id}
            value={id}
            disabled={isLoading}
            sx={{
              borderRadius: '15px',
            }}
          >
            <TypoGraph variant="body1" sx={{ fontSize: fontSize }}>
              {value}
            </TypoGraph>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={selectedSubTags}
        onChange={handleChangeOnSubTag}
        className={styles.filterButtonGroup}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
        }}
      >
        {subTags?.map(([id, value]) => (
          <ToggleButton
            key={id}
            value={id}
            disabled={isLoading}
            sx={{
              borderRadius: '15px',
            }}
          >
            <TypoGraph variant="body1" sx={{ fontSize: fontSize }}>
              {value}
            </TypoGraph>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Container>
  )
}

export default TagFilter