import { Navigate } from 'react-router-dom'
import {
  Toast as toast,
} from 'components'
import Page404 from 'pages/page404'
import strings from 'strings'

export interface IProtectedComponentProps {
  isAuth: boolean,
  hasAccess: boolean,
  children: JSX.Element
}

export function ProtectedRoute (props: IProtectedComponentProps) {
  const {
    isAuth,
    hasAccess,
    children
  } = props
  
  if(!isAuth) {
    return <Navigate to={strings.ROUTES.LOGIN} />
  }
  if (!hasAccess) {
    toast({
      title: "No Accesss",
      subTitle: "You don't have access to that route",
      type: 'error'
    })
    
    return <Page404 />
  }
  
  return children
}

export default ProtectedRoute
