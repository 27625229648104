import React, { ReactNode } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button, { IButtonProps } from '../button'
import { Container, TypoGraph } from 'components'
import styles from './blade.module.scss'

const sizes = {
  sm: 600,
  md: 892,
  lg: 1168,
}

export interface IBladeProps {
  position?: 'top' | 'bottom' | 'left' | 'right'
  open: boolean
  onClose: () => void
  hideBackdrop?: boolean
  sx?: object
  variant?: 'permanent' | 'persistent' | 'temporary'
  className?: string
  containerClassName?: string
  size?: 'sm' | 'md' | 'lg' | number
  bodyNode?: ReactNode
  headerNode?: ReactNode
  footerNode?: ReactNode
  footerBtns?: {
    primary: IButtonProps
    secondary?: IButtonProps
  } | null
  footerInfo?: string
  title?: string
}

const Blade: React.FC<IBladeProps> = (props) => {
  const {
    position = 'right',
    open = false,
    hideBackdrop = false,
    variant = 'temporary',
    onClose,
    sx = {},
    className,
    size = 'sm',
    title,
    bodyNode,
    headerNode,
    footerNode,
    containerClassName,
    footerBtns = {
      primary: { id : 'primaryBladeButton' ,label: 'Primary', variant: 'contained' },
      secondary: {  id : 'secondaryBladeButton' ,label: 'Secondary', variant: 'outlined' },
    },
    footerInfo,
  } = props

  let width = 600

  if (typeof size === 'string') {
    width = sizes[size]
  }

  if (typeof size === 'number') {
    width = size
  }

  const DrawerHeader = () => {
    if (headerNode) return <>{headerNode}</>
    return (
      <Container className={styles.headerContainer}>
        <Container className={styles.headerContent}>
          <TypoGraph variant="h2" mb={0} className={styles.title}>
            {title || 'Title'}
          </TypoGraph>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Container>
      </Container>
    )
  }

  const DrawerBody = () => {
    return (
      <Container className={styles.bodyContainer}>
        <Container className={styles.bodyContent}>{bodyNode}</Container>
      </Container>
    )
  }

  const DrawerFooter = () => {
    const { primary, secondary } = footerBtns || {}
    if (footerNode) return <>{footerNode}</>
    return (
      <Container className={styles.footerContainer}>
        <Container className={styles.footerContent}>
          <Container className={styles.footerBtn}>
            {primary && <Button {...primary} />}
            {secondary && <Button {...secondary} />}
          </Container>
          <p>{footerInfo || ''}</p>
        </Container>
      </Container>
    )
  }

  return (
    <MuiDrawer
      anchor={position}
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      sx={sx}
      variant={variant}
      className={`${containerClassName} ${styles.containerShadow}`}
    >
      {['top', 'bottom'].includes(position) ? (
        <Container sx={{ maxHeight: '50vh', minHeight: '50vh' }}>
          <DrawerBody />
        </Container>
      ) : (
        <Container
          className={`${className} ${styles.wrapper}`}
          sx={{ width: width }}
        >
          <DrawerHeader />
          <DrawerBody />
          {(footerBtns || footerInfo) && <DrawerFooter />}
        </Container>
      )}
    </MuiDrawer>
  )
}

export default Blade
