import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Container, TypoGraph } from 'components'
import { InputFormField } from 'components/hookForm'
import { ObjectStrings } from 'interfaces'
import strings from 'strings'

interface IProfileTabProps {
  id?:string
  styles: ObjectStrings
  profileStrings: ObjectStrings
}

const ProfileTab = (props: IProfileTabProps) => {
  const { id, styles, profileStrings } = props
  const methods = useForm()
  const { reset } = methods

  useEffect(() => {
    reset({
      userName: localStorage.getItem('userLogin'),
      userType: localStorage.getItem('userType'),
      companyName: localStorage.getItem('companyName'),
    })
  }, [reset])

  return (
    <Container>
      <TypoGraph content={profileStrings.PROFILE_DETAILS} variant="h2" />
      <Container className={styles.formContainer}>
        <Container>
          <FormProvider {...methods}>
            <InputFormField
                id={"companyNameProfile"}
              name="companyName"
              label={strings.COMPANY_NAME}
              readOnly
            />
            <InputFormField
                id={"userNameProfile"}
              name="userName"
              label={profileStrings.USER_NAME}
              readOnly
            />
            <InputFormField
                id={"userTypeProfile"}
              name="userType"
              label={profileStrings.USER_TYPE}
              readOnly
            />
          </FormProvider>
        </Container>
      </Container>
    </Container>
  )
}

export default ProfileTab
