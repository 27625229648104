import { useState } from "react";
import { Container, Button, TypoGraph, Toast } from "components";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import colors from "theme/colors";
import { InputFormField } from "components/hookForm";
import { emailValidator } from "tools/validators";
import { FormProvider, useForm } from "react-hook-form";
import { requestPasswordReset } from "api";
import { reportException } from "tools/logs";

interface IProps {
  styles: ObjectStrings;
  handleStep: (val: number) => void;
}

interface IForgotPassword {
  email: string
}

const ForgotPassword = (props: IProps) => {
  const { styles, handleStep } = props;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)
  const methods = useForm<IForgotPassword>()
  const {handleSubmit} = methods

  const onSubmit = (formData: IForgotPassword) => {
    setLoading(true)
    const payLoad = {
      email: formData.email
    }

    requestPasswordReset(payLoad)
      .then((res: any) => {
        Toast({ id:"recoverPasswordToast", type: "success", title: "Mail sent successfully!", subTitle: "You have 10 minutes to reset your password." })
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container className={styles.formContainer}>
      <FormProvider {...methods}>
        <InputFormField
          id={"email"}
          name="email"
          label={strings.EMAIL_ADRESS}
          type="text"
          validate={emailValidator}
          required
        />
        <TypoGraph
          id={"backToLogin"}
          content={strings.BACKTO_LOGIN}
          onClick={() => handleStep(0)}
          align="center"
          color={colors.primary}
          link
        />
        <Container className={styles.buttonContainer}>
          <Button
            id={"recoverPasswordButton"}
            variant="contained"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            label={strings.RECOVER_PASSWORD}
          />
        </Container>
      </FormProvider>
    </Container>
  );
};

export default ForgotPassword;
