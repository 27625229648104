import React, { useState } from 'react'
import { Container, HeaderBar, Tabs } from 'components'
import { carriersForAgentsTabs, carriersTabs } from 'constants/status'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import { MyCarrierList, InvitedCarrier, SearchCarrier } from './partial'
import styles from './carriers.module.scss'
import strings from 'strings'
import { isAgentLogin } from 'tools/auth'

const t = strings.CARRIERS

interface ICarrierProps {
  dispatchPaymentList?: (data: ObjectAnyKeys[]) => void
  myCarrierData?: ObjectAnyKeys[]
}

const CarrierList: React.FunctionComponent<ICarrierProps> = (props) => {
  const [showSearchCarrier, setShowSearchCarrier] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [reloadInvitedCarrier, setReloadInvitedCarrier] = useState(
    Math.random()
  )

  const handleSuccessCallback = () => {
    setShowSearchCarrier(false)
    setReloadInvitedCarrier(Math.random())
  }

  return (
    <Container id={"containerSearchedCarrierList"} className={styles.paymentsContainer}>
      {isAgentLogin() ?
      <HeaderBar
        title={t.CARRIERS}
      />
      :
      <HeaderBar
        title={t.CARRIERS}
        primaryBtnProps={{
          label: t.INVITE_CARRIERS,
          onClick: () => setShowSearchCarrier(true),
        }}
      />
      }
      <Tabs
        tabData={isAgentLogin() ? carriersForAgentsTabs : carriersTabs}
        value={tabValue}
        id={carriersTabs[tabValue].label}
        handleChange={(_e, val) => setTabValue(val)}
        sxContainer={{ marginLeft: '1rem' }}
      />
      {tabValue === 0 && <MyCarrierList />}
      {tabValue === 1 && <InvitedCarrier reload={reloadInvitedCarrier} />}
      <SearchCarrier
        open={showSearchCarrier}
        onClose={() => setShowSearchCarrier(false)}
        onSuccessCallback={handleSuccessCallback}
      />
    </Container>
  )
}

export default CarrierList
