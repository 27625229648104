import { useEffect, useState } from 'react'

import { getConnectedAccounts, offboardAccount } from 'api'
import {
  getSecondaryCarrierTableData,
  getMasterCarrierData,
} from 'tools/tableDataHelper'
import { Container, DataGridTable, Icon, Tooltip, TypoGraph, Toast as toast } from 'components'
import styles from './myConnectedAccounts.module.scss'
import colors from 'theme/colors'
import {
  GridColumnVisibilityModel,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid'
import ButtonMenu from 'components/buttonMenu'
import strings from 'strings'
import { Button } from '@mui/material'
import { reportException } from 'tools/logs'

interface IMyConnectedAccountsProps {}

interface IMasterCarrierSummary {
  carrierName: string
  contactName: string
  contactEmail: string
  primaryCode: string
  primaryCodeType: string
  cuid: string
  cuidType: string
  activeVehicles: string
}

const MyConnectedAccounts: React.FC<IMyConnectedAccountsProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [responseData, setResponseData] = useState(null)
  const [myAccountsData, setMyAccountsData] = useState([])
  const [masterCarrierData, setMasterCarrierData] =
    useState<IMasterCarrierSummary | null>(null)
  const [codeTypes, setCodeTypes] = useState(['DOT', 'MC', 'SCAC', 'UUID'])
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      dot: false,
      mc: false,
      scac: false,
      uuid: false,
    })

  const apiRef = useGridApiRef()

  useEffect(() => {
    setLoading(true)
    getConnectedAccounts()
      .then((res: any) => {
        setResponseData(res)
      })
      .catch((error: any) => {
        reportException(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (responseData) {
      const data = getSecondaryCarrierTableData(responseData, codeTypes)
      setMyAccountsData(data)
    }
  }, [responseData])

  useEffect(() => {
    if (myAccountsData) {
      setMasterCarrierData(getMasterCarrierData(responseData, codeTypes))
    }
  }, [myAccountsData])

  useEffect(() => {
    if (masterCarrierData) {
      const newVisibilityModel: Record<string, boolean> = {}

      myAccountsList.myAccountColumn.forEach((column) => {
        newVisibilityModel[column.field] =
          column.headerName === masterCarrierData.primaryCodeType ||
          !codeTypes.includes(column.headerName)
      })

      setColumnVisibilityModel(newVisibilityModel)
    }
  }, [masterCarrierData])

  const offboard = (carrierId: string, eldVendor: string) => {
    offboardAccount(carrierId, eldVendor).then((res:any) => {
      toast({ id: 'offboardToast', title: res?.message })
      getConnectedAccounts()
        .then((res: any) => {
          setResponseData(res)
        })
        .catch((error: any) => {
          reportException(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const myAccountsList = {
    myAccountColumn: [
      {
        field: 'carrierName',
        sortable: true,
        headerName: 'Carrier Name',
        minWidth: 120,
        flex: 0.2,
        renderCell: function (params: GridRenderCellParams) {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              {params.value}
            </Tooltip>
          )
        },
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        width: 100,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        width: 100,
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        width: 110,
      },
      {
        field: 'dot',
        headerName: 'DOT',
        sortable: true,
        width: 100,
      },
      {
        field: 'mc',
        headerName: 'MC',
        sortable: true,
        width: 100,
      },
      {
        field: 'scac',
        headerName: 'SCAC',
        sortable: true,
        width: 100,
      },
      {
        field: 'uuid',
        headerName: 'UUID',
        sortable: true,
        width: 100,
      },
      {
        field: 'cuid',
        headerName: 'CUID',
        sortable: true,
        width: 100,
      },
      {
        field: 'eldVendorDisplayName',
        headerName: 'ELD Provider',
        sortable: true,
        minWidth: 120,
        flex: 0.2,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip
              title={params.value}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              {params.value}
            </Tooltip>
          )
        },
      },
      {
        field: 'onboardDate',
        headerName: 'Onboard Date',
        sortable: true,
        width: 150,
      },
      {
        field: 'data',
        headerName: 'Data Sharing',
        sortable: false,
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Container
              sx={{
                gridTemplateColumns: 'auto auto auto auto',
              }}
            >
              <Tooltip
                title={'Terms & Conditions'}
                containerClassName="ellipsis"
                maxWidth={210}
              >
                <Button
                  onClick={() =>
                    window.open(params.row?.letterOfAuthorizationUrl, '_blank')
                  }
                  className={
                    params.row?.letterOfAuthorizationUrl
                      ? 'termsAndConditionButton'
                      : 'inactiveButton'
                  }
                  disabled={!params.row?.letterOfAuthorizationUrl}
                >
                  <Icon materialIcon="PictureAsPdf" size={28} />
                </Button>
              </Tooltip>
            </Container>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        width: 100,
      },
      {
        field: 'activeVehicles',
        headerName: 'Active Vehicles',
        sortable: true,
        width: 130,
      },
      {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        width: 100,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams) => {
          const t = strings.OFFBOARDING
          const menuItemsData = [
            {
              label: t.OFFBOARDING,
              onClick: () =>
                offboard(params.row.carrierId, params.row.eldVendor),
              useDialog: true,
              dialogTitle: t.CONFIRMATION_DIALOG_TITLE,
              dialogMsg: t.CONFIRMATION_DIALOG_MESSAGE,
              dialogAgreeText: t.AGREE_BUTTON_TEXT,
              dialogDisagreeText: t.DISAGREE_BUTTON_TEXT,
            },
          ]
          return (
            <ButtonMenu
              label="Action"
              color="primary"
              menuItems={menuItemsData}
            ></ButtonMenu>
          )
        },
      },
    ],
  }

  return (
    <Container className={styles.connectedAccountsContainer}>
      <Container className={styles.summaryMainContainer}>
        <Container className={styles.summaryHeaderContainer}>
          <Container className={styles.summaryList}>
            <Container className={styles.itemContainer}>
              <Icon name="summary_carriers" size={32} />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content="Carrier Name"
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={
                      masterCarrierData ? masterCarrierData?.carrierName : '-'
                    }
                  />
                </Container>
              </Container>
            </Container>
            <Container className={styles.itemContainer}>
              <Icon materialIcon="Person" size={32} color={colors.white} />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content="Contact Name"
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={
                      masterCarrierData ? masterCarrierData?.contactName : '-'
                    }
                  />
                </Container>
              </Container>
            </Container>
            <Container className={styles.itemContainer}>
              <Icon materialIcon="Mail" size={32} color={colors.white} />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content="Contact Email"
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={
                      masterCarrierData ? masterCarrierData?.contactEmail : '-'
                    }
                  />
                </Container>
              </Container>
            </Container>
            <Container className={styles.itemContainer}>
              <Icon materialIcon="Feed" size={32} color={colors.white} />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content={
                    masterCarrierData
                      ? masterCarrierData?.primaryCodeType
                      : 'DOT'
                  }
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={
                      masterCarrierData ? masterCarrierData?.primaryCode : '-'
                    }
                  />
                </Container>
              </Container>
            </Container>
            <Container className={styles.itemContainer}>
              <Icon materialIcon="VpnKey" size={32} color={colors.white} />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content={
                    masterCarrierData?.cuidType
                      ? `CUID (${masterCarrierData.cuidType})`
                      : 'CUID'
                  }
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={masterCarrierData ? masterCarrierData?.cuid : '-'}
                  />
                </Container>
              </Container>
            </Container>
            <Container className={styles.itemContainer}>
              <Icon
                materialIcon="LocalShipping"
                size={32}
                color={colors.white}
              />
              <Container className={styles.summaryContentContainer}>
                <TypoGraph
                  variant="subtitle2"
                  color={colors.white}
                  content="Active Vehicles"
                />
                <Container className={styles.summaryDataContainer}>
                  <TypoGraph
                    color={colors.white}
                    content={
                      masterCarrierData
                        ? masterCarrierData?.activeVehicles
                        : '-'
                    }
                  />
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>

      <DataGridTable
        id={'myAccountsTable'}
        title="My Connected Accounts"
        loading={loading}
        data={myAccountsData || []}
        columns={myAccountsList.myAccountColumn}
        columnVisibilityModel={columnVisibilityModel}
        totalCount={myAccountsData?.length}
        reduceHeight={260}
        checkboxSelection={false}
        multiSearchFilter={false}
        getRowId={(row: any) => row?.index}
      />
    </Container>
  )
}

export default MyConnectedAccounts
