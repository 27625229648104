import { styled } from '@mui/material/styles'
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

export interface ITooltipProps {
  title: string | React.ReactElement
  children: React.ReactElement
  sx?: object
  maxWidth?: number
  show?: boolean
  containerClassName?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

const Tooltip: React.FC<ITooltipProps> = (props) => {
  const {
    title,
    children,
    placement = 'top',
    sx = {},
    maxWidth = 300,
    show = true,
    containerClassName,
  } = props
  if (!show) {
    return children
  }
  return (
    <BootstrapTooltip
      title={title}
      placement={placement}
      sx={{ maxWidth: maxWidth, margin: 'auto', textAlign: 'center', ...sx }}
    >
      <div style={{ margin: 'auto' }} className={containerClassName}>
        {children && children}
      </div>
    </BootstrapTooltip>
  )
}

export default Tooltip
