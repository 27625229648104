import request from 'tools/request'

export const getCobList = async (invitationkey: any) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/invitation/${invitationkey}`
  )
  const data: any = res?.data;
  const filteredData = {
    ...data, eldInfo: data?.eldInfo.filter((item: { showCobList: boolean }) => item?.showCobList === true),
    removedElds: data?.eldInfo.filter((item: { showCobList: boolean }) => item?.showCobList === false)
  }
  return filteredData
}

export const getEldTerms = async (invitationkey: any, eld: any) => {
  if (!invitationkey || !eld) return
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/terms-and-conditions/${invitationkey}/${eld}`
  )
  return res?.data
}

export const onboardCarrier = async (invitationkey: any, payLoad: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/onboard/${invitationkey}`,
    payLoad
  )
  return res?.data
}

export const onboardOauth = async (payLoad: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/onboard/oauth`,
    payLoad
  )
  return (
    res !== undefined && res !== null && 
    res.data !== undefined && res.data !== null) ? 
    res.data : (
        res !== undefined && res !== null) ? 
        res : null
}

export const carrierSignupOnboarding = async (invitationKey: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/signup/${invitationKey}`
  )
  return res
}

export const updateLastCheckedEld = async (invitationKey: any, eldProviderId: any) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/updateLastCheckedEld/${invitationKey}/${eldProviderId}`
  )
  return res
}
