import MenuItem from '@mui/material/MenuItem'
import TypoGraph from 'components/typograph'
import { DropdownOptions } from 'interfaces'
import TextField, { ITextFieldProps } from '../input'

export interface IDropdownProps extends ITextFieldProps {
  options?: DropdownOptions[]
}

const DropdownField: React.FunctionComponent<IDropdownProps> = (props) => {
  const { id,options } = props
  return (
    <TextField select={true} {...props}>
      {options ? (
        options.map((option) => (
          <MenuItem key={option.value} value={option.value} id={option.label.toString()}>
            {option.label}
          </MenuItem>
        ))
      ) : (
        <TypoGraph content="No options found" align="center" />
      )}
    </TextField>
  )
}

export default DropdownField
