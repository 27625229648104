import React, { useEffect, useState, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  getUserList,
  createUser,
  getNewUserEmailTemplateList,
  getRolesList,
  getStateOptions,
  deleteUser,
} from 'api'
import {
  Container,
  HeaderBar,
  DataGridTable,
  Blade,
  Tabs,
  SubmitDialog,
  Toast as toast,
} from 'components'
import { DropdownFormField, InputFormField } from 'components/hookForm'
import { userManagementStatus } from 'constants/status'
import { userManagementList } from 'constants/tableData'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import styles from './usersList.module.scss'
import strings from 'strings'
import { emailValidator } from 'tools/validators'

const formatStateOptions = (data: any) => {
  return data?.map((item: any) => ({ id: item.name, value: item.name, label: item.name }))
}

const formatRolesOptions = (data: any) => {
  return data?.map((item: any) => ({
      id:item.name,
    value: item?.roleId,
    label: item?.roleName,
  }))
}

const formatEmailTemplateOptions = (data: any) => {
  return data?.map((item: any) => ({
      id:item.name,
    value: item?.carrierEmailTemplateId,
    label: item?.emailTemplateName,
  }))
}

const t = strings.USER_MANAGEMENT

const toastType: ObjectAnyKeys = {
  reject: 'error',
  hold: 'warning',
  deposit: 'success',
}

interface IUserManagementListProps {}

interface IBladeContentProps {}

const UserManagementList: React.FunctionComponent<IUserManagementListProps> =
  () => {
    const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
    const [isEditForm, setIsEditForm] = useState(false)
    const [tabValue, setTabValue] = useState(0)
    const [userListData, setUserListData] = useState([])
    const [selectedUser, setSelectedUser] = useState<string | number>('')
    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [dropdownOptions, setDropdownOptions] = useState({
      emailTemplateOptions: [],
      rolesOptions: [],
      stateOptions: [],
    })

    const { emailTemplateColumn, actionList } = userManagementList

    const methods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const loadUsersPage = useCallback(async () => {
      try {
        setLoading(true)
        callUserList()
        const emailTemplateData: any = await getNewUserEmailTemplateList()
        const rolesListData: any = await getRolesList()
        const stateListData: any = await getStateOptions()
        setDropdownOptions({
          emailTemplateOptions: formatEmailTemplateOptions(
            emailTemplateData?.data
          ),
          rolesOptions: formatRolesOptions(rolesListData?.roleList),
          stateOptions: formatStateOptions(stateListData?.data),
        })
      } finally {
        setLoading(false)
      }
    }, [])

    const callUserList = () => {
      setLoading(true)
      getUserList()
        .then((res: any) => {
          setUserListData(res?.Users)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
      loadUsersPage()
    }, [loadUsersPage])

    const handleClose = () => {
      setShowTemplateForm(false)
      reset({})
    }

    const handleActions = (_id: number | string, actionName: string) => {
      console.log('_id', _id)
      if (actionName === 'edit') {
        const templateFormData = userListData.filter(
          (item: any) => item.userId === _id
        )
        setIsEditForm(true)
        reset(templateFormData[0])
        setShowTemplateForm(true)
      } else {
        setSelectedUser(_id)
        setShowDeleteModal(true)
      }
    }

    const handleUserDelete = () => {
      setBtnLoader(true)
      deleteUser(selectedUser)
        .then((res) => {
          toast({
              id:"userManagementToastDelete",
            title: 'Deleted Successfully',
            subTitle: `User with ID: ${selectedUser} deleted successfully`,
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowDeleteModal(false)
          callUserList()
        })
    }

    const handleCheckboxSelection = (selections: any) => {
      console.log('checkbox', selections)
    }

    const handleSelectionActions = (
      actionName: string,
      selectionModel: any
    ) => {
      toast({
          id:"updatedRowsUserManagement",
        type: toastType[actionName],
        title: actionName,
        subTitle: `${selectionModel?.length} rows updated`,
      })
    }

    const loadPayments = (pageNumber: number, pageSize: number) => {
      console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
    }

    const onSubmit = (data: any) => {
      setBtnLoader(true)
      const companyId = localStorage.getItem('companyId')
      const userTypeId = localStorage.getItem('userTypeId')
      const payLoad = {
        userId: data?.userId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        emailId: data?.emailId,
        currentAddress1: data?.currentAddress1,
        City: data?.city,
        State: data?.state,
        Zip: data?.zip,
        phoneNumber: data?.phoneNumber,
        companyId,
        userTypeId,
      }
      createUser(payLoad)
        .then((res: any) => {
          toast({
            id: "createUserToast",
            subTitle: res?.description ?? 'User created successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          callUserList()
        })
    }

    const BladeContent = (_props: IBladeContentProps) => {
      return (
        <Container className={styles.bladeBodyContainer}>
          <FormProvider {...methods}>
            <InputFormField
              id="firstName"
              name="firstName"
              label={strings.FIRST_NAME}
              maxLength={128}
              required
            />
            <InputFormField
              id={"lastName"}
              name="lastName"
              label={strings.LAST_NAME}
              maxLength={128}
              required
            />
            <InputFormField
              id="emailId"
              name="emailId"
              label={strings.EMAIL}
              maxLength={128}
              validate={emailValidator}
              required
            />
            <InputFormField
              id={"phoneNumber"}
              type="number"
              name="phoneNumber"
              label={strings.MOBILE_NUMBER}
              maxLength={12}
            />
            <InputFormField
              id={"currentAddress1"}
              name="currentAddress1"
              label={strings.ADDRESS}
              maxLength={128}
            />
            <DropdownFormField
              id={"state"}
              name="state"
              label={strings.STATE}
              options={dropdownOptions?.stateOptions}
            />
            <InputFormField
              id={"city"}
              name="city"
              label={strings.CITY}
              maxLength={30}
            />
            <InputFormField
              id={"zipcode"}
              name="zip"
              label={strings.ZIP}
              maxLength={8}
            />
          </FormProvider>
        </Container>
      )
    }

    return (
      <Container className={styles.emailTemplateContainer}>
        <HeaderBar
          title={t.USER_MANAGEMENT}
          primaryBtnProps={{
            label: t.CREATE_USER,
            onClick: () => {
              reset({})
              setIsEditForm(false)
              setShowTemplateForm(true)
            },
          }}
        />
        <Tabs
          tabData={userManagementStatus}
          value={tabValue}
          id={userManagementStatus[tabValue].label}
          handleChange={(_e, val) => setTabValue(val)}
          sxContainer={{ marginLeft: '1rem' }}
        />
        <DataGridTable
          title={t.USERS}
          loading={loading}
          data={userListData || []}
          columns={emailTemplateColumn}
          columnVisibilityModel={{userId: false}}
          checkboxSelection={false}
          totalCount={userListData?.length}
          reduceHeight={280}
          actionList={actionList}
          multiSearchFilter={false}
          getRowId={(row: any) => row?.userId}
          handleActions={handleActions}
          handleCheckboxSelection={handleCheckboxSelection}
          handleSelectionActions={handleSelectionActions}
          handlePagination={(pageNumber, pageSize) =>
            loadPayments(pageNumber, pageSize)
          }
        />
        <Blade
          open={showTemplateForm}
          title={isEditForm ? t.EDIT_USER : t.CREATE_USER}
          onClose={handleClose}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
              id: "createOrUpdateUserBladeConfirmation",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit(onSubmit),
              loading: btnLoader,
              disabled: btnLoader,
            },
            secondary: {
              id: "closeUserCreationOrUpdateBlade",
              label: 'Close',
              variant: 'outlined',
              onClick: handleClose,
            },
          }}
        />
        <SubmitDialog
            cancelId="cancelDeleteUser"
          type="delete"
          open={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          title="Delete Confirmation"
          body1={`Are you sure, you want to delete this user with ID: ${selectedUser}?`}
          body2="Once deleted no longer it will be available"
          primaryBtnProps={{
              id:"confirmDelete",
            label: 'Yes, I confirm',
            loading: btnLoader,
            onClick: handleUserDelete,
            disabled: btnLoader
          }}
        />
      </Container>
    )
  }

export default UserManagementList
