import { useNavigate } from 'react-router'
import { HeaderBar, Container, Card, TypoGraph, Button, Icon } from 'components'
import { settings } from 'constants/settingsList'
import styles from './settings.module.scss'
import strings from 'strings'

const PaymentsCategory = () => { 
  const navigate = useNavigate()

  const navigateToPaymentList = (link: string) => {
    navigate(`/settings/${link}`)
  }

  const PaymentCategoryCard = () => {
    return (
      <>
        {settings.map((item, index) => (
          <Container key={`key-${index}`}>
            <Card sxContent={{ display: 'grid', gridGap: '24px'}}>
              <Container sx={{gridGap: '8px'}}>
                <Container sx={{ justifyContent: 'center'}}>
                  {item.icon ? (
                    <Icon name={item.icon} size={30} />
                  ) : (
                    <Icon materialIcon={item.muiIcon} size={30} boxClassName={styles.muiIcon}
                    />
                  )}
                </Container>
                <TypoGraph variant="h3" content={item.title} align="center" />
                <TypoGraph variant="body2" content={item.description} align="center" />
              </Container>
              <Container className={styles.btnContainer}>
                <Button
                    id={"settings-"+item.buttonLabel.split(" ")[0]+item.buttonLabel.split(" ")[1]}
                  variant="outlined"
                  label={item.buttonLabel}
                  onClick={() => navigateToPaymentList(item.link)}
                />
              </Container>
            </Card>
          </Container>
        ))}
      </>
    )
  }

  return (
    <Container>
      <HeaderBar title={strings.SETTINGS} />
      <Container className={styles.paymentCategoryContainer}>
        <PaymentCategoryCard />
      </Container>
    </Container>
  )
}

export default PaymentsCategory
