import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Container, Icon, TypoGraph } from 'components'
import Login from './partial/login'
import ForgotPassword from './partial/forgotPassword'
import ResetPassword from './partial/resetPassword'
import MarketingChangelog from './partial/marketingChangelog'
import { shutdownIntercom } from 'tools/intercom'
import styles from './loginContainer.module.scss'
import strings from 'strings'
import colors from 'theme/colors'

interface IApploginProps {
  currentStep?: 0 | 1 | 2
}

const AppLogin: React.FC<IApploginProps> = (props) => {
  const { currentStep } = props
  /**
   * step state used to show/hide the login forms
   * step number(0) -> to show the login form
   * step number(1) -> to show the forgotPassword form
   * step number(2) -> to show the resetForm
   */
  const [step, setStep] = useState(currentStep || 0)

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const updateStep = (newStep: number) => setStep(newStep)

  useEffect(() => {
    shutdownIntercom()
  }, [])

  const FormComponent = (props: { step: number }) => {
    const { step } = props
    switch (step) {
      case 0:
        return <Login styles={styles} handleStep={(val) => setStep(val)} />
      case 1:
        return <ForgotPassword styles={styles} handleStep={(val) => setStep(val)} />
      case 2:
        return <ResetPassword styles={styles} setStep={updateStep} />
      default:
        return <Login styles={styles} handleStep={(val) => setStep(val)} />
    }
  }

  return (
    <Container>
      <Box component="main">
        <Container className={styles.loginWrapperContainer}>
          <MarketingChangelog styles={styles} companyProfile={companyProfile} />
          <Container className={styles.bgGrey}>
            <Container className={styles.loginContainer}>
              <Container className={styles.brandDetail}>
                {companyProfile?.logo ? (
                  <Icon
                    type="images"
                    url={companyProfile?.logo}
                    height={100}
                    width={400}
                    boxClassName="fullLogo"
                  />
                ) : (
                  <Icon name="icon_logoFullname" height={100} width={400} />
                )}
                <TypoGraph
                  variant="h1"
                  content={strings.TRUCKER_CLOUD_TITLE}
                  align="center"
                />
              </Container>
              <FormComponent step={step} />
              <Container className={styles.demoTxtContainer}>
                <TypoGraph
                  variant="subtitle1"
                  content={strings.NOT_REGISTERED}
                />
                <TypoGraph
                    id={"demoLink"}
                  variant="body1"
                  content={strings.WANT_TO_SEE_DEMO}
                  onClick={() =>
                    window.open('https://r1vs.com/#contact', '_blank')
                  }
                  color={colors.primary}
                  link
                />
              </Container>
            </Container>
            <TypoGraph
              variant="overline"
              content="© 2022, R1vs"
              align="center"
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
              }}
            />
          </Container>
        </Container>
      </Box>
    </Container>
  )
}

export default AppLogin
