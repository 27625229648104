import { Line } from '@nivo/line'

const commonProperties = {
    width: 300,
    height: 200,
    margin: { top: 30, right: 10, bottom: 30, left: 30 },
    animate: true,
    enableSlices: 'x'
}
const currentYear = new Date().getFullYear()
const chartData = [
    { x: `${currentYear}-01-01`, y: 2 },
    { x: `${currentYear}-02-01`, y: 4 },
    { x: `${currentYear}-03-01`, y: 8 },
    { x: `${currentYear}-04-01`, y: 5 },
    { x: `${currentYear}-05-01`, y: 6 },
    { x: `${currentYear}-06-01`, y: 10 },
]

const LineChart = (props) => {
    const { data } = props
    let yScale = {
        type: 'linear',
        min: 0,
        stacked: false,
        reverse: false
    }
    if (data === 0) {
        yScale['max'] = 10
    }
    return (
        <div style={{ height: '100%' }}>
            <Line
                {...commonProperties}
                colors="#FA706B"
                theme={{
                    fontSize: 10,
                    textColor: '#A3A3A3',
                    "grid": {
                        "line": {
                            "stroke": "#f5f5f5",
                            "strokeWidth": 1
                        }
                    },
                }}
                curve="monotoneX"
                data={[
                    {
                        id: 'Customers Onboarded',
                        data: data === 0 ? chartData : data,
                    },
                ]}
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d',
                    useUTC: false,
                    precision: 'day'
                }}
                xFormat="time:%Y-%m-%d"
                yScale={yScale}
                axisLeft={{
                    legendOffset: 12,
                }}
                axisBottom={{
                    format: '%b',
                    legendOffset: -12,
                }}
                enablePoints={true}
                enablePointLabel={false}
            />
        </div>
    )
}

export default LineChart