import { GridCellParams, GridRenderCellParams, gridClasses } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { TypoGraph, Chip, Tooltip, Container, Icon } from 'components'
import moment from 'moment'
import { carrierInviteStatus } from 'constants/status'
import invitedCarrierData from './mockData/invitedCarrier'
import { ratingColor, formatRiskCell } from 'tools/helper'
import colors from 'theme/colors'
import { numberOrStringComparator, numberComparator } from 'tools/comparators'
import 'App.scss'
import { Button } from '@mui/material'
import HeaderWithIcon from 'components/tableHeaderWithIcon'
import CircleIcon from '@mui/icons-material/Circle';
import { isAgentLogin } from 'tools/auth'

const dataGridSx = {
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
  },
  '.MuiDataGrid-columnSeparator--sideRight': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeaderDraggableContainer': {
    width: 'auto',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .actionButton': {
    display: 'none',
  },
  '.MuiDataGrid-actionsCell': {
    position: 'absolute',
    right: '2rem',
  },
  [`& .${gridClasses.row}:hover`]: {
    '.actionButton': {
      display: 'block',
    },
  },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-selectedRowCount ': {
    visibility: 'hidden',
  },
  background: 'transparent',
}

const ColumnDescription = (props: any) => {
  const { title, description, cell } = props
  return (
    <Container
      className="columnDescriptionContainer"
      sx={
        cell && {
          gridTemplateRows: '0.5rem 0.5rem',
        }
      }
    >
      <strong>{title}</strong>
      <TypoGraph
        variant="subtitle1"
        content={description}
        className="descriptionText"
      />
    </Container>
  )
}

const ColumnDescriptionWithoutTitle = (props: any) => {
  const { description, cell } = props
  return (
    <Container
      className="columnDescriptionContainer withoutTitle"
      sx={
        cell && {
          gridTemplateRows: '0.5rem 0.5rem'
        }
      }
    >
      <TypoGraph
        variant="subtitle1"
        content={description}
        className="descriptionText"
        sx={{color:'#C4C4C4', paddingBottom: '1rem!important', marginLeft: 'auto!important', marginRight: 'auto!important'}}
      />
    </Container>
  )
}

const FleetDot = (props: any) => {
  const { title, description, cell, onClick } = props
  return (
    <Container
      className="columnDescriptionContainer"
      sx={
        cell && {
          display: 'block',
        }
      }
      onClick={onClick}
    >
      <Tooltip 
        title={title} 
        containerClassName="ellipsis elevated" 
        maxWidth={150}>
          <TypoGraph
            id={props.id}
            component="span"
            variant="body1"
            color={colors.primary}
            onClick={onClick}
            link
          >
            {title}
          </TypoGraph>
      </Tooltip>
      <TypoGraph
        variant="subtitle1"
        content={description}
        className="descriptionText elevated"
      />
    </Container>
  )
}

const paymentsList = {
  paymentsColumns: [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
      hideable: false,
      handleClick: (_val: number | string) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value)}
            link
          >
            {params.value}
          </TypoGraph>
        )
      },
    },
    {
      field: 'agent',
      headerName: 'column 1',
      width: 160,
    },
    {
      field: 'property',
      headerName: 'column 2',
      width: 250,
    },
    {
      field: 'amount',
      headerName: 'column 3',
      width: 140,
    },
    {
      field: 'createdAt',
      headerName: 'column 4',
      sortable: false,
      width: 130,
    },
    {
      field: 'due',
      headerName: 'column 5',
      sortable: false,
      width: 120,
    },
    {
      field: 'method',
      headerName: 'column 6',
      sortable: false,
      width: 150,
    },
  ],
  paymentsData: [
    {
      id: 1101,
      agent: 'Mauseth, Jensen',
      property: '123 Americus Way, Mukiteo WA',
      amount: '$10,000.00',
      createdAt: 'May 12th, 2022',
      due: '5d 2h',
      method: 'Remote Capture',
    },
    {
      id: 1102,
      agent: 'Lannister',
      property: 'Cersei, CA',
      amount: '$10,000.00',
      createdAt: 'Jun 12th, 2022',
      due: '4d 2h',
      method: 'Digital Check',
    },
    {
      id: 1103,
      agent: 'Lannister',
      property: 'Jaime, JP',
      amount: '$20,00.00',
      createdAt: 'July 12th, 2022',
      due: '5d 1h',
      method: 'Check Capture',
    },
    {
      id: 1104,
      agent: 'Stark',
      property: 'Arya, IN',
      amount: '$10,00.00',
      createdAt: 'Aug 12th, 2022',
      due: '5d 2h',
      method: 'ATH Transfer',
    },
    {
      id: 1105,
      agent: 'Targaryen',
      property: '123 Americus Way, Mukiteo WA',
      amount: '$40,000.00',
      createdAt: 'Sep 12th, 2022',
      due: '5d 2h',
      method: 'Remote Capture',
    },
    {
      id: 1106,
      agent: 'Melisandre',
      property: '123 Americus Way, Mukiteo WA',
      amount: '$10,00.00',
      createdAt: 'Oct 12th, 2022',
      due: '5d 2h',
      method: 'Remote Capture',
    },
    {
      id: 1107,
      agent: 'Clifford',
      property: 'Ferrara, FC',
      amount: '$30,000.00',
      createdAt: 'May 12th, 2022',
      due: '5d 2h',
      method: 'Remote Capture',
    },
    {
      id: 1108,
      agent: 'Frances',
      property: '143, Rossini, CA',
      amount: '$10,00.00',
      createdAt: 'Dec 12th, 2022',
      due: '3d 2h',
      method: 'Remote Capture',
    },
    {
      id: 1109,
      agent: 'Roxie',
      property: '33, Harvey',
      amount: '$80,000.00',
      createdAt: 'May 12th, 2022',
      due: '3d 2h',
      method: 'Remote Capture',
    },
  ],
  actionList: [
    {
      name: 'reject',
      icon: 'icon_reject',
    },
    {
      name: 'hold',
      icon: 'icon_hold',
    },
    {
      name: 'deposit',
      icon: 'icon_deposit',
    },
  ],
  filters: [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: 'Agent',
      key: 'agent',
    },
    {
      label: 'Amount',
      key: 'amount',
    },
    {
      label: 'Created',
      key: 'created',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ],
}

const riskScoresColumnFlex = 0.15
const riskDashboard = {
  riskColumns: [
    {
      field: 'fleetName',
      headerName: 'Fleet',
      minWidth: 170,
      flex: 0.5,
      sortable: true,
      handleClick: (row: any) => {},
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Fleet" description="DOT" />
      ),
      renderCell: function (params: GridRenderCellParams) {
        return (
          <FleetDot
            id={`carrierName_${params.row?.eldProvider}_${params.row?.companyEldAuthenticationId}`}
            title={params?.row?.fleetName}
            description={
              params?.row?.carrierCodes?.filter(
                (item: any) => item.codeType === 'DOT'
              )[0]?.codeValue
            }
            onClick={() => this.handleClick(params.row)}
            link
            cell
          />
        )
      },
    },
    {
      field: 'eldProvider',
      headerName: 'ELD Provider',
      minWidth: 90,
      flex: 0.3,
      sortable: true,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle
          title="Provider"
          description="ELD/CAMERA"
        />
      ),
    },
    {
      field: 'totalMiles',
      headerName: 'Miles',
      minWidth: 90,
      flex: 0.2,
      sortable: true,
      cellClassName: 'centeredCell',
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Miles" description="TOTAL" />
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph content={formatRiskCell(params.row?.totalMiles)} />
      },
      valueGetter: (value: any, row: any) => {
        return row.totalMiles !== undefined && row.totalMiles !== null ? formatRiskCell(row.totalMiles) : '-'
      },
    },
    {
      field: 'fleetScore',
      headerName: 'Score',
      headerClassName: 'withBorder',
      width: 90,
      flex: 0.22,
      headerAlign: 'center' as const,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberOrStringComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Score" description="SUMMARY" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'summaryScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => (
        <TypoGraph
          sx={{ fontWeight: isAgentLogin() ? 'normal' : 'bold' , color: ratingColor(params?.row?.fleetScore)}}
          variant={isAgentLogin() ? "body1" : "h6"}
          content={formatRiskCell(params.row?.fleetScore)}
        />
      ),
      valueGetter: (value: any, row: any) => {
        return row.fleetScore !== undefined && row.fleetScore !== null ? formatRiskCell(row.fleetScore) : '-'
      },
    },
    {
      field: 'safetyIndex',
      headerName: 'Safety Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      headerAlign: 'center' as const,
      sortable: true,
      sortComparator: numberOrStringComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph sx={{color: ratingColor(params?.row?.fleetScore)}}
        content={formatRiskCell(params.row?.harsh?.index)} />
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.index)
      },
    },
    {
      field: 'safetySev1',
      headerName: 'Safety High-G',
      width: 80,
      sortable: true,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="HIGH-G" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity1, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity1, 1)
      },
    },
    {
      field: 'safetySev2',
      headerName: 'Safety Brake',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="BRAKE" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity2, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity2, 1)
      },
    },
    {
      field: 'safetySev3',
      headerName: 'Safety Cor/Acc',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="COR/ACC" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity3, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity3, 1)
      },
    },
    {
      field: 'speedingIndex',
      headerName: 'Speeding Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberOrStringComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph sx={{color: ratingColor(params?.row?.fleetScore)}}
        content={formatRiskCell(params.row?.speed?.index)} />
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.index)
      },
    },
    {
      field: 'speedingLimitPlus5',
      headerName: 'Speeding Limit Plus 5',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="5-10" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus5,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus5, 1)
      },
    },
    {
      field: 'speedingLimitPlus10',
      headerName: 'Speeding Limit Plus 10',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="10.1-15" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus10,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus10, 1)
      },
    },
    {
      field: 'speedingLimitPlus15',
      headerName: 'Speeding Limit Plus 15',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="15.1+" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus15,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus15, 1)
      },
    },
    {
      field: 'speedingOver75',
      headerName: 'Speeding Over 75',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="75+" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.speed?.pingsOver75mph, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOver75mph, 1)
      },
    },
    {
      field: 'geoIndex',
      headerName: 'Geo Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.index)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.index)
      },
    },
    {
      field: 'geoLow',
      headerName: 'Geo Low',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="LOW" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.low, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.low, 1)
      },
    },
    {
      field: 'geoMedium',
      headerName: 'Geo Medium',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="MED" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.medium, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.medium, 1)
      },
    },
    {
      field: 'geoHigh',
      headerName: 'Geo High',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="HIGH" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.high, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.high, 1)
      },
    },
  ],
  riskGroups: [
    {
      groupId: 'carrier',
      headerName: 'Fleet',
      // description: 'Fleet Name',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'fleetName' }],
    },
    {
      groupId: 'eldProvider',
      headerName: 'Provider',
      // description: 'ELD/Camera Provider',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'eldProvider' }],
    },
    {
      groupId: 'totalMiles',
      headerName: 'Miles',
      // description: 'Total Miles',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'totalMiles' }],
    },
    {
      groupId: 'summaryScore',
      headerName: 'Risk Score',
      // description: 'Summary Score',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#8CC0E8',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [{ field: 'fleetScore' }],
    },
    {
      groupId: 'safety',
      headerName: 'Harsh Events',
      description: 'per 1k miles',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#F9706B',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'safetyIndex' },
        { field: 'safetySev1' },
        { field: 'safetySev2' },
        { field: 'safetySev3' },
      ],
    },
    {
      groupId: 'speeding',
      headerName: 'Speeding %',
      description: 'time over limit',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#89EBB0',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'speedingIndex' },
        { field: 'speedingLimitPlus5' },
        { field: 'speedingLimitPlus10' },
        { field: 'speedingLimitPlus15' },
        { field: 'speedingOver75' },
      ],
    },
    {
      groupId: 'geographic',
      headerName: 'Geographic Risk',
      description: 'miles in thousands',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#FAE178',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'geoIndex' },
        { field: 'geoLow' },
        { field: 'geoMedium' },
        { field: 'geoHigh' },
      ],
    },
  ],
}


const searchCarrierList = {
  column: [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'radio',
      headerName: '',
      width: 40,

      handleClick: (_val: any) => {}
    },
    {
      field: 'carrierInfo',
      sortable: false,
      headerName: 'Carrier Name',
      minWidth: 160,
      flex: 1,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Tooltip
            title={params.row?.carrierInfo?.carrierName}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            {params.row?.carrierInfo?.carrierName}
          </Tooltip>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row?.contactInfo?.firstName) return '-'
        return (
          <Tooltip
            title={`${params.row?.contactInfo?.firstName} ${params.row?.contactInfo?.lastName}`}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            <>
              {params.row?.contactInfo?.firstName}{' '}
              {params.row?.contactInfo?.lastName}
            </>
          </Tooltip>
        )
      },
    },
    {
      field: 'dot',
      headerName: 'DOT',
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams) =>
        params.row?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'DOT'
        )[0]?.codeValue || '-',
    },
    {
      field: 'mc',
      headerName: 'MC',
      width: 90,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'MC'
        )[0]?.codeValue || '-',
    },
    {
      field: 'SCAC',
      headerName: 'SCAC',
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams) => {
        const val = params.row?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'SCAC'
        )[0]?.codeValue
        return !val ? '-' : val
      },
    },
    {
      field: 'policyId',
      headerName: 'Policy ID',
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams) => {
        const val = params.row?.carrierInfo?.carrierCodes.filter(
          (item: any) => item.codeType === 'POLICY_ID'
        )[0]?.codeValue
        return !val ? '-' : val
      },
    },
    {
      field: 'eldVendorDisplayName',
      headerName: 'ELD Provider',
      sortable: false,
      minWidth: 140,
      flex: 0.6,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip
            title={params.row?.eldInfo[0]?.eldVendorDisplayName}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            {params.row?.eldInfo[0]?.eldVendorDisplayName || 'Unknown'}
          </Tooltip>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      flex: 0.4,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Chip
            label={carrierInviteStatus[params.row?.statusInfo?.status]?.label}
            variant="filled"
            sx={{
              backgroundColor:
                carrierInviteStatus[params.row?.statusInfo?.status]?.background,
              color: carrierInviteStatus[params.row?.statusInfo?.status]?.color,
            }}
          />
        )
      },
    },
    {
      field: 'fmcsaStatus',
      headerName: 'FMCSA Status',
      minWidth: 140,
      flex: 0.6,
      renderCell: (params: GridRenderCellParams) =>
        params.row?.statusInfo?.fmcsaStatus,
    },
  ],
}

const assetActivityList = {
  column: [
    {
      field: 'assetId',
      headerName: 'Asset ID',
      width: 90,
    },
    {
      field: 'assetEldId',
      headerName: 'Asset ID',
      minWidth: 90,
      sortable: true,
      flex: 0.3,
      sortComparator: numberOrStringComparator,
      // handleClick: (row: any) => {},
      renderHeader: () => {
        return (
          <ColumnDescriptionWithoutTitle title="Asset" description="ID" />
        )
      },
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Tooltip
            title={params.value}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            <TypoGraph
              component="span"
              variant="body1"
              // color={colors.primary}
              // onClick={() => this.handleClick(params.row)}
              // link
            >
              {params.value}
            </TypoGraph>
          </Tooltip>
        )
      },
    },
    {
      field: 'vin',
      headerName: 'VIN',
      minWidth: 170,
      sortable: true,
      flex: 0.5,
      renderHeader: () => {
        return <ColumnDescriptionWithoutTitle title="VIN" description="VEHICLE IDENTIFIER" />
      },
      valueGetter: (value: any, row: any) => {
        return row?.identificationNo || '-'
      }
    },
    {
      field: 'totalMiles',
      headerName: 'Miles',
      minWidth: 90,
      flex: 0.2,
      sortable: true,
      cellClassName: 'centeredCell',
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Miles" description="TOTAL" />
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph content={formatRiskCell(params.row?.totalMiles)} />
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row.totalMiles)
      },
    },
    {
      field: 'assetScore',
      headerName: 'Score',
      headerClassName: 'withBorder',
      width: 90,
      flex: riskScoresColumnFlex,
      headerAlign: 'center' as const,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Score" description="SUMMARY" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'summaryScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => (
        <TypoGraph
          sx={{ fontWeight: 'bold' }}
          variant="h6"
          content={formatRiskCell(params.row?.score)}
        />
      ),
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row.score)
      },
    },
    {
      field: 'safetyIndex',
      headerName: 'Safety Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      headerAlign: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph content={formatRiskCell(params.row?.harsh.index)} />
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh.index)
      },
    },
    {
      field: 'safetySev1',
      headerName: 'Safety High-G',
      width: 80,
      sortable: true,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="HIGH-G" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity1, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity1, 1)
      },
    },
    {
      field: 'safetySev2',
      headerName: 'Safety Brake',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="BRAKE" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity2, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity2, 1)
      },
    },
    {
      field: 'safetySev3',
      headerName: 'Safety Cor/Acc',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Safety" description="COR/ACC" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'safetyScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.harsh?.severity3, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.harsh?.severity3, 1)
      },
    },
    {
      field: 'speedingIndex',
      headerName: 'Speeding Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return <TypoGraph content={formatRiskCell(params.row?.speed?.index)} />
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.index)
      },
    },
    {
      field: 'speedingLimitPlus5',
      headerName: 'Speeding Limit Plus 5',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="5-10" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus5,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus5, 1)
      },
    },
    {
      field: 'speedingLimitPlus10',
      headerName: 'Speeding Limit Plus 10',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="10.1-15" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus10,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus10, 1)
      },
    },
    {
      field: 'speedingLimitPlus15',
      headerName: 'Speeding Limit Plus 15',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="15.1+" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(
              params.row?.speed?.pingsOverSpeedLimitPlus15,
              1
            )}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOverSpeedLimitPlus15, 1)
      },
    },
    {
      field: 'speedingOver75',
      headerName: 'Speeding Over 75',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Speeding" description="75+" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'speedingScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.speed?.pingsOver75mph, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.speed?.pingsOver75mph, 1)
      },
    },
    {
      field: 'geoIndex',
      headerName: 'Geo Index',
      headerClassName: 'withBorder',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="INDEX" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreStart centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.index)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.index)
      },
    },
    {
      field: 'geoLow',
      headerName: 'Geo Low',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="LOW" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.low, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.low, 1)
      },
    },
    {
      field: 'geoMedium',
      headerName: 'Geo Medium',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="MED" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.medium, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.medium, 1)
      },
    },
    {
      field: 'geoHigh',
      headerName: 'Geo High',
      width: 80,
      flex: riskScoresColumnFlex,
      align: 'center' as const,
      sortable: true,
      sortComparator: numberComparator,
      renderHeader: () => (
        <ColumnDescriptionWithoutTitle title="Geo Risk" description="HIGH" />
      ),
      cellClassName: (params: GridCellParams<any>) =>
        'geoScoreCell centeredCell',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph
            content={formatRiskCell(params.row?.geographical?.high, 1)}
          />
        )
      },
      valueGetter: (value: any, row: any) => {
        return formatRiskCell(row?.geographical?.high, 1)
      },
    },
  ],
  groups: [
    {
      groupId: 'asset',
      headerName: 'Asset',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'assetEldId' }],
    },
    {
      groupId: 'vin',
      headerName: 'VIN',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'vin' }],
    },
    {
      groupId: 'totalMiles',
      headerName: 'Miles',
      headerClassName: 'columnGroupTitle',
      renderHeaderGroup: (params: any) => <HeaderWithIcon {...params} />,
      children: [{ field: 'totalMiles' }],
    },
    {
      groupId: 'summaryScore',
      headerName: 'Score',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#8CC0E8',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [{ field: 'assetScore' }],
    },
    {
      groupId: 'safety',
      headerName: 'Safety Events',
      description: 'per 1k miles',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#F9706B',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'safetyIndex' },
        { field: 'safetySev1' },
        { field: 'safetySev2' },
        { field: 'safetySev3' },
      ],
    },
    {
      groupId: 'speeding',
      headerName: 'Speeding %',
      description: 'time over limit',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#89EBB0',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'speedingIndex' },
        { field: 'speedingLimitPlus5' },
        { field: 'speedingLimitPlus10' },
        { field: 'speedingLimitPlus15' },
        { field: 'speedingOver75' },
      ],
    },
    {
      groupId: 'geographic',
      headerName: 'Geo Risk',
      description: 'miles in thousands',
      headerClassName: 'columnGroupTitle withBorder centered',
      renderHeaderGroup: (params: any) => (
        <HeaderWithIcon
          {...params}
          icon={
            <CircleIcon
              fontSize="small"
              sx={{
                color: '#FAE178',
                width: '1rem',
                height: 'auto',
                paddingRight: '0.2rem',
              }}
            />
          }
        />
      ),
      children: [
        { field: 'geoIndex' },
        { field: 'geoLow' },
        { field: 'geoMedium' },
        { field: 'geoHigh' },
      ],
    },
  ],
}

const userManagementList = {
  emailTemplateColumn: [
    {
      field: 'userId',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 200,
      flex: 0.8,
    },
    {
      field: 'roleName',
      headerName: 'Role',
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: 'emailId',
      headerName: 'Email',
      minWidth: 180,
      flex: 0.8,
    },
    {
      field: 'phoneNumber',
      headerName: 'Mobile',
      minWidth: 180,
      flex: 0.7,
    },
    {
      field: 'createdTime',
      headerName: 'Created',
      minWidth: 110,
      flex: 0.8,
      renderCell: (params: GridRenderCellParams) => {
        return moment(params.value).format('MMM DD, YYYY')
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 110,
      flex: 0.6,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Chip
            label={params.value}
            variant="filled"
            sx={{
              backgroundColor:
                params.value === 'Active' ? '#E1FAEB' : '#FAF0F0',
              color: params.value === 'Active' ? '#20693D' : '#BA0D0D',
            }}
          />
        )
      },
    },
  ],
  actionList: [
    {
      name: 'edit',
      icon: 'icon_edit',
      iconSize: 40,
    },
    {
      name: 'delete',
      icon: 'icon_delete',
      iconSize: 40,
    },
  ],
}

const emailTemplateList = {
  emailTemplateColumn: [
    {
      field: 'carrierEmailTemplateId',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'emailTemplateName',
      headerName: 'Template Name',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'carrierEmailTemplateType',
      headerName: 'Type',
      width: 250,
      flex: 1,
      renderCell: (params: any) => params.value?.carrierEmailTemplateTypeName,
    },
    {
      field: 'carrierEmailAccountSetting',
      headerName: 'Email',
      minWidth: 150,
      flex: 0.8,
      renderCell: (params: any) => params.value?.senderEmail,
    },
    {
      field: 'createdTime',
      headerName: 'Created',
      minWidth: 110,
      flex: 0.8,
      renderCell: (params: GridRenderCellParams) => {
        return moment(params.value).format('MMM DD, YYYY')
      },
    },
  ],
  actionList: [
    {
      name: 'edit',
      icon: 'icon_edit',
      iconSize: 40,
    },
  ],
}

const myAccountsList = {
  myAccountColumn: [
    {
      field: 'carrierName',
      sortable: true,
      headerName: 'Carrier Name',
      minWidth: 120,
      flex: 0.2,
      handleClick: (row: any) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Tooltip
            title={params.value}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            {params.value}
          </Tooltip>
        )
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      width: 100,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      width: 100,
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      width: 110,
    },
    {
      field: 'dot',
      headerName: 'DOT',
      sortable: true,
      width: 100,
    },
    {
      field: 'mc',
      headerName: 'MC',
      sortable: true,
      width: 100,
    },
    {
      field: 'scac',
      headerName: 'SCAC',
      sortable: true,
      width: 100,
    },
    {
      field: 'uuid',
      headerName: 'UUID',
      sortable: true,
      width: 100,
    },
    {
      field: 'cuid',
      headerName: 'CUID',
      sortable: true,
      width: 100,
    },
    {
      field: 'eldVendorDisplayName',
      headerName: 'ELD Provider',
      sortable: true,
      minWidth: 120,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip
            title={params.value}
            containerClassName="ellipsis"
            maxWidth={210}
          >
            {params.value}
          </Tooltip>
        )
      },
    },
    {
      field: 'onboardDate',
      headerName: 'Onboard Date',
      sortable: true,
      width: 150,
    },
    {
      field: 'data',
      headerName: 'Data Sharing',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Container
            sx={{
              gridTemplateColumns: 'auto auto auto auto',
            }}
          >
            <Tooltip
              title={'Terms & Conditions'}
              containerClassName="ellipsis"
              maxWidth={210}
            >
              <Button
                onClick={() =>
                  window.open(params.row?.letterOfAuthorizationUrl, '_blank')
                }
                className={
                  params.row?.letterOfAuthorizationUrl
                    ? 'termsAndConditionButton'
                    : 'inactiveButton'
                }
                disabled={!params.row?.letterOfAuthorizationUrl}
              >
                <Icon materialIcon="PictureAsPdf" size={28} />
              </Button>
            </Tooltip>
          </Container>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      width: 100,
    },
    {
      field: 'activeVehicles',
      headerName: 'Active Vehicles',
      sortable: true,
      sortComparator: numberComparator,
      width: 130,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 100,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {}}
          >
            Action
          </Button>
        )
      },
    },
  ],
}

const emailAccountList = {
  emailAccountColumn: [
    {
      field: 'carrierEmailAccountSettingId',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'senderName',
      headerName: 'Sender Name',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'senderEmail',
      headerName: 'Sender Email',
      width: 250,
      flex: 1,
    },
    {
      field: 'isVerified',
      headerName: 'Is Verified',
      renderCell: function (params: GridRenderCellParams) {
        return params.value ? <DoneIcon /> : <CloseIcon />
      },
      minWidth: 150,
      flex: 0.8,
    },
  ],
  actionList: [
    {
      name: 'edit',
      icon: 'icon_edit',
      iconSize: 40,
    },
    // {
    //   name: 'delete',
    //   icon: 'icon_delete',
    //   iconSize: 40,
    // },
  ],
}

export {
  dataGridSx,
  paymentsList,
  emailTemplateList,
  emailAccountList,
  userManagementList,
  searchCarrierList,
  myAccountsList,
  riskDashboard,
  assetActivityList,
}
