import request from 'tools/request'
import {
  dispatchCompanyProfile,
  dispatchWhiteLabelTheme,
} from 'actions/whitelabel'

export const getCompanyProfile = async (dispatch: any) => {
  const companyProfile = {
    colourCode: '#52a5ea',
    banner:
      'https://truckercloudv2.s3.us-east-2.amazonaws.com/production/coddocuments/banner_6682.png',
    logo: 'https://truckercloudv2.s3.us-east-2.amazonaws.com/production/coddocuments/6682.png',
    favicon:
      'https://truckercloudv2.s3.us-east-2.amazonaws.com/production/coddocuments/6682F.png',
    fullData: {},
  }
  const res: any = await request.get('publicresource/profile/getbycode')
  const data = res?.data?.companyProfile
  let resData = null
  if (data) {
    resData = {
      clourCode: data?.colourCode,
      logo: `${data?.logo}?${Math.floor(Math.random() * 10000000)}`,
      banner: `${data?.banner}?${Math.floor(Math.random() * 10000000)}`,
      favicon: `${data?.favicon}?${Math.floor(Math.random() * 10000000)}`,
      fullData: data,
    }
  }
  dispatch(dispatchCompanyProfile(resData || companyProfile))
  dispatch(
    dispatchWhiteLabelTheme({
      primary: res?.data?.companyProfile?.colourCode || '#52a5ea',
    })
  )
  return res
}

export const getCompanyDetails = async () => {
  const res = await request.get('companyresource/company/profileview')
  return res?.data
}

export const getWhitelabelDetails = async () => {
  const companyId = localStorage.getItem('companyId')
  const res = await request.get('companyprofileresource/profile/get', {
    params: {
      companyId,
    },
  })
  return res?.data
}

export const uploadCompanyFavIcon = async (formData: any) => {
  const companyId = localStorage.getItem('companyId')
  formData.append('companyId', companyId)
  const res = await request.post(
    'companyprofileresource/uploadProfileFavicon',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return res?.data
}

export const uploadBannerImage = async (formData: any) => {
  const companyId = localStorage.getItem('companyId')
  formData.append('companyId', companyId)
  const res = await request.post(
    'companyprofileresource/uploadProfileBanner',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return res?.data
}

export const uploadLogoImage = async (formData: any) => {
  const companyId = localStorage.getItem('companyId')
  formData.append('companyId', companyId)
  const res = await request.post(
    'companyprofileresource/uploadProfileLogo',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return res?.data
}

export const updateCompanyProfile = async (payLoad: any) => {
  const companyId = localStorage.getItem('companyId')
  payLoad['companyId'] = companyId
  const res = await request.post('companyprofileresource/profile/add', payLoad)
  return res?.data
}
