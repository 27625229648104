import { ReactNode } from 'react'
import { TypoGraph, Container, Button } from 'components'
import { IButtonProps } from 'components/button'
import styles from './header.module.scss'

interface IheaderProps {
  title?: string
  primaryBtnProps?: IButtonProps
  secondaryBtnProps?: IButtonProps
  dom?: ReactNode
  titleProps?: any
  mainContainerClass?: string
}

const HeaderBar: React.FC<IheaderProps> = (props) => {
  const {
    title,
    titleProps,
    mainContainerClass,
    primaryBtnProps,
    secondaryBtnProps,
    dom,
  } = props

  return (
    <Container className={`${styles.headerContainer} ${mainContainerClass}`}>
      {dom ? (
        dom
      ) : (
        <Container>
          <TypoGraph variant="h1" mb={0} {...titleProps}>
            {title ? title : '  '}
          </TypoGraph>
        </Container>
      )}
      <Container className={styles.headerAction}>
        {secondaryBtnProps && (
          <Button id={secondaryBtnProps.label} variant="outlined" {...secondaryBtnProps} />
        )}
        {primaryBtnProps && <Button id={primaryBtnProps.label} variant="contained" {...primaryBtnProps} />}
      </Container>
    </Container>
  )
}

export default HeaderBar
