import LocalizedStrings from 'react-localization'

interface ObjectString {
  [index: string]: any
}

let strings = new LocalizedStrings({
  en: {
    /**
     * Place all the strings here to reuse and to maintain the strings in the application
     */
    CREATE: 'Create',
    EDIT: 'Edit',
    DELETE: 'Delete',
    VIEW: 'View',
    UPDATE: 'Update',
    SAVE: 'Save',
    CLOSE: 'Close',
    STORY_BOOK: 'View Document',
    DEMO_TABLE_COMPONENT: 'Demo Table Component',
    PRIMARY_ACTION: 'Primary Action',
    SECONDARY_ACTION: 'Secondary Action',
    CONTROLLER_LIST: 'List of Controllers',
    INPUT_CONTROLLER: 'Input Field Controller',
    SINGLE_SELECT_CONTROLLER: 'Single Select Controller',
    CHECKBOX_CONTROLLER: 'Checkbox Controller',
    SETTINGS: 'Settings',

    USER_NAME: 'User Name',
    ADDRESS: 'Address',
    STATE: 'State',
    CITY: 'City',
    ZIP: 'Zip',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    MOBILE_NUMBER: 'Mobile Number',
    ROLE: 'Role',
    EMAIL_TEMPLATE: 'Email Template',

    PLEASE_ENTER_A_VALID_EMAIL: 'Please enter a valid email',

    COMPANY: 'Company',
    SESSION_HAS_TIMED_OUT: 'Session has timed out',
    PLEASE_RE_ENTER_YOUR_CREDENTIALS:
      'Please re-enter your credentials to login again.',
    ERROR_BOUNDRY_HEADER: 'We ran into a problem.',
    ERROR_BOUNDRY_BODY:
      'Try to refresh your browser or use your browser back button to go back to the previous page.',
    PAGE_404_TITLE: '404',
    PAGE_404_SUBHEADER: 'This page cannot be found',
    PAGE_404_MESSAGE: `The page you are looking for might have been removed,
         had its name changed, or is temporarily unavailable.`,
    PASSWORD_ERROR_FORMAT:
      'Password must be at least 8 characters, include at least one digit, one capital letter and at least one special character (@, $, !, #).',
    EMAIL_ERROR_FORMAT:
      'The email format is not correct.',
    LOGIN: 'Login',
    LOGOUT: 'Log Out',
    PROFILE: 'Profile',
    PAGE_UNDER_CONSTRUCTION: 'Page Under Construction',
    READ_BLOG: 'Read Our Blog',
    REVIEW_CHANGELOG: 'Review full changelog',
    EMAIL_ADRESS: 'Email Address',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    TRUCKER_CLOUD_TITLE:
      'The fastest way to connect ELD data to any application.',
    FORGOT_PASSWORD: 'Forgot Password?',
    RECOVER_PASSWORD: 'Recover Password',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    RESET_PASSWORD: 'Reset Password',
    BACKTO_LOGIN: 'Back to Login',
    NOT_REGISTERED: 'Not registered, and ',
    WANT_TO_SEE_DEMO: 'want to see a demo?',
    COMPANY_NAME: 'Company name',
    ADDRESS_1: 'Address 1',
    ADDRESS_2: 'Address 2',
    MENU: {
      DASHBOARD: 'Risk Dashboard',
      CARRIERS: 'Carriers',
      ACCOUNTS: 'My Accounts',
      REPORTING: 'Reporting',
      HELP: 'Help',
      SETTINGS: 'Settings',
      PROFILE: 'Profile',
      FLEET_REPORTING: 'Fleet Reporting',
    },
    ROUTES: {
      LOGIN: '/login',
      WIDGET: '/tcwidget/*',
      DASHBOARD: '/dashboard/*',
      CARRIERS: '/carriers/*',
      CARRIER_DETAILS: '/details',
      ACCOUNTS: '/accounts/*',
      REPORTING: '/reporting/*',
      FLEET_REPORTING: '/fleet-reporting',
      SETTINGS: '/settings/*',
      SETTINGS_COMPANY_CONFIG: '/company-config',
      SETTINGS_EMAIL_TEMPLATES: '/email-templates',
      SETTINGS_EMAIL_ACCOUNTS: '/email-accounts',
      SETTINGS_WHITE_LABEL: '/white-label',
      SETTINGS_USERS: '/users',
      SETTINGS_AGENTS: '/agents',
      SETTINGS_TAGS: '/tags',
      CHANGE_PASSWORD: '/password-change',
      PROFILE: '/profile/*',
      COB: '/cob/*',
      COB_DIRECT: '/cob/direct/*',
      COB_INSTALL: '/cob/install/*',
      COB_FV: '/cob/fv/*',
      CURRENT_PATH: '/',
      WILDCARD: '*',
    },
    CARRIERS: {
      CARRIERS: 'Carriers',
      INVITE_CARRIERS: 'Invite Carriers',
      MY_CARRIERS: 'My Carriers',
      INVITED_CARRIERS: 'Invited Carriers',
      EDIT_CARRIER: 'Edit Carrier',
      CARRIER_NAME: 'Carrier Name',
      DOT: 'DOT',
      MC: 'MC',
      SCAC: 'SCAC',
      UUID: 'UUID',
      CUID: 'CUID',
      POLICY_ID: 'Policy ID'
    },
    CARRIERS_MANAGEMENT: {
      MY_CONNECTED_ACCOUNTS: 'My Connected Accounts',
      INVITE_CARRIER: 'Invite Accounts',
      INVITATION_TITLE: 'Invite an Account',
      PLEASE_ENTER_A_VALID_EMAIL: 'Please enter a valid email for the account',
      CONFIRMATION_DIALOG_TITLE: 'Confirm Account Invitation',
      CONFIRMATION_DIALOG_MESSAGE: 'This operation will create an invitation link for the motor carrier, and the email you set can\'t be invited again. Are you sure you want to continue?',
      AGREE_BUTTON_TEXT: 'Agree',
      DISAGREE_BUTTON_TEXT: 'Disagree',
    },
    USER_MANAGEMENT: {
      USER_MANAGEMENT: 'User Management',
      USER: 'User',
      USERS: 'Users',
      CREATE_USER: 'Create User',
      EDIT_USER: 'Edit User',
      USER_INFORMATION: 'User Information',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
    },
    AGENT_MANAGEMENT: {
      AGENT_MANAGEMENT: 'Agent Management',
      AGENT: 'Agent',
      AGENTS: 'Agents',
      CREATE_AGENT: 'Create Agent',
      EDIT_AGENT: 'Edit Agent',
      AGENT_INFORMATION: 'Agent Information',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
      ASSIGN: 'Assign',
      UNASSIGN: 'Unassign',
      AVAILABLE_CARRIERS: 'Available Motor Carriers',
      ASSIGNED_CARRIERS: 'Assigned Motor Carriers'
    },
    TAG_MANAGEMENT: {
      TAG_MANAGEMENT: 'Tag Management',
      TAG: 'Tag',
      TAGS: 'Tags',
      CREATE_TAG: 'Create Tag',
      EDIT_TAG: 'Edit Tag',
      TAG_NAME: 'Tag Name',
      TAG_CATEGORY: 'Category',

      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      CONTENT: 'Content',
      ASSIGN: 'Assign',
      UNASSIGN: 'Unassign',
      AVAILABLE_CARRIERS: 'Available Motor Carriers',
      ASSIGNED_CARRIERS: 'Assigned Motor Carriers'
    },
    COMPANY_CONFIG: {
      COMPANY_NAME: 'Company Name',
      COMPANY_ID: 'Company ID',
      COMPANY_TYPE: 'Company Type',
      MC: 'MC',
      DOT: 'DOT',
      SCAC: 'SCAC',
      SUPPORT_EMAIL: 'Support Email',
      EMAIL_SUBSCRIPTION: 'Email Subscription',
      ADDRESS: 'Address',
      WEBSITE: 'Website',
      PHONE_NUMBER: 'Phone Number',
    },
    EMAIL_TEMPLATES: {
      EMAIL_TEMPLATES: 'Email Templates',
      NEW_TEMPLATE: 'New Template',
      TEMPLATE_NAME: 'Template Name',
      TEMPLATE_TYPE: 'Template Type',
      EMAIL_ACCOUNTS: 'Email Accounts',
      CONTENT: 'Content',
      EDIT_TEMPLATE: 'Edit Template',
      CREATE_TEMPLATE: 'Create Template',
    },
    EMAIL_ACCOUNTS: {
      EMAIL_ACCOUNTS: 'Email Accounts',
      NEW_ACCOUNT: 'New Account',
      SENDER_NAME: 'Sender Name',
      SENDER_EMAIL: 'Sender Email',
      EDIT_ACCOUNT: 'Edit Account',
      CREATE_ACCOUNT: 'Create Account',
    },
    WHITE_LABEL: {
      WHITE_LABEL: 'White Label',
    },
    PROFILE_STRING: {
      PROFILE: 'Profile',
      USER_NAME: 'User Name',
      USER_TYPE: 'User Type',
      COMPANY_NAME: 'Company Name',
      EMAIL: 'Email',
      PROFILE_DETAILS: 'Profile Details',
      PASSWORD: 'Password',
      NEW_PASSWORD: 'New Password',
      REQUEST_PASSWORD: 'Request Password Change',
      REQUEST_PASSWORD_INTRO: 'If you want to change your current password, press the button below and you will receive an email with a link to complete the process.',
      CONFIRM_PASSWORD: 'Confirm Password',
      CHANGE_PASSWORD: 'Change Password',
      PASSWORD_FORMAT:
        'Password must be at least 8 characters, including at least one digit and at least one special character (that is not a digit or letter).',
    },
    OFFBOARDING: {
      OFFBOARDING: 'Offboard',
      CONFIRMATION_DIALOG_TITLE: 'Confirm Carrier Offboarding',
      CONFIRMATION_DIALOG_MESSAGE: "You will no longer be able to access this carrier's information. Are you sure you want to continue?",
      AGREE_BUTTON_TEXT: 'Agree',
      DISAGREE_BUTTON_TEXT: 'Disagree',
    },
    REQUIRE_2FA: 'Require 2FA',
    MOBILE_2FA: 'Mobile 2FA',
    ALLOW_APP_SIGNUP: 'Allow App Sign up',
    CREATE_COMPANY: 'Create company',
    UPDATE_COMPANY: 'Update company',
    DELETE_COMPANY: 'Delete company',
  },
})

export const addStrings = (obj: object, lang: string = 'en') => {
  const original = strings
  strings.setContent({
    [lang]: mergeDeep(original, obj),
  })
  return strings
}

const isObject = (item: object | string) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep(source: ObjectString, target: ObjectString) {
  let output: ObjectString = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, {
            [key]: source[key],
          })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, {
          [key]: source[key],
        })
      }
    })
  }
  return output
}

export default strings
