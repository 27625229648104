import { ResponsiveLine } from '@nivo/line'

const MultiLineChart = ({ data, axisTitles /* see data tab */ }) => (
    <div style={{ height: '20rem' }}>
        <ResponsiveLine
            id="multilineChart"
            data={data}
            margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
            xScale={{ type: 'point' }}
            colors={['#8CC0E8', '#F9706B', '#FAE178', '#89EBB0']}
            // colors={{ scheme: 'nivo' }}
            theme={{
                fontSize: 13,
                textColor: '#000',
                axis: {
                    legend: {
                        text: {
                            outlineWidth: 1
                        }
                    }
                }
            }}
            curve="cardinal"
            yScale={{
                type: 'linear',
                min: '0',
                max: '100',
                stacked: false,
                reverse: false
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisTitles?.xAxis ?? 'Score Date',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValue: 20,
                legend: axisTitles?.yAxis ?? 'Index',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 60,
                    itemsSpacing: 30,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 14,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    </div>
)

export default MultiLineChart