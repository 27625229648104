import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { getCobList, onboardOauth } from 'api'
import { Container, Icon, TypoGraph } from 'components'
import {
  buildMotiveUrl,
  getCookiesValues,
} from 'pages/cob/partial/oauthHelper'
import { DeleteCookie, GetCookie, SetCookie } from 'tools/cookies'
import styles from './fvCob.module.scss'
import { reportException } from 'tools/logs'

interface IFvCobProps {}

const FvCob: React.FC<IFvCobProps> = () => {
  const [loading, setLoading] = useState(false)
  const [fvLoading, setFvLoading] = useState(false)
  const [fvInvitationApi, setFvInvitationApi] = useState(false)
  const [response, setResponse] = useState<any>({})
  const [apiCallFinished, setApiCallFinished] = useState(false)
  const [isFvOnboarding, setIsFvOnboarding] = useState(false)
  const [urlParams, setUrlParams] = useState<URLSearchParams>(new URLSearchParams(''))
  const search = useLocation().search

  const marketplaceOnboardingSeparator = '~'
  const cookies = getCookiesValues()
  const invitationKey: string = cookies?.invitationKey ?? ''
  const companyShortCode: string = GetCookie('companyShortCode', true) ?? ''
  let isValidCSRF: boolean = true
  let code: any = null
  let scope: any = null
  let state: any = null
  let identifier: any = null
  let identifierParts: any = null

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const hasValidUrlParams = (urlParams: any) => {
    return urlParams.get('identifier') && 
        urlParams.get('identifier').length > 0 && 
        urlParams.get('identifier').includes(marketplaceOnboardingSeparator) &&
        urlParams.get('code') &&
        urlParams.get('code').length > 0 &&
        (!urlParams.get('error') || 
        urlParams.get('error').length === 0);
  }

  const isValidRedirectCookie = (invitationToken: any) => {
    return invitationToken && 
        invitationToken.length > 0 && 
        invitationToken !== undefined && 
        invitationToken !== '';
  }

  const startFvFlow = (invitationToken: any) => {
    getCobList(invitationToken)
        .then((res: any) => {

          const selectedEld = res?.eldInfo?.find(
            (info: { eldProviderId: number }) => info.eldProviderId === Number(urlParams.get('eldProviderId')));

          const companyId = selectedEld?.oAuth[0]?.companyId
          const companyShortCode = res?.invitationInfo?.companyShortCode

          SetCookie('invitationKey', invitationToken, 0.1, true)
          SetCookie('companyShortCode', companyShortCode, 0.1, true)

          window.location.href = buildMotiveUrl(companyId, selectedEld)
          
        })
        .catch((error: any) => {
          reportException(error)
          console.log('Error on Invitation API')
          setFvInvitationApi(true)
          setFvLoading(false)
          setLoading(false)
          setResponse({
            status: error.status,
            message: error.message,
          })
        })
  }

  const callOnboardingEndpoint = () => {
    code = urlParams.get('code')
    identifier = urlParams.get('identifier')
    identifierParts = identifier.split(marketplaceOnboardingSeparator)
    const payLoad = {
      eldName: identifierParts[0],
      companyId: identifierParts[1],
      code: code,
    }

    return onboardOauth(payLoad)
            .then((res: any) => {
              console.log('----- SUCCESS! -----')
              setApiCallFinished(true);
              setLoading(false)
              setResponse({
                status: res.status,
                message: res.message,
                companyShortCode: res?.companyShortCode,
                invitationToken: res?.invitationToken,
              })
            })
            .catch((error: any) => {
              reportException(error)
              console.log('----- ERROR :( -----')
              setApiCallFinished(true);
              setLoading(false)
              setResponse({
                status: error.status,
                message: error.message,
              })
            })
  }

  useEffect(() => {
    if (search.length > 0) {
      setLoading(true);

      const url = (search.includes("?identifier=") === true && search.includes("?code=") === true) ? search.replace("?code=", "&code=") : search
      setUrlParams(new URLSearchParams(url))
    }
    else {
      console.log('No params found');
    }
  }, [search])

  useEffect(() => {
    if (urlParams !== null && urlParams !== undefined && urlParams.toString().length > 0) {
      if(!!urlParams.get('invitationToken')){
        setFvLoading(true)
        startFvFlow(urlParams.get('invitationToken'))
      }else{
        if (hasValidUrlParams(urlParams) === true) {
          code = urlParams.get('code');
          scope = urlParams.get('scope') ?? '';
          state = urlParams.get('state') ?? '';
  
          scope = decodeURIComponent(scope)
          state = decodeURIComponent(state)
  
          if (state !== "" && state.length > 0 && cookies.csrf !== null && cookies.csrf !== undefined) {
            if (state !== cookies.csrf) {
              isValidCSRF = false;
              console.log('Invalid CSRF state');
            }
          }
          else {
            console.log('No CSRF validation needed')
          }
  
          identifier = urlParams.get('identifier');
          if (isValidCSRF) {
            if (isValidRedirectCookie(invitationKey) === true && !!companyShortCode) {
              DeleteCookie('invitationKey', true);
              DeleteCookie('companyShortCode', true);
              window.location.href = window.location.protocol+"//"+ companyShortCode+ '.truckercloud.com/cob?invitationkey='+ invitationKey+'&code='+ code
            }
            else {
              identifierParts = identifier.split(marketplaceOnboardingSeparator);
              setIsFvOnboarding(true)
            }
          }
        }
        else {
          setLoading(false)
          setIsFvOnboarding(true)
          setApiCallFinished(true)
          setResponse({
            status: 'ERROR',
            message: 'Invalid onboarding parameters',
          })
        }
      }
      
    }
  }, [urlParams])

  useEffect(() => {
    if (apiCallFinished === false && isFvOnboarding === true) {
      if (!invitationKey || invitationKey === undefined) {
        callOnboardingEndpoint()
      }
      else {
        setLoading(false);
        setResponse({
          status: 'ERROR',
          message: 'It seems the installation process is broken. Please contact support@truckercloud.com for assistance.'
        });
      }
    }
  }, [isFvOnboarding, identifierParts, urlParams, apiCallFinished]);

  return (
    <Container className={styles.fvCobContainer}>
      <Container>
        {companyProfile?.favicon ? (
          <Icon
            type="images"
            url={companyProfile?.favicon}
            boxClassName="fullLogo"
            width={500}
            height={150}
          />
        ) : (
          <Icon name={'icon_logo'} width={200} height={30} />
        )}
      </Container>
      {loading === true && fvLoading === true && (
        <>
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
          <Container>
            <TypoGraph
              variant="h5"
              content="Redirecting"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="Please wait"
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {fvLoading === false && fvInvitationApi === true && response?.status !== 'OK' && (
        <>
          <Container>
            <Icon name="icon_dialog_warning" size={200} />
            <TypoGraph
              variant="h5"
              content="The Freight Visibility Onboarding redirect failed!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content={response?.message}
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {loading === true && fvLoading === false && (
        <>
          <Container className={styles.loader}>
            <CircularProgress />
          </Container>
          <Container>
            <TypoGraph
              variant="h5"
              content="Thank you for installing our application!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="We will finish the process for you please wait"
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {isFvOnboarding === true && apiCallFinished === true && response?.status !== 'OK' && loading === false && (
        <>
          <Container>
            <Icon name="icon_dialog_warning" size={200} />
            <TypoGraph
              variant="h5"
              content="The Freight Visibility Onboarding failed!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content={response?.message}
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
      {isFvOnboarding === true && apiCallFinished === true && response?.status === 'OK' && loading === false && (
        <>
          <Container>
            <Icon name="icon_dialog_success" size={200} />
            <TypoGraph
              variant="h5"
              content="The Freight Visibility Onboarding was successful!"
              sx={{ textAlign: 'center' }}
            />
            <TypoGraph
              variant="subtitle2"
              content="You can close the tab now."
              sx={{ textAlign: 'center' }}
            />
          </Container>
        </>
      )}
    </Container>
  )
}

export default FvCob
