import React, { useEffect, useState, useCallback } from 'react'
import {
    Container,
    Blade,
    DataGridTable,
    Toast as toast
} from 'components'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { carrierListTableColumns } from '../tagListTableData'
import styles from '../tagList.module.scss'
import strings from 'strings'
import { reportException } from 'tools/logs'
import { assignCarriersForTags, unassignCarriersForTags } from 'api'

const t = strings.TAG_MANAGEMENT

interface ICarriersForTagsProps {
    open: boolean
    currentTag: any
    motorCarriersForTags: any
    isAssignedCarriers: boolean
    loading: boolean
    onClose: () => void
}

const CarriersForTags: React.FC<ICarriersForTagsProps> = (props: ICarriersForTagsProps) => {
    const { open, currentTag, motorCarriersForTags, isAssignedCarriers, loading, onClose } = props
    const [btnLoader, setBtnLoader] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])

    useEffect(() => {
        setDisableBtn(selectedRows.length === 0);
    }, [selectedRows.length]);

    const handleClose = () => {
        setSelectedRows([])
        onClose()
    }

    const handleCheckboxSelection = useCallback((selections: GridRowSelectionModel) => {
        setSelectedRows(selections);
    }, []);

    const handleAssignUnassign = useCallback((action: any, tagId: any, payload: any) => {
        setBtnLoader(true);
        action(tagId, payload)
            .then((response: any) => toast({ id: `tagManagementToast`, title: response?.message }))
            .catch((error: any) => {
                reportException(error)
            })
            .finally(() => {
                setBtnLoader(false);
                handleClose();
            });
    }, [])

    const onSubmit = () => {

        const payLoad = {
            carrierEnrollmentIds: selectedRows
        }

        if (selectedRows.length === 0) {
            console.error('No rows selected');
            return;
        }

        if (isAssignedCarriers) {
            handleAssignUnassign(unassignCarriersForTags, currentTag, payLoad)
        } else {
            handleAssignUnassign(assignCarriersForTags, currentTag, payLoad)
        }
    }

    const MotorCarriersBlade = () => (
      <Container className={styles.bladeBodyContainer}>
        <Container className={styles.resultsContainer} sx={{ mt: 1 }}>
          <DataGridTable
            loading={loading}
            data={motorCarriersForTags || []}
            columns={carrierListTableColumns.columns}
            columnVisibilityModel={{ carrierEnrollmentId: false }}
            getRowId={(row) => row.carrierEnrollmentId}
            showFilter={true}
            multiSearchFilter={false}
            showTitle={false}
            checkboxSelection={true}
            reduceHeight={210}
            onSelectionModelChange={handleCheckboxSelection}
            customSelectionModel={selectedRows}
            isRowSelectable={(params) => {
                return !(
                  params.row?.dot === '-' &&
                  params.row?.mc === '-' &&
                  params.row?.scac === '-' &&
                  params.row?.uuid === '-' &&
                  params.row?.cuid === '-'
                );
              }}
          />
        </Container>
      </Container>
    )

    return (
        <Blade
            open={open}
            title={isAssignedCarriers ? t.ASSIGNED_CARRIERS : t.AVAILABLE_CARRIERS}
            onClose={onClose}
            bodyNode={<MotorCarriersBlade/>}
            size={'lg'}
            footerBtns={{
                primary: {
                    id: "carriersForTagsBlade",
                    label: isAssignedCarriers ? t.UNASSIGN : t.ASSIGN,
                    onClick: onSubmit,
                    loading: btnLoader,
                    disabled: btnLoader || disableBtn,
                },
                secondary: {
                    id: "closeCarriersForTagsBlade",
                    label: 'Cancel',
                    variant: 'outlined',
                    onClick: handleClose
                },
            }}
        />
    )
}

export default CarriersForTags