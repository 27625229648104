type AccessPrivilege = {
    domain: string;
    element: string;
    action: string;
}

const validateAccessPrivilege = (domain: string, element: string, action: string) : boolean => {
    if (!localStorage.getItem("accessPrivileges") || typeof localStorage.getItem("accessPrivileges") !== "string") {
        console.log('No privileges loaded.');
        return false;
    }

    const userPrivileges : any = localStorage.getItem("accessPrivileges") ?? []
    const accessPrivileges: AccessPrivilege[] = userPrivileges && typeof userPrivileges === "string" ? JSON.parse(userPrivileges) : [];

    return Array.isArray(accessPrivileges) ? accessPrivileges.some(
        (privilege: AccessPrivilege) => privilege.domain === domain && privilege.element === element && privilege.action === action) : false;
}

export default validateAccessPrivilege