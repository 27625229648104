import { isAgentLogin, isCarrierLogin } from 'tools/auth'
import request from 'tools/request'

export const getCarrierToken = async () => {
  const carrierToken: any = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL}authenticate`,
    {
      username: localStorage.getItem('userName'),
      password: localStorage.getItem('password'),
    }
  )
  return carrierToken?.data?.token
}

export const getRiskScores = async () => {
  const carrierToken: any = localStorage.getItem('authToken')
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores`,
    {
      headers: {
        Authorization: carrierToken,
        'Content-Encoding': 'gzip'
      },
    }
  )
  return res?.data
}

export const getRiskScoresWithAssets = async () => {
  const carrierToken: any = localStorage.getItem('authToken')
  const carrierCodeType: string = localStorage.getItem('carrierCodeType') ?? ""
  const carrierCodeValue: string = localStorage.getItem('carrierCodeValue') ?? ""
  if (carrierCodeType === "" || carrierCodeValue === "") {
    return []
  }
  const url = `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores?codeType=${carrierCodeType}&carrierCode=${carrierCodeValue}&showAssetDetails=true`
  const res = await request.get(
    url,
    {
      headers: {
        Authorization: carrierToken,
        'Content-Encoding': 'gzip'
      },
    }
  )
  return res?.data
}

export const getSupersetGuestToken = async (dashboardId: string, useCarrierFilters: boolean, useCeaList?: boolean) => {
  const carrierToken: any = localStorage.getItem('authToken')
  const isAgentOrCarrier =  isAgentLogin() || isCarrierLogin()
  const params: any = {
    companyId: isAgentOrCarrier ? localStorage.getItem('invitingCompanyId') : localStorage.getItem('companyId'),
    ...!isAgentOrCarrier && ({invitingCompanyId: localStorage.getItem('invitingCompanyId')}),
    dashboardId: dashboardId ?? process.env.REACT_APP_SUPERSET_ID
  };
  if (useCarrierFilters) {
    params.carrierId = localStorage.getItem('carrierId')
    params.carrierCodeType = localStorage.getItem('carrierCodeType')
    params.carrierCodeValue = localStorage.getItem('carrierCodeValue')
  }

  params.useCeaList = useCeaList ?? null

  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}superset/guest-token`,
    {
      headers: {
        Authorization: carrierToken,
        'Content-Encoding': 'gzip'
      },
      params: params
    }
  )

  return res?.data
}

export const getNewRiskScores = async () => {
  const carrierToken: any = localStorage.getItem('authToken')
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-dashboard`,
    {
      headers: {
        Authorization: carrierToken,
        'Content-Encoding': 'gzip'
      },
    }
  )

  return res?.data
}

export const getNewRiskScoresWithAssets = async () => {
  const carrierToken: any = localStorage.getItem('authToken')
  const params: any = {
    codeType: localStorage.getItem('carrierCodeType'),
    carrierCode: localStorage.getItem('carrierCodeValue'),
  }
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-by-assets`,
    {
      params: params,
    }
  )
  return res?.data
}

export const getSupersetGuestTokenWithoutRules= async (dashboardId: string) => {
  const carrierToken: any = localStorage.getItem('authToken')
  
  const res = await request.get(
      `${process.env.REACT_APP_CARRIER_API_URL}superset/guest-token`,
      {
        headers: {
          Authorization: carrierToken,
          'Content-Encoding': 'gzip'
        },
        params: {
          dashboardId: dashboardId ?? process.env.REACT_APP_SUPERSET_ID,
        }
      }
    )
  return res?.data
}

export const getPercentiles = async (codeType?: string|null, codeValue?: string|null) => {
  var endpointRoute = `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-carrier`
  const params: any = {
    codeType: codeType,
    codeValue: codeValue,
    useLabels: false,
  }
  const res = await request.get(endpointRoute, { params: params })
  return res?.data
}

export const getRiskScoresByAssetsLabelled = async (isCarrierLogin: boolean) => {
  const params: any = isCarrierLogin
    ? {
      codeType: 'TCID',
      carrierCode: localStorage.getItem('companyId'),
    }
    : {
      codeType: localStorage.getItem('carrierCodeType'),
      carrierCode: localStorage.getItem('carrierCodeValue'),
    }
  params.useLabels = false

  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-by-assets-labelled`,
    {
      params: params,
    }
  )
  return res?.data
}

export const getNewRiskScoresForAgent = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-dashboard-labelled`)
  return res?.data
}

export const getSubtagsForFilter = async (categoryIds: any) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-dashboard/references`,
    {
      params: {
        tagCategoryIds: categoryIds,
      }
    }
  )
  return res?.data
}

export const getRiskScoresFilteredByTag = async (tagIds: any) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}risk-scores-dashboard`,
    {
      params: {
        tagSubCategoryIds: tagIds,
      },
    }
  )
  return res?.data
}