import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronLeftRight from '@mui/icons-material/ChevronRight'
import Icon from 'components/icon'
import { DrawerHeader, MuiDrawer } from 'theme/themeOverride'
import styles from './menuBar.module.scss'
import { TypoGraph, Tooltip, Popover, Divider } from 'components'
import colors from 'theme/colors'
import { isAgentLogin, isCarrierLogin } from 'tools/auth'
import insuranceMenuBar from 'constants/menuBar'
import carrierMenuBar from 'constants/menuBarCarrier'
import agentMenuBar from 'constants/menuBarAgent'
import { ClearCookies } from 'tools/cookies'

interface ImenuBarProps {
  path: string
  navigate: Function
}

interface ImenuBarList {
  name: string
  icon?: string
  muiIcon?: string
  id: string
  url?: string
}

interface ImenuListItem {
  data: ImenuBarList[]
  open: boolean
  selectedItem: string
  handleMenuOnclick: Function
  className?: string
}

const MenuListItem: React.FC<ImenuListItem> = (props) => {
  const { data, open, selectedItem, handleMenuOnclick, className } = props
  return (
    <List className={className ? className : styles.menuList}>
      {data.map((item: ImenuBarList) => (
        <Tooltip key={item.id} title={item.name} placement="right">
          <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                height: 50,
                justifyContent: open ? 'initial' : 'center',
                borderRadius: 2,
                marginTop: 1,
                marginBottom: 1,
              }}
              id={`lateralMenu-${item.id}`}
              selected={selectedItem === item.id}
              onClick={(e) =>
                item?.url
                  ? window.open(item?.url)
                  : handleMenuOnclick(e, item.id)
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon ?
                <Icon
                  name={item.icon}
                  width={25}
                  height={25}
                  boxClassName={selectedItem === item.id ? styles.active : ''}
                />
                :
                <Icon
                  materialIcon={item.muiIcon}
                  width={25}
                  height={25}
                  boxClassName={selectedItem === item.id ? styles.active : ''}
                />
                }
              </ListItemIcon>
              <TypoGraph
                variant="body1"
                mb={0}
                color={selectedItem === item.id ? colors.primary : 'default'}
              >
                {item.name}
              </TypoGraph>
            </ListItemButton>
          </ListItem>
        </Tooltip>
      ))}
    </List>
  )
}

const MenuBar: React.FunctionComponent<ImenuBarProps> = (props) => {
  const { path, navigate } = props
  const [selectedItem, setSelectedItem] = useState('companies')
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )
  
  const getMenuBar = () => {
    if (isCarrierLogin()) return carrierMenuBar()
    if (isAgentLogin()) return agentMenuBar()
    return insuranceMenuBar()
  };

  const { list1, list2 } = getMenuBar()

  /*
   * This useffect will trigger whenever the current url path changes
   * It will check the current path and menubar list id matches once it matched,
   * It will set the active menuBarItem to hightlight.
   */
  useEffect(() => {
    if (list1 && list2) {
      let findItemInList1: ImenuBarList | undefined
      let findItemInList2: ImenuBarList | undefined
      findItemInList1 = list1.find((ele: ImenuBarList) => {
        return path.includes(ele.id) ? ele : null
      })
      findItemInList2 = list2.find((ele: ImenuBarList) => {
        return path.includes(ele.id) ? ele : null
      })

      const findItemId = findItemInList1 || findItemInList2
      if (findItemId) {
        setSelectedItem(findItemId.id)
      }
    }
  }, [path, list1, list2])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const handleMenuOnclick = (e: any, path: String) => {
    if (path === 'profile') {
      setAnchorEl(e.currentTarget)
    } else {
      navigate(path)
    }
  }

  const openCloseIconEvent = {
    onMouseOver: () => setShowCloseIcon(true),
    onMouseLeave: () => {
      setTimeout(() => {
        if (!showCloseIcon) {
          setShowCloseIcon(false)
        }
      }, 800)
    },
    onMouseOut: () => setShowCloseIcon(false),
  }

  const onLogoutPressed = () => {
    ClearCookies(true)
    navigate('/login')
  }

  return (
    <div {...openCloseIconEvent}>
      {showCloseIcon && (
        <Icon
          boxClassName={`${styles.leftIconButton} ${
            !open && styles.rightIconButton
          }`}
          onClick={handleDrawerOpen}
        >
          {open ? (
            <ChevronLeftIcon sx={{ color: 'black' }} />
          ) : (
            <ChevronLeftRight sx={{ color: 'black' }} />
          )}
        </Icon>
      )}
      <MuiDrawer variant="permanent" open={open}>
        <DrawerHeader>
          {companyProfile?.logo && companyProfile?.favicon ? (
            <Icon
              type="images"
              url={open ? companyProfile?.logo : companyProfile?.favicon}
              boxClassName="fullLogo"
              width={200}
              height={50}
            />
          ) : (
            <Icon
              name={open ? 'icon_logoFullname' : 'icon_logo'}
              width={200}
              height={30}
            />
          )}
        </DrawerHeader>
        <MenuListItem
          open
          data={list1}
          selectedItem={selectedItem}
          handleMenuOnclick={handleMenuOnclick}
        />
        <MenuListItem
          open
          data={list2}
          selectedItem={selectedItem}
          handleMenuOnclick={handleMenuOnclick}
          className={styles.stickyMenuList}
        />
      </MuiDrawer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              id={'profile'}
              sx={{ padding: '1rem' }}
              onClick={() => {
                setAnchorEl(null)
                navigate('/profile')
              }}
            >
              <ListItemIcon>
                <Icon name="icon_profile" size={27} />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          <Divider rootSx={{ margin: 0 }} />
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              id={'logout'}
              sx={{ padding: '1rem' }}
              onClick={onLogoutPressed}
            >
              <ListItemIcon>
                <Icon name="icon_logout" size={27} />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  )
}

export default MenuBar
