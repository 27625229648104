export const SET_THEME = 'SET_THEME'
export const COMPANY_PROFILE = 'COMPANY_PROFILE'

/**
 * Dispatch an action to reducer and changes the state values
 */
export const dispatchWhiteLabelTheme = (data: {primary: string}) => ({
    type: SET_THEME,
    data,
})

export const dispatchCompanyProfile = (data: any) => ({
    type: COMPANY_PROFILE,
    data
})
