import React from 'react'
import { Route, Routes, RouteProps } from 'react-router-dom'
import Page404 from 'pages/page404'
import ConnectedAccounts from 'pages/connectedAccounts'
import strings from 'strings'

const AccountsRoutes: React.FunctionComponent<RouteProps> = () => {
  return (
    <Routes>
      <Route path={strings.ROUTES.CURRENT_PATH} element={<ConnectedAccounts />} />
      <Route path={strings.ROUTES.WILDCARD} element={<Page404 />} />
    </Routes>
  )
}

export default AccountsRoutes
