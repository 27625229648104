import { Container, Icon, TypoGraph } from 'components'
import Button, { IButtonProps } from 'components/button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

interface ISubmitDialogProps {
  cancelId:string
  open: boolean
  primaryBtnProps?: IButtonProps
  secondaryBtnProps?: IButtonProps
  type?: 'success' | 'warning' | 'delete'
  title?: string
  body1?: string
  body2?: string
  showCancel?: boolean
  handleClose: () => void
}

const SubmitDialog: React.FC<ISubmitDialogProps> = (props) => {
  const {
    cancelId,
    open,
    handleClose,
    primaryBtnProps,
    secondaryBtnProps,
    type = 'success',
    title = 'Confirmation',
    showCancel = true,
    body1,
    body2,
  } = props
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Icon name={`icon_dialog_${type}`} height={150} width={300} />
        <TypoGraph
          mt={2}
          mb={2}
          content={body1}
          variant="body1"
          align="center"
        />
        <TypoGraph content={body2} variant="body2" align="center" />
      </DialogContent>
      <DialogActions>
        <Container
          sx={{
            gridTemplateColumns: 'auto auto',
            justifyContent: 'end',
            gridGap: '1rem',
            padding: '12px 20px',
          }}
        >
          {showCancel && (
            <Button
                id={cancelId}
              label="Cancel"
              variant="text"
              onClick={handleClose}
              color="inherit"
              {...secondaryBtnProps}
            />
          )}
          {primaryBtnProps && (
            <Button
              color={type === 'delete' ? 'error' : 'primary'}
              {...primaryBtnProps}
            />
          )}
        </Container>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitDialog
