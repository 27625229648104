import React, { ReactNode } from 'react'
import Button, { IButtonProps } from '../button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

export interface IModalProps {
  open: boolean
  primaryBtnProps?: IButtonProps
  secondaryBtnProps?: IButtonProps
  title?: string
  body?: ReactNode
  dom?: ReactNode
  handleClose?: () => void
  size?: 'lg' | 'sm' | 'md' | 'xl' | 'xs'
  showCancel?: boolean
  showPrimary?: boolean
  fullWidth?: boolean
  dismissOnClose?: boolean
  className?: string
  backdropFused?: boolean
}

const Modal: React.FC<IModalProps> = (props) => {
  const {
    open,
    handleClose,
    dismissOnClose = true,
    primaryBtnProps,
    secondaryBtnProps,
    title = 'Title',
    body,
    dom,
    size = 'md',
    showCancel = true,
    showPrimary = true,
    fullWidth = true,
    className = '',
    backdropFused = false,
  } = props
  return (
    <Dialog
      open={open}
      onClose={dismissOnClose ? handleClose : undefined}
      maxWidth={size}
      fullWidth={fullWidth}
      disableEscapeKeyDown={true}
      className={className}
      sx={backdropFused ? {
         '& .MuiBackdrop-root': {backgroundColor: 'white', opacity: 1}, // Backdrop on white
         '& .MuiPaper-root': {boxShadow:'none'},  // Remove shadow
      } : {}}
    >
      {dom ? (
        dom
      ) : (
        <>
          <DialogTitle>{title}</DialogTitle>
          {body}
          <DialogActions>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                justifyContent: 'end',
                gridGap: '10px',
                padding: '12px 20px',
              }}
            >
              {showCancel && (
                <Button
                  label="Cancel"
                  variant="text"
                  onClick={handleClose}
                  {...secondaryBtnProps}
                />
              )}
              {showPrimary && (
                <Button label="OK" color="primary" {...primaryBtnProps} />
              )}
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default Modal
