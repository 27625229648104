import { embedDashboard } from '@superset-ui/embedded-sdk';
import { Card, Container, TypoGraph } from 'components';
import React, { useCallback, useEffect, useRef } from 'react';
import './heartbeat.module.scss';
import { getSupersetGuestToken } from 'api/dashboard';
import { Box } from '@mui/material';
import { reportException } from 'tools/logs';

interface IHeartbeatProps {
  print?: boolean
  useCarrierFilters: boolean
}

const HeartbeatDashboard: React.FC<IHeartbeatProps> = (props) => {
  const { useCarrierFilters } = props
  const localStorageDashboardId = localStorage.getItem('embeddedDashboardIds') ?? JSON.stringify([])
  const heartbeatDashboardId = JSON.parse(localStorageDashboardId)
  const dashboardId: string = heartbeatDashboardId?.HEARTBEAT ?? process.env.REACT_APP_SUPERSET_HEARTBEAT_ID ?? ""
  const dashboardUrl: string = process.env.REACT_APP_SUPERSET_URL ?? ""
  const hasValidData: boolean = (useCarrierFilters
    && !!localStorage.getItem('carrierCodeType') && localStorage.getItem('carrierCodeType') != ""
    && !!localStorage.getItem('carrierCodeValue') && localStorage.getItem('carrierCodeValue') != "")
    || !useCarrierFilters

  const tokenPromiseRef = useRef<Promise<string> | null>(null);

  const getGuestToken = useCallback(() => {
    if (!tokenPromiseRef.current) {
      tokenPromiseRef.current = getSupersetGuestToken(dashboardId, useCarrierFilters)
        .then((response: any) => response.token)
        .catch((error:any) => {
          reportException(error)
          throw error;
        })
    }
    return tokenPromiseRef.current;
  }, [dashboardId, useCarrierFilters]);

  const dashboardEmbedded = useRef(false);

  useEffect(() => {
    if (hasValidData && !dashboardEmbedded.current) {
      embedDashboard({
        id: dashboardId,
        supersetDomain: dashboardUrl,
        mountPoint: document.getElementById("setContainer") as HTMLElement,
        debug: true,
        fetchGuestToken: getGuestToken,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
          },
        },
      })
      dashboardEmbedded.current = true;
    }
  }, [dashboardEmbedded, getGuestToken])

  return (
    <>
      <TypoGraph
        variant="h1"
        sx={{ marginRight: '1rem', marginY: '1rem' }}
        mb={0}
        content={"Carrier Heartbeat for " + localStorage.getItem('carrierName')}
      />
      <Card>
        <Container>
          {hasValidData ?
            <div id="setContainer" style={{ width: '90vw', height: '75vh' }}>
            </div>
            :
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 'auto', height: '75vh' }}>
              <h5>No data</h5>
            </Box>
          }
        </Container>
      </Card>
    </>
  )
}
export default HeartbeatDashboard