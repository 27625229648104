const rbacDomains = {
    RISK_DASHBOARD: "RISK",
    CARRIERS: "CARRIERS",
    ACCOUNTS: "ACCOUNTS",
    REPORTING: "REPORTING",
    SETTINGS: "SETTINGS",
    PROFILE: "PROFILE",
    FLEET_REPORTING: "FLEET_REPORTING"
}

export default rbacDomains