import { Tabs as MuiTabs } from '@mui/material'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { Container } from 'components'
import styles from './tab.module.scss'

export interface ITabData {
  id?:string
  label: string
  badgeColor?: string
  badgeContent?: string
  filterValue?: string
}

interface ITabsProps {
  id?:string
  tabData: ITabData[]
  value: number
  iconPosition?: 'top' | 'start' | 'end' | 'bottom'
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  sxContainer?: any
}

interface IMuiTabProps {
  id?:string
  label: string
  value: number | string
  key: string
  iconPosition?: 'top' | 'start' | 'end' | 'bottom'
  icon?: any
}

const MuiTab = styled((props: IMuiTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: 500,
  width: 'auto',
  minHeight: '45px',
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
}))

const Tabs: React.FC<ITabsProps> = (props) => {
  const {  id,tabData, value, handleChange, iconPosition = 'end', sxContainer } = props
  return (
    <Container sx={{ position: 'relative', ...sxContainer }}>
      <MuiTabs value={value} onChange={(e, val) => handleChange(e, val)}>
        {tabData?.map((item, index) => (
          <MuiTab
              id={id}
            key={`key-${index}`}
            icon={
              item.badgeColor && (
                <Container
                  className={styles.badge}
                  sx={{ background: item.badgeColor }}
                >
                  12
                </Container>
              )
            }
            value={index}
            label={item.label}
            iconPosition={iconPosition}
          />
        ))}
      </MuiTabs>
    </Container>
  )
}

interface ITabpanelProps {
  id?:string
  children: React.ReactNode
  value: number
  index: number
}

const TabPanel: React.FC<ITabpanelProps> = (props) => {
  const { id,children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export { Tabs, TabPanel }
