import { useEffect, useState } from 'react'
import { getRiskScoresByAssetsLabelled } from 'api'
import {
  Container,
  DataGridTable,
  HeaderBar,
  Blade,
  TypoGraph,
  Divider,
  Chip,
} from 'components'
import moment from 'moment'
import styles from './fleetDetailsForAgent.module.scss'
import strings from 'strings'
import { useGridApiRef } from '@mui/x-data-grid'
import fleetDetailsTableData from './fleetDetailsForAgentTableData'
import { isCarrierLogin } from 'tools/auth'

const t = strings.CARRIERS

interface IFleetDetailsProps {
  print?: boolean
}

const FleetDetailsForAgents: React.FC<IFleetDetailsProps> = (props) => {
  const { print } = props
  const [loading, setLoading] = useState(false)
  const [showAssetDetails, setShowAssetDetails] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [responseData, setResponseData] = useState<any>()
  const [riskAssetData, setRiskAssetData] = useState<any>([])
  const { column, groups } = fleetDetailsTableData
  const apiRef = useGridApiRef()
  const isCarrier = isCarrierLogin()

  useEffect(() => {
    setLoading(true)
    getRiskScoresByAssetsLabelled(isCarrier)
      .then((res: any) => {
        setResponseData(res)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (responseData) {
      setRiskAssetData(responseData.assetsDetails)
    }
  }, [responseData])

  const loadPagination = (pageNumber: number, pageSize: number) => {
    console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
  }

  const handleRiskExport = () => {
    const csvString = apiRef.current.getDataAsCsv()
    const csvRows = csvString.split('\n')
    const modifiedCsv = csvRows.slice(1).join('\n')
    const blob = new Blob([modifiedCsv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'AssetsDataExport.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  const tableMenuItems = [
    {
      label: 'Download as CSV',
      key: 'export',
      action: () => {
        handleRiskExport()
      },
    },
  ]

  const BladeContent = (_props: any) => {
    return (
      <Container className={styles.bladeBodyContainer}>
        <Container className={styles.bladeTabContainer}>

        </Container>
        <Container className={styles.documentsSection}>
          <TypoGraph variant="h6" content="Documents" />
          <Divider />

        </Container>
      </Container>
    )
  }

  const HeaderDom = () => {
    return (
      <Container className={styles.headerContainer}>
        <Container className={styles.headerTitleContainer}>
          <TypoGraph
            variant="h1"
            sx={{ marginRight: '1rem' }}
            mb={0}
            content={localStorage.getItem('carrierName') ?? localStorage.getItem('companyName') ?? 'Carrier Details'}
          />
          <Chip
            label={`Updated ${moment().format('dddd, MMMM Do')}`}
            variant="filled"
            sx={{
              backgroundColor: '#E1FAEB',
              color: '#20693D',
            }}
          />
        </Container>
      </Container>
    )
  }

  return (
    <Container className={styles.agentsBladeBodyContainer}>
      <Container>
        <HeaderBar dom={<HeaderDom />} />
        <TypoGraph
          variant="h2"
          sx={{ marginRight: '1rem', marginTop: '-15px', marginBottom: '1rem' }}
          mb={0}
          content={localStorage.getItem('eldProviderRisk') ?? ''}
        />
        {/* Asset Activity & Scoring */}
        <DataGridTable
          apiRef={apiRef}
          title={'Asset Activity & Scoring'}
          loading={loading}
          data={riskAssetData || []}
          columns={column}
          columnGroupingModel={groups}
          columnHeaderHeight={30}
          columnVisibilityModel={{ assetId: false }}
          totalCount={riskAssetData?.length}
          reduceHeight={342}
          checkboxSelection={false}
          multiSearchFilter={false}
          getRowId={(row: any) => row?.assetId + "-" + row?.score}
          handlePagination={(pageNumber, pageSize) =>
            loadPagination(pageNumber, pageSize)
          }
          menuList={tableMenuItems}
        />
        <Blade
          title={selectedRow?.assetEldId}
          open={showAssetDetails}
          onClose={() => setShowAssetDetails(false)}
          bodyNode={<BladeContent />}
        />
      </Container>
    </Container>
  )
}

export default FleetDetailsForAgents