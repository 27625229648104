import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import CheckBoxField, { ICheckBoxProps } from 'components/controllers/checkBox'

interface ICheckboxFieldProps extends ICheckBoxProps {
    id?:string
  name: string
  required?: boolean
}

const CheckboxFormField: React.FC<ICheckboxFieldProps> = (props) => {
  const { id,name, required = false } = props
  const { control } = useFormContext()
  const isRequired = required ? 'Field is Required' : undefined
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <>
            <CheckBoxField
              {...props}
              value={value ? Boolean(value) : false}
              onChange={onChange}
              inputRef={ref}
            />
            <ErrorMessage errors={errors} name={name} />
          </>
        )
      }}
    />
  )
}

export default CheckboxFormField
