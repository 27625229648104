interface IInvitedAccountsProps {
    
}

const InvitedAccounts: React.FC<IInvitedAccountsProps> = (props) => {

    return (
        <></>
    )
}

export default InvitedAccounts