import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import jwt_Decode from 'jwt-decode'
import { createTheme } from '@mui/material/styles'
import { CssBaseline, Box, CircularProgress } from '@mui/material'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router'
import ApplicationRoutes from 'routes'
import { getCompanyProfile } from 'api'
import { lightTheme, themeSettings } from 'theme/themeOverride'
import {
  Container,
  BreadCrumbs,
  ToastContainer,
  Toast as toast,
} from 'components'
import MenuBar from './menuBar'
import Login from 'pages/loginPage'
import COB from 'pages/cob'
import TcWidget from 'pages/tcwidget'
import DirectCob from 'pages/directCob'
import InstallCob from 'pages/installCob'
import colors from 'theme/colors'
import { getContainerHemmet } from 'tools/variants'
import strings from 'strings'
import { ClearCookies, GetCookie, SetCookie } from 'tools/cookies'
import FvCob from 'pages/fvCob'
import {createPendoLoginData} from 'tools/pendo'
import { isAgentLogin, isCarrierLogin } from 'tools/auth'
import { getAgentMenuAccess, getCarrierMenuAccess, getInsuranceMenuAccess } from 'rbac'
import AppLogin from 'pages/loginPage'

const defineIsAuth = (path: any) => {
  const directAccessPaths = [strings.ROUTES.COB, strings.ROUTES.WIDGET, strings.ROUTES.COB_DIRECT]
  if (directAccessPaths.includes(path)) {
    // Saving OAuth and Canal cookies before localStorage clear
    const cookies = {
      eldIndex: GetCookie('eldIndex', true) ?? "",
      invitationKey: GetCookie('invitationKey', true) ?? "",
      eldDataSharing: GetCookie('eldDataSharing', true) ?? "",
      eldVendor: GetCookie('eldVendor', true) ?? "",
      csrf: GetCookie('csrf', true) ?? "",
      canalUrl: GetCookie('canalUrl', true) ?? "",
      canalState: GetCookie('canalState', true) ?? "",
      carrierName: GetCookie('carrierName', true) ?? "",
      eldProvider: GetCookie('eldProvider', true) ?? "",
      scac: GetCookie('scac', true) ?? "",
      policyId: GetCookie('policyId', true) ?? "",
    }
    ClearCookies(true)
  
    // Restoring OAuth and Companies cookies
    if (cookies.eldIndex !== null && cookies.eldIndex !== "") SetCookie('eldIndex', cookies.eldIndex, 0.1, true)
    if (cookies.invitationKey !== null && cookies.invitationKey !== "") SetCookie('invitationKey', cookies.invitationKey, 0.1, true)
    if (cookies.eldDataSharing !== null && cookies.eldDataSharing !== "") SetCookie('eldDataSharing', JSON.stringify(JSON.parse(cookies.eldDataSharing)), 0.1, true)
    if (cookies.eldVendor !== null && cookies.eldVendor !== "") SetCookie('eldVendor', cookies.eldVendor, 0.1, true)
    if (cookies.csrf !== null && cookies.csrf !== "") SetCookie('csrf', cookies.csrf, 0.1, true)
    if (cookies.canalUrl !== null && cookies.canalUrl !== "") SetCookie('canalUrl', cookies.canalUrl, 0.1, true)
    if (cookies.canalState !== null && cookies.canalState !== "") SetCookie('canalState', cookies.canalState, 0.1, true)
    if (cookies.carrierName !== null && cookies.carrierName !== "") SetCookie('carrierName', cookies.carrierName, 0.1, true)
    if (cookies.eldProvider !== null && cookies.eldProvider !== "") SetCookie('eldProvider', cookies.eldProvider, 0.1, true)
    if (cookies.scac !== null && cookies.scac !== "") SetCookie('scac', cookies.scac, 0.1, true)
    if (cookies.policyId !== null && cookies.policyId !== "") SetCookie('policyId', cookies.policyId, 0.1, true)

    return false
  }
  const token = localStorage.getItem('authToken')

  if (token === null) {
    return false
  }

  const decodedToken: any = jwt_Decode(token)

  const currentTime = Math.floor(Date.now() / 1000)
  const remainingTime = decodedToken.exp - currentTime
  if (remainingTime < 0) {
    return false
  }
  return true
}

const getMenuPrivileges = (isAuth: boolean) => {
  if (!isAuth) return null
  if (isCarrierLogin()) return getCarrierMenuAccess()
  if (isAgentLogin()) return getAgentMenuAccess()
  return getInsuranceMenuAccess()
};

const AppContainer: React.FunctionComponent = () => {
  const location = useLocation()
  const isAuth = defineIsAuth(location.pathname)
  const menuPrivileges = getMenuPrivileges(isAuth)
  const navigate = useNavigate()
  const [path, setPath] = useState(location.pathname)
  const [loading, setLoading] = useState(false)
  const primaryColor = useSelector(
    (state: any) => state?.whiteLabel?.theme?.primary
  )
  const companyProfile = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const dispatch = useDispatch()

  if (primaryColor) {
    colors.primary = primaryColor
    colors.secondary = primaryColor
    // Accessing scss variable "--primary"
    // and "--secondary" using plain JavaScript
    // and changing the same according to the state of "primaryColor"
    const root = document.documentElement
    root?.style.setProperty('--primary', primaryColor)
    root?.style.setProperty('--secondary', primaryColor)
  }

  const customTheme = createTheme({
    ...themeSettings,
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: primaryColor,
      },
    },
  })

  useEffect(() => {
    setLoading(true)
    getCompanyProfile(dispatch).finally(() => setLoading(false))
  }, [dispatch])

  useEffect(() => {
    createPendoLoginData(isAuth);
    const interval = setInterval(() => {
      const token = localStorage.getItem('authToken')
      if (token != null) {
        const decodedToken: any = jwt_Decode(token)
        const currentTime = Math.floor(Date.now() / 1000)
        const remainingTime = decodedToken.exp - currentTime
        if (remainingTime < 0) {
          localStorage.removeItem('authToken')
          navigate('/*')
          toast({
            title: strings.SESSION_HAS_TIMED_OUT,
            subTitle: strings.PLEASE_RE_ENTER_YOUR_CREDENTIALS,
          })
        }
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [isAuth, navigate])

  useEffect(() => {
    setPath(location.pathname)
  }, [location.pathname])

  return (
    <Container>
      {getContainerHemmet(companyProfile)}
      {loading ? (
        <Container className="containerLoader">
          <CircularProgress />
        </Container>
      ) : (
        <>
          <ToastContainer />
          {isAuth ? (
            <Box sx={{ display: 'flex' }}>
              <ThemeProvider theme={primaryColor ? customTheme : lightTheme}>
                <CssBaseline />
                <MenuBar path={path} navigate={navigate} />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    p: 2,
                    margin: 'auto',
                    position: 'relative',
                  }}
                >
                  <BreadCrumbs path={path} />
                  <ApplicationRoutes 
                    isAuth={isAuth}
                    menuAccess={menuPrivileges}
                  />
                </Box>
              </ThemeProvider>
            </Box>
          ) : (
            <ThemeProvider theme={primaryColor ? customTheme : lightTheme}>
              <Routes>
                <Route path={strings.ROUTES.LOGIN} element={<Login />} />
                <Route path={strings.ROUTES.CHANGE_PASSWORD} element={<AppLogin currentStep={2} />} />
                <Route path={strings.ROUTES.COB} element={<COB />} />
                <Route path={strings.ROUTES.WIDGET} element={<TcWidget />} />
                <Route path={strings.ROUTES.COB_DIRECT} element={<DirectCob />} />
                <Route path={strings.ROUTES.COB_INSTALL} element={<InstallCob />} />
                <Route path={strings.ROUTES.COB_FV} element={<FvCob />}/>
                <Route path="*" element={<Navigate to={strings.ROUTES.LOGIN} />} />
              </Routes>
            </ThemeProvider>
          )}
        </>
      )}
    </Container>
  )
}

export default AppContainer
