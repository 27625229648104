const isPendoEnabled = process.env.REACT_APP_PENDO_ENABLED === 'true';

const createPendoOnboardingData = (data: any) => {
    if (isPendoEnabled) {
        if (data != null) {
            const initialData = {
                visitor: {
                    id: data?.invitationInfo.invitingUserId,
                },
                account: {
                    id: data?.invitationInfo.invitingCompanyId,
                    companyName: data?.invitationInfo.invitingCompanyName,
                    email: data?.invitationInfo.invitingUserEmail,
                }
            }
            initializePendo(initialData.visitor, initialData.account);
        }
    }
}
const createPendoLoginData = (isAuth: boolean) => {
    if (isPendoEnabled) {
        if (isAuth) {
            const initialData = {
                visitor: {
                    id: localStorage.getItem('userId'),
                    isInsurance: localStorage.getItem('isInsurance'),
                    userTypeId: localStorage.getItem('userTypeId'),
                },
                account: {
                    id: localStorage.getItem('companyId'),
                    companyName: localStorage.getItem('companyName'),
                }
            }
            initializePendo(initialData.visitor, initialData.account);
        }
    }
}

const createPendoWidgetData = (data: any) => {
    if (isPendoEnabled) {
        const initialData = {
            visitor: {
                id: data?.createdBy,
            },
            account: {
                id: data?.companyId,
            }
        }
        initializePendo(initialData.visitor, initialData.account);
    }
}

const initializePendo = (visitor: any, account: any) => {
    if (isPendoEnabled) {
        const pendoScript = (window as any).pendo;
        if (typeof pendoScript !== 'undefined') {
            if (!pendoScript.isReady()) {
                pendoScript.initialize({
                    visitor,
                    account,
                });
            }
            else {
                pendoScript.updateOptions({
                    visitor,
                    account,
                });
            }
        } else {
            console.error('Could not load Pendo');
        }
    }
};

export { createPendoLoginData, createPendoOnboardingData, createPendoWidgetData, initializePendo }