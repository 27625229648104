import { useEffect, useState } from 'react'
import { getNewRiskScoresWithAssets } from 'api'
import {
    Container,
    DataGridTable,
    HeaderBar,
    Blade,
    Icon,
    TypoGraph,
    Divider,
    Card,
    MultiLineChart,
    Chip,
    Spinner,
  } from 'components'
import { assetActivityList } from 'constants/tableData'
import moment from 'moment'
import styles from './insuranceCarriers.module.scss'
import colors from 'theme/colors'
import strings from 'strings'
import { forEach } from 'lodash'
import { useGridApiRef } from '@mui/x-data-grid'

const t = strings.CARRIERS

interface IInsuranceCarrierProps {
    print?: boolean
}

interface ISummaryData {
    totalAssets: number;
    totalAssetsPrev: number;
    totalAssetsVariation: number;
    riskIndex: number;
    riskIndexPrev: number;
    riskVariation: number;
    geographicIndex: number;
    geographicIndexPrev: number;
    geographicVariation: number;
    speedIndex: number;
    speedIndexPrev: number;
    speedVariation: number;
    harshEventsIndex: number;
    harshEventsIndexPrev: number;
    harshEventsVariation: number;
}

const InsuranceCarriers: React.FC<IInsuranceCarrierProps> = (props) => {
    const { print } = props
    const [loading, setLoading] = useState(false)
    const [showAssetDetails, setShowAssetDetails] = useState(false)
    const [selectedRow, setSelectedRow] = useState<any>()
    const [responseData, setResponseData] = useState<any>()
    const [riskData, setRiskData] = useState<any>([])
    const [riskAssetData, setRiskAssetData] = useState<any>([])
    const [fleetPerformanceData, setFleetPerformanceData] = useState<any>([])
    const [summaryData, setSummaryData] = useState<ISummaryData>()
    const {column, groups} = assetActivityList
    const apiRef = useGridApiRef()

    const axisTitles = {
        yAxis: 'Index',
        xAxis: 'Score Date',
    };
    
    useEffect(() => {
      setLoading(true)
      getNewRiskScoresWithAssets()
        .then((res: any) => {
          setResponseData(res)
        })
        .finally(() => {
          setLoading(false)
        })
    }, [])

    useEffect(() => {
      setSummaryData(responseData?.summary)
    }, [responseData])

    useEffect(() => {
      const filteredRiskData = responseData?.riskScores.filter((item: any) =>
        localStorage.getItem('eldProviderRisk')?.includes(item?.eldProvider)
      )
      setRiskData(filteredRiskData)
    }, [summaryData])

    useEffect(() => {
      const graphData = getPerformanceData(riskData)
      setFleetPerformanceData(graphData)
    }, [riskData])

    useEffect(() => {
      const assetsData = responseData?.assetsDetails.filter((item: any) =>
        localStorage.getItem('eldProviderRisk')?.includes(item?.eldProviderName)
      )
      if (assetsData?.length > 0) {
        const assetRiskScore = [...assetsData].sort(
          (a: any, b: any) => b.updated - a.updated
        )
        const uniqueAssets = new Set()
        const filteredAssets = assetRiskScore.filter((item: any) => {
          if (!uniqueAssets.has(item.assetEldId)) {
            uniqueAssets.add(item.assetEldId)
            return true
          }
          return false
        })
        setRiskAssetData(filteredAssets)
      }
    }, [fleetPerformanceData])

    const loadPagination = (pageNumber: number, pageSize: number) => {
        console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
    }

    function formatDate(inputDateString: string) {
        const inputDate = new Date(inputDateString);        
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        
        return `${month}-${day}`;
    }

    const getPerformanceData = (riskData: any) => {
        let summaryArray: any = []
        let harshIndexArray: any = []
        let geoIndexArray: any = []
        let speedIndexArray: any = []
        forEach(riskData?.reverse(), (item: any) => {
            const rowDate = formatDate(item?.executionDate)
            summaryArray.push({
                'x': rowDate,
                'y': item?.fleetScore ?? 0
            })
            harshIndexArray.push({
                'x': rowDate,
                'y': item?.harsh.index ?? 0
            })
            geoIndexArray.push({
                'x': rowDate,
                'y': item?.geographical.index ?? 0
            })
            speedIndexArray.push({
                'x': rowDate,
                'y': item?.speed.index ?? 0
            })
        })
        
        const summaryData = {
            'id': 'Summary',
            'label': 'Summary',
            'color': '#8CC0E8',
            'data': summaryArray
        }
        const harshIndexData = {
            'id': 'Harsh Index',
            'label': 'Harsh Index',
            'color': '#F9706B',
            'data': harshIndexArray
        }
        const speedIndexData = {
            'id': 'Speeding Index',
            'label': 'Speeding Index',
            'color': '#FAE178',
            'data': speedIndexArray
        }
        const geoIndexData = {
            'id': 'Geo Index',
            'label': 'Geo Index',
            'color': '#89EBB0',
            'data': geoIndexArray
        }

        const fleetData = []
        fleetData.push(summaryData)
        fleetData.push(harshIndexData)
        fleetData.push(geoIndexData)
        fleetData.push(speedIndexData)

        return fleetData
    }

    const handleRiskExport = () => {
        const csvString = apiRef.current.getDataAsCsv()
        const csvRows = csvString.split('\n')
        const modifiedCsv = csvRows.slice(1).join('\n')
        const blob = new Blob([modifiedCsv], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'AssetsDataExport.csv'
        a.click()
        URL.revokeObjectURL(url)
      }
  
      const tableMenuItems = [
        {
          label: 'Download as CSV',
          key: 'export',
          action: () => {
            handleRiskExport()
          },
        },
      ]

    const BladeContent = (_props: any) => {
        return (
          <Container className={styles.bladeBodyContainer}>
            <Container className={styles.bladeTabContainer}>
                
            </Container>
            <Container className={styles.documentsSection}>
              <TypoGraph variant="h6" content="Documents" />
              <Divider />
              
            </Container>
          </Container>
        )
    }

    const HeaderDom = () => {
        return (
            <Container className={styles.headerContainer}>
                <Container className={styles.headerTitleContainer}>
                    <TypoGraph
                        variant="h1"
                        sx={{ marginRight: '1rem' }}
                        mb={0}
                        content={localStorage.getItem('carrierName') ?? 'Carrier Details'}
                    />
                    <Chip
                        label={`Updated ${moment().format('dddd, MMMM Do')}`}
                        variant="filled"
                        sx={{
                            backgroundColor: '#E1FAEB',
                            color: '#20693D',
                        }}
                    />
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.insuranceBladeBodyContainer}>
            <Container>
                <HeaderBar dom={<HeaderDom />} />
                <TypoGraph
                    variant="h2"
                    sx={{ marginRight: '1rem', marginTop: '-15px', marginBottom: '1rem' }}
                    mb={0}
                    content={localStorage.getItem('eldProviderRisk') ?? ''}
                />
                {/* Summary Header */}
                <Container className={styles.summaryMainContainer}>
                    <Container className={styles.summaryHeaderContainer}>
                        {(loading && <Spinner />) || (
                        <Container className={styles.summaryList}>
                            {/* Summary */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_carriers" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                    <TypoGraph
                                        variant="subtitle2"
                                        color={colors.white}
                                        content="Summary"
                                    />
                                    <Container className={styles.summaryDataContainer}>
                                        <TypoGraph
                                        color={colors.white}
                                        content={summaryData?.riskIndex.toFixed(2)}
                                        />
                                    </Container>
                                </Container>
                            </Container>
                            {/* Harsh Index */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_harsh" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Harsh Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <TypoGraph
                                    color={colors.white}
                                    content={summaryData?.harshEventsIndex.toFixed(2)}
                                    />
                                </Container>
                                </Container>
                            </Container>
                            {/* Geo Index */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_geo" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Geo Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <TypoGraph
                                    color={colors.white}
                                    content={summaryData?.geographicIndex.toFixed(2)}
                                    />
                                </Container>
                                </Container>
                            </Container>
                            {/* Speed Index */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_speed" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Speed Index"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <TypoGraph
                                    color={colors.white}
                                    content={summaryData?.speedIndex.toFixed(2)}
                                    />
                                </Container>
                                </Container>
                            </Container>
                            {/* Active Assets */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_assets" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Active Assets"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <TypoGraph
                                    color={colors.white}
                                    content={riskAssetData?.length.toString()}
                                    />
                                </Container>
                                </Container>
                            </Container>
                            {/* Total Assets */}
                            <Container className={styles.itemContainer}>
                                <Icon name="summary_assets" size={32} />
                                <Container className={styles.summaryContentContainer}>
                                <TypoGraph
                                    variant="subtitle2"
                                    color={colors.white}
                                    content="Total Assets"
                                />
                                <Container className={styles.summaryDataContainer}>
                                    <TypoGraph
                                    color={colors.white}
                                    content={riskAssetData?.length.toString()}
                                    />
                                </Container>
                                </Container>
                            </Container>
                        </Container>
                        )}
                    </Container>
                </Container>
                {/* Fleet Graph */}
                <Container className={styles.chartContainer} sx={{marginBottom: '1rem'}}>
                    <Card containerClass="flexAlignCenter">
                    {(loading && <Spinner />) || (
                        <>
                            <TypoGraph content="Fleet Performance" />
                            <MultiLineChart data={fleetPerformanceData} axisTitles={axisTitles} />
                        </>
                    )}
                    </Card>
                </Container>
                {/* Asset Activity & Scoring */}
                <DataGridTable
                    apiRef={apiRef}
                    title={'Asset Activity & Scoring'}
                    loading={loading}
                    data={riskAssetData || []}
                    columns={column}
                    columnGroupingModel={groups}
                    columnHeaderHeight={30}
                    columnVisibilityModel={{assetId: false}}
                    totalCount={riskAssetData?.length}
                    reduceHeight={260}
                    checkboxSelection={false}
                    multiSearchFilter={false}
                    getRowId={(row: any) => row?.assetId}
                    handlePagination={(pageNumber, pageSize) =>
                        loadPagination(pageNumber, pageSize)
                    }
                    menuList={tableMenuItems}
                />
                <Blade
                    title={selectedRow?.assetEldId}
                    open={showAssetDetails}
                    onClose={() => setShowAssetDetails(false)}
                    bodyNode={<BladeContent />}
                />
            </Container>
        </Container>
    )
}

export default InsuranceCarriers