import request from 'tools/request'

export const getMyCarrierList = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers`,
    {
      params: {
        status: 'ONBOARDED',
        'carrierName:fuzzy': '',
      },
    }
  )
  return res?.data
}

export const getInvitedCarrierList = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers`,
    {
      params: {
        status: 'INVITED, EXPIRED, VERIFICATION_FAILED, REINVITED',
      },
    }
  )
  return res?.data
}

/**
 * carrierName:fuzzy:
 *
 * others
 * codeType (DOT, MC, UUID, SCAC)
 * codeValue (val)
 */
export const getCarrierSearchList = async (params: any) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/search`,
    {
      params: params,
    }
  )
  return res?.data
}

/**
 *
 * @param payLoad {"carrierInfo":{"carrierName":"ABC CARRIER"},"contactInfo":{"email":"sadam@r1vs.com","firstName":"ss","lastName":"s"}}
 * @param codeType DOT
 * @param codeValue 9292
 * @returns
 */
export const inviteCarrier = async (
  payLoad: any,
  codeType: string,
  codeValue: string
) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/invite?codeType=${codeType}&codeValue=${codeValue}`,
    payLoad
  )
  return res?.data
}

export const getAgentMyCarrierList = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/assigned/agent`,
  )
  return res?.data
}

export const editCarrier = async (payLoad: any, codeType: string, codeValue: string) => {
  const res = await request.put(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers?codeType=${codeType}&codeValue=${codeValue}`, 
    payLoad
  )
  return res?.data
}