import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Route from 'route-parser'
import { useBreadcrumbOptions } from './breadcrumbOptions'
import renderBreadcrumbs from 'constants/breadCrumb'
import { ObjectAnyKeys } from 'interfaces'
import { TypoGraph, Container } from 'components'
import styles from './breadcrumb.module.scss'
import colors from 'theme/colors'

interface IBreadcrumbProps {
  path: string
}

/** **** Get Path Tokens *****
 *
 * Returns path segments (tokens)
 * @param {String} pathname Full pathname to split
 */
const getPathTokens = (pathname: string) => {
  const paths = ['/']

  if (pathname === '/') {
    return paths
  }

  pathname.split('/').reduce((prev: string, current: string) => {
    const currentPath = `${prev}/${current}`
    paths.push(currentPath)

    return currentPath
  })

  return paths
}

/** **** Get Route Match *****
 *
 * Returns matched route
 * @param {Object} routes Object with valid routes
 * @param {String} path Path to match
 */
const getRouteMatch = (routes: ObjectAnyKeys | null, path: string) => {
  return (
    routes &&
    Object.keys(routes)
      .map((key) => {
        const params = new Route(key).match(path)

        return {
          didMatch: params !== false,
          params,
          key,
        }
      })
      .filter((item) => item.didMatch)[0]
  )
}

/** **** Get Breadcrumbs *****
 *
 * Returns array of breadcrumbs
 * @param {Object} routes Object with valid routes
 * @param {Undefined} match Current match (not used)
 * @param {String} location Current path
 */
const getBreadcrumbs = (routes: ObjectAnyKeys | null, location: any) => {
  const pathTokens = getPathTokens(location.pathname)

  return pathTokens.map((path) => {
    const routeMatch = getRouteMatch(routes, path)

    if (!routeMatch) {
      return { name: '', path: '/' }
    }

    const routeValue = routes && routes[routeMatch.key]
    const name = routeValue

    return { name, path }
  })
}

/** **** Breadcrumbs element *****
 *
 * Renders Breadcrumbs
 * @param {Object} routes Object with valid routes
 * @param {Undefined} match Current match (not used)
 * @param {String} location Current path
 */
const BreadcrumbsModule: React.FC<IBreadcrumbProps> = (props) => {
  const { path } = props
  const location = useLocation()
  const navigate = useNavigate()
  const { lastSectionName } = useBreadcrumbOptions()
  const routes = renderBreadcrumbs(path)
  const breadcrumbs = getBreadcrumbs(routes, location).filter(
    (breadcrumb) => breadcrumb.name
  )

  if (breadcrumbs?.length === 1) return <></>

  return (
    <Container className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, i) => {
        const isBeforeLast = i + 1 < breadcrumbs.length

        return (
          <React.Fragment key={`key-${i}`}>
            {isBeforeLast ? (
              <>
                <TypoGraph
                  onClick={() => navigate(breadcrumb.path)}
                  color={colors.grey}
                  variant="subtitle2"
                  content={breadcrumb.name}
                  link
                />
                <TypoGraph color={colors.grey} sx={{marginTop: '-2px'}} content=">" />
              </>
            ) : (
              <TypoGraph
                color={colors.primary}
                variant="subtitle2"
                content={lastSectionName || breadcrumb.name}
              ></TypoGraph>
            )}
          </React.Fragment>
        )
      })}
    </Container>
  )
}

export default BreadcrumbsModule
