import React, { useState, useEffect, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { getEmailAccountsList, addEmailAccount, updateEmailAccount } from 'api'
import {
  Container,
  HeaderBar,
  DataGridTable,
  Blade,
  Toast as toast,
} from 'components'
import { InputFormField } from 'components/hookForm'
import { emailAccountList } from 'constants/tableData'
import { ObjectAnyKeys } from 'interfaces/Ihelper'
import styles from './emailAccounts.module.scss'
import strings from 'strings'

const t = strings.EMAIL_ACCOUNTS

const toastType: ObjectAnyKeys = {
  reject: 'error',
  hold: 'warning',
  deposit: 'success',
}

interface IEmailTemplateListProps {}

interface IBladeContentProps {}

const EmailAccountsList: React.FunctionComponent<IEmailTemplateListProps> =
  () => {
    const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
    const [isEditForm, setIsEditForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formLoader, setFormLoader] = useState(false)
    const [data, setData] = useState<any>()

    const { emailAccountColumn, actionList } = emailAccountList

    const methods = useForm<any>({
      reValidateMode: 'onChange',
    })

    const { handleSubmit, reset } = methods

    const callEmailAccountList = useCallback(() => {
      setLoading(true)
      getEmailAccountsList()
        .then((res: any) => {
          setData(res)
        })
        .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
      callEmailAccountList()
    }, [callEmailAccountList])

    const handleActions = (_id: number | string, actionName: string) => {
      if (actionName === 'edit') {
        const templateFormData = data.filter(
          (item: any) => item.carrierEmailAccountSettingId === _id
        )
        setIsEditForm(true)
        reset(templateFormData[0])
        setShowTemplateForm(true)
      } else {
        toast({
            id:"emailAccountsToastGeneral",
          type: toastType[actionName],
          title: actionName,
          subTitle: 'Updated Successfully',
        })
      }
    }

    const handleCheckboxSelection = (selections: any) => {
      console.log('checkbox', selections)
    }

    const handleSelectionActions = (
      actionName: string,
      selectionModel: any
    ) => {
      toast({
          id:"emailAccountsToastUpdateMany",
        type: toastType[actionName],
        title: actionName,
        subTitle: `${selectionModel?.length} rows updated`,
      })
    }

    const loadPayments = (pageNumber: number, pageSize: number) => {
      console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
    }

    const formSuccessState = () => {
      setFormLoader(false)
      setShowTemplateForm(false)
      callEmailAccountList()
    }

    const onSubmit = (data: any) => {
      setFormLoader(true)
      if (!isEditForm) {
        addEmailAccount(data).finally(() => {
          formSuccessState()
          toast({
              id:"emailAccountsToastSave",
            title: 'Created Successfully',
            subTitle: 'Email Account Created Successfully',
          })
        })
      } else {
        const payLoad = {
          id: data?.carrierEmailAccountSettingId,
          senderName: data?.senderName,
          senderEmail: data?.senderEmail,
        }
        updateEmailAccount(payLoad).finally(() => {
          formSuccessState()
          toast({
              id:'emailAccountsToastUpdate',
            title: 'Updated Successfully',
            subTitle: 'Email Account Updated Successfully',
          })
        })
      }
    }

    const BladeContent = (_props: IBladeContentProps) => {
      return (
        <Container className={styles.bladeBodyContainer}>
          <FormProvider {...methods}>
            <InputFormField
                id={"senderName"}
              name="senderName"
              label={t.SENDER_NAME}
              maxLength={128}
              required
            />
            <InputFormField
                id="senderEmail"
              name="senderEmail"
              label={t.SENDER_EMAIL}
              maxLength={128}
              required
            />
          </FormProvider>
        </Container>
      )
    }

    return (
      <Container className={styles.emailAccountsContainer}>
        <HeaderBar
          title={t.EMAIL_ACCOUNTS}
          primaryBtnProps={{
            label: t.NEW_ACCOUNT,
            onClick: () => {
              reset({})
              setIsEditForm(false)
              setShowTemplateForm(true)
            },
          }}
        />
        <DataGridTable
          title={t.EMAIL_ACCOUNTS}
          data={data || []}
          columns={emailAccountColumn}
          columnVisibilityModel={{carrierEmailAccountSettingId: false}}
          checkboxSelection={false}
          totalCount={data?.length}
          reduceHeight={240}
          actionList={actionList}
          loading={loading}
          multiSearchFilter={false}
          getRowId={(row: any) => row?.carrierEmailAccountSettingId}
          handleActions={handleActions}
          handleCheckboxSelection={handleCheckboxSelection}
          handleSelectionActions={handleSelectionActions}
          handlePagination={(pageNumber, pageSize) =>
            loadPayments(pageNumber, pageSize)
          }
        />
        <Blade
          open={showTemplateForm}
          title={isEditForm ? t.EDIT_ACCOUNT : t.CREATE_ACCOUNT}
          onClose={() => setShowTemplateForm(false)}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
                id:"createOrUpdateEmailAccountBlade",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit((data) => onSubmit(data)),
              loading: formLoader,
            },
            secondary: {
                id:"closeCreateOrUpdateEmailAccountBlade",
              label: strings.CLOSE,
              variant: 'outlined',
              onClick: () => setShowTemplateForm(false),
            },
          }}
        />
      </Container>
    )
  }

export default EmailAccountsList
