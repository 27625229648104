import { isNumber } from "lodash"
import { isAgentLogin } from "./auth"
import { Toast as toast} from "components"

const getCarrierParamsData = (formData: any) => {
  const paramData: any = {}

  for (const key in formData) {
    if (formData[key] !== undefined && key !== 'carrierName:fuzzy') {
      paramData['codeType'] = key?.toUpperCase()
      paramData['codeValue'] = formData[key]
    } else if (formData[key] !== undefined && key === 'carrierName:fuzzy') {
      paramData[key] = formData[key]
    }
  }

  return paramData
}

const getChildCarrierParamsData = (formData: any) => {
  const paramData: any = {}

  for (const key in formData) {
    if (formData[key] !== undefined ) {
      paramData[key] = formData[key]
    }
  }

  return paramData
}

const getCodeTypeValue = (listData: any) => {
  const paramData: any = {}
  listData.forEach((item: any) => {
    if (item.codeType === 'DOT') {
      paramData['codeType'] = item.codeType
      paramData['codeValue'] = item.codeValue
    } else if (item.codeType === 'UUID') {
      paramData['codeType'] = item.codeType
      paramData['codeValue'] = item.codeValue
    }
  })
  return paramData
}

// Function to check if all keys are present and have non-empty values
function areKeysValid(obj: any, keys: any[]) {
  for (const key of keys) {
    if (!(key in obj) || !obj[key] || obj[key].includes('�')) {
      return false
    }
  }
  return true
}

const getPercentageDiff = (currentValue: number, previousValue: number, totalValue: number): number => {
  if (totalValue === 0) return 0

  const previousPercentage = (previousValue / totalValue) * 100
  const currentPercentage = (currentValue / totalValue) * 100
  const diff = currentPercentage - previousPercentage

  return diff
}

const generateVariationTooltip = (currentValue: any, previousValue: any, totalValue: number, withPercentages: boolean): string => {
  if ((currentValue === undefined && previousValue === undefined)
    || (currentValue === null && previousValue === null) 
    || (isNaN(currentValue) && isNaN(previousValue))) {
    return 'No data'
  }
  if (currentValue === undefined || currentValue === null || isNaN(currentValue)) {
    return 'No data'
  }
  if (previousValue === undefined || previousValue === null || isNaN(previousValue)) {
    if (withPercentages === true && !isNaN(currentValue))
      return `${currentValue.toFixed(2)} (100%)`
    else
      return `${currentValue.toFixed(2)}`
  }

  if (withPercentages === true && !isNaN(totalValue) && !isNaN(previousValue) && !isNaN(currentValue)) {
    const previousPercentage = (previousValue / totalValue) * 100
    const currentPercentage = (currentValue / totalValue) * 100

    if (!isNaN(previousPercentage) && !isNaN(currentPercentage)) {
      return `${previousValue.toFixed(2)} (${previousPercentage.toFixed(2)}%) -> ${currentValue.toFixed(2)} (${currentPercentage.toFixed(2)}%)`
    } else {
      return `${previousValue.toFixed(2)} -> ${currentValue.toFixed(2)}`
    }
  }
  else {
    return `${previousValue.toFixed(2)} -> ${currentValue.toFixed(2)}`
  }
}

const formatRiskCell = (value: any, decimals?: number) => {
  const formatValue = (num: number, decimals?: number) => {
    if (isNaN(num)) return '-';
    const formatted = decimals ? Math.abs(num).toFixed(decimals) : Math.trunc(Math.abs(num));
    return formatted?.toLocaleString() || '-'
  }
  const isEmptyString = (str: any): boolean => typeof str === 'string' && str.trim() === ''

  if (isAgentLogin()) {
    if (isNumber(value) || isNumber(parseFloat(value))) {
      return formatValue(value, decimals)
    }
    if (value === null || value === undefined || isEmptyString(value)) {
      return '-'
    }
    return String(value)
  }
  else {
    return formatValue(value, decimals)
  }
}

const ratingColor = (value: any) => {
  if (typeof value !== 'string') {
    return '';
  }
  switch (value?.toLocaleLowerCase()) {
    case 'poor':
      return '#EA1C04';
    case 'average':
      return '#4276C9';
    case 'great':
      return '#34864E';
    default:
      return '';
  }
};

const copyToClipboard = (value: string, message?: string) => {
  navigator.clipboard.writeText(value);
  toast({
    id: value,
    type: "info",
    title: `Copied to clipboard`,
    subTitle: message ? message : value,
    autoClose: 2000
  })
}

export { 
  getCarrierParamsData, 
  getChildCarrierParamsData, 
  getCodeTypeValue, 
  areKeysValid, 
  getPercentageDiff, 
  generateVariationTooltip,
  formatRiskCell,
  ratingColor,
  copyToClipboard
}
