import Container from "components/layout";
import TypoGraph from "components/typograph";

interface ColumnDescriptionWithoutTitleProps {
    description: string;
    cell?: boolean;
}

export const ColumnDescription = (props: any) => {
    const { title, description, cell } = props
    return (
      <Container
        className="columnDescriptionContainer"
        sx={
          cell && {
            gridTemplateRows: '0.5rem 0.5rem',
          }
        }
      >
        <strong>{title}</strong>
        <TypoGraph
          variant="subtitle1"
          content={description}
          className="descriptionText"
        />
      </Container>
    )
  }

export const ColumnDescriptionWithoutTitle = ({ description, cell }: ColumnDescriptionWithoutTitleProps) => {
    return (
        <Container
            className="columnDescriptionContainer withoutTitle"
            sx={cell ? { gridTemplateRows: '0.5rem 0.5rem' } : undefined}
        >
            <TypoGraph
                variant="subtitle1"
                content={description}
                className="descriptionText"
                sx={{ paddingBottom: '1rem!important', marginLeft: 'auto!important', marginRight: 'auto!important' }}
            />
        </Container>
    );
};