const setupLoginCred = (data: any) => {
  if (!data) return
  localStorage.setItem('authToken', data?.AuthToken)
  localStorage.setItem('userName', data?.UserName)
  localStorage.setItem('userLogin', data?.UserLogin)
  localStorage.setItem('userType', data?.UserType)
  localStorage.setItem('userId', data?.UserId)
  localStorage.setItem('companyProfile', JSON.stringify(data?.Company_Profile))
  localStorage.setItem('companyId', data?.CompanyId)
  localStorage.setItem('companyName', data?.Company_Name)
  localStorage.setItem('companyTypeId', data?.CompanyTypeId)
  localStorage.setItem('userTypeId', data?.UserTypeId)
  localStorage.setItem('isInsurance', data?.Is_Insurance)
  localStorage.setItem('accessPrivileges', (data?.accessPrivileges ? JSON.stringify(data?.accessPrivileges) : JSON.stringify([])))
  if(data?.embeddedDashboardIds!== undefined){
    localStorage.setItem('embeddedDashboardIds', JSON.stringify(data?.embeddedDashboardIds))
  }
  localStorage.setItem('tagCategories', data?.tagCategories ? JSON.stringify(data?.tagCategories) : JSON.stringify([]))
}

const isCarrierLogin = () => {
  if (localStorage.getItem('companyTypeId') === '3') {
    return true
  }
  return false
}

const isAgentLogin = () => {
  if (localStorage.getItem('companyTypeId') === '10') {
    return true
  }
  return false
}

const isInsuranceLogin = () => {
  if (localStorage.getItem('companyTypeId') === '7') {
    return true
  }
  return false
}

export { setupLoginCred, isCarrierLogin, isAgentLogin, isInsuranceLogin }