import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import moment from "moment";

const agentsListColumnsDefinition = (onHandleCarriersForAgent: (domain: string, agentId: string, status: string) => void) => [
    {
        field: 'agentId',
        headerName: 'Agent ID',
        minWidth: 50,
        flex: 0,
        sortable: false,
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        minWidth: 100,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        minWidth: 100,
        flex: 0.8,
        sortable: true
    },
    {
        field: 'email',
        headerName: 'E-mail',
        minWidth: 150,
        flex: 1.2,
        sortable: true,
    },
    {
        field: 'agencyDomain',
        headerName: 'Agency',
        minWidth: 80,
        flex: 0.8,
        sortable: false,
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        minWidth: 90,
        flex: 0.9,
        sortable: true,
        renderCell: (params: GridRenderCellParams) => {
            return moment(params.value).format('MMM DD, YYYY HH:mm:ss')
        },
    },
    {
        field: 'availableMotorCarriers',
        headerName: 'Available Carriers',
        minWidth: 150,
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Button
                    sx={{borderRadius: 25}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onHandleCarriersForAgent(params?.row?.agencyDomain, params?.row?.agentId, 'unassigned')}
                >
                    Assign Carriers
                </Button>
            )
        },
    },
    {
        field: 'assignedMotorCarriers',
        headerName: 'Assigned Carriers',
        minWidth: 150,
        flex: 0.8,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Button
                    sx={{borderRadius: 25}}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => onHandleCarriersForAgent(params?.row?.agencyDomain, params?.row?.agentId, 'assigned')}
                >
                    Manage Carriers
                </Button>
            )
        },
    },
];

const availableCarriersColumnsDefinition = [
    {
        field: 'carrierId',
        headerName: 'Carrier ID',
        minWidth: 50,
        flex: 0,
        sortable: false,
    },
    {
        field: 'carrierName',
        headerName: 'Carrier Name',
        minWidth: 80,
        flex: 1.2,
        sortable: true,
    },
    {
        field: 'dotNumber',
        headerName: 'DOT',
        minWidth: 80,
        flex: 0.8,
        sortable: true
    },
    {
        field: 'mcNumber',
        headerName: 'MC',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'scacCode',
        headerName: 'SCAC',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'uuidCode',
        headerName: 'UUID',
        minWidth: 80,
        flex: 0.8,
        sortable: true,
    },
    {
        field: 'carrierUniqueId',
        headerName: 'CUID',
        minWidth: 80,
        flex: 0.8,
        sortable: true,

    },
];

const actionList = [
    {
        name: 'Edit',
        icon: 'icon_edit',
        iconSize: 40,
    },
    {
        name: 'Delete',
        icon: 'icon_delete',
        iconSize: 40,
    },
];

const agentsListTableColumns = (onHandleCarriersForAgent: (domain: string, agentId: string, status: string) => void) => ({
    columns: agentsListColumnsDefinition(onHandleCarriersForAgent).map(({ field, headerName, minWidth, flex, sortable, renderCell }) => ({
        field,
        headerName,
        minWidth,
        flex,
        sortable,
        renderCell,
    }))
});

const carrierListTableColumns = {
    columns: availableCarriersColumnsDefinition.map(({ field, headerName, minWidth, flex, sortable }) => ({
        field,
        headerName,
        minWidth,
        flex,
        sortable,
    }))
};

export {
    agentsListTableColumns,
    carrierListTableColumns,
    actionList
}