import request from 'tools/request'

/**
 *
 * @param payLoad {"carrierInfo":{"carrierName":"ABC CARRIER"},"contactInfo":{"email":"sadam@r1vs.com","firstName":"ss","lastName":"s"}}
 * @returns
 */
export const inviteAccount = async (
    payLoad: any
  ) => {
    const res = await request.post(
      `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/invite`,
      payLoad
    )
    return res?.data
  }

export const getConnectedAccounts = async () => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/accounts`,
    {
      params: {
        status: 'ONBOARDED',
      },
    }
  )
  return res?.data
}

export const offboardAccount = async (carrierId: string, eldVendor: string) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL_V2}carriers/offboard?codeType=TCID&codeValue=${carrierId}&eldVendor=${eldVendor}`
  )
  return res?.data
}